import axios from "@/services/axios";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({
    isCompression: false
});

export default {
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],

    namespaced: true,

    state: {
        lojas: Array,
        pessoa: Object,
        tokenEmpresa: String,
        sessionIdNetCred: String,
        fezAcordoPorPortal: Boolean,
        botaoConsultarParcelamentosClicado: Boolean
    },

    mutations: {
        SET_LOJAS(estado, lojas) {
            estado.lojas = lojas;
        },
        SET_PESSOA(estado, pessoa) {
            estado.pessoa = pessoa;
        },
        SET_TOKEN_EMPRESA(estado, tokenEmpresa) {
            estado.tokenEmpresa = tokenEmpresa;
        },
        SET_SESSION_ID_NET_NETCRED(estado, sessionIdNetCred) {
            estado.sessionIdNetCred = sessionIdNetCred;
        },
        SET_FEZ_ACORDO_POR_PORTAL(estado, fezAcordoPorPortal) {
            estado.fezAcordoPorPortal = fezAcordoPorPortal;
        },
        SET_BOTAO_CONSULTAR_PARCELAMENTOS_CLICADO(estado, botaoConsultarParcelamentosClicado) {
            estado.botaoConsultarParcelamentosClicado = botaoConsultarParcelamentosClicado;
        }
    },

    getters: {
        getPesCpf(estado) {
            return estado.pessoa.pesCpfCnpj;
        },

        getFezAcordoPorPortal(estado) {
            return estado.fezAcordoPorPortal;
        }
    },

    actions: {
        async acaoPasso1ConsultarCpf(contexto, dados) {
            const integra_senff = contexto.rootState.parceiroConfig.identificador == 'negociasenff' ? 'S' : 'N';

            return await axios({
                method: "POST",

                url: "api.php",

                params: {
                    a: "negociacao_robo",
                    b: "passo_1",
                    token: contexto.state.tokenEmpresa,
                },

                data: {
                    pes_cpf_cnpj: dados.pesCpfCnpj,
                    origem_requisicao: 'S',
                    integra_senff,
                    identificador: contexto.rootState.parceiroConfig.identificador,
                }

            }).then(resposta => {
                let dados;
                if (resposta.data.dados) {
                    dados = resposta.data.dados;

                } else {
                    return resposta;
                }

                contexto.commit("SET_PESSOA", {
                    pesCodigo: dados.pes_codigo,
                    pesNome: dados.pes_nome,
                    pesCpfCnpj: dados.pes_cpf_cnpj,
                    pesEmail: dados.pes_email,
                    pefFone: dados.pef_fone
                });

                let lojas = dados.lojas;
                if (
                    contexto.rootState.parceiroConfig.loj_codigo_nao_negocia != null &&
                    contexto.rootState.parceiroConfig.loj_codigo_nao_negocia != undefined
                ) {
                    if (contexto.rootState.parceiroConfig.loj_codigo_nao_negocia.includes(',')) {
                        const lojasConfig = contexto.rootState.parceiroConfig.loj_codigo_nao_negocia.split(',');
                        lojas.forEach((loja, posicao) => {
                            if (lojasConfig.includes((String)(loja.loj_codigo))) {
                                lojas.splice(posicao, 1);
                            }
                        });
                    } else {
                        lojas = dados.lojas.filter(loja => loja.loj_codigo != contexto.rootState.parceiroConfig.loj_codigo_nao_negocia);
                    }
                }

                if (contexto.rootState.parceiroConfig.loj_codigo != null && contexto.rootState.parceiroConfig.loj_codigo != undefined) {
                    if (contexto.rootState.parceiroConfig.loj_codigo.includes(',')) {
                        lojas = dados.lojas.filter(loja => contexto.rootState.parceiroConfig.loj_codigo.includes(loja.loj_codigo));
                    } else {
                        lojas = dados.lojas.filter(loja => loja.loj_codigo == contexto.rootState.parceiroConfig.loj_codigo);
                    }
                }
                contexto.commit("SET_LOJAS", lojas);

                return resposta;
            });
        },

        async acaoPasso2ConsultarDividas(contexto, dados) {
            return await axios({
                method: "POST",

                url: "api.php",

                params: {
                    a: "negociacao_robo",
                    b: "passo_2_novo",
                    token: contexto.state.tokenEmpresa
                },

                data: {
                    pes_codigo: contexto.state.pessoa.pesCodigo,
                    loj_codigo: dados.lojCodigo,
                    lancamento_futuro: dados.lancamentoFuturo,
                    origem_requisicao: 'S',
                }

            }).then(resposta => {
                return resposta.data;
            });
        },

        async acaoPasso2ConsultarDividasStage(contexto, dados) {
            return await axios({
                method: "POST",

                url: "http://stage.aquicob.com.br/api/cobransaas/v2/calcular",

                params: {
                    token: contexto.state.tokenEmpresa
                },

                data: {
                    pes_codigo: contexto.state.pessoa.pesCodigo,
                    loj_codigo: dados.lojCodigo,
                    fator_desconto: 0,
                    calcularDescontoParcelamento: true,
                    meio_pagamento_padrao: "BOLETO",
                    parcelamentos: null
                }
            }).then(resposta => {
                return resposta.data;
            });
        },

        async acaoPasso3ConsultarNegociacoes(contexto, dados) {
            return await axios({
                method: "POST",

                url: "api.php",

                params: {
                    a: "negociacao_robo",
                    b: "passo_3_novo",
                    token: contexto.state.tokenEmpresa
                },

                data: {
                    pes_codigo: contexto.state.pessoa.pesCodigo,
                    loj_codigo: dados.lojCodigo,
                    pre_codigo: dados.preCodigo,
                    primeiro_vencimento: dados.primeiroVencimento,
                    pagamento_avista_prazo: dados.pagamentoAvistaPrazo,
                    metodo_pagamento: dados.metodoPagamento,
                    bandeira_cartao: dados.bandeiraCartao,
                    forma_cartao: dados.formaCartao,
                    origem_requisicao: 'S',
                }

            }).then(resposta => {
                if (resposta.data) {
                    return resposta.data;
                }
            });
        },

        async acaoPasso3ConsultarNegociacoesStage(contexto, dados) {
            return await axios({
                method: "POST",

                url: "http://stage.aquicob.com.br/api/cobransaas/v2/calcular",

                params: {
                    token: contexto.state.tokenEmpresa
                },

                data: {
                    pes_codigo: contexto.state.pessoa.pesCodigo,
                    loj_codigo: dados.lojCodigo,
                    pre_codigo: dados.preCodigo,
                    fator_desconto: 0,
                    dataVencimento: dados.primeiroVencimento,
                    meio_pagamento_padrao: "BOLETO",
                    parcelamentos: [],
                    calcularDescontoParcelamento: true
                }
            }).then(resposta => {
                return resposta.data;
            });
        },

        async acaoPasso4GravarAcordo(contexto, dados) {
            return await axios({
                method: "POST",

                url: "api.php",

                params: {
                    a: "negociacao_robo",
                    b: "passo_4_novo",
                    token: contexto.state.tokenEmpresa
                },

                data: {
                    pra_codigo: dados.praCodigo,
                    forma_pagamento: dados.formaDePagamento,
                    sessao_id_net_cred: dados.sessaoIdNetCred,
                    dados_do_cartao: dados.dadosDoCartao,
                    primeiro_vencimento: dados.primeiroVencimento,
                    gerar_boleto: 1,
                    origem_requisicao: 'S',
                    pes_codigo: dados.pes_codigo,
                }

            }).then(resposta => {
                if (resposta.data) {
                    contexto.commit('SET_FEZ_ACORDO_POR_PORTAL', true);
                    return resposta.data;
                }
            });
        },

        async acaoPasso4GravarAcordoStage(contexto, dados) {
            return await axios({
                method: "POST",

                url: "http://stage.aquicob.com.br/api/cobransaas/v2/acordo",

                params: {
                    token: contexto.state.tokenEmpresa
                },

                data: {
                    pes_codigo: contexto.state.pessoa.pesCodigo,
                    loj_codigo: dados.lojCodigo,
                    usu_codigo: 1,
                    dataVencimento: dados.primeiroVencimento,
                    meio_pagamento_padrao: "BOLETO",
                    emp_codigo: contexto.rootState.emp_codigo,
                    pra_codigo: dados.praCodigo
                }
            }).then(resposta => {
                return resposta.data;
            });
        },

        async acaoPasso5RetornarAcordo(contexto, dados) {
            let data = {
                pes_codigo: contexto.state.pessoa.pesCodigo,
                loj_codigo: dados.lojCodigo,
                origem_requisicao: 'S',
            };

            if (dados.itensPorPagina && dados.paginaAtual && dados.pagina) {
                data = {
                    ...data,
                    itens_por_pagina: dados.itensPorPagina,
                    pagina_atual: dados.paginaAtual,
                    pagina: dados.pagina
                };
            }

            return await axios({
                method: "POST",

                url: "api.php",

                params: {
                    a: "negociacao_robo",
                    b: "passo_5",
                    token: contexto.state.tokenEmpresa
                },

                data

            }).then(resposta => {
                if (resposta.data) {
                    return resposta.data;
                }
            });
        },

        async acaoRetornarTokenEmpresa(contexto) {
            return await axios({
                method: "POST",

                url: "api.php",

                params: {
                    a: "portal",
                    b: "retornarTokenEmpresa"
                },

                data: {
                    emp_codigo: contexto.rootState.parceiroConfig.emp_codigo,
                    token: contexto.rootState.globalToken
                }

            }).then(resposta => {

                if (resposta.data.dados !== null) {
                    contexto.commit("SET_TOKEN_EMPRESA", resposta.data.dados);
                }

                return resposta;
            });
        }
    }
}