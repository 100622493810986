<template>
	<ValidationProvider :class="`col-${cols}`" class="col" tag="div" :name="label" :rules="rules" v-slot="{ errors }">
		<label>
			{{ label }}
		</label>
		<textarea
			@input="$emit('update:value', text)"
			@keyup.enter="$emit('onEnter', text)"
			:id="variableName"
			v-model="text" />
		<span class="text-error" v-if="errors[0]">
			{{ errors[0] }}
		</span>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
	name: "TextAreaField",
	components: {
		ValidationProvider
	},
	props: ["rules", "cols", "label", "value", "solidColor", "valueField", "variableName"],
	data() {
		return {
			text: ""
		};
	},
	mounted() {
		if (this.valueField) {
			this.text = this.valueField;
			this.$emit("update:value", this.text);
		}
	},
	watch: {
		valueField() {
			this.text = this.valueField;
			this.$emit("update:value", this.text);
		}
	},
	computed: {
		hasValue() {
			return !!this.text;
		}
	}
};
</script>

<style scoped>
label {
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}

label.label-default {
	color: var(--blackColor);
}

label.label-red {
	color: var(--dangerColor);
}

label.solid-color {
	color: var(--whiteColor);
}

textarea {
	margin: 5px 0;
	height: 100px;
	background: var(--whiteColor);
	border: 1px solid var(--blackColor) !important;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 10px;
	border: 1px solid #878785 !important;
	resize: none;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
}

textarea.solid-color {
	background: var(--whiteColor);
}

textarea.border-default,
textarea.has-value {
	border: 0;
}

textarea.border-red {
	border: thin solid var(--dangerColor);
}

span.text-error {
	color: var(--dangerColor);
	font-size: 12px;
	font-family: "Roboto";
}
</style>
