<template>
	<div class="containerSMSSender">

		<div class="content-page">

			<h3>Informe o telefone celular pelo qual você deseja receber os seus boletos:</h3>
			<div class="proposal_text">
				<text-field-mask
					:maskFormat="'(##) #####-####'"
					:valueField="telefone"
					:variableName="'telefone'"
					:value.sync="telefone"
					:type="'phone'" ></text-field-mask>
			</div>

			<button-default
				:color="corPrimaria"
				@click.native="send"
				text="Enviar boleto por SMS" ></button-default>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods.vue";
import mixins from "@/mixins/mixins";

import TextFieldMask from "@/components/inputs/TextFieldMask";
import ButtonDefault from "@/components/buttons/ButtonDefault";

export default {
	name: "SMSSender",

	components: { TextFieldMask, ButtonDefault },

	mixins: [globalMethods, mixins],

	props: {
		acordo: {
			type: Object,
			required: true
		},

		paymentsSelected: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			telefone: ""
		};
	},

	computed: {
		...mapState({
			pessoa: (estado) => estado.passosRetornarDividas.pessoa
		}),
	
		lojaNome() {
			if (this.parceiro === "senff") {
				return this.acordo.detalhes &&
					this.acordo.detalhes[0].observacao_contrato &&
					this.acordo.detalhes[0].observacao_contrato <= 10
					? this.acordo.detalhes[0].observacao_contrato
					: "Senff";
			}
			return this.acordo.loj_razao_social;
		}
	},

	methods: {
		...mapActions(["action_enviar_boletos_portal_sms"]),

		send() {
			if (!this.removeMaskCharacters(this.telefone) || this.removeMaskCharacters(this.telefone).length != 11) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um celular válido`,
					styleMessage: "danger"
				});
			}

			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Enviando por SMS");

			this.acaoSalvarEtapa({
				cpfCnpj: this.pessoa.pesCpfCnpj,
				esn_fk_ets_codigo: 38,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: "Enviou o(s) boleto(s) por SMS",
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual
			});

			let aciCodigoParcelas = []
			this.paymentsSelected.forEach(parcela => {
				aciCodigoParcelas.push(parcela.aci_codigo);
			});

			aciCodigoParcelas = aciCodigoParcelas.join(',');

			this.action_enviar_boletos_portal_sms({
				cpfCnpj: this.pessoa.pesCpfCnpj,
				loj_codigo: this.acordo.loja.loj_codigo,
				emp_codigo: this.parceiroConfig.emp_codigo,
				fone: this.removeMaskCharacters(this.telefone),
				aci_codigo: aciCodigoParcelas

			}).then((response) => {
				if (response.data.retorno) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: response.data.mensagem ?? `SMS enviado com sucesso. Aguarde alguns segundos caso não tenha chego no seu celular.`,
						styleMessage: "success"
					});

				} else {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: response.data.mensagem ?? `Erro ao enviar o SMS.`,
						styleMessage: "danger"
					});
					return -1;
				}
			}).catch((e) => {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Um erro grave aconteceu ao enviar o SMS.`,
					styleMessage: "danger"
				});
				return -1;
			});
			this.$emit('close');
			this.SET_LOADING_MESSAGE("");
			this.SET_LOADING(false);
		}
	}
};
</script>

<style lang="scss" scoped>
.iconBoxBack {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: "Roboto Black";
	color: var(--primaryColor);
	text-transform: uppercase;
	cursor: pointer;
}
.iconBoxBack i {
	margin-right: 10px;
	font-size: 22px;
}

h3 {
	margin-top: 20px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	background: #f5f6fa;
	padding: 10px 0;
}

.content-page h3 {
	margin-bottom: 20px;
}
.proposal_text {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
}
.containerSMSSender {
	width: 100%;
	max-width: 1024px;
	box-sizing: border-box;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-bottom: 40px;
}
</style>
