<template>
	<div class="container-talktous">
		<div class="header-talktous">

			<i 
				class="fas fa-times"
				:style="corEstilo"
				@click="$router.push({ name: 'AccordionTabs' })" ></i>
		</div>
		<h3 :style="corEstilo">fale conosco</h3>
		<p class="sub-message">Escolha um canal para nos chamar</p>
		<div class="row-buttons">

			<outline-button 
				v-if="parceiroConfig.sessoes.omniChannel.campos.chatOmni != undefined"
				:text="'Chat'"
				:icon="'fas fa-comments fa-3x'"
				:color="parceiroConfig.estilo.corPrimaria"
				:colorHover="'none'"
				:url="parceiroConfig.sessoes.omniChannel.campos.chatOmni"
				@click.native="salvarEtapa('chat', 42)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.emailOmni != undefined"
				:text="'E-mail'"
				:icon="'fas fa-envelope-open-text fa-3x'"
				:color="'black'"
				:colorHover="'none'"
				:url="'mailto:' + parceiroConfig.sessoes.omniChannel.campos.emailOmni"
				@click.native="salvarEtapa('email', 43)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.instagramOmni != undefined"
				:text="'Instagram'"
				:icon="'fab fa-instagram fa-3x'"
				:color="'purple'"
				:colorHover="'linear-gradient(45deg,#833ab4,#c13584,#e1306c,#fd1d1d, orange, yellow)'"
				:url="parceiroConfig.sessoes.omniChannel.campos.instagramOmni"
				@click.native="salvarEtapa('instagram', 17)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.linkedinOmni != undefined"
				:text="'LinkedIn'"
				:icon="'fab fa-linkedin fa-3x'"
				:color="'#0a66c2'"
				:colorHover="'none'"
				:url="parceiroConfig.sessoes.omniChannel.campos.linkedinOmni"
				@click.native="salvarEtapa('linkedin', 18)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.messengerOmni != undefined"
				:text="'Messenger'"
				:icon="'fab fa-facebook-messenger fa-3x'"
				:color="'#2444d6'"
				:colorHover="'none'"
				:url="'http://m.me/' + parceiroConfig.sessoes.omniChannel.campos.messengerOmni"
				@click.native="salvarEtapa('messenger', 19)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.telegramOmni != undefined"
				:text="'Telegram'"
				:icon="'fab fa-telegram fa-3x'"
				:color="'#0088cc'"
				:colorHover="'none'"
				:url="'https://t.me/' + parceiroConfig.sessoes.omniChannel.campos.telegramOmni"
				@click.native="salvarEtapa('telegram', 21)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.skypeOmni != undefined"
				:text="'Skype'"
				:icon="'fab fa-skype fa-3x'"
				:color="'#00aff0'"
				:colorHover="'none'"
				:url="'skype:' + parceiroConfig.sessoes.omniChannel.campos.skypeOmni + '?chat'"
				@click.native="salvarEtapa('skype', 22)"></outline-button>

			<outline-button
				v-if="parceiroConfig.sessoes.omniChannel.campos.whatsappOmni != undefined"
				:text="'Whatsapp'"
				:icon="'fab fa-whatsapp fa-3x'"
				:color="'#4caf50'"
				:colorHover="'none'"
				:url="linkWhatsapp"
				@click.native="salvarEtapa('whatsapp', 23)"></outline-button>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import OutlineButton from "./components/OutlineButton";

export default {
	name: "TalkToUs",

	components: { OutlineButton },

	mixins: [globalMethods, mixins],

	computed: {
		...mapState({
			pessoa: (estado) => estado.passosRetornarDividas.pessoa
		}),

		corEstilo() {
			return { color: this.parceiroConfigCorSecundariaCorPrimaria };
		},

		linkWhatsapp() {
			return `'https://api.whatsapp.com/send?phone=55${this.removeMaskCharacters(this.parceiroConfig.sessoes.omniChannel.campos.whatsappOmni)}&text=Olá estou vindo do portal ${this.parceiro.toUpperCase()}`;
		}
	},

	mounted() {
		this.SET_LOADING(true);
		this.SET_LOADING_MESSAGE("Carregando meios de comunicação");
		this.SET_LOADING_MESSAGE("");
		this.SET_LOADING(false);
	},

	methods: {
		salvarEtapa(metodo, esn_fk_ets_codigo = 15) {
			this.acaoSalvarEtapa({
				cpfCnpj: this.pessoa.pes_cpf_cnpj,
				esn_fk_ets_codigo,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: `Entrou em contato por ${metodo.toUpperCase()}`,
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual
			});
		}
	}
};
</script>

<style>
.container-talktous {
	padding: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #f3f5f9;
	flex: 1;
	box-sizing: border-box;
}

h3 {
	color: #031b4e;
	font-family: Montserrat-Bold;
	font-size: 28px;
	text-transform: uppercase;
}

.header-talktous {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	cursor: pointer;
}

.header-talktous i {
	font-size: 48px;
	color: #031b4e;
}

.sub-message {
	color: #5b6987;
	font-size: 14px;
	margin: 10px 0 10px 0;
}
.row-buttons {
	display: flex;
	margin-top: 100px;
	flex-wrap: wrap;
	justify-content: center;
}
</style>
