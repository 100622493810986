<template>
	<div :class="{ full: full }" class="col">
		<button :style="corPrimaria">
			{{ text }}
		</button>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "ButtonLg",
	props: ["text", "full", "color", "outline"],
	computed: {
		...mapState({
			corPrimaria: (state) => state.corPrimaria
		})
	}
};
</script>

<style scoped>
.col {
	display: flex;
	flex-direction: column;
	margin: 12px 6px;
	position: relative;
}

.col.full {
	width: 100% !important;
}

button {
	padding: 0 60px;
	height: 50px;
	border-radius: 25px;
	background-color: var(--color);
	color: var(--whiteColor);
	border: 0;
	font-size: 16px;
	font-family: Cabin;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

button:hover {
	background-color: var(--hoverColor);
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}
</style>
