<template>
	<template-post
		title="Atualizar dúvida"
		@close="$router.back()"
		@post="completeRegister">

		<div>
			<form-fields
				:fields="fields"
				@save="completeRegister"
				@update:values="inputsValues = $event" ></form-fields>

		</div>
	</template-post>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

import TemplatePost from "@/components/TemplatePost";
import FormFields from "@/components/FormFields";

export default {
	name: "UpdateQuestions",

	components: { FormFields, TemplatePost },

	data() {
		return {
			fields: [],
			inputsValues: {}
		}
	},

	mounted() {
		this.SET_LOADING(true)

		if (this.$route.params.codigo) {
			this.action_buscar_duvidas({ codigo: this.$route.params.codigo }).then((resposta) => {

				if (resposta) {
					let duvida = resposta.data.dados.duvidas[0]

					this.fields = [
						{
							variableName: "tituloDuvida",
							fieldType: "TextField",
							label: "Titulo da dúvida",
							type: "text",
							cols: "12",
							valueField: duvida?.titulo
						},
						{
							variableName: "textoDuvida",
							fieldType: "TextAreaField",
							label: "Texto dúvida",
							type: "text",
							cols: "12",
							valueField: duvida?.descricao
						}
					]
					this.SET_LOADING(false)

				} else {
					this.SET_LOADING(false)
					this.$router.push({ name: "ListQuestions" })
				}
			});

		} else {
			this.SET_LOADING(false)
			this.$router.push({ name: "ListQuestions" })
		}
	},

	methods: {
		...mapMutations(["SET_LOADING", "CHANGE_MESSAGE_SNACKBAR", "CHANGE_MESSAGE_MODAL"]),

		...mapActions(["action_buscar_duvidas", "action_editar_duvida"]),

		async completeRegister() {
			let { tituloDuvida, textoDuvida } = this.inputsValues

			if (!tituloDuvida || tituloDuvida.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo título da dúvida é obrigatório`,
					styleMessage: "danger"
				})

			} else if (!textoDuvida || textoDuvida.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo texto da dúvida é obrigatório`,
					styleMessage: "danger"
				})

			} else {
				this.SET_LOADING(true)
				let obj = {
					codigo: this.$route.params.codigo,
					titulo: tituloDuvida,
					descricao: textoDuvida
				}

				this.action_editar_duvida(obj).then((resposta) => {
						this.SET_LOADING(false)

						if (resposta) {
							this.$router.push({ name: "ListQuestions" })
							this.CHANGE_MESSAGE_SNACKBAR("Dúvida editada com sucesso!")
							setTimeout(() => this.CHANGE_MESSAGE_SNACKBAR(""), 5000)

						} else {
							this.SET_LOADING(false)
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Ocorreu um erro",
								message: `Não foi possivel editar esta dúvida`,
								styleMessage: "danger"
							})
						}
					}).catch((_) => {
						this.SET_LOADING(false)
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Ocorreu um erro",
							message: `Não foi possivel cadastrar esta dúvida`,
							styleMessage: "danger"
						})
					})
			}
		}
	}
}
</script>

<style scoped>
</style>
