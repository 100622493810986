var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.secondaryColor)?_c('button',{class:{
			secondaryColor: _vm.parceiroConfigCorSecundariaCorSecundaria,
			'tab-left': _vm.tabBorder === 'tab-left',
			'tab-right': _vm.tabBorder === 'tab-right',
			disabled: _vm.disable,
			actived: _vm.active
		},style:(_vm.corSecundaria),attrs:{"disabled":_vm.disable,"type":"button"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_c('button',{class:{
			secondaryColor: _vm.parceiroConfigCorSecundariaCorSecundaria,
			'tab-left': _vm.tabBorder === 'tab-left',
			'tab-right': _vm.tabBorder === 'tab-right',
			disabled: _vm.disable,
			actived: _vm.active
		},style:(_vm.corPrimaria),attrs:{"disabled":_vm.disable,"type":"button"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }