<template>
	<div :class="`col-${cols}`" class="col">
		<label>
			{{ label }}
		</label>
		<div class="boxzin-img">
			<input @change="uploadImage" type="file" name="photo" accept="image/*" />
			<!--            Nenhuma imagem selecionada-->
		</div>
		<div class="row-images">
			<div v-for="img in arrayImages" :key="img.identificador" class="mini-preview-img">
				<i @click="removeImage(img)" class="fas fa-times" style="font-size: 35px; cursor: pointer; color: #595959; top: 5px; right: 5px; position: absolute" />
				<img :src="img.arquivo" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MultipleImagesField",
	props: ["label", "cols", "valueField"],
	data() {
		return {
			imageSrc: null,
			arrayImages: []
		};
	},
	mounted() {
		if (this.valueField) {
			this.valueField.forEach((img) => {
				let vm = this;
				function toDataURL(url, callback) {
					var xhr = new XMLHttpRequest();
					xhr.onload = function() {
						var reader = new FileReader();
						reader.onloadend = function() {
							callback(reader.result);
						};
						reader.readAsDataURL(xhr.response);
					};
					xhr.open("GET", url);
					xhr.responseType = "blob";
					xhr.send();
				}

				toDataURL(img.arquivo, function(dataUrl) {
					vm.arrayImages.push({
						arquivo: dataUrl,
						identificador: vm.arrayImages.length + 1,
						tipoImagem: "png"
					});
					vm.$emit("update:value", vm.arrayImages);
				});
			});
		}
	},
	methods: {
		removeImage(img) {
			let index = this.arrayImages.indexOf(img);
			if (index > -1) {
				this.arrayImages.splice(index, 1);
			}
			this.$emit("update:value", this.arrayImages);
			this.imageSrc = null;
		},
		uploadImage(event) {
			var reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = () => {
				this.imageSrc = event.target.files[0];
				this.arrayImages.push({
					identificador: this.arrayImages.length + 1,
					arquivo: reader.result,
					tipoImagem: this.imageSrc.type.replace("image/", "")
				});
				this.$emit("update:value", this.arrayImages);
				this.imageSrc = null;
			};
		}
	}
};
</script>

<style scoped>
label {
	display: block;
	color: var(--blackColor);
	font-size: 14px;
	font-family: "Roboto";
}

.boxzin-img {
	cursor: pointer;
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0;
	font-size: 14px;
	font-family: "Roboto Bold";
	border: 1px solid #ffffff !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.row-images {
	display: flex;
	align-items: center;
	padding: 15px;
	flex-wrap: wrap;
	justify-content: center;
}
.mini-preview-img {
	width: 150px;
	height: 150px;
	background: #ffffff;
	margin: 15px;
	object-fit: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.mini-preview-img img {
	width: 140px;
	height: 140px;
	object-fit: cover;
}
</style>
