<template>
	<p class="p">{{ message }}</p>
</template>

<script>
	export default {
		name: "LoadingMessage",
		props: ["message"],
	};
</script>
<style>
	.p {
		color: white;
		font-size: 20px;
		font-weight: bold;
		padding-top: 20px;
	}
</style>
