<template>
	<div :class="`col-${cols}`" class="col">
		<label>
			{{ label }}
		</label>
		<input
			@change="uploadFile"
			:id="variableName"
			type="file"
			name="uploadFile"
			accept="application/pdf,application"
			:required="required" />
	</div>
</template>

<script>
export default {
	name: "FileField",
	props: [
		"label",
		"cols",
		"valueField",
		"required",
		"disabled",
		"withButton",
		"buttonColor",
		"borderSuccess",
		"borderDanger",
		"maskMoney",
		"maskFormat",
		"solidColor",
		"variableName"
	],
	data() {
		return {
			fileSrc: null,
			base64: null
		};
	},
	mounted() {},
	methods: {
		removeFile() {
			this.base64 = null;
			this.fileSrc = null;
			this.$emit("update:value", null);
		},
		uploadFile(event) {
			var reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = () => {
				this.fileSrc = event.target.files[0];
				this.base64 = reader.result;
				this.$emit("update:value", {
					base64: reader.result,
					name: this.fileSrc.name,
					format: this.fileSrc.type.replace("File/", "")
				});
			};
		}
	}
};
</script>

<style scoped>
label {
	display: block;
	color: var(--blackColor);
	font-size: 14px;
	font-family: "Roboto";
}

.boxzin-img {
	cursor: pointer;
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0;
	font-size: 14px;
	font-family: "Roboto Bold";
	border: 1px solid #ffffff !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

img {
	width: 100%;
	margin-top: 5px;
}
</style>
