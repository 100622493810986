<template>
    <header>
        <div>
            <i
                class="icon-menu fas fa-bars"
                @click="showMobileMenu = !showMobileMenu" ></i>

            <div
                v-if="showMobileMenu"
                class="menu-container"
                @mouseleave="showMobileMenu = false" >

                <Menu class="mobile-menu" ></Menu>
            </div>
        </div>

        <div class="header-box">
            <div>
                <h5>Administrador</h5>
                <h6></h6>
            </div>

            <div class="avatar" ></div>
        </div>
    </header>
</template>

<script>
import Menu from "@/views/admin/Menu";

export default {
    name: "Header",

    components: {Menu},

    data() {
        return{
            showMobileMenu:false
        }
    }
}
</script>

<style scoped>
    header{
        display: flex;
        justify-content: flex-end;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    .header-box{
        display: flex;
        align-items: center;
        text-align: right;
    }

    .header-box h5{
        font-family: "Roboto Bold";
        font-size: 14px;
        color: var(--blackColor);
    }

    .header-box h6{
        font-family: "Roboto Light";
        font-size: 12px;
        color: var(--grayColor);
    }

    .header-box .avatar{
        width: 48px;
        height: 48px;
        background: var(--blackColor);
        border-radius: 100%;
        margin-left: 10px;
    }

    .icon-menu{
        font-size: 20px;
        color: var(--blackColor);
        margin-left: 15px;
        z-index: 0;
        margin-top: 5px;
    }

    .icon-menu:hover{
        cursor: pointer;
        color: var(--primaryColor);
    }

    .menu-container{
        position: fixed;
        top:0;
        left: 0;
        z-index: 300;
    }

    .mobile-menu{
        z-index: 999 !important;
    }

    @media only screen and (min-width: 900px){
        .icon-menu, .icon-menu:hover{
            color: transparent;
            cursor: auto;
        }

    }

</style>
