<template>
	<div class="col">
		<label @click="valueToggle = !valueToggle" :style="corSecundaria" :class="valueToggle ? 'toggle-box-active' : 'toggle-box'">{{ label }}</label>
	</div>
</template>

<script>
import { mapState } from "vuex"
export default {
	name: "ToggleToken",
	props: {
		cols: {
			default: 12
		},
		label: {
			default: "Label"
		},
		valueField: {
			default: false
		}
	},
	data() {
		return {
			valueToggle: {}
		}
	},
	mounted() {
		this.valueToggle = this.valueField
	},
	methods: {
		selectOption(option) {
			this.showOptions = false
			this.text = option
		}
	},
	computed: {
		...mapState({
			corSecundaria: state => state.corSecundaria
		})
	},
	watch: {
		options() {
			this.selectOption(this.options[0] || {})
		},
		valueToggle() {
			this.$emit("update:value", this.valueToggle)
		}
	}
}
</script>

<style scoped>
.col {
	display: flex;
	flex-direction: column;
	margin: 12px 6px;
	position: relative;
}

label {
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}

.toggle-box {
	border: 3px solid var(--color);
	border-radius: 25px;
	padding: 15px;
	background-color: var(--whiteColor);
	text-align: center;
	color: var(--color);
	transition: all 0.2s ease-in-out;
}

.toggle-box-active {
	border-radius: 25px;
	padding: 15px;
	background-color: var(--color);
	text-align: center;
	color: var(--whiteColor);
	transition: all 0.2s ease-in-out;
}
</style>
