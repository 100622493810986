<template>
	<div class="privacy-policy-body">
		<p class="bold">Política de Privacidade</p>

		<p class="bold">1 &ndash; Introdução</p>

		<p>Versão: 1.1</p>
		<p>Data: 01/01/2022</p>

		<p class="email-para-contato">A {{ nomeParceiro }} realiza o monitoramento contínuo da sua Política de Privacidade, revisando-a, no mínimo, a cada 6 (seis) meses. Esta versão inicial reflete apenas o tratamento dos dados pessoais realizado a partir do seu site. Em breve, buscaremos fortalecê-la e ampliá-la. Em caso de dúvidas, pedimos a gentileza de entrar em contato com nosso setor de Tratamento de Dados Pessoais (DPO), por meio do e-mail </p>
		<p>Aqui, a transparência é sinônimo de respeito, <span class="bold">e este vem sempre em primeiro lugar!</span></p>

		<p class="bold">2 &ndash; Declaração de Privacidade</p>
		<p>A {{ nomeParceiro }} trata com seriedade, confidencialidade e integridade todos os dados pessoais que se encontrem sob a sua responsabilidade. Nós cuidamos não apenas de tecnologia, mas também do sigilo das informações de nossos clientes, colaboradores, prestadores de serviço e fornecedores. É por essa razão que somos reconhecidos como uma das melhores fábricas de softwares do Paraná!</p>

		<p class="bold">3 &ndash; Quais tipos de dados pessoais coletamos?</p>
		<p>A {{ nomeParceiro }} coleta o mínimo necessário de dados pessoais para desempenhar as suas atividades. A partir do nosso site, na seção “Fale Conosco” coletamos os seguintes dados pessoais:</p>

		<p>Os dados coletados têm a finalidade de promover o bom atendimento às suas expectativas, portanto servem para dar sequência em nosso relacionamento. Estes dados têm como base legal o <span class="bold">CONSENTIMENTO</span>, por isso o solicitamos quando do preenchimento do respectivo formulário.</p>

		<p class="bold">4 &ndash; Por quanto tempo armazenamos os dados pessoais?</p>
		<p>Os dados pessoais armazenados pela {{ nomeParceiro }} permanecem retidos por um prazo que poderá variar de acordo com a finalidade do tratamento dos dados pessoais.</p>
		<p>Por respeitarmos o seu direito à privacidade de seus dados pessoais, a cada 12 meses, revisamos a nossa base de dados para sabermos se as informações registradas ainda se fazem necessárias ao alcance da finalidade inicialmente pretendida.</p>
		<p>Após esse prazo, eliminamos os dados pessoais da nossa base de dados, a não ser que, justificadamente, possam ser anonimizados, sem prejuízo das regulamentações que tratam acerca do tema, em especial, no que diz respeito ao cumprimento de obrigação legal ou regulatória pelo controlador.</p>

		<p class="bold">5 &ndash; Quais cookies utilizamos em nosso site?</p>
		<p>Um cookie é um pequeno arquivo de texto utilizado pelo nosso site, quando visitado por um usuário, com o objetivo de permitir um funcionamento adequado da plataforma, obter informações mais precisas e otimizar nossas campanhas de marketing e publicidade.</p>
		<p>A {{ nomeParceiro }} utiliza os seguintes tipos de cookies:</p>

		<p><span class="bold">Cookies necessários.</span> Eles permitem um funcionamento adequado do nosso site, não coletando ou armazenando informações sobre você ou em relação às suas preferências.</p>

		<p><span class="bold">Cookies de publicidade/marketing.</span> Eles são utilizados para se criar um perfil dos visitantes e de suas preferências.</p>
		<p>Caso você queira saber como excluí-los ou restringi-los, use a configuração do seu navegador.</p>
		<p>Para desativar os cookies em seu navegador, siga as instruções normalmente localizadas nos menus “Ajuda”, “Ferramentas” ou “Editar” no seu navegador. Observe que a desativação de uma das categorias de cookies não exclui, automaticamente, os cookies armazenados previamente em seu navegador, devendo ser realizada de forma manual pelo usuário.</p>
		<p>Você encontrará mais explicações sobre como proceder clicando nos links abaixo.</p>
		<p>• Firefox <a href="https://www.mozilla.org/pt-BR/firefox/new/">https://www.mozilla.org/pt-BR/firefox/new/</a></p>
		<p>• Chrome <a href="https://www.google.com/intl/pt-BR/chrome/">https://www.google.com/intl/pt-BR/chrome/</a></p>
		<p>• Safari <a href="https://www.apple.com/br/safari/">https://www.apple.com/br/safari/</a></p>
		<p>• Internet Explorer <a href="https://support.microsoft.com/pt-br/windows/downloads-do-internet-explorer-d49e1f0d-571c-9a7b-d97e-be248806ca70">https://support.microsoft.com/pt-br/windows/downloads-do-internet-explorer-d49e1f0d-571c-9a7b-d97e-be248806ca70</a></p>
		<p>Lembre-se de que se você bloquear cookies estritamente necessários em nosso site, não poderemos garantir o correto funcionamento de todas as funcionalidades e talvez você não consiga acessar determinadas áreas e/ou páginas.</p>

		<p class="bold">6 &ndash; Direitos das Titulares</p>
		<p class="email-para-contato">A Titular de Dados Pessoais poderá entrar em contato conosco para solicitar o cumprimento dos direitos previstos pela Lei Geral de Proteção de Dados (LGPD). Para tanto, basta acessar o canal “Seus Direitos” disponivel na aba “Sua Privacidade” em nosso site bem como nos enviar a sua solicitação por meio do seguinte canal de contato </p>

		<p>Destacamos que são os direitos previstos pela legislação indicada (em especial, art. 18 da LGPD):</p>

		<p style="margin-left: 30px">1. Confirmação da existência do tratamento;</p>
		<p style="margin-left: 30px">2. Acesso aos dados;</p>
		<p style="margin-left: 30px">3. Correção de dados incompletos, inexatos ou desatualizados;</p>
		<p style="margin-left: 30px">4. Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei Geral de Proteção de Dados;</p>
		<p style="margin-left: 30px">5. Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</p>
		<p style="margin-left: 30px">6. Eliminação dos dados pessoais tratados com o consentimento dA titular, exceto nas hipóteses de cumprimento de obrigação legal ou regulatória pelo controlador e estudo por órgão de pesquisa, garantindo a anonimização dos dados pessoais sempre que possível;</p>
		<p style="margin-left: 30px">7. Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na Lei Geral de Proteção de Dados ou uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados;</p>
		<p style="margin-left: 30px">8. Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</p>
		<p style="margin-left: 30px">9. Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e</p>
		<p style="margin-left: 30px">10. Revogação do consentimento.</p>

		<p class="bold">7 &ndash; Dúvidas</p>

		<p class="email-para-contato">Para mais informações, pedimos a gentileza de entrar em contato com nosso Encarregado pelo Tratamento de Dados Pessoais (DPO), por meio do e-mail </p>

		<p v-if="parceiroConfig.identificador === 'aqui'">Ponta Grossa, 01 de janeiro de 2022</p>
	</div>
</template>

<script>
import mixins from "@/mixins/mixins";

export default {
	name: "PrivacyPolicy",

	mixins: [mixins],

	computed: {
		emailParceiro() {
			return this.parceiroConfig.identificador === "aqui" ? "contato@negociaronline.com.br" : "dpo@aquicob.com.br";
		},

		linkEmailParceiro() {
			return `<a href="mailto:${this.emailParceiro}" class="bold">${this.emailParceiro}</a>`;
		}
	},

	mounted() {
		Object.values(document.getElementsByClassName("email-para-contato")).forEach(paragrafo => {
						
			paragrafo.insertAdjacentHTML("beforeend", this.linkEmailParceiro);

		});
	}
};
</script>

<style >
.privacy-policy-body {
	padding: 50px;
	line-height: 1.5;
	text-align: justify;
	margin: 0px 70px;
	border: 2px solid #e6e6e6;
}

.privacy-policy-body p,
.privacy-policy-body span,
.privacy-policy-body a {
	font-size: 16px;
	margin-bottom: 10px;
	font-family: "Arial", sans-serif;
}
.bold {
	font-weight: bold;
}

@media only screen and (max-width: 990px) {
	.privacy-policy-body {
		padding: 20px;
		line-height: 1.25;
		margin: 0px 20px;
	}
}
</style>