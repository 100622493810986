<template>
    <div class="box-forms-regiter">

        <form-fields
            :fields="fields"
            @save="completeRegister"
            @update:values="inputsValues = $event" ></form-fields>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";
import StepperHeader from "@/components/StepperHeader";

export default {
    name: "LoginData",

    mixins: [globalMethods, mixins],

    components: { StepperHeader, ButtonLg, FormFields, ButtonDefault},

    props: ['loginData'],

    data() {
        return {
        fields: [],
        inputsValues: {},
        }
    },

    computed: {
        ...mapState({
            showMessageModal: (estado) => estado.messageModal.show
        })
    },

    created() {
        this.$root.$refs.LoginData = this;
        // if(!this.$route.params?.identification){
        //   this.parceiro
        //       ? this.$router.go(-1)
        //       : this.$router.push({name: 'LandPage'})
        // }
    },

    mounted() {
        if (Object.keys(this.loginData).length === 0) {
            this.fields = [
                {
                    variableName: 'razaoSocial',
                    fieldType: 'TextField',
                    label: 'Razão Social',
                    type: 'text',
                    cols: '6',
                },
                {
                    variableName: 'nomeContato',
                    fieldType: 'TextField',
                    label: 'Nome para contato',
                    type: 'text',
                    cols: '6',
                },
                {
                    variableName: 'usuario',
                    fieldType: 'TextField',
                    label: 'Usuário',
                    type: 'text',
                    cols: '12',
                },
                {
                    variableName: 'senha',
                    fieldType: 'TextField',
                    label: 'Senha',
                    type: 'password',
                    cols: '12',
                },
                {
                    variableName: 'email',
                    fieldType: 'TextField',
                    label: 'E-mail',
                    type: 'text',
                    cols: '12',
                }
            ]
        } else{
            this.fields = [
                {
                    variableName: 'razaoSocial',
                    fieldType: 'TextField',
                    label: 'Razão Social',
                    type: 'text',
                    cols: '6',
                    valueField: this.loginData?.emp_nome
                },
                {
                    variableName: 'nomeContato',
                    fieldType: 'TextField',
                    label: 'Nome para contato',
                    type: 'text',
                    cols: '6',
                    valueField: this.loginData?.usu_nome
                },
                {
                    variableName: 'usuario',
                    fieldType: 'TextField',
                    label: 'Usuário',
                    type: 'text',
                    cols: '12',
                    valueField: this.loginData?.usu_login
                },
                {
                    variableName: 'senha',
                    fieldType: 'TextField',
                    label: 'Senha',
                    type: 'password',
                    cols: '12',
                    valueField: this.loginData?.usu_senha
                },
                {
                    variableName: 'email',
                    fieldType: 'TextField',
                    label: 'E-mail',
                    type: 'text',
                    cols: '12',
                    valueField: this.loginData?.usu_email
                }
            ]
        }
    },

    methods: {
        completeRegister() {
        const {
            razaoSocial,
            nomeContato,
            usuario,
            senha,
            email,
        } = this.inputsValues;

        let required = false;
        Object.entries(this.inputsValues).forEach(([key, value]) => {
            if (
                !this.showMessageModal
                && key !== 'website'
                && key !== 'telefoneFixo'
                && (
                    !value
                    || value === ""
                )
            ) {
                required = true;
                this.CHANGE_MESSAGE_MODAL({
                    show: true,
                    title: 'Atenção',
                    message: `O campo ${this.fields.filter(field => field.variableName === key)[0].label} é obrigatório`,
                    styleMessage: 'danger'
                });
            }
        });

        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;

        if (!required) {
            if (/.+@.+\..+/.test(email) === false) {
                this.CHANGE_MESSAGE_MODAL({
                    show: true,
                    title: 'Atenção',
                    message: `Digite um e-mail válido`,
                    styleMessage: 'danger'
                });

            } else if (!senha.match(lowerCaseLetters) || !senha.match(upperCaseLetters) || !senha.match(numbers) || senha.length < 6) {
                this.CHANGE_MESSAGE_MODAL({
                    show: true,
                    title: 'Atenção',
                    message: `Senha deve conter no mínimo 6 dígitos, letras minúsculas, maiúsculas e números.`,
                    styleMessage: 'danger'
                });

            } else {
                let obj = {
                    emp_nome_banco: razaoSocial.toLowerCase().replace(/\s/g, ''),
                    emp_nome: razaoSocial,
                    usu_nome: nomeContato,
                    usu_email: email,
                    emp_usuario_banco: usuario,
                    emp_senha_banco: senha,
                    usu_login: usuario,
                    usu_senha: senha,
                    emp_numero_usuarios: 1,
                    emp_cobranca_prospeccao: "cobranca"
                };

                this.$emit("update:returnLoginData", obj);
                return true;
            }
        }

            return false;

        },

        completaDados(loginData){
            this.fields[0].valueField = loginData.razao_social;
            this.fields[1].valueField = loginData.nome_contato;
            this.fields[4].valueField = loginData.email;
            this.$root.$refs.FormFields.$forceUpdate();
        }
    }
}
</script>

<style scoped lang="scss">
.box-register {
  min-height: 100vh;
  display: flex;
  flex: 1;
  padding: 50px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #F3F5F9;
}

.box-register h2 {
  font-family: Montserrat-Bold;
  color: var(--primaryColor);
  font-size: 32px;
  text-align: center;
  @media only screen and (max-width: 990px) {
    font-size: 28px
  }
}

.box-register h3 {
  font-family: Cabin;
  font-size: 18px;
  color: var(--grayColor);
  margin: 10px 0 30px 0;
  text-align: center;
}

.box-forms-regiter {
  width: 50vw;
  box-sizing: border-box;
  @media only screen and (max-width: 990px) {
    width: 90%;
  }

}

.box-button-register {
  width: 50vw;
  box-sizing: border-box;
  @media only screen and (max-width: 990px) {
    width: 90%;
  }
}
</style>
