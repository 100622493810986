<template>
	<div
		:title="title"
		class="small-button"
		:class="{ danger: color === 'danger', 'botao-whatsapp': this.botaoWhatsapp }"
		:style="color">

		<i :class="icon" />
	</div>
</template>

<script>
export default {
	name: "SmallButton",
	props: {
		icon: {
			type: String,
			default: "fas fa-phone"
		},
		title: {
			type: String,
			default: "Botão"
		},
		color: {
			type: Object,
			default: () => ({
				"--color": "#ffffff",
				"--hoverColor": "#00131e"
			})
		},

		botaoWhatsapp: {
			type: Boolean,
			required: false
		}
	}
};
</script>

<style scoped>
.small-button {
	width: 50px;
	height: 50px;
	background: var(--color);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
	transition: all 0.2s ease-in-out;
}

.small-button.danger {
	background: var(--color);
}

.small-button:hover,
.small-button:hover i {
	background: var(--hoverColor);
	cursor: pointer;
	color: var(--color);
	transition: all 0.2s ease-in-out;
}

.small-button i {
	color: var(--hoverColor);
	font-size: 25px;
}

.botao-whatsapp {
	background-color: #fff;
	color: #25d366;
	right: 20px;
	position: absolute;
}

.botao-whatsapp:hover {
	background-color: #25d366;
	color: #fff;
}

.botao-whatsapp i {
	color: #25d366;
}

.botao-whatsapp:hover i {
	background: #25d366;
	cursor: pointer;
	color: #fff;
	transition: all 0.2s ease-in-out;
}
</style>
