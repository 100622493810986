<template>
	<div class="container-template" >

		<div class="menu-aux" ></div>

		<div
			class="menu-debits"
			:style="fundoMenuDebitsEstilizado">
			<menu-debits ></menu-debits>
		</div>

		<div class="page-debit">
			<router-view ></router-view>
		</div>

	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import MenuDebits from "./MenuDebits";

export default {
	name: "TemplateDebts",

	components: { MenuDebits },

	computed: {
		...mapState({
			parceiroConfig: (estado) => estado.parceiroConfig
		}),

		fundoMenuDebitsEstilizado() {
			if (this.parceiroConfig.sessoes.customization.campos.menuDebits.corFundoMenuDebits != undefined && this.parceiroConfig.sessoes.customization.campos.menuDebits.corFundoMenuDebits != '') {
				return `background-color: ${this.parceiroConfig.sessoes.customization.campos.menuDebits.corFundoMenuDebits}`;
			} else {
				return 'background-color: #050b17';
			}
		}
	},

	created() {
		if (localStorage.getItem("empresas")) {
			this.SET_EMPRESAS(JSON.parse(localStorage.getItem("empresas")));
		}
	},

	methods: mapMutations(["SET_EMPRESAS"])
};
</script>

<style scoped lang="scss">
.container-template {
	box-sizing: border-box;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	@media only screen and (max-width: 1269px) {
		flex-direction: column;
		height: auto;
	}
}

.menu-debits {
	z-index: 9999;
	box-sizing: border-box;
	width: 30vw;
	background-size: cover;
	background-position: top center;
	position: fixed;
	@media only screen and (min-width: 1270px) {
		min-height: 100vh;
	}
	@media only screen and (max-width: 1269px) {
		width: 100%;
		height: 130px;
	}
}

.menu-aux {
	box-sizing: border-box;
	width: 30vw;
	height: 100vh;
	position: relative;
	@media only screen and (max-width: 1269px) {
		height: 130px;
	}
}

.page-debit {
	box-sizing: border-box;
	background: #f3f5f9;
	flex: 1;
}
</style>
