 <template>
	<div v-show="parcela">

		<div class="iconBoxBack" @click="$emit('backOption')"
			:style="{ color: parceiroConfig.corPrimaria }">
			<i class="fas fa-chevron-left" ></i>
			Voltar
		</div>

			<h3
				class="titleOption">
				CONFIRMAÇÃO DE VALORES PARA PAGAMENTO ({{planoDeParcelaFormatado}})
			</h3>

			<div class="infos-row" v-if="parcela">

				<div class="infos">
					<h6>Forma de pagamento</h6>
					<h2>{{ tipoPagamentoFormatado }}</h2>
				</div>

				<div class="infos">
					<h6>Parcelas</h6>
					<h2
						class="titleOption">
						{{planoDeParcelaFormatado}}
					</h2>
				</div>

				<div class="infos">
					<h6>Valor total</h6>
					<h2>R$ {{ valorTotalAcordo | formatarDinheiro }}</h2>
				</div>

			</div>
			<h3
				v-if="parcela && tipoPagamento == 'cartao' && parceiroConfig.identificador == 'mw'"
				class="titleOption">Dados do cartão de crédito</h3>
			<div
				v-if="parcela && tipoPagamento == 'cartao' && parceiroConfig.identificador == 'mw'"
				class="infos-row px-3" >

				<form-fields
					:fields="fieldsCard"
					@save="savePayment"
					@update:values="inputsValues = $event" ></form-fields>
			</div>

		<h3 class="titleOption">Confirmação do acordo</h3>

		<div class="infos">
			<h6 class="text-cofirm">
				{{ textoConfirmarAcordo }}
			</h6>

			<div
				class="checkTerm"
				@click="checkTerm = !checkTerm" >

				<i :class="checkTerm ? 'far fa-check-square' : 'far fa-square'" ></i>
				Eu concordo com as condições de pagamento
			</div>
		</div>

		<button-default
			:color="corPrimaria"
			@click.native="savePayment()"
			text="Confirmar acordo"
			:disable="!checkTerm"></button-default>
	</div>
</template>

<script>
import { mapState, mapMutations, createNamespacedHelpers } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import Template from "@/views/admin/Template";
import FormFields from "@/components/FormFields";

const { mapActions: mapActionsPassosRetornarDividas } = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "DetailsMonthlyPayment",

	components: { FormFields, Template, ButtonDefault },

	mixins: [globalMethods, mixins],

	props: {
		parcela: {
			type: Object,
			required: true
		},

		divida: {
			type: Object,
			required: true
		},

		lancamentosFuturos: {
			type: Object,
			required: true
		},

		tipoPagamento: {
			type: String,
			required: true
		},

		sessaoIdNetCred: {
			type: String,
			required: false
		},

		formaCartao: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			checkTerm: false,
			fieldsCard: [],
			inputsValues: {},
			dadosDoCartao: []
		};
	},

	computed: {
		...mapState({
			showMessageModal: (estado) => estado.messageModal.show,
			pesCodigo: (estado) => estado.passosRetornarDividas.pessoa.pesCodigo
		}),

		textoConfirmarAcordo() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.detailsMonthlyPayment &&
					this.parceiroConfig.sessoes.customization.campos.myDebits.detailsMonthlyPayment.textConfirmaAcordo ?
					this.parceiroConfig.sessoes.customization.campos.myDebits.detailsMonthlyPayment.textConfirmaAcordo :
					`Ao clicar em 'Confirmar Acordo' o cliente CONCORDA com os valores, datas e forma de pagamento apresentados
								bem como, com as regras de negociação do Credor.O Portal ${this.nomeParceiroContratos} envia diariamente informações de pagamento
								das parcelas ao Credor, para que o mesmo cumpra com as determinações legais junto aos órgãos de proteção ao
								crédito.`;
		},

		tipoPagamentoFormatado() {
			switch (this.tipoPagamento) {
				case 'cartao':
					return 'Cartão de crédito';
				case 'boleto':
					return this.parceiroConfig.identificador == 'mw' ? 'Boleto híbrido' : 'Boleto';
				case 'pix':
					return 'PIX';
			}
		},

		planoDeParcelaFormatado() {
			if (this.parcela.pra_quantidade == 0) {
				return `Entrada de R$ ${this.formatarDinheiro(this.parcela.pra_valor_entrada)}`;
			} else {
				return `Entrada de R$ ${this.formatarDinheiro(this.parcela.pra_valor_entrada)} + ${this.parcela.pra_quantidade} X de R$ ${this.formatarDinheiro(this.parcela.pra_valor_parcela)}`;
			}
		},

		valorTotalAcordo() {
			return this.parcela.pra_quantidade == 0 ? this.parcela.pra_valor_entrada : this.parcela.valor_total_acordo;
		}
	},

	mounted() {
		this.fieldsCard = [
			{
				variableName: "card_number",
				fieldType: "TextFieldMask",
				label: "Número do cartão",
				type: "text",
				cols: "7",
				maskFormat: "####.####.####.####",
				placeholder: "XXXX.XXXX.XXXX.XXXX"
			},
			{
				variableName: "valid_date",
				fieldType: "TextFieldMask",
				label: "Data de validade (mês/ano)",
				type: "text",
				cols: "5",
				maskFormat: "##/##",
				placeholder: "MM/AA"
			},
			{
				variableName: "cvv",
				fieldType: "TextFieldMask",
				label: "CVV (código de segurança)",
				type: "text",
				cols: "4",
				maskFormat: "###",
				placeholder: "XXX"
			},
			{
				variableName: "name",
				fieldType: "TextField",
				label: "Nome impresso no cartão",
				type: "text",
				cols: "8"
			}
		];
	},

	methods: {
		...mapActionsPassosRetornarDividas(["acaoPasso4GravarAcordo", "acaoPasso4GravarAcordoStage"]),
		...mapMutations(['SET_LOADING', 'SET_LOADING_MESSAGE']),

		savePayment() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Aguarde enquanto trabalhamos em sua requisição!");

			if (this.checkTerm) {
				this.acaoSalvarEtapa({
					pes_codigo: this.pesCodigo,
					esn_fk_ets_codigo: 28,
					emp_codigo: this.parceiroConfig.emp_codigo,
					motivo_agendamento: 'Gravou acordo',
					data_agendamento: this.dataAtual,
					hora_agendamento: this.horaAtual
				});

				if (this.tipoPagamento == 'cartao') {
					this.dadosDoCartao.push(this.inputsValues.card_number);
					this.dadosDoCartao.push(this.inputsValues.valid_date);
					this.dadosDoCartao.push(this.inputsValues.cvv);
					this.dadosDoCartao.push(this.inputsValues.name);
					this.dadosDoCartao.push(this.formaCartao);
				}

				if (this.parceiroConfig.sessoes.customization.campos.myDebits.flgPortalUsaStage) {
					this.acaoPasso4GravarAcordoStage({
						lojCodigo: this.divida.loja.loj_codigo,
						praCodigo: this.parcela.pra_codigo,
						primeiroVencimento: this.parcela.pra_primeiro_vcto
					}).then(resposta => {
						if (!resposta.success) {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Erro",
								message: resposta.message,
								styleMessage: "danger"
							});
							this.SET_LOADING(false);
							this.SET_LOADING_MESSAGE("");
						} else {
							this.SET_LOADING(false);
							this.SET_LOADING_MESSAGE("");
							setTimeout(() => {
								this.$router.push({name: "MyAgreements"});
							}, 2000);
						}
					}).catch(_ => {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Erro",
							message: "Não foi possível gerar o seu acordo",
							styleMessage: "danger"
						});
					});
				} else {
					let primeiroVencimento = this.parcela.pra_primeiro_vcto.split('-').reverse().join('/');

					this.acaoPasso4GravarAcordo({
						praCodigo: this.parcela.pra_codigo,
						formaDePagamento: this.tipoPagamento,
						sessaoIdNetCred: this.sessaoIdNetCred,
						dadosDoCartao: this.dadosDoCartao,
						primeiroVencimento,
						pes_codigo: this.pesCodigo,
					}).then(resposta => {
						this.SET_LOADING(false);
						this.SET_LOADING_MESSAGE('');
						if (resposta.retorno === true) {
							setTimeout(() => {
								let obj = {name: 'MyAgreements'};

								if (this.parceiroConfig.identificador == 'mw') {
									obj.query = {
										estadoDaTransacao: resposta.dados.parcelas[0]['estado_da_transacao'],
										formaDePagamento: this.tipoPagamento
									}
								}

								this.$router.push({
									...obj
								});
							}, 2000);
						} else {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Erro",
								message: resposta.mensagem,
								styleMessage: "danger"
							});
						}
					});
				}
				this.dadosDoCartao = [];
			}
		}
	}
};
</script>

<style scoped>
h3 {
	margin-top: 20px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	background: #f5f6fa;
	padding: 10px 0;
}

.iconBoxBack {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: "Roboto Black";
	color: var(--primaryColor);
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 15px;
}

.iconBoxBack i {
	margin-right: 10px;
	font-size: 22px;
}

.infos-row {
	margin: 20px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.infos {
	padding: 20px;
}

.infos h6 {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 11px;
	margin-bottom: 3px;
}

.infos h2 {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 18px;
}

.img-box {
	width: 110px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
}

.img-box img {
	object-fit: cover;
	width: 110px;
}

.checkTerm {
	color: #031b4e;
	margin: 20px 0 10px 0;
	font-size: 14px;
	font-family: "Roboto Bold";
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.checkTerm i {
	font-size: 20px;
	margin: 0 5px 0 0;
}

.px-3 {
	padding: 15px;
}

h6.text-cofirm {
	font-size: 14px;
	line-height: 20px;
	text-align: justify;
}

.row-tabs {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 0 0 0;
}

.tab {
	width: 100%;
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
	padding: 10px 0;
	border-bottom: 3px solid transparent;
	font-family: "Roboto Black";
	color: #9a9a9a;
}

.tab.active {
	border-bottom: 3px solid var(--color);
	font-family: "Roboto Black";
	color: var(--color);
}

.tab:hover {
	cursor: pointer;
}

.hide {
	display: none;
}
</style>
