<template>
	<ValidationProvider :class="`col-${cols}`" class="col" tag="div" :name="label" :rules="rules" v-slot="{ errors }">
		<label :class="{ 'solid-color': solidColor, 'label-default': !errors[0], 'label-red': errors[0] }">
			{{ label }}
		</label>
		<input
			v-if="disabled"
			:id="variableName"
			type="date"
			:class="{
				'solid-color': solidColor,
				'border-default': !errors[0],
				'border-red': errors[0],
				'has-value': hasValue
			}"
			class="text-opacity"
			@input="$emit('update:value', text)"
			@keyup.enter="$emit('onEnter', text)"
			v-model="text"
			disabled
			onkeydown="return false" />
		<input
			v-else
			:id="variableName"
			:class="{
				'solid-color': solidColor,
				'border-default': !errors[0],
				'border-red': errors[0],
				'has-value': hasValue
			}"
			type="date"
			@input="$emit('update:value', text)"
			@keyup.enter="$emit('onEnter', text)"
			v-model="text"
			onkeydown="return false" />
		<span class="text-error" v-if="errors[0]">
			{{ errors[0] }}
		</span>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { min } from "moment";
export default {
	name: "DateField",
	components: {
		ValidationProvider
	},
	props: {
		valueField: String,
		type: String,
		rules: String,
		cols: String,
		label: String,
		value: String,
		solidColor: String,
		disabled: Boolean,
		futureDateOnly: Boolean,
		plusEighteen: Boolean,
		placeholder: String,
		variableName: String
	},
	data() {
		return {
			text: "",
			price: 0,
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "R$ ",
				suffix: "",
				precision: 2,
				masked: false
			},
			min: ""
		};
	},
	mounted() {
		if (this.valueField) this.$emit("update:value", this.valueField);
		if (this.futureDateOnly) this.setTomorrowOnInput();
		else if (this.plusEighteen) this.setPlusEighteen();
	},
	watch: {
		valueField() {
			this.text = this.valueField;
			this.$emit("update:value", this.text);
		}
	},
	computed: {
		hasValue() {
			return !!this.text;
		}
	},
	methods: {
		getDateValue(minus = 0) {
			let today = new Date();
			let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
			let mm = today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1; //January is 0!
			let yyyy = today.getFullYear() - minus;

			return yyyy + "-" + mm + "-" + dd;
		},
		setTomorrowOnInput() {
			document.getElementById(this.variableName).setAttribute("min", this.getDateValue());
		},
		setPlusEighteen() {
			document.getElementById(this.variableName).setAttribute("max", this.getDateValue(18));
		}
	}
};
</script>

<style scoped>
label {
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}

label.label-default {
	color: #1d1d1b;
}

label.label-red {
	color: var(--dangerColor);
}

label.solid-color {
	color: var(--whiteColor);
}

input {
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #878785 !important;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
}

.text-opacity {
	opacity: 0.7;
}

input.solid-color {
	background: var(--whiteColor);
}

input.border-default,
input.has-value {
	border: 0;
}

input.border-red {
	border: thin solid var(--dangerColor);
}

span.text-error {
	color: var(--dangerColor);
	font-size: 12px;
	font-family: "Roboto";
}
</style>
