<template>
	<div class="containerDebitDetail">

		<h4>{{ divida.integra_senff === 'S' ? "Dados do Cartão" : "Dados da empresa" }}</h4>
		<div v-for="contrato in divida.contratos">
			<div class="infos-row">

				<div class="infos">
					<h6>{{ divida.integra_senff === 'S' ? "" : "Razão social" }}</h6>
					<h2>{{ divida.integra_senff === 'S' ? "" : divida.loja.loj_razao }}</h2>
				</div>

				<div class="infos">
					<h6>{{ divida.integra_senff === 'S' ? "" : "Nome fantasia" }}</h6>
					<h2>{{ divida.integra_senff === 'S' ? "" : divida.loja.loj_razao }}</h2>
				</div>
				
				<div class="infos">
					<h6>{{ divida.integra_senff === 'S' ? "" : "CNPJ" }}</h6>
					<h2>{{ divida.integra_senff === 'S' ? "" : cnpjMask(divida.loja.loj_cpf_cnpj) }}</h2>
				</div>

			</div>


			<div style="display: flex; justify-content: space-between; align-items: center">
				<h4
					v-if="divida.integra_senff === 'S'"
					style="text-align: left">{{"LOJA: " + contrato.con_nome_loja + " | " + cnpjMask(contrato.con_filial_credor)}}</h4>
				<h4 style="text-align: right">CONTRATO: {{ contrato.con_contrato }}</h4>
			</div>

			<div class="infos-row parcelas-box">

				<table class="table-parcelas">
					<tr>
						<th>Parcela</th>
						<th>Vencimento</th>
						<th>Dias em atraso</th>
						<th>Valor</th>
					</tr>

					<tr v-for="(parcela) in contrato.parcelas">
						<td>{{ parcela.par_prestacao === "0" ? "À vista" : parcela.par_prestacao }}</td>
						<td>{{ parcela.par_vencimento }}</td>
						<td>{{ diasEmAtraso(parcela) }}</td>
						<td>R$ {{ parceiroConfig.identificador == 'validu' || parceiroConfig.identificador == 'resolvaloft' ? parcela.par_saldo : parcela.corrigido | formatarDinheiro }}</td>
					</tr>
				</table>

			</div>
		</div>

		<div v-if="lancamentosFuturos.aVista.length > 0" class="infos-row">
			<h4>Parcelas Futuras</h4>

			<div class="infos">
				<h6>Total lançamentos futuros</h6>
				<h2>R$ {{ money2(valorTotalLancamentosFuturos) }}</h2>
			</div>

			<div class="box-buttons">
				<div style="width: 100%; padding-right: 10px">

					<button-default :color="corPrimaria" :secondaryColor="true"
						@click.native="showDetails = showDetails === true ? null : true" text="Detalhes"></button-default>
				</div>
			</div>
		</div>

		<div v-if="lancamentosFuturos.aVista.length > 0 && showDetails" class="infos-row parcelas-box">

			<table class="table-parcelas" itemref="">

				<caption>
					Detalhes das dívidas.
				</caption>
				<tr>
					<th id="parcelasFuturas">Parcelas futuras</th>
					<th id="nomeEstabelecimento">Nome Estabelecimento</th>
					<th id="dataCompra">Data compra</th>
					<th id="valor</">Valor</th>
				</tr>
				<tr v-for="parcela in lancamentosFuturos.aVista">
					<td>{{ parcela.par_prestacao }}</td>
					<td>{{ divida.loja.loj_fantasia }}</td>
					<td>{{ parcela.par_vencimento }}</td>
					<td>R$ {{ money2(parcela.corrigido) }}</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script lang="js">
import moment from "moment";

import { mapState, createNamespacedHelpers } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import PaymentOptionsDebit from "@/views/documentDebits/MyDebits/components/PaymentOptionsDebit";

const { mapGetters: mapGettersPassosRetornarDividas } = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "DetailsDebit",

	components: { PaymentOptionsDebit, ButtonDefault },

	mixins: [globalMethods, mixins],

	props: {
		divida: {
			type: Object,
			required: true
		},

		lancamentosFuturos: {
			type: Object,
			required: true
		},

		valorTotalLancamentosFuturos: {
			type: Number,
			required: true
		},

		conContrato: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			showDetails: null,
		};
	},

	computed: {
		...mapState({
			parceiro: (estado) => estado.parceiroConfig.nome,
			parceiroConfig: (estado) => estado.parceiroConfig,
			pesCodigo: (estado) => estado.pesCodigo,
			lojas: (estado) => estado.passosRetornarDividas.lojas,
			pessoa: (estado) => estado.passosRetornarDividas.pessoa
		})
	},

	mounted() {
		this.acaoSalvarEtapa({
			pes_cpf_cnpj: this.pessoa.pesCpfCnpj,
			pes_codigo: this.pessoa.pes_codigo,
			esn_fk_ets_codigo: 33,
			emp_codigo: this.parceiroConfig.emp_codigo,
			data_agendamento: this.dataAtual,
			hora_agendamento: this.horaAtual,
			motivo_agendamento: 'Vizualizou os detalhes da dívida'
		});
	},

	methods: {
		...mapGettersPassosRetornarDividas(["getPesCpf"]),

		diasEmAtraso(parcela) {
			if (parcela.par_vencimento === null || parcela.par_vencimento == undefined) return "---";

			else {
				let par_vencimento = moment(parcela.par_vencimento, "DD/MM/YYYY").format("MM/DD/YYYY");
				return moment(par_vencimento).diff(moment(), "days") * -1;
			}
		}
	}
};
</script>

<style scoped>
.containerDebitDetail {
	width: 100%;
	box-sizing: border-box;
	max-width: 1024px;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-bottom: 100px;
}

.header-debits {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	cursor: pointer;
}

h3 {
	margin-bottom: 30px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
}

.header-debits i {
	font-size: 48px;
	color: #031b4e;
}

.infos-row {
	margin: 10px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.infos {
	padding: 10px 20px;
}

.infos h6 {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 11px;
	margin-bottom: 3px;
}

.infos h2 {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 14px;
}

h4 {
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	background: #f5f6fa;
	padding: 10px;
	flex: 1;
	text-align: center;
}

.table-parcelas {
	width: 100%;
	text-align: center;
}

.table-parcelas th {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 14px;
	height: 50px;
}

tr:nth-child(even) {
	background-color: #f9f9f9;
}

.table-parcelas td {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 14px;
	height: 30px;
}

.parcelas-box {
	max-height: 400px;
	overflow-y: auto;
}
</style>