import { render, staticRenderFns } from "./AlertLGPD.vue?vue&type=template&id=790bb94c&scoped=true"
import script from "./AlertLGPD.vue?vue&type=script&lang=js"
export * from "./AlertLGPD.vue?vue&type=script&lang=js"
import style0 from "./AlertLGPD.vue?vue&type=style&index=0&id=790bb94c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790bb94c",
  null
  
)

export default component.exports