var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{staticClass:"col",class:`col-${_vm.cols}`,attrs:{"tag":"div","name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{class:{ 'solid-color': _vm.solidColor, 'label-default': !errors[0], 'label-red': errors[0] }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.disabled)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"text-opacity",class:{
			'solid-color': _vm.solidColor,
			'border-default': !errors[0],
			'border-red': errors[0],
			'has-value': _vm.hasValue
		},attrs:{"id":_vm.variableName,"type":"date","disabled":"","onkeydown":"return false"},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.text=$event.target.value},function($event){return _vm.$emit('update:value', _vm.text)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onEnter', _vm.text)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
			'solid-color': _vm.solidColor,
			'border-default': !errors[0],
			'border-red': errors[0],
			'has-value': _vm.hasValue
		},attrs:{"id":_vm.variableName,"type":"date","onkeydown":"return false"},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.text=$event.target.value},function($event){return _vm.$emit('update:value', _vm.text)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onEnter', _vm.text)}}}),(errors[0])?_c('span',{staticClass:"text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }