<template>
	<div class="col">
		<label>{{ label }}</label>
		<input @click="showOptions = true" :readonly="true" v-model="text[keyName]" type="text" :id="variableName" />
		<div class="select-box" v-show="showOptions">
			<div
				class="option"
				@click="selectOption(option)"
				v-for="option in options"
				:key="option[keyValue] + option[keyName]">
				{{ option[keyName] }}
			</div>
			<div v-if="options.length === 0" class="option">Nenhum dado encontrado</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SelectField",
	props: ["cols", "label", "value", "options", "keyName", "keyValue", "valueField", "variableName"],
	data() {
		return {
			text: {},
			showOptions: false
		};
	},
	mounted() {
		this.selectOption(this.options.find((option) => option.codigo === this.valueField) || {});
	},
	methods: {
		selectOption(option) {
			this.showOptions = false;
			this.text = option;
		}
	},
	watch: {
		options() {
			this.selectOption(this.options[0] || {});
		},
		text() {
			this.$emit("update:value", this.text[this.keyValue]);
		}
	}
};
</script>

<style scoped>
.col {
	display: flex;
	flex-direction: column;
	margin: 12px 6px;
	position: relative;
}

label {
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}

input {
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #878785 !important;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
}

.select-box {
	background: #ffffff;
	border-radius: 10px;
	-webkit-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	-moz-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	position: absolute;
	margin-top: 65px;
	width: 93%;
	z-index: 10;
}

.select-box .option {
	height: 46px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 10px;
	font-family: "Roboto Bold";
	background: #ffffff;
}

.select-box .option:hover {
	cursor: pointer;
	background: #9b9b93;
}
</style>
