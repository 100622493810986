<template>
	<div>
		<button
			v-if="secondaryColor"
			:class="{
				secondaryColor: parceiroConfigCorSecundariaCorSecundaria,
				'tab-left': tabBorder === 'tab-left',
				'tab-right': tabBorder === 'tab-right',
				disabled: disable,
				actived: active
			}"
			:style="corSecundaria"
			:disabled="disable"
			type="button">
			{{ text }}
		</button>
		<button
			v-else
			:class="{
				secondaryColor: parceiroConfigCorSecundariaCorSecundaria,
				'tab-left': tabBorder === 'tab-left',
				'tab-right': tabBorder === 'tab-right',
				disabled: disable,
				actived: active
			}"
			:style="corPrimaria"
			:disabled="disable"
			type="button">
			{{ text }}
		</button>
	</div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

export default {
	name: "ButtonDefault",

	mixins: [mixins],

	props: {
		text: {
			type: String,
			default: ""
		},

		secondaryColor: {
			type: Boolean,
			default: false
		},

		tabBorder: {
			type: String,
			default: ""
		},

		color: {
			type: String,
			default: ""
		},

		disable: {
			type: Boolean,
			default: false
		},

		active: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			windowWidth: window.innerWidth
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria
		})
	},
};
</script>

<style lang="scss" scoped>
button {
	padding: 15px 25px;
	background: var(--hoverColor);
	font-family: "Roboto Bold";
	color: #ffffff;
	border: 3px solid var(--color);
	border-radius: 27px;
	width: 100%;
	transition: all 0.2s ease-in-out;
	&:hover {
		background-color: var(--hoverColor);
		border-color: var(--hoverColor);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
}

button.secondaryColor {
	background: transparent;
	color: var(--color);
	border: 3px solid var(--color);
	background: var(--whiteColor);
	width: 100%;
	transition: all 0.2s ease-in-out;
	&:hover {
		background: var(--color);
		color: var(--whiteColor);
		border: 3px solid var(--color);
		transition: all 0.2s ease-in-out;
	}
}

button.actived {
	background: var(--color);
	color: var(--whiteColor);
	border: 3px solid var(--color);
	transition: all 0.2s ease-in-out;
}

button button.tab-left {
	border-bottom-right-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

button.tab-left:hover {
	background-color: var(--color);
	border-color: var(--color);
	transition: all 0.2s ease-in-out;
}

button.tab-right {
	border-bottom-left-radius: 0 !important;
	border-top-left-radius: 0 !important;
}

button.tab-right:hover {
	background-color: var(--color);
	border-color: var(--color);
	transition: all 0.2s ease-in-out;
}

button.disabled {
	color: var(--whiteColor);
	border: 3px solid var(--hoverColor);
	background: var(--houverColor);
	:hover {
		background: var(--houverColor);
		color: var(--whiteColor);
		border: 3px solidvar(--hoverColor);
		transition: all 0.2s ease-in-out;
	}
}

@media only screen and (max-width: 900px) {
	button {
		margin-top: 1em;
		width: 100%;
	}
}
</style>
