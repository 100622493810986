<template>
	
	<header
		class="land-page-header"
		:class="{ 'bg-transparent': isBackgroundTransparentActive }"
		:style="estiloHeader">
	
		<template>
	
			<div
				:style="estiloLogo" >

				<img
					v-if="parceiro && parceiroConfig.logo != null && parceiroConfig.identificador != 'mw' && !portalCamec"
					style="margin: auto"
					:src="parceiroConfig.logo" />

				<img
					v-else-if="parceiroConfig.identificador == 'mw' || portalCamec"
					style="margin: auto"
					:src="parceiroConfig.sessoes.logoForBackgroundWhite.campos"/>

				<img
					v-else
					style="margin: auto"
					src="@/assets/images/logo.png"
					@click="$router.push({ name: 'LandPageParceiro' })" />

				<img
					v-if="portalCamec"
					style="margin: auto; max-width: 18em;"
					:src="parceiroConfig.sessoes.partners.arquivos[0].arquivo">
			</div>

		</template>

		<menu v-if="parceiroConfig != null && !portalCamec && parceiroConfig.identificador != 'Senff'">

			<ul>

				<li
					v-if="parceiro == 'negocie'"
					style="border-color: transparent"
					:style="corBotaoCabecalho"
					@click="$router.push({ name: 'WorkWithUs' })" >Trabalhe Conosco</li>

				<li
					style="border-color: transparent"
					:style="corBotaoCabecalho"
					@click="$router.push({ name: 'PrivacyPortal' })" >Portal da privacidade</li>

				<li
					v-if="parceiro && parceiroConfig.sessoes.about && parceiroConfig.sessoes.about.campos"
					style="border-color: transparent"
					:style="corBotaoCabecalho"
					@click="goToSection('about')" >Sobre</li>

				<li
					v-if="parceiro && parceiroConfig.sessoes.tutorial && parceiroConfig.sessoes.tutorial.campos"
					style="border-color: transparent"
					:style="corBotaoCabecalho"
					@click="goToSection('tutorial')" >Como funciona</li>

				<li
					v-if="parceiro && parceiroConfig.sessoes.contact && parceiroConfig.sessoes.contact.campos"
					style="border-color: transparent"
					:style="corBotaoCabecalho"
					@click="goToSection('contact')" >Contato</li>

				<li
					v-if="parceiro && parceiroConfig.sessoes.creditor != null"
					:style="corBotaoCabecalho"
					@click="$router.push({ name: 'RegisterCreditor' })" >Seja um credor parceiro</li>

				<li
					v-if="parceiroConfig.sessoes.customization == undefined ? true : parceiroConfig.sessoes.customization.campos.landPage.flgAcessoCredor"
					:style="corBotaoCabecalho"
					@click="openAdmin()" >Acesso credor</li>

			</ul>

			<div
				v-if="parceiroConfig.identificador != 'mw'"
				class="btnIntegraRobo">

				<small-button
					v-if="parceiroConfig.sessoes.customization.campos.landPage.flgIntegraRobo"
					:icon="'fab fa-whatsapp'"
					:title="'Fale com nosso atendimento'"
					:color="color"
					:botaoWhatsapp="true"
					@click.native="connectWithBot()" ></small-button>

			</div>

			<div
				class="land-page-menu-mobile"
				:style="corBotaoCabecalho" >

				<i
					class="fas fa-bars"
					@click="showMobile = true" ></i>

				<div
					v-if="showMobile"
					class="menu-background-full" >

					<div style="display: flex; justify-content: flex-end" >

						<i
							class="fas fa-times"
							@click="showMobile = false" ></i>

					</div>

					<div style="display: flex; justify-content: center" >

						<img
							v-if="parceiro"
							:src="parceiroConfig.sessoes.logoForBackgroundWhite"
							alt="logo"
							@click="$router.push({ name: 'LandPageParceiro' })" />

						<img
							v-else
							src="@/assets/images/logo.png"
							alt="logo"
							@click="$router.push({ name: 'LandPageParceiro' })" />

					</div>

					<div style="display: flex; justify-content: center">

						<ul style="display: flex; flex-direction: column; max-width: 200px">

							<li
								v-if="parceiro && parceiroConfig.sessoes.about && parceiroConfig.sessoes.about.campos && !nokia"
								:style="corBotaoCabecalho"
								@click="goToSection('about')" >Sobre</li>

							<li
								v-if="parceiro && parceiroConfig.sessoes.tutorial && parceiroConfig.sessoes.tutorial.campos && !nokia"
								:style="corBotaoCabecalho"
								@click="goToSection('tutorial')" >Como funciona</li>

							<li
								v-if="parceiro && parceiroConfig.sessoes.contact && parceiroConfig.sessoes.contact.campos && !nokia"
								:style="corBotaoCabecalho"
								@click="goToSection('contact')" >Contato</li>

							<li
								v-if="parceiro && parceiroConfig.sessoes.creditor != null"
								:style="corBotaoCabecalho"
								@click="$router.push({
									name: 'RegisterCreditor'
								})" >Seja um credor parceiro</li>

							<li
								v-if="parceiroConfig.sessoes.customization == undefined ? true : parceiroConfig.sessoes.customization.campos.landPage.flgAcessoCredor"
								:style="corBotaoCabecalho"
								@click="openAdmin()" >Acesso credor</li>

						</ul>

					</div>

				</div>

			</div>

		</menu>

	</header>

</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

import Header from "@/components/Header";
import Template from "@/views/admin/Template";
import ButtonDefault from "@/components/buttons/ButtonDefault";
import ExpandingButton from "@/components/buttons/ExpandingButton.vue";
import SmallButton from "@/components/buttons/SmallButton.vue";

export default {
	name: "LandPageHeader",

	components: { ButtonDefault, Template, Header, ExpandingButton, SmallButton },

	mixins: [mixins],

	props: {
		backgroundTransparent: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			showMobile: {
				type: Boolean,
				default: false
			},

			nokia: {
				type: Boolean,
				default: window.innerWidth == 240
			},

			color: {
				type: Object,
				default: {
				"--color": "#FFF",
				"--hoverColor": "#25D366"
				}
			},

			botaoWhatsapp: true
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria,
			portalCamec: (estado) => estado.portalCamec
		}),

		isBackgroundTransparentActive () {
			return this.backgroundTransparent == undefined ? this.parceiro : this.backgroundTransparent;
		},

		corBotaoCabecalho() {
			return (this.corPrimaria, {color: this.checkColors('headerButtonsColor') ? this.parceiroConfig.sessoes.header.campos.headerButtonsColor : '#FFF'});
		},
		
		estiloLogo () {
			let estiloPosicionamentoLogo = window.innerWidth > 700 ? {'display': 'flex'} : {'margin' : 'auto'};
			if (this.parceiroConfig.emp_codigo == 350) {
				estiloPosicionamentoLogo = {'display': 'flex'};
			}

			return {
				"--imgMaxWidth": this.parceiroConfig.sessoes.customization.campos.landPage.flgMaxWidth ? "25em" : "13em",
				...estiloPosicionamentoLogo
			}
		},

		estiloHeader() {
			if (this.parceiroConfig.emp_codigo == 350) {
				return {
					'margin-top': '0px',
					'background-color': '#FFF',
					'height': this.parceiroConfig.sessoes.customization.campos.landPage.flgMaxWidth ? '180px' : '120px',
					...this.corPrimaria
				}
			} else if (this.parceiroConfig.emp_codigo == 128) {
				return {
					'display': 'flex',
					'justify-content': 'center'
				}
			} else {
				return this.corPrimaria
			}
		}
	},

	mounted() {
		this.$nextTick(() => {
			window.addEventListener("resize", this.onResize);
		});
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},

	methods: {
		openAdmin() {
			window.open("https://app.aquicob.com.br/", "_blank");
		},

		goToSection(section) {
			if (this.$route.name !== "LandPageParceiro") {
				this.$router.push({ name: "LandPageParceiro" });
			} else {
				var element = this.$parent.$parent.$refs[section];
				var top = element.offsetTop;
				element.scrollIntoView({ behavior: "smooth", top });
				this.showMobile = false;
			}
		},

		onResize() {
			this.nokia = window.innerWidth == 240;
		},

		connectWithBot() {
			if (this.parceiroConfig.sessoes.contact.campos.whatsapp) {
				let janela = window.open(`https://api.whatsapp.com/send?phone=55${this.removeMaskCharacters(this.parceiroConfig.sessoes.contact.campos.whatsapp)}
											&text=Olá estou vindo do portal ${this.parceiro.toUpperCase()}.`, "_blank");
				janela.focus();
			} else {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Ocorreu um erro",
					message: 'Não foi possível encontrar o número de Whatsapp da assessoria! ',
					styleMessage: "danger"
				});
			}
		},

		checkColors(configuracaoCampo) {
			return this.parceiro && this.parceiroConfig.sessoes.header.campos && this.parceiroConfig.sessoes.header.campos[configuracaoCampo];
		}
	}
};
</script>

<style scoped lang="scss">
.land-page-header {
	background: var(--lightGrayColor);
	display: flex;
	justify-content: space-around;
	margin-top: 25px;

	img {
		margin-top: 20px;
		max-width: var(--imgMaxWidth);
		width: 100%;
		box-sizing: border-box;
		cursor: pointer;

		@media only screen and (max-width: 500px) {
			max-width: 10em;
		}
	}

	menu {
		ul {
			display: flex;
			gap: 20px;
			justify-content: flex-end;
			margin-top: 50px;

			li {
				display: inline-block;
				font-size: 18px;
				font-family: "Roboto-Bold", sans-serif;
				cursor: pointer;
				padding: 15px;
				width: fit-content;
				height: fit-content;
				border-radius: 27px;
				transition: all 0.2s ease-in-out;
				border: 2px solid var(--whiteColor);

				&:hover {
					background-color: var(--color);
					color: var(--whiteColor);
					transition: all 0.2s ease-in-out;
					border: 2px solid transparent;
				}

				@media only screen and (max-width: 1245px) {
					width: 110%;
					margin-bottom: 15px;
					display: flex;
					justify-content: center;
					padding: 12px;
					font-size: 18px;
					font-family: "Roboto-Bold", sans-serif;
					color: var(--color);
					cursor: pointer;
					border: 2px solid var(--color);

					a {
						font-size: 32px;
						color: var(--color);
						font-family: Cabin;
						text-align: center;
					}
				}

				@media only screen and (max-width: 768px) {
					width: 100%;
					margin-bottom: 10px;
				}

				@media only screen and (max-width: 414px) {
					margin-bottom: 10px;
				}

				@media only screen and (max-width: 240px) {
					width: 90%;
					font-size: 15px;
					margin: 0px;
				}
			}

			@media only screen and (max-width: 1245px) {
				display: none;
			}

			@media only screen and (max-width: 240px) {
				justify-content: center;
				gap: 20;
				margin: 0px;
			}
		}

		@media (max-width: 1245px) {
			display: none;
		}

		@media (max-width: 700px) {
			position: absolute;
			right: 20px;
		}
	}
}

.land-page-menu-mobile {
	display: none;
	align-content: center;
	justify-content: end;

	i {
		font-size: 32px;
		color: var(--whiteColor);
		cursor: pointer;
	}
}

.bg-transparent {
	background: transparent !important;
}

.menu-background-full {
	position: fixed;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	background: var(--whiteColor);
	display: flex;
	flex-direction: column;
	z-index: 900;

	@media only screen and (max-width: 240px) {
		justify-content: flex-start;
		height: 200vh;
	}

	img {
		margin-bottom: 35px;

		@media only screen and (max-width: 450px) {
			height: 20vh;
			margin-bottom: 20px;
		}

		@media only screen and (max-width: 240px) {
			width: 50%;
			margin-right: 15px;
		}
	}

	i {
		font-size: 38px;
		color: var(--color);
		justify-self: end;
		top: -10;

		@media only screen and (max-width: 240px) {
			right: 15vw;
			font-size: 20px;
		}
	}
}

.btnIntegraRobo {
	align-self: end;
	margin: 0 8% 10px 0;
	display: none;

	@media screen and (max-width: 700px) {
		display: flex;
	}
}</style>
