<template>
	<div class="template">
		<div class="menu-container">

			<Menu ></Menu>

		</div>
		<div class="content">

			<Header class="header" ></Header>
			<div class="page">
				<router-view ></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import Menu from "./Menu";
import Header from "@/components/Header";

export default {
	name: "Template",

	components: { Header, Menu },

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria
		})
	},

	mounted() {
		this.SET_LOADING(true);

		this.SET_COR_PRIMARIA({
			"--color": "#00c0fb",
			"--hoverColor": "#5938c8"
		});

		this.SET_COR_SECUNDARIA({
			"--color": "#5938c8",
			"--hoverColor": "#00c0fb"
		});

		if (!localStorage.getItem("user_id") || !localStorage.getItem("access_token") ||
			localStorage.getItem("access_token") === "undefined" || localStorage.getItem("access_token") === "" ||
			localStorage.getItem("access_token") === null || localStorage.getItem("access_token").length < 20) {

			this.SET_LOADING(false);
			this.$router.push("/login");

		} else {
			this.SET_LOADING(false);
			// this.action_setUserAuth().then(() => {
			//     this.CHANGE_LOADING(false);
			// }).catch(() => {
			//     this.CHANGE_LOADING(false);
			//     this.$router.push('/login');
			// })
		}
	},

	methods: {
		...mapMutations(["CHANGE_MESSAGE_MODAL", "SET_LOADING", "SET_COR_PRIMARIA", "SET_COR_SECUNDARIA"])
	}
};
</script>

<style scoped>
.template {
	display: flex;
	width: 100%;
	min-height: 100vh;
	background: #f3f5f9;
}

.menu-container {
	min-width: 290px;
	width: 290px;
	max-width: 290px;
}

.content {
	padding: 20px;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.page {
	flex: 1;
}

@media only screen and (max-width: 900px) {
	.menu-container {
		display: none;
	}
}
</style>
