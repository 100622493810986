<template>
	<div class="bg-page">
		<div class="background-card" v-if="acordo" :style="mobileStyle">
			<div class="debit-card">

				<div class="img-box">
					<img
						v-if="acordo.loja.loj_caminho_logo && acordo.loja.loj_caminho_logo.length > 0 && !imgError"
						key="portal-parceiro-logo"
						:src="imagemLogoLoja"
						alt="logo_loja"
						@error="imgError = true"/>

					<img
						v-else-if="parceiro && parceiroConfig.sessoes.logoForBackgroundWhite && parceiroConfig.sessoes.logoForBackgroundWhite.campos"
						key="portal-parceiro-logo-para-fundo-branco"
						:src="parceiroConfig.sessoes.logoForBackgroundWhite.campos"
						alt="logo_loja" />

					<img
						v-else-if="parceiro"
						key="portal-parceiro-parceiro-config"
						:src="parceiroConfig.logo"
						alt="logo_loja" />

					<img
						v-else
						key="portal-parceiro-sem-logo"
						src="@/assets/images/teae.png"
						alt="logo_loja" />
				</div>

				<div class="container-info">
					<div class="info">

						<div class="inner-info">

							<i
								v-if="parceiro != 'prefeiturapg' && hidebuttons"
								class="fas fa-plus"
								style="align-self: end; margin-bottom: 20px"
								@click="showOrHideButtons(false)"></i>

							<i
								v-if="parceiro != 'prefeiturapg' && !hidebuttons"
								class="fas fa-minus"
								style="align-self: end; margin-bottom: 20px"
								@click="showOrHideButtons(true)"></i>

							<div class="row-info">
								<div style="display: flex; align-items: baseline; text-align: start">
									<p style="margin-bottom: 5px; width: 500px">{{ labelNomeLoja }}</p>
								</div>

								<h6><span>Código do acordo:</span> {{ acordo.dadosAcordo.aco_codigo }}</h6>

								<h6><span>Pagamento:</span> {{ formaPagamento }}</h6>

								<h6 v-if="acordo.dadosAcordo.parcelas && acordo.dadosAcordo.parcelas.length > 0">
									<span>Parcelas: {{ acordo.dadosAcordo.parcelas.length }}</span>
								</h6>
							</div>

						</div>

						<div
							class="small-buttons"
							v-show="mostrarOpcoesTermoDeConfissao">

							<div
								title="Baixar termo de confissão de dívida"
								class="small-button"
								@click="baixarTermo" >

								<i class="fas fa-file-download" ></i>
							</div>

							<div
								title="Enviar termo de confissão de dívida por e-mail"
								class="small-button"
								@click="enviarTermo" >

								<i class="fas fa-envelope-open-text" ></i>
							</div>

						</div>

						<div class="row-totals">
							<div class="totals">

								<h6>Valor negociado</h6>
								<h2><span>R$</span> {{ acordo.dadosAcordo.valor_negociado | formatarDinheiro }}</h2>

							</div>

							<div class="totals">

								<h6>Valor restante</h6>
								<h2><span>R$</span> {{ acordo.dadosAcordo.valor_restante == null ? 0 : acordo.dadosAcordo.valor_restante | formatarDinheiro }}</h2>

							</div>
						</div>						
					</div>
					<div class="box-buttons" style="align-items: center;">
						<div style="width: 100%; padding: 0 10%; display: flex; justify-content: space-around;">

							<button-default
								:color="parceiroConfigCorSecundariaCorSecundaria"
								:secondaryColor="true"
								:active="acordoSelecionado === valorAcordoCodigo + ' ' + 'PaymentOptionsAgreement'"
								@click.native="showPaymentOptionsAgreement"
								:text="textoBotaoMostrarPagamentos" ></button-default>

							<button-default
								v-if="portalCamec"
								:color="parceiroConfigCorSecundariaCorSecundaria"
								:secondaryColor="true"
								@click.native="carregarTermoDeConfissaoCamec"
								text="Termo de Conciliação" ></button-default>
							</div>
					</div>
				</div>
			</div>
		</div>

		<component
			:is="'PaymentOptionsAgreement'"
			v-if="acordoSelecionado === valorAcordoCodigo + ' ' + 'PaymentOptionsAgreement'"
			:acordo="acordo" ></component>
	</div>
</template>

<script>
import jsPDF from "jspdf";

import { mapState, mapActions, mapMutations } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import PaymentOptionsAgreement from "./PaymentOptionsAgreement";
import DetailsAgreement from "./DetailsAgreement";

export default {
	name: "AgreementCard",

	components: { ButtonDefault, PaymentOptionsAgreement, DetailsAgreement },

	mixins: [globalMethods, mixins],

	props: ["acordo"],

	mounted () {
		this.SET_ACORDO_SELECIONADO("");
	},

	computed: {
		...mapState({
			showMessageModal: (estado) => estado.messageModal.show,
			pessoaState: (estado) => estado.passosRetornarDividas.pessoa,
			acordoSelecionado: (estado) => estado.acordoSelecionado,
			portalCamec: (estado) => estado.portalCamec,
		}),

		mobileStyle() {
			return { "--hidebuttons": this.hidebuttons ? "none" : "flex" };
		},

		hidebuttons() {
			return !this.acordoSelecionado.includes(this.acordo.dadosAcordo.aco_codigo);
		},

		valorAcordoCodigo() {
			return this.acordo.dadosAcordo.aco_codigo === String ? this.acordo.dadosAcordo.aco_codigo : this.acordo.dadosAcordo.aco_codigo.toString();
		},

		valorNegociado() {
			return this.acordo.dadosAcordo.parcelas
			.map(parcela => Number.parseFloat(parcela.valor))
			.reduce((parcela, resultado) => resultado + parcela);
		},

		mostrarOpcoesTermoDeConfissao() {
			return this.parceiroConfig.sessoes.customization.campos.myAgreements === undefined ||
			this.parceiroConfig.sessoes.customization.campos.myAgreements.flgTermoCofissao === undefined ?
			false : this.parceiroConfig.sessoes.customization.campos.myAgreements.flgTermoCofissao;
		},

		textoBotaoMostrarPagamentos() {
			return this.parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreementsPayment ?
			this.parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreementsPayment : 'Visualizar pagamentos';
		},

		imagemLogoLoja() {
			return `${process.env.VUE_APP_API_URL}/${this.acordo.loja.loj_caminho_logo}`;
		},

		formaPagamento() {
			switch (this.acordo.dadosAcordo.forma_pagamento) {
				case 'pix':
					return 'PIX';

				case 'boleto':
					return 'Boleto';

				case 'cartao':
					return 'Cartão de Crédito';

				case 'link':
					return 'Link de Pagamento';

				case 'sem':
					return 'Parcela sem Cobrança';

				default:
					return this.acordo.dadosAcordo.forma_pagamento;
			}
		},

		labelNomeLoja() {
			return this.parceiroConfig.emp_codigo == 128 ? this.acordo.dadosAcordo.con_nome_loja : this.acordo.loja.loj_fantasia;
		}
	},

	methods: {
		...mapMutations(["SET_ACORDO_SELECIONADO"]),

		...mapActions(["action_enviar_email"]),

		prepararTermo() {
			this.PDF = new jsPDF();
			let coment = {
				label: "Termo de confissão e renegociação de dívida.",
				firstParagraph:
					"\nPelo presente termo e na melhor forma de direito as partes abaixo qualificadas." + "\nDevedor(a) " + this.infoAcordo.pessoa.nome + ", um brasileiro(a), residente e domiciliado(a) no " + this.infoAcordo.pessoa.endereco + ", nº " + this.infoAcordo.pessoa.numero + " " + this.infoAcordo.pessoa.bairro + " na cidade de " + this.infoAcordo.pessoa.cidade + " - " + this.infoAcordo.pessoa.estado + " CEP: " + this.infoAcordo.pessoa.cep + " inscrito no CPF/MF nº " + this.infoAcordo.pessoa.cpfCnpj + ", simplesmente denominado(a) DEVEDOR (A). " + "\nCREDORA: " + this.infoAcordo.loja.nome + " uma pessoa jurídica com sede localizada em " + this.infoAcordo.loja.endereco + ", nº" + this.infoAcordo.loja.numero + " " + this.infoAcordo.loja.bairro + " na cidade de " + this.infoAcordo.loja.cidade + " " + this.infoAcordo.loja.estado + ", CEP: " + this.infoAcordo.loja.cep + " inscrita no CNPJ " + this.infoAcordo.loja.cpf_cnpj + ", doravante simplesmente denominada CREDORA.",
				secondParagraph: "\n\nCLÁUSULA PRIMEIRA - Através do presente reconhece expressamente o DEVEDOR(A) que possui uma dívida a ser paga à CREDORA, originário de empréstimo" + " para crédito produtivo orientado nos termos da legislação que rege as sociedades de crédito ao microempreendedor e empresa de pequeno porte," + " consubstanciada no montante total de R$ " + this.infoAcordo.valor_total + " que devidamente atualizada na Cédula de Crédito Bancária nº 415295 com juros e correção monetária perfaz a quantia de R$ " + this.infoAcordo.valor_total + ", e que quitará este valor conforme as condições previstas neste \ncontrato.",
				thirdParagraph: "\n\nParágrafo Primeiro - O DEVEDOR(A) neste ato declara que o débito total será pago, inteiramente nos termos do presente instrumento, obrigando-se a efetuar" + " o pagamento da dívida em " + this.infoAcordo.boletos.parcelas + " parcela(s), com o primeiro vencimento no dia " + this.infoAcordo.vencimento + ", no valor de a título de sinal e princípio de pagamento e mais parcela no valor de " + this.infoAcordo.boletos.valor + " com o último vencimento em " + this.infoAcordo.boletos.ultimoVencimento + ".",
				fourthParagraph: "\n\nParágrafo Segundo - Por outro lado a CREDORA compromete-se a dar baixa no que diz respeito exclusivamente ao débito ora confessado, " + "excluindo o nome do DEVEDOR(A) do Banco de Dados do SPC ou órgão que o valha, nem até 5 dias úteis após efetuar o pagamento do empréstimo.",
				fifthParagraph: "\n\nParágrafo Terceiro - Para os casos de pagamento do sinal via depósito, o DEVEDOR(A) deverá efetivar depósito identificado na conta corrente nº 1454-0" + ", agência 3090-2 do Banco Bradesco, e não sendo possível tal identificação, o DEVEDOR(A) deverá entrar em contato com a " + "CREDORA para que seja feita a identificação do depósito e dar continuidade ao processo de baixa, no qual o não cumprimento desta exigência," + " a CREDORA não poderá ser responsabilizada pela manutenção da negativação dos órgãos de proteção ao crédito.",
				sixthParagraph: "\n\nParágrafo Quarto - Para os casos de pagamento do sinal via boleto bancário a CREDORA irá aguardar a compensação" + " do título para dar continuidade ao processo de baixa. ",
				seventhParagraph: "\n\nCLÁUSULA SEGUNDA - As demais parcelas do acordo deverão ser pagas mensalmente em boletos indicada pela CREDORA, " + "evitando assim que os valores não sejam quitados e o DEVEDOR(A) seja negativado(a) novamente.",
				eighthParagraph: "\n\nCLÁUSULA TERCEIRA - O presente acordo, não tem o objetivo de novar a dívida existente, sendo certo que o não pagamento" + " de qualquer parcela deste acordo, ensejará na manutenção da dívida oriunda da cédula de crédito bancário" + " em seu valor original, incidindo aí todos os demais encargos ali pactuados, deduzindo os valores aqui pagos." + " Sujeitando-se desta forma às cobranças extrajudiciais ou judiciais que se fizerem necessárias, além dos " + "encargos e honorários advocatícios advindos da cobrança até a data do efetivo pagamento, terá incluído " + "o seu nome no Serviço de Proteção ao Crédito - SPC ou demais órgãos equivalentes.",
				ninthParagraph: "\n\nCLÁUSULA QUARTA - O presente termo passa a vigorar entre as partes a partir da assinatura do mesmo, " + "sendo realizado em caráter irrevogável, irretratável e intransferível, o qual obrigam as partes a cumpri-lo, " + "a qualquer título, bem como os seus herdeiros e sucessores.",
				tenthParagraph: "\n\nCLÁUSULA QUINTA - Para dirimir quaisquer controvérsias oriundas do presente contrato, as partes elegem o foro da Comarca de São Paulo. " + "Por estarem, assim juntos e contratados, firma o presente termo, em duas vias de igual teor, juntamente com duas testemunhas.",
				storeSignature: "____________________________\n" + this.infoAcordo.loja.nome.toUpperCase(),
				clientSignature: "____________________________\n" + this.infoAcordo.pessoa.nome.toUpperCase()
			};
			this.PDF.setFont("Helvetica");
			this.PDF.setFontSize(25);

			let comentWidth = this.PDF.internal.pageSize.getWidth() + 100;
			let labelWidth = this.PDF.internal.pageSize.getWidth() - 2 * 21 - 10;
			let blockHeight = (this.PDF.splitTextToSize(coment.firstParagraph, comentWidth).length * this.PDF.getLineHeight(coment.firstParagraph)) / this.PDF.internal.scaleFactor;
			let yPos = 15;
			let xPos = 20;

			this.PDF.text(xPos, 42, this.PDF.splitTextToSize(coment.label, labelWidth));
			this.PDF.setFontSize(12);

			this.PDF.text(xPos, yPos + 42, this.PDF.splitTextToSize(coment.firstParagraph, comentWidth), { maxWidth: 170, align: "justify" });
			yPos += (this.PDF.splitTextToSize(coment.firstParagraph, comentWidth).length * this.PDF.getLineHeight(coment.firstParagraph)) / this.PDF.internal.scaleFactor + 47;

			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.secondParagraph, comentWidth), { maxWidth: 170, align: "justify" });
			yPos += (this.PDF.splitTextToSize(coment.secondParagraph, comentWidth).length * this.PDF.getLineHeight(coment.secondParagraph)) / this.PDF.internal.scaleFactor + 10;

			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.thirdParagraph, comentWidth), { maxWidth: 170, align: "justify" });
			yPos += (this.PDF.splitTextToSize(coment.thirdParagraph, comentWidth).length * this.PDF.getLineHeight(coment.thirdParagraph)) / this.PDF.internal.scaleFactor + 5;

			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.fourthParagraph, comentWidth), { maxWidth: 170, align: "justify" });
			yPos += (this.PDF.splitTextToSize(coment.fourthParagraph, comentWidth).length * this.PDF.getLineHeight(coment.fourthParagraph)) / this.PDF.internal.scaleFactor + 5;

			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.fifthParagraph, comentWidth), { maxWidth: 170, align: "justify" });
			yPos += (this.PDF.splitTextToSize(coment.fifthParagraph, comentWidth).length * this.PDF.getLineHeight(coment.firstParagraph)) / this.PDF.internal.scaleFactor + 10;

			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.sixthParagraph, comentWidth), { maxWidth: 170, align: "justify" });

			this.PDF.addPage();

			yPos = 30;
			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.seventhParagraph, comentWidth), { maxWidth: 170, align: "justify" });

			yPos += (this.PDF.splitTextToSize(coment.seventhParagraph, comentWidth).length * this.PDF.getLineHeight(coment.seventhParagraph)) / this.PDF.internal.scaleFactor;
			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.eighthParagraph, comentWidth), { maxWidth: 170, align: "justify" });

			yPos += (this.PDF.splitTextToSize(coment.eighthParagraph, comentWidth).length * this.PDF.getLineHeight(coment.eighthParagraph)) / this.PDF.internal.scaleFactor + 15;
			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.ninthParagraph, comentWidth), { maxWidth: 170, align: "justify" });

			yPos += (this.PDF.splitTextToSize(coment.eighthParagraph, comentWidth).length * this.PDF.getLineHeight(coment.eighthParagraph)) / this.PDF.internal.scaleFactor - 5;
			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.tenthParagraph, comentWidth), { maxWidth: 170, align: "justify" });

			yPos += (this.PDF.splitTextToSize(coment.eighthParagraph, comentWidth).length * this.PDF.getLineHeight(coment.eighthParagraph)) / this.PDF.internal.scaleFactor + 55;
			this.PDF.text(xPos, yPos, this.PDF.splitTextToSize(coment.storeSignature, comentWidth));

			this.PDF.text(111, yPos, this.PDF.splitTextToSize(coment.clientSignature, comentWidth));
		},

		baixarTermo() {
			this.prepararTermo();
			this.PDF.save("Termo de Confissão de Divida " + this.acordo.aco_codigo + ".pdf");
		},

		enviarTermo() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Enviando por e-mail");
			this.prepararTermo();

			let nome_anexo = "Termo de Confissão de Divida " + this.acordo.aco_codigo + ".pdf";
			let url = this.PDF.output("dataurlstring", nome_anexo).replace("data:application/pdf;filename=" + nome_anexo + ";base64,", "");

			this.acaoSalvarEtapa({
				esn_fk_ets_codigo: 26,
				emp_codigo: this.parceiroConfig.emp_codigo,
				pes_codigo: this.pessoa.pes_codigo,
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual,
				motivo_agendamento: 'Enviou por email um termo confissão de dívida'
			});

			if (this.acordo.pessoa.email == null) {
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);

				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Infelizmente não encontramos nenhum endereço de e-mail vinculado com o seu cadastro. Por favor, entre em contato com o nosso atendimento para que possamos ajudá-lo.`,
					styleMessage: "danger"
				});
				return -1;

			} else if (this.parceiroConfig.emp_codigo == "128") {
				this.acordo.pessoa.email = "m.rondon@senff.com.br";
			}

			this.acordo.pessoa.email.split(",").forEach((email) => {
				this.action_enviar_email({
					emp_codigo: this.acordo.emp_codigo,
					pes_codigo: this.acordo.pessoa.pes_codigo,
					loj_codigo: this.acordo.detalhes.loj_codigo,
					pem_email: email,
					assunto: "Envio do seu termo de confissão de dívida.",
					texto_email: "O seu termo de confissão de dívida está anexado a este e-mail.",
					termo: true,
					anexo: url,
					nome_anexo: nome_anexo

				}).then((response) => {
					if (response?.data?.mensagem == "E-mail enviado com sucesso.") {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `E-mail enviado com sucesso. Caso não encontre na sua caixa de entrada, verifique senão está na sua pasta de spam.`,
							styleMessage: "success"
						});
						return -1;

					} else {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `Erro ao enviar o e-mail. Verifique se você tem um e-mail cadastrado junto com o seu CPF.`,
							styleMessage: "danger"
						});
						return -1;
					}
				});
			});

			this.SET_LOADING_MESSAGE("");
			this.SET_LOADING(false);
		},

		criarChaveTermo() {
			const chave = btoa(btoa(this.acordo.emp_codigo + "_" + this.acordo.aco_codigo + "_" + this.acordo.pessoa.pes_codigo));
			return {
				chave: chave
			};
		},

		showPaymentOptionsAgreement() {
			if (this.acordoSelecionado === this.valorAcordoCodigo + " " + "PaymentOptionsAgreement") this.SET_ACORDO_SELECIONADO(window.screen.width > 760 ? "" : this.valorAcordoCodigo);

			else {
				this.SET_ACORDO_SELECIONADO(this.valorAcordoCodigo + " " + "PaymentOptionsAgreement");
			}
		},

		showOrHideButtons(value) {
			this.SET_ACORDO_SELECIONADO(value ? "" : this.valorAcordoCodigo);
		},

		carregarTermoDeConfissaoCamec() {
			window.open(this.acordo.dadosAcordo.aco_termo_anexo);
		},
	},

	data() {
		return {
			showComponent: 0,
			PDF: {},
			imgError: false,

			infoAcordo: {

				valor_total: this.acordo.valor_total === undefined ? "___________" : this.acordo.valor_total,

				parcelas: {
					parcelas: this.acordo.dadosAcordo.parcelas.length == 0 ? "_________" : this.acordo.dadosAcordo.parcelas.length,
					valor: this.acordo.dadosAcordo.parcelas[0].valor === undefined ? "___________" : this.acordo.dadosAcordo.parcelas[0].valor,
					ultimoVencimento: this.acordo.dadosAcordo.parcelas[this.acordo.dadosAcordo.parcelas.length == 0 ? 0 : this.acordo.dadosAcordo.parcelas.length - 1].vencimento
				},

				vencimento: this.acordo.dadosAcordo.parcelas[0].vencimento === undefined ? "___________________" : this.acordo.dadosAcordo.parcelas[0].vencimento,

				pessoa: {
					nome: this.$store.state.passosRetornarDividas.pessoa.nome === undefined ? "_______________________________________" : this.$store.state.passosRetornarDividas.pessoa.nome,

					email: this.$store.state.passosRetornarDividas.pessoa.email === undefined ? "____________________________" : this.$store.state.passosRetornarDividas.pessoa.email,

					cpfCnpj: this.$store.state.passosRetornarDividas.pessoa.cpfCnpj === undefined ? "_____________________" : this.$store.state.passosRetornarDividas.pessoa.cpfCnpj,

					cep: this.$store.state.passosRetornarDividas.pessoa.endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_cep === undefined ? "__________-______" : this.$store.state.passosRetornarDividas.pessoa.endereco.pee_cep,

					endereco: this.$store.state.passosRetornarDividas.pessoa.endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_endereco == "end" ? "________________________________________________________" : this.$store.state.passosRetornarDividas.pessoa.endereco.pee_endereco,

					numero: this.$store.state.passosRetornarDividas.pessoa.endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_numero === undefined ? "_________" : this.$store.state.passosRetornarDividas.pessoa.endereco.pee_numero,

					bairro: this.$store.state.passosRetornarDividas.pessoa.endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_bairro === undefined ? "____________________________" : this.$store.state.passosRetornarDividas.pessoa.endereco.pee_bairro,

					cidade: this.$store.state.passosRetornarDividas.pessoa.endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_cidade === undefined ? "____________________________" : this.$store.state.passosRetornarDividas.pessoa.endereco.pee_cidade,

					estado: this.$store.state.passosRetornarDividas.pessoa.endereco === undefined || this.$store.state.passosRetornarDividas.pessoa.endereco.pee_estado === undefined ? "_____" : this.$store.state.passosRetornarDividas.pessoa.endereco.pee_estado,

					observacao: this.acordo.dadosAcordo.aco_codigo === undefined ? "____________________________" : this.acordo.dadosAcordo.aco_codigo
				},

				loja: {
					nome: this.acordo.loja.nome_loja === undefined ? "____________________________" : this.acordo.loja.nome_loja,

					cpf_cnpj: this.acordo.loja.loj_cpf_cnpj === undefined ? "____________________________" : this.acordo.loja.loj_cpf_cnpj,

					endereco: this.acordo.loja === undefined || this.acordo.loja.loj_endereco === undefined || this.acordo.loja.loj_endereco == "end" ? "________________________________________________________" : this.acordo.loja.loj_endereco,

					numero: this.acordo.loja === undefined || this.acordo.loja.loj_numero === undefined ? "_________" : this.acordo.loja.loj_numero,

					bairro: this.acordo.loja === undefined || this.acordo.loja.loj_bairro === undefined ? "____________________________" : this.acordo.loja.loj_bairro,

					cep: this.acordo.loja === undefined || this.acordo.loja.loj_cep === undefined ? "__________-______" : this.acordo.loja.loj_cep,

					cidade: this.acordo.loja === undefined || this.acordo.loja.loj_cidade === undefined ? "____________________________" : this.acordo.loja.loj_cidade,

					estado: this.acordo.loja === undefined || this.acordo.loja.loj_estado === undefined ? "_____" : this.acordo.loja.loj_estado
				}
			}
		};
	}
};
</script>

<style scoped lang="scss">
.bg-page {
	box-sizing: border-box !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.background-card {
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-top: 15px;
	margin-bottom: 5px;

	@media only screen and (max-width: 470px) {
		padding: 5px 20px;
	}
}

.debit-card {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 760px) {
		flex-direction: column;
		height: auto;
		max-width: 100%;
		width: auto;
	}
}

.img-box {
	width: 110px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		display: var(--hidebuttons);
	}
}

.img-box img {
	object-fit: cover;
	width: 110px;
}

.container-info {
	flex: 1;
	height: auto;
	margin-left: 30px;
	padding-left: 30px;
	border-left: 1px solid #f0f0f0;
	width: 100%;

	@media only screen and (max-width: 760px) {
		border: 0;
		padding: 0;
		margin: 0;
	}
}

.info {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 760px) {
		justify-content: space-around;
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		text-align: center;
	}
}

.info div {
	@media only screen and (max-width: 470px) {
		width: 100% !important;
		text-align: center;
	}
}

.box-buttons {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media only screen and (max-width: 470px) {
		display: var(--hidebuttons);
		margin-top: 0px;
		flex-direction: column;
		padding: 5px 20px;
	}
}

.box-buttons div {
	@media only screen and (max-width: 470px) {
		flex-direction: column;
		width: 100% !important;
		margin: 5px 0;
		padding: 0 !important;
	}
}

.row-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	max-width: 250px;
	transition-duration: 0.2s;

	h6 {
		color: #5b6987;
		font-family: "Roboto";
		font-size: 12px;
		margin-right: 5px;
		text-align: left;
	}

	span {
		color: #5b6987;
		font-family: "Roboto Bold";
		font-size: 12px;
	}

	p {
		color: #031b4e;
		font-family: "Roboto Black";
		text-transform: uppercase;
		font-size: 16px;
		width: 230px;
	}
}

.row-totals {
	display: flex;
	flex-direction: row;

	@media only screen and (max-width: 760px) {
		flex-direction: column;
		margin-left: 20px;
		display: var(--hidebuttons);
		width: 100% !important;
		margin: 10px 0 !important;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding: 5px 20px;
	}
}

.totals {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 15px;

	@media only screen and (max-width: 760px) {
		margin-bottom: 20px;
	}

	@media only screen and (max-width: 470px) {
		margin: 0 !important;
	}
}

.totals h6 {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 11px;
}

.totals h2 {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 24px;

	@media only screen and (min-width: 761px) and (max-width: 1120px) {
		font-size: 18px;
	}

	@media only screen and (max-width: 760px) {
		font-size: 18px;
	}
}

.box-options-payment {
	margin-top: 50px;
}

.box-options-payment h2 {
	font-size: 24px;
	text-align: center;
	font-family: "Roboto Black";
	color: #031b4e;
}

.column-options-payment {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
}

.paymentFormRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 60px;
	align-items: center;
	padding: 0 20px;
	border-bottom: 1px solid var(--grayColor);
}

.paymentFormRow h4 {
	font-size: 18px;
	font-family: "Roboto Bold";
	color: #031b4e;
}

.paymentFormRow i {
	font-size: 24px;
}

.details-option {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
}

.details-option h2 {
	font-size: 24px;
	text-align: center;
	font-family: "Roboto Black";
	color: var(--blackColor);
}

.detail-row {
	padding-top: 20px;
	display: flex;
	flex-direction: row;
	width: 500px;
	justify-content: space-between;
	align-items: center;
}

.detail-row-icon {
	width: 50px;
	display: flex;
	justify-content: center;
}

.detail-row-icon i {
	font-size: 38px;
}

.detail-row-label {
	font-size: 18px;
	font-family: "Roboto Bold";
}

.detail-row-value {
	font-size: 18px;
	font-family: "Roboto Bold";
}

.container-button {
	padding-left: 20px;
	width: 100%;
}

.totals span {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 11px;
}

span.tag-porcent {
	background: var(--primaryColor);
	padding: 3px 5px;
	color: #ffffff;
	font-family: "Roboto Bold";
	font-size: 10px;
	border-radius: 27px;
}

.small-button {
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
}

.small-button:hover,
.small-button:hover i {
	background: var(--blackColor);
	cursor: pointer;
	color: #ffffff;
}

.small-button i {
	color: var(--blackColor);
}

.inner-info {
	display: flex;
	flex-direction: column;

	i {
		display: none;
		transition-duration: 0.2s;

		@media only screen and (max-width: 760px) {
			display: inline;
		}
	}

	@media only screen and (max-width: 760px) {
		padding: 20px;
		transition-duration: 0.2s;
	}
}
</style>
