<template>
	<div
		class="contact"
		:style="fundo"
		style="display: flex; justify-content: center; align-items: center; margin-top: -100px">

		<div
			class="container"
			:style="corPrimaria">

			<h3 :style="portalCamec ? {'color' : '#333'} : ''">Entre em contato conosco</h3>
			<div class="contact-row">
			
				<div
					class="contact-details"
					:style="portalCamec ? {'justify-content': 'center'} : ''">
					<div v-if="!portalCamec">

						<p :style="corPrimaria">Ficou com alguma dúvida? Entre em contato com a nossa equipe de suporte.</p>
						<div
							v-if="temWhatsapp"
							class="contact-social mt-5 pt-5"
							:style="corPrimaria"
							@click="goToSite(linkParaMensagemDoWhatsapp, 15, 'WhatsApp página inicial')">

							<i class="fab fa-whatsapp" :style="corIcones"></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.whatsapp }}</h6>
						</div>

						<div
							v-else-if="!parceiro"
							class="contact-social mt-5 pt-5"
							:style="corPrimaria">

							<i class="fab fa-whatsapp" :style="corIcones"></i>
							<h6 :style="corContatosValidu">(42) 99983-2385</h6>
						</div>

						<div
							v-if="temTelefone"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-phone" :style="corIcones" ></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.phone }}</h6>
						</div>

						<div
							v-else-if="!parceiro"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-phone" ></i>
							<h6 :style="corContatosValidu">(42) 3028-6061</h6>
						</div>

						<div
							v-if="temZeroOitecentos"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-phone" :style="corIcones" ></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.phoneZeroEightHundred }}</h6>
						</div>

						<div
							v-if="temEmail"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-envelope" :style="corIcones" ></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.email }}</h6>
						</div>

						<div
							v-else-if="!parceiro"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-envelope" ></i>
							<h6 :style="corContatosValidu">suporte3@aquicob.com.br</h6>
						</div>

						<div
							v-if="temSite"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-globe" :style="corIcones" ></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.site }}</h6>
						</div>

						<div
							v-if="temEndereco"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-map-marker-alt" :style="corIcones" ></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.address }}</h6>
						</div>

						<div
							v-else-if="!parceiro"
							class="contact-social">
							
							<i class="fas fa-map-marker-alt" ></i>
							<h6 :style="corContatosValidu">R. , 195 - Oficinas - Ponta Grossa - PR</h6>
						</div>

						<div
							v-if="temDadosEmpresa"
							class="contact-social"
							:style="corPrimaria">

							<i class="fas fa-pen" :style="corIcones"></i>
							<h6 :style="corContatosValidu">{{ parceiroConfig.sessoes.contact.campos.dadosEmpresa }}</h6>
						</div>

						<div
							v-if="temHorarioDeFuncionamento"
							class="contact-social"
							:style="corPrimaria">

							<i class="far fa-clock" :style="corIcones"></i>
							<div style="display: flex; flex-direction: column">
								<h6 :style="corContatosValidu">{{ horarioDeFuncionamento }}</h6>
								<h6 :style="corContatosValidu" v-if="funcionaFimDeSemana">{{ horarioDeFuncionamentoFimDeSemana }}</h6>
							</div>
						</div>

						<div class="social-circle-row">
							<div v-if="temFacebook"
								class="social-circle"
								:style="corPrimaria"
								@click="goToSite(parceiroConfig.sessoes.contact.campos.url_facebook, 29)">

								<i class="fab fa-facebook-f"></i>
							</div>
							<div v-else-if="!parceiro"
								class="social-circle"	
								:style="corPrimaria"
								@click="goToSite('https://pt-br.facebook.com/aquicob/', 29)">

								<i class="fab fa-facebook-f" ></i>
							</div>

							<div 
								v-if="temInstagram"
								class="social-circle"
								:style="corPrimaria"
								@click="goToSite(parceiroConfig.sessoes.contact.campos.url_instagram, 30)">

								<i class="fab fa-instagram" ></i>
							</div>
							<div
								v-else-if="!parceiro"
								class="social-circle"
								:style="corPrimaria"
								@click="goToSite('https://www.instagram.com/aquicob/', 30, 'Acessou instagram aquicob')">

								<i class="fab fa-instagram" ></i>
							</div>

							<div
								v-if="temWhatsapp"
								class="social-circle"	
								@click="goToSite(linkParaMensagemDoWhatsapp, 15, 'WhatsApp página inicial')">

								<i class="fab fa-whatsapp" ></i>
							</div>

							<div
								v-if="temTelegram"
								class="social-circle"
								@click="goToSite('https://t.me/' + parceiroConfig.sessoes.contact.campos.telegram, 21)">

								<i class="fab fa-telegram" ></i>
							</div>

							<div
								v-if="temLinkedIn"
								class="social-circle"
								@click="goToSite(parceiroConfig.sessoes.contact.campos.linkedin, 18)">

								<i class="fab fa-linkedin-in" ></i>
							</div>
						</div>
					</div>
					<div v-else style="display: flex; justify-content: center;">
						<div
							@click="goToSite(linkParaMensagemDoWhatsapp, 15, 'WhatsApp página inicial')"
							class="estilo-contatos-validu">
							<i class="fab fa-whatsapp fa-4x" style="color: rgb(24, 180, 24)"></i>
							<p>Contato por</p>
							<p>Whatsapp</p>
						</div>

						<a
							class="estilo-contatos-validu"
							style="text-decoration: none;"
							:href="`mailto:${this.parceiroConfig.sessoes.contact.campos.email}`">
							<i class="fas fa-envelope-open-text fa-4x" style="color: gold;"></i>
							<p>Contato por</p>
							<p>E-mail</p>
						</a>
					</div>
				</div>

				<div
					v-if="!portalCamec"
					class="contact-img">

					<div class="form">
						<input
							id="inputname"
							v-model="name"
							type="text"
							placeholder="Nome" />

						<input
							id="inputemail"
							v-model="email" 
							type="text"
							placeholder="E-mail" />

						<the-mask
							id="inputidentificationcontact"
							v-model="identification"
							placeholder="CPF/CNPJ"
							pattern="[0-9]*"
							inputmode="numeric"
							:mask="['###.###.###-##', '##.###.###/####-##']" />

							<textarea 
								id="inputtext"
								v-model="text"
								placeholder="Como podemos ajudar?" ></textarea>

						<div id="userAgreement">
							<input
								v-model="agreement"
								type="checkbox" />

							<span>Declaro estar ciente e autorizo a {{ nomeParceiro }} a utilizar os dados pessoais acima solicitados para responder a mensagem por mim enviada.</span>
						</div>

						<button-default
							class="form-button"
							:color="corEstiloBotao"
							text="Enviar mensagem"
							:disable="!agreement"
							@click.native="sendMessage" ></button-default>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { mapState, mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";

export default {
	name: "LandPageContact",

	components: { TheMask, ButtonDefault },

	mixins: [globalMethods, mixins],

	data() {
		return {
			name: "",
			email: "",
			identification: "",
			text: "",
			agreement: false
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria,
			portalCamec: (estado) => estado.portalCamec
		}),

		fundo() {
			if (this.parceiroConfig.emp_codigo == 350) {
				return {
					"background-color": "#FFF"
				};
			} else {
				return {
					"--fundoFull": this.parceiro && this.parceiroConfig.sessoes.contact.arquivos && this.parceiroConfig.sessoes.contact.arquivos.length > 0 ?
					"url(" + this.parceiroConfig.sessoes.contact.arquivos[0].arquivo + ")" : "url(" + require(`../../../assets/images/background-cyan-darkblue.png`) + ")"
				};
			}
		},

		horarioDeFuncionamento() {
			return `Horário de funcionamento: ${ this.parceiroConfig.sessoes.contact.campos.initialTime } às ${ this.parceiroConfig.sessoes.contact.campos.finalTime } de Segunda a Sexta`;
		},

		temHorarioDeFuncionamento() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.initialTime && this.parceiroConfig.sessoes.contact.campos.initialTime.length > 0
					&& this.parceiroConfig.sessoes.contact.campos.finalTime && this.parceiroConfig.sessoes.contact.campos.finalTime.length > 0;
		},

		funcionaFimDeSemana() {
			return this.parceiroConfig.sessoes.contact.campos.initialTimeWeekend && this.parceiroConfig.sessoes.contact.campos.initialTimeWeekend.length > 0 
					&& this.parceiroConfig.sessoes.contact.campos.finalTimeWeekend && this.parceiroConfig.sessoes.contact.campos.finalTimeWeekend.length > 0;
		},
		
		horarioDeFuncionamentoFimDeSemana() {
			return `e nos Sábados ${ this.parceiroConfig.sessoes.contact.campos.initialTimeWeekend } às ${ this.parceiroConfig.sessoes.contact.campos.finalTimeWeekend }`;
		},

		linkParaMensagemDoWhatsapp() {
			return `https://api.whatsapp.com/send?phone=55${this.removeMaskCharacters(this.parceiroConfig.sessoes.contact.campos.whatsapp)}&text=%23Olá estou vindo do portal ${this.parceiro.toUpperCase()}`;
		},

		temWhatsapp() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.whatsapp && this.parceiroConfig.sessoes.contact.campos.whatsapp.length > 0;
		},

		temTelefone() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.phone && this.parceiroConfig.sessoes.contact.campos.phone.length > 0;
		},

		temZeroOitecentos() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.phoneZeroEightHundred && this.parceiroConfig.sessoes.contact.campos.phoneZeroEightHundred.length > 0;
		},

		temEmail() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.email && this.parceiroConfig.sessoes.contact.campos.email.length > 0;
		},

		temSite() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.site && this.parceiroConfig.sessoes.contact.campos.site.length > 0;
		},

		temEndereco() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.address && this.parceiroConfig.sessoes.contact.campos.address.length > 0;
		},

		temDadosEmpresa() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.dadosEmpresa && this.parceiroConfig.sessoes.contact.campos.dadosEmpresa.length > 0;
		},

		temFacebook() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.url_facebook && this.parceiroConfig.sessoes.contact.campos.url_facebook.length > 0;
		},

		temInstagram() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.url_instagram && this.parceiroConfig.sessoes.contact.campos.url_instagram.length > 0;
		},

		temLinkedIn() {
			return this.parceiro && this.parceiroConfig.sessoes.contact.campos.linkedin && this.parceiroConfig.sessoes.contact.campos.linkedin.length > 0;
		},

		temTelegram() {
			return this.parceiroConfig.sessoes.contact.campos != null &&
				this.parceiroConfig.sessoes.contact.campos.telegram != null &&
				this.parceiroConfig.sessoes.contact.campos.telegram != undefined;
		},

		corEstiloBotao() {
			return this.corPrimaria.color;
		},

		corIcones() {
			return this.parceiroConfig.identificador == "mw" ? this.corSecundaria : this.corPrimaria;
		},

		corContatosValidu() {
			return this.parceiroConfig.emp_codigo == 350 ? {'color': '#666'} : '';
		}
	},

	methods: {
		...mapActions(["action_enviar_email"]),

		goToSite(url, esn_fk_ets_codigo, observacao = "") {
			this.acaoSalvarEtapa({
				cpfCnpj: this.removeMaskCharacters(this.identification),
				esn_fk_ets_codigo,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: observacao,
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual
			});

			let win = window.open(url, "_blank");
			win.focus();
		},

		sendMessage() {
			if (
				!this.name ||
				this.name.length < 3 ||
				this.name.length > 20 ||
				this.name
					.toString()
					.split(".")
					.join("").length === 0
			) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Nome é obrigatório!",
					message: `Por favor, digite o seu nome completo`,
					styleMessage: "danger"
				});

			} else if (/.+@.+\..+/.test(this.email) === false) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "E-mail é obrigatório!",
					message: `Por favor, digite um e-mail válido`,
					styleMessage: "danger"
				});

			} else if (!this.validIdentification(this.identification)) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "CPF/CNPJ é obrigatório!",
					message: `Por favor, digite um CPF/CNPJ válido`,
					styleMessage: "danger"
				});

			} else if (this.text.length < 10 || this.text.length > 300) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Mensagem é obrigatória!",
					message: (this.text.length < 10 ? "Seu texto está muito curto. " : "Seu texto está muito longo. ") + `Por favor, digite uma mensagem do tamanho válido.`,
					styleMessage: "danger"
				});

			} else {
				this.action_enviar_email({
					emp_codigo: "262",
					pes_codigo: "138",
					loj_codigo: "2",
					pem_email: this.parceiroConfig.sessoes.contact.campos.email,
					assunto: "Ajuda em relação ao portal na sessão contato",
					texto_email: "Nome: " + this.name + "<br>" + "CPF/CNPJ: " + this.identification + "<br>" + "E-mail: " + this.email + "<br>" + "Mensagem: " + this.text

				}).then((resposta) => {
						this.name = "";
						this.email = "";
						this.identification = "";
						this.text = "";
						if (resposta?.data?.mensagem == "E-mail enviado com sucesso.") {
							this.CHANGE_MESSAGE_SNACKBAR("E-mail enviado com sucesso!");
						} else {
							throw new Error("Erro ao enviar e-mail");
						}
						setTimeout(() => this.CHANGE_MESSAGE_SNACKBAR(""), 5000);
					}).catch((erro) => {
						if (erro.response) {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção!",
								message: `Ocorreu um erro ao enviar o e-mail. Tente novamente mais tarde`,
								styleMessage: "danger"
							});
						}
					});
			}
		},

		validIdentification(ident) {
			if (ident.length == 11) {
				var Soma;
				var Resto;
				Soma = 0;
				var strCPF = ident;
				if (strCPF == "00000000000") return false;

				for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
				Resto = (Soma * 10) % 11;

				if (Resto == 10 || Resto == 11) Resto = 0;
				if (Resto != parseInt(strCPF.substring(9, 10))) return false;

				Soma = 0;
				for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
				Resto = (Soma * 10) % 11;

				if (Resto == 10 || Resto == 11) Resto = 0;
				if (Resto != parseInt(strCPF.substring(10, 11))) return false;

				return true;

			} else if (ident.length == 14) {
				var c = ident;
				var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

				if ((c = c.replace(/[^\d]/g, "")).length != 14) return false;

				if (/0{14}/.test(c)) return false;

				for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
				if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

				for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
				if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.contact {
	padding: 60px 0;
	width: 100%;
	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-image: var(--fundoFull);
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media screen and (min-height: 1px) and (max-height: 1200px) {
		margin-top: 150px;
	}

	@media only screen and (max-width: 1290px) {
		background-size: 100% 100%;
		background-attachment: initial;
	}

	h3 {
		text-align: left;
		font-size: 32px;
		font-family: "Montserrat-Bold";

		color: var(--whiteColor);
		line-height: 48px;
		margin-bottom: 30px;
		@media only screen and (max-width: 1290px) {
			text-align: center;
		}
	}

	p {
		color: var(--lightGrayColor);
		font-family: Cabin;
		font-size: 20px;
		@media only screen and (max-width: 1290px) {
			margin-top: 30px;
		}
	}
}

.contact-row {
	display: flex;
	flex-direction: row;
	@media only screen and (max-width: 1290px) {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
	}
}

.contact-details {
	width: 450px;
	box-sizing: border-box;
	@media only screen and (max-width: 1290px) {
		width: 500px;
		padding: 0 30px;
	}
	@media only screen and (max-width: 766px) {
		width: 85vw;
		padding: 0;
	}
}

.contact-social {
	display: flex;
	align-items: center;
	margin: 30px 0;
	transition: all 0.2s ease-in-out;
	border: 2px solid transparent;
	border-radius: 27px;
	padding: 10px 30px;
	i {
		font-size: 24px;
		color: var(--color);
		margin-right: 15px;
		transition: all 0.2s ease-in-out;
	}
	h6 {
		color: var(--whiteColor);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
	&:hover {
		background-color: var(--hoverColor);
		border-color: var(--whiteColor);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		i {
			color: var(--whiteColor);
			transition: all 0.2s ease-in-out;
			cursor: pointer;
		}
		h6 {
			color: var(--whiteColor);
			transition: all 0.2s ease-in-out;
			cursor: pointer;
		}
	}
}

.contact-img {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	.form {
		text-align: center;
		background: var(--whiteColor);
		width: 500px;
		height: 460px;
		display: flex;
		border-radius: 7px;
		box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07);
		padding: 15px 30px;
		flex-direction: column;
		box-sizing: border-box;
		@media only screen and (max-width: 766px) {
			width: 85vw;
			padding: 10px 20px;
		}
		input {
			color: #595959;
			font-family: "Montserrat-Bold";
			height: 50px;
			border: 0;
			border-bottom: 1px solid var(--hoverColor);
			margin: 15px 0;
			font-size: 20px;
			color: var(--hoverColor);
			&::placeholder {
				color: #595959;
				font-family: "Montserrat-Bold";
				font-size: 18px;
			}
		}
		textarea {
			color: #595959;
			resize: none;
			width: 100%;
			font-family: "Montserrat-Bold";
			font-size: 20px;
			height: 100px;
			border: 0;
			border-bottom: 1px solid var(--hoverColor);
			margin: 15px 0;
			color: var(--hoverColor);
			&::placeholder {
				color: #595959;
				font-family: "Montserrat-Bold";
				font-size: 18px;
			}
		}
		button {
			width: 100%;
			height: 50px;
			border-radius: 25px;
			background: var(--color);
			color: var(--whiteColor);
			border: 0;
			font-size: 18px;
			font-family: Cabin;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			&:hover {
				background-color: var(--hoverColor);
				border-color: var(--whiteColor);
				transition: all 0.2s ease-in-out;
				cursor: pointer;
			}
		}
	}
}
.social-circle-row {
	display: flex;
	align-items: center;
	margin-top: 50px;
	padding: 0 28px;
	@media only screen and (max-width: 1290px) {
		justify-content: space-around;
		margin-top: 50px;
	}
}

.social-circle {
	cursor: pointer;
	width: 60px;
	height: 60px;
	background: var(--color);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--whiteColor);
	margin-right: 30px;
	transition: all 0.2s ease-in-out;
	@media only screen and (max-width: 1290px) {
		margin-right: 0;
	}
	&:hover {
		background-color: var(--hoverColor);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
	i {
		font-size: 20px;
	}
}

@media only screen and (min-width: 1400px) {
	.contact-details {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		padding-right: 50px;
	}
	.contact-img {
		padding-left: 50px;
		flex: 1;
		justify-content: flex-start;
	}

	.contact h3 {
		text-align: center;
	}
}

#userAgreement {
	display: flex;
	align-items: center;
	span {
		text-align: justify;
	}
	input {
		background-color: #f9f9f9;
		border: 6px solid #4d90fe;
		border-radius: 40px;
		border-bottom-color: transparent;
		border-left-color: transparent;
		height: 40px;
		left: -4px;
		outline: 0;
		top: -4px;
		width: 40px;
		box-sizing: border-box;
		animation: spinner-spin linear 2.5s infinite;
		animation-play-state: paused;
		transition-duration: 1s;
		margin-right: 6px;
	}
}

.cor-texto-validu {
	color: #000;
}

.estilo-contatos-validu {
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.estilo-contatos-validu:hover {
	cursor: pointer;
}

.estilo-contatos-validu p {
	margin: 0;
	font-size: 18px;
}
</style>
