<template>
  <div class="container-my-debits">
    <template>
      <div class="header-debits">
        <i
          :style="{ color: parceiroConfigCorSecundariaCorPrimaria }"
          class="fas fa-times"
          @click="$router.push({ name: 'AccordionTabs' })"
        ></i>
      </div>

      <h3 :style="{ color: parceiroConfigCorSecundariaCorPrimaria }">
        {{ tituloPagina }}
      </h3>

      <p class="message-sub">{{ temAcordos }}</p>

      <div
        v-if="parceiroConfig.sessoes.customization.campos.myDebits.textChavePix"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <img
          src="../../../assets/images/pix-106.svg"
          style="margin-bottom: 10px"
        />
        <p class="message-sub">Aceitamos pagamentos por PIX.</p>
        <p class="message-sub">
          Nossa chave é PIX:
          {{
            parceiroConfig.sessoes.customization.campos.myDebits.textChavePix
          }}
        </p>
      </div>

      <agreement-card
        v-for="acordo in acordos"
        :key="acordo.aco_codigo"
        :acordo="acordo"
      ></agreement-card>

      <div
        v-if="
          parceiroConfig.sessoes.customization.campos.myAgreements.flgPaginar &&
          quantidadeDeAcordos != 0
        "
        style="
          display: flex;
          margin: 20px;
          align-items: center;
          width: 450px;
          justify-content: space-between;
        "
      >
        <p style="margin-right: 15px; width: 100px">
          Página {{ this.paginaAtual }} de {{ this.quantidadeDePaginas }}
        </p>
        <div style="display: flex; justify-content: space-evenly; width: 350px">
          <button-default
            text="<<"
            @click.native="paginar('PRI')"
          ></button-default>
          <button-default
            text="<"
            @click.native="paginar('ANT')"
          ></button-default>
          <button-default
            text=">"
            @click.native="paginar('PRO')"
          ></button-default>
          <button-default
            text=">>"
            @click.native="paginar('ULT')"
          ></button-default>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, createNamespacedHelpers } from "vuex";
import mixins from "@/mixins/mixins";

import Template from "@/views/admin/Template";
import AgreementCard from "./components/AgreementCard";
import ButtonDefault from "@/components/buttons/ButtonDefault";

const { mapActions: mapActionsPassosRetornarDividas } = createNamespacedHelpers(
  "passosRetornarDividas"
);

export default {
  name: "MyAgreements",

  components: { AgreementCard, Template, ButtonDefault },

  mixins: [mixins],

  data() {
    return {
      mensagem: "Você ainda não possui acordos.",
      acordos: [],
      itensPorPagina: 5,
      paginaAtual: 1,
      quantidadeDeAcordos: 0,
      quantidadeDePaginas: 0,
    };
  },

  computed: {
    ...mapState({
      empresas: (estado) => estado.empresas,
      lojas: (estado) => estado.passosRetornarDividas.lojas,
    }),

    tituloPagina() {
      return this.parceiroConfig.sessoes.customization.campos.myAgreements
        .textMyAgreements
        ? this.parceiroConfig.sessoes.customization.campos.myAgreements
            .textMyAgreements
        : "Meus Acordos";
    },

    mensagemDeQuantidadeDeAcordos() {
      return (
        "Você possui " +
        this.quantidadeDeAcordos +
        " " +
        (this.parceiroConfig.sessoes.customization.campos.myAgreements
          .subtextPanelMyAgreements
          ? this.parceiroConfig.sessoes.customization.campos.myAgreements
              .subtextPanelMyAgreements
          : this.quantidadeDeAcordos == 1
          ? "acordo"
          : "acordos")
      );
    },

    mensagemSemAcordos() {
      return this.parceiroConfig.sessoes.customization.campos.myAgreements
        .subtextPanelMyAgreementsNotFound
        ? this.parceiroConfig.sessoes.customization.campos.myAgreements
            .subtextPanelMyAgreementsNotFound
        : this.mensagem;
    },

    temAcordos() {
      if (this.acordos.length > 0) {
        return this.mensagemDeQuantidadeDeAcordos;
      } else {
        return this.mensagemSemAcordos;
      }
    },
  },

  created() {
    this.SET_LOADING(true);
    this.SET_LOADING_MESSAGE("Carregando os seus acordos.");

    if (this.lojas.length == 0) {
      this.SET_LOADING(false);
      this.SET_LOADING_MESSAGE("");
    } else {
      this.lojas.forEach((loja) => {
        let obj = {
          lojCodigo: loja.loj_codigo,
        };

        if (
          this.parceiroConfig.sessoes.customization.campos.myAgreements
            .flgPaginar
        ) {
          obj = {
            ...obj,
            paginaAtual: this.paginaAtual,
            itensPorPagina: this.itensPorPagina,
            pagina: "PRI",
          };
        }

        this.acaoPasso5RetornarAcordo({
          ...obj,
        }).then((resposta) => {
          if (resposta.dados !== null) {
            for (const acordo in resposta.dados.acordos) {
              this.quantidadeDeAcordos = resposta.dados.quantidade_de_acordos;
              if (
                this.parceiroConfig.sessoes.customization.campos.myAgreements
                  .flgPaginar
              ) {
                this.quantidadeDePaginas =
                  resposta.dados.paginacao.quantidade_de_paginas;
              }

              this.acordos.push({
                dadosAcordo: resposta.dados.acordos[acordo],
                loja,
              });
            }
            this.SET_LOADING(false);
            this.SET_LOADING_MESSAGE("");
          }
        });
      });
    }
  },

  mounted() {
    if (
      this.parceiroConfig.identificador == "mw" &&
      this.$route.query.formaDePagamento == "cartao"
    ) {
      let titulo = "";
      let mensagem = "";
      let estilo = "danger";
      switch (this.$route.query.estadoDaTransacao) {
        case "PAID":
          titulo = "Pagamento";
          estilo = "success";
          mensagem = "Pagamento realizado com sucesso!";
          break;
        case "REJECTED":
          titulo = "Rejeitado";
          mensagem = "Pagamento rejeitado";
          break;
        case "VOIDED":
          titulo = "Cancelado";
          mensagem = "Cobrança cancelada";
          break;
      }

      this.CHANGE_MESSAGE_MODAL({
        show: true,
        title: titulo,
        message: mensagem,
        styleMessage: estilo,
      });
    }
  },

  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_LOADING_MESSAGE",
      "CHANGE_MESSAGE_MODAL",
    ]),

    ...mapActionsPassosRetornarDividas(["acaoPasso5RetornarAcordo"]),

    paginar(pagina) {
      this.acordos = [];

      this.lojas.forEach((loja) => {
        this.acaoPasso5RetornarAcordo({
          lojCodigo: loja.loj_codigo,
          itensPorPagina: this.itensPorPagina,
          paginaAtual: this.paginaAtual,
          pagina,
        }).then((resposta) => {
          if (resposta.dados !== null) {
            this.paginaAtual = resposta.dados.paginacao.pagina_atual;

            for (const acordo in resposta.dados.acordos) {
              this.acordos.push({
                dadosAcordo: resposta.dados.acordos[acordo],
                loja,
              });
            }
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container-my-debits {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f5f9;
  flex: 1;
  box-sizing: border-box;
}

.header-debits {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1024px;
  cursor: pointer;
}

h3 {
  color: #031b4e;
  font-family: Montserrat-Bold;
  font-size: 28px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.header-debits i {
  font-size: 48px;
  color: #031b4e;
}

.message-sub {
  color: #5b6987;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
