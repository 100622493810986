<template>
	<div class="friends">
		<div class="container">

			<h3 style="color: #111;">Conheça nossos parceiros</h3>

			<p
				v-if="parceiro && parceiroConfig.sessoes.partners.campos"
				key="portal-parceiro-texto">

				{{ parceiroConfig.sessoes.partners.campos.text }}
			</p>

			<p
				v-else
				key="portal-aquicob">
				Conheça os parceiros que acreditam e confiam no {{ nomeParceiro }} Autonegociação.</p>

			<div
				v-if="!parceiro"
				key="portal-aquicob"
				class="friends-row">

				<img src="@/assets/images/parceiros/logo-clinipam.jpg" />
				<img src="@/assets/images/parceiros/logo-cred-jb.jpg" />
				<img src="@/assets/images/parceiros/logo-dentaluni.jpg" />
				<img src="@/assets/images/parceiros/logo_fastalways_transparente.png" />
				<img src="@/assets/images/parceiros/logo_hipercob.png" />
				<img src="@/assets/images/parceiros/logo_maxipas_1.png" />
				<img src="@/assets/images/parceiros/logo_nacional.png" />
				<img src="@/assets/images/parceiros/logo_tercred.png" />
				<img src="@/assets/images/parceiros/solut.png" />
			</div>

			<div
				v-else-if="parceiroConfig && imagensDosParceiros.length > 0"
				key="portal-parceiro"
				class="friends-row">

				<img
					v-for="parceiro in imagensDosParceiros"
					:key="parceiro.id"
					class="logo-parceiro"
					:src="parceiro.arquivo"
					alt="log-parceiro" />
			</div>
		</div>
	</div>
</template>

<script>
import mixins from "@/mixins/mixins";

export default {
	name: "LandPagePartner",

	mixins: [mixins],

	data() {
		return {
			imagensDosParceiros: []
		}
	},
	created() {
		this.imagensDosParceiros = []
		if (this.parceiroConfig && this.parceiroConfig.sessoes.partners.arquivos.length > 0) {
			this.imagensDosParceiros = this.parceiroConfig.sessoes.partners.arquivos
		}
	}
}
</script>

<style scoped lang="scss">
.friends {
	padding: 0;
}

.friends-row {
	text-align: center;
}

.friends h3 {
	text-align: center;
	font-size: 32px;
	font-family: "Montserrat-Bold";
	color: var(--secondaryColor);
	line-height: 48px;
	margin-bottom: 30px;
}

.friends p {
	text-align: center;
}

.friends-row img {
	margin: 30px;

	@media only screen and (max-width: 766px) {
		margin: 30px 10px;
	}
}

.logo-parceiro {
	max-width: 300px;
	max-height: 120px;
}
</style>
