<template>

	<div
		:style="fundoSlider"
		:class="!portalCamec ? 'slider' : 'slider-validu'">

		<div v-if="loginSenff" :style="estiloDivExernaLogin">
			<div :style="estiloDivInternaLogin">
				<img :src="parceiroConfig.logo">
				<p v-if="!telaMenor" style="color: #fff; font-size: 24px; padding: 0px 50px;"><strong>Negocie</strong> seus débitos com <strong>descontos</strong> e <strong>volte a comprar!</strong></p>
			</div>
			<div :style="estiloDivExternaRecaptcha">
				<div :style="estiloDivInternaRecaptcha">
					<h3 style="font-size: 22px; color: rgba(56, 64, 118, 0.9)">LOGIN</h3>
					<div style="display: flex; flex-direction: column; margin: 20px; width: 100%">
						<label for="input-cpf-cnpj" style="color: #7D7D7E">Digite seu CPF ou CNPJ</label>
						<the-mask
							id="input-cpf-cnpj"
							readonly
							style="border: none; border-bottom: 1px solid #7D7D7E; color: rgb(56, 64, 118); margin-top: 5px; font-family: 'Montserrat-Bold'; font-size: 20px;"
							:mask="['###.###.###-##', '##.###.###/####-##']"
							:value="cpfCnpjInput"></the-mask>
					</div>
					<div v-if="parceiroConfig.sessoes.customization == undefined ||
				 	   	parceiroConfig.sessoes.customization.campos.confirmBirthday == undefined ||
					   	parceiroConfig.sessoes.customization.campos.confirmBirthday.flgValidarTelefone"
						style="display: flex; flex-direction: column; margin: 20px; width: 100%">
						<label for="input-telefone" style="color: #7D7D7E">Telefone</label>
						<the-mask
							id="input-telefone"
							ref="telefone"
							v-model="telefone"
							pattern="[0-9]*"
							inputmode="numeric"
							:mask="['(##) ####-####', '(##) #####-####']"
							style="border: none; border-bottom: 1px solid #7D7D7E; color: rgb(56, 64, 118); font-size: 20px; font-family: 'Montserrat-Bold'; margin-top: 5px" ></the-mask>
					</div>
					<div class="box-buttons" style="display: flex; flex-direction: column; height: 140px; justify-content: space-between; margin-top: 10px;">
						<vue-recaptcha
							:sitekey="'6Lcd2rQpAAAAAABiBRByFn5pdaL-nYVMUVowVqLD'"
							class="vue-recaptcha"
							:loadRecaptchaScript="true"
							@verify="verified = true"></vue-recaptcha>

						<button
							:disabled="!verified"
							ref="botaoEntrar"
							@click="next" >Entrar</button>
					</div>
				</div>
			</div>
		</div>

		<div v-else style="display: flex; height: 100%;">
			<div
				v-if="parceiroConfig.sessoes.customization.campos.landPage.flgMaxWidth"
				:class="!portalCamec ? 'overlay' : 'overlay-validu'"
				:style="fundoOverlay">

				<div style="display:flex; flex-direction: column">
					<land-page-header :backgroundTransparent="!portalCamec" ></land-page-header>
					<land-page-search v-if="parceiroConfig.identificador != 'Senff'"></land-page-search>
					<land-page-search-senff
						v-else
						@proximo-passo="proximoPasso"></land-page-search-senff>
				</div>
				<div>
					<land-page-totals></land-page-totals>
				</div>
			</div>
			<div
				v-else
				:class="!portalCamec ? 'overlay' : 'overlay-validu'"
				:style="fundoOverlay">

				<land-page-header :backgroundTransparent="!portalCamec" ></land-page-header>
				<land-page-search v-if="parceiroConfig.identificador != 'Senff'"></land-page-search>
				<land-page-search-senff
					v-else
					@proximo-passo="proximoPasso"></land-page-search-senff>
				<div>
					<land-page-totals></land-page-totals>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, createNamespacedHelpers } from "vuex";
import mixins from "@/mixins/mixins";

import LandPageHeader from "./LandPageHeader";
import LandPageSearch from "./LandPageSearch";
import LandPageTotals from "./LandPageTotals";
import LandPageSearchSenff from './LandPageSearchSenff.vue';

import VueRecaptcha from "vue-recaptcha";
import { TheMask } from "vue-the-mask";

const { mapActions: mapActionsPassosRetornarDividas } = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "LandPageSlider",

	components: { LandPageTotals, LandPageSearch, LandPageHeader, LandPageSearchSenff, TheMask, VueRecaptcha },

	mixins: [mixins],

	data() {
		return {
			loginSenff: false,
			telefone: '',
			verified: false,
			cpfCnpjInput: '',
		}
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria,
			portalCamec: (estado) => estado.portalCamec,
			pessoa: (estado) => estado.passosRetornarDividas.pessoa,
			token: (estado) => estado.token,
			lojas: (estado) => estado.passosRetornarDividas.lojas,
			nomeParceiroContratos: (estado) => estado.nomeParceiroContratos
		}),

		fundoSlider() {
			let background = this.parceiro && this.parceiroConfig.sessoes.header.arquivos && this.parceiroConfig.sessoes.header.arquivos.length > 0 ?
				"url(" + this.parceiroConfig.sessoes.header.arquivos[0].arquivo + ")" :
				"url(" + require(`@/assets/images/background-blue.png`) + ")";

			let fundoMobile = background;
			if (this.parceiroConfig.sessoes.header.arquivos && this.parceiroConfig.sessoes.header.arquivos.length > 1) {
				fundoMobile = `url(${this.parceiroConfig.sessoes.header.arquivos[1].arquivo})`;
			} else if (this.parceiroConfig.sessoes.header.campos
					&& this.parceiroConfig.sessoes.header.campos.imagesMobile
					&& this.parceiroConfig.sessoes.header.campos.imagesMobile.length > 0) {
				fundoMobile = `url(${this.parceiroConfig.sessoes.header.campos.imagesMobile[0].arquivo})`;
			}

			if (this.parceiroConfig.emp_codigo == 350) {
				return {
					"--fundoMobile": fundoMobile,

					"--coverOrStretch": this.parceiroConfig.sessoes.customization.campos.landPage.flgStretch ? "100% 100%" : "cover"
				};
			}

			return {
				"--fundoFull": background,

				"--fundoMobile": fundoMobile,

				"--coverOrStretch": this.parceiroConfig.sessoes.customization.campos.landPage.flgStretch ? "100% 100%" : "cover"
			};
		},

		isOverlayActive: function() {
			return this.parceiroConfig.sessoes.customization.campos.landPage.flgOverlay == undefined ? true : !this.parceiroConfig.sessoes.customization.campos.landPage.flgOverlay;
		},

		fundoOverlay() {
			if (this.isOverlayActive) {
				return {
					"background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC) repeat"
				}
			}
		},

		estiloDivExternaRecaptcha() {
			const objetoEstiloDivExternaRecaptcha = {
				'background-color': 'rgba(255, 255, 255, 0.9)',
				'height': '100%',
				'backdrop-filter': 'blur(10px)',
				'display': 'flex',
				'flex-direction': 'column',
				'justify-content': 'center',
				'align-items': 'center'
			};

			if (window.innerWidth <= 500) {
				objetoEstiloDivExternaRecaptcha.width = '100%';
			} else {
				objetoEstiloDivExternaRecaptcha.width = '70%';
			}
			return objetoEstiloDivExternaRecaptcha;
		},

		estiloDivInternaRecaptcha() {
			const objetoEstiloDivInternaRecaptcha = {
				'background-color': '#fff',
				'border-radius': '7px',
				'display': 'flex',
				'flex-direction': 'column',
				'justify-content': 'center',
				'align-items': 'center',
				'padding': '20px' 
			};

			if (window.innerWidth <= 500) {
				objetoEstiloDivInternaRecaptcha.width = '90%';
				objetoEstiloDivInternaRecaptcha.margin = '20px';
			} else {
				objetoEstiloDivInternaRecaptcha.width = '500px';
			}
			return objetoEstiloDivInternaRecaptcha;
		},

		estiloDivExernaLogin() {
			const objetoEstiloDivExternaLogin = {
				'display': 'flex',
				'height': '100%'
			};

			if (window.innerWidth <= 500) {
				objetoEstiloDivExternaLogin['flex-flow'] = 'column';
			}
			return objetoEstiloDivExternaLogin;
		},

		estiloDivInternaLogin() {
			const objetoEstiloDivInternaLogin = {
				'background-color': 'rgba(14, 32, 96, 0.9)',
				'backdrop-filter': 'blur(10px)',
				'display': 'flex',
				'flex-direction': 'column',
				'justify-content': 'flex-start',
				'align-items': 'center'
			};

			if (window.innerWidth <= 500) {
				objetoEstiloDivInternaLogin.width = '100%';
				objetoEstiloDivInternaLogin.height = '150px';
			} else {
				objetoEstiloDivInternaLogin.width = '30%';
				objetoEstiloDivInternaLogin.height = '100%';
			}

			return objetoEstiloDivInternaLogin;
		},

		telaMenor() {
			return window.innerWidth <= 500;
		}
	},

	watch: {
		verified() {
			if (this.verified) {
				this.$refs.botaoEntrar.removeAttribute('disabled');
			} else {
				this.$refs.botaoEntrar.disabled = true;
			}
		}
	},

	methods: {
		...mapMutations(["SET_RECEBER_LIGACAO"]),

		...mapActionsPassosRetornarDividas(['acaoPasso1ConsultarCpf']),

		proximoPasso(cpfCnpj) {
			this.cpfCnpjInput = cpfCnpj;
			this.loginSenff = true;
		},

		dadosValidos() {
			if (this.parceiroConfig.sessoes?.customization == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarDataNasc)
				if (this.inputsValues.nascimento == "") {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Por favor",
						message: `Insira uma data de nascimento válida.`,
						styleMessage: "danger"
					});
					return false;
				}

			if (this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarTelefone)
				if (this.telefone == "" || this.telefone.length <= 9) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Por favor",
						message: `Insira um telefone válido.`,
						styleMessage: "danger"
					});
					return false;
				}

			return true;
		},

		next() {
			if (!this.dadosValidos()) {
				return -1;
			}

			if (this.verified) {
				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Confirmando os seus dados");
				this.receberCPFCNPJ();
			} else {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Por favor",
					message: `Conclua a verificação de segurança`,
					styleMessage: "danger"
				});
				return -1;
			}
		},

		receberCPFCNPJ() {
			this.acaoPasso1ConsultarCpf({
				pesCpfCnpj: this.removeMaskCharacters(this.cpfCnpjInput)
			}).then(resposta => {

				if (resposta.data.retorno) {

					if (resposta.data.dados == null) {
						this.$router.push({
							name: "Register",
							params: {
								identification: this.removeMaskCharacters(this.cpfCnpjInput)
							}
						});
						return -1;

					} else if (resposta.data.dados) {
						this.SET_RECEBER_LIGACAO(false);

						if (this.parceiroConfig.sessoes?.customization == undefined || this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday == undefined ||
							this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgValidarToken && this.parceiroConfig.identificador === "Senff") {

							this.$router.push({
								name: "VerifyToken",
								params: {
									fone: this.telefone
								}
							});
							this.SET_LOADING_MESSAGE("");
							this.SET_LOADING(false);

						} else if (this.parceiroConfig.sessoes?.customization?.campos?.menuDebits?.flgPrefeituraMode) {
							this.$router.push({ name: "MyDebits" });

						} else {
							this.SET_LOADING_MESSAGE("");
							this.SET_LOADING(false);
							this.$router.push({ name: "AccordionTabs" });
						}
					}

				} else if (!resposta.data.retorno && !resposta.data?.dados) {
					this.SET_LOADING(false);
					this.SET_LOADING_MESSAGE("");

					if (resposta.data.mensagem == "Erro ao consultar o usuário! 1") {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Data de nascimento incorreta",
							message: `Data de nascimento informada não condiz com a data do seu cadastro.`,
							styleMessage: "danger"
						});
						return -1;

					} else if (resposta.data.mensagem == "Erro ao consultar o usuário! 2") {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Telefone incorreto",
							message: `Telefone informada não condiz com o telefone do seu cadastro.`,
							styleMessage: "danger"
						});
						return -1;

					} else {

						if (this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgDesativaCadastro) {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Usuario não encontrado",
								message: `Você não possui nenhuma dívida ou acordo cadastrado em nosso banco de dados.`,
								styleMessage: "danger"
							});

						} else {
							this.$router.push({
								name: "Register",
								params: {
									identification: this.removeMaskCharacters(this.cpfCnpjInput)
								}
							});
						}
						return -1;
					}

				} else if (resposta.data.retorno && (resposta.data.dados === "CPF/CNPJ não encontrado!" || resposta.data.dados === null)) {
					this.SET_LOADING(false);
					this.SET_LOADING_MESSAGE("");

					if (this.parceiroConfig.sessoes?.customization?.campos?.confirmBirthday?.flgDesativaCadastro) {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Usuario não encontrado",
							message: `Você não possui nenhuma dívida ou acordo cadastrado em nosso banco de dados.`,
							styleMessage: "danger"
						});

					} else {
						this.$router.push({
							name: "Register",
							params: {
								identification: this.removeMaskCharacters(this.cpfCnpjInput)
							}
						});
					}

				} else {
					this.SET_LOADING_MESSAGE("");
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Erro",
						message: `Ocorreu um erro ao tentar encontrar o seu CPF`,
						styleMessage: "danger"
					});
					return -1;
				}

			}).catch(e => {
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Erro",
					message: "Não foi possível achar seu CPF em nossos registros!",
					styleMessage: "danger"
				});
			});

			this.acaoSalvarEtapa({
				cpfCnpj: this.removeMaskCharacters(this.cpfCnpjInput),
				esn_fk_ets_codigo: 8,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: 'Consultou o CPF/CNPJ',
				hora_agendamento: this.dataAtual,
				data_agendamento: this.horaAtual
			});
		}
	}
};
</script>

<style scoped lang="scss">
.slider {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;

	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-image: var(--fundoFull);
	background-size: var(--coverOrStretch);
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	@media only screen and (max-width: 1024px) {
		background-image: var(--fundoMobile);
		background-attachment: local;
	}
}

.slider-validu {
	width: 100%;
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;

	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-image: var(--fundoFull);
	background-size: var(--coverOrStretch);
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	@media only screen and (max-width: 1024px) {
		height: 110vh;
		background-image: var(--fundoMobile);
		background-attachment: local;
	}
}

.overlay {
	content: "";
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-attachment: fixed;
}

.overlay-validu {
	content: "";
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-attachment: fixed;
}
.overlay_background {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC) repeat;
}

strong {
	font-weight: bold;
	font-size: inherit;
}

button {
	margin-top: 20px;
	cursor: pointer;
	width: 300px;
	border-radius: 7px;
	color: var(--whiteColor);
	border: 0;
	font-size: 16px;
	font-family: Cabin;
	background-color: rgb(56, 64, 118);
	height: 40px;

	@media only screen and (max-width: 700px) {
		width: 75%;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
		font-size: 16px;
	}
}

button:hover {
	background-color: rgb(14, 32, 96);
	transition: all 0.2s ease-in-out;
}

button:disabled {
	background-color: #cecece;
	cursor: not-allowed;
}
</style>
