<template>
	<div class="optionPayment" :style="corPrimaria">
		<div style="display: flex; align-items: center">
			<div>
				<h5 class="titleOption">{{ tituloCardPagamento }}</h5>
				<h5 class="subTitleOption">Vencimento em {{ parcela.pra_primeiro_vcto | formatarData }}</h5>
			</div>
		</div>
		<i class="fas fa-chevron-right" />
	</div>
</template>

<script>
	import globalMethods from "@/mixins/globalMethods";
	import { mapState } from "vuex";

	export default {
		name: "MonthlyPaymentCard",

		mixins: [globalMethods],

		props: ["parcela", "typePayment"],

		computed: {
			...mapState({
				showMessageModal: (state) => state.messageModal.show,
				parceiro: (state) => state.parceiroConfig.nome,
				parceiroConfig: (state) => state.parceiroConfig,
				corPrimaria: (state) => state.corPrimaria,
				corSecundaria: (state) => state.corSecundaria
			}),

			tituloCardPagamento() {
				let tituloCardPagamento = "";

				if (this.parcela.pra_quantidade === 0) {
					tituloCardPagamento = tituloCardPagamento.concat(`R$ ${globalMethods.methods.money2(this.parcela.valor_total_acordo)} à vista`);
				} else if (this.parcela.pra_valor_entrada) {
					tituloCardPagamento = tituloCardPagamento.concat(`Entrada de R$ ${globalMethods.methods.money2(this.parcela.pra_valor_entrada)} + (${this.parcela.pra_quantidade}x de R$ ${globalMethods.methods.money2(this.parcela.pra_valor_parcela)})`);
				} else {
					tituloCardPagamento = tituloCardPagamento.concat(`${this.parcela.pra_quantidade}x de R$ ${globalMethods.methods.money2(this.parcela.pra_valor_parcela)}`);
				}

				if (this.parcela.valorFuturoIncluso) {
					tituloCardPagamento = tituloCardPagamento.concat(`(incluso lançamento futuro de R$ ${globalMethods.methods.money2(this.parcela.valorFuturoTotal)})`);
				}

				return tituloCardPagamento;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.optionPayment {
		box-sizing: border-box;
		width: 100%;
		height: 90px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		border: 1px solid #fafafa;
		border-left: 15px solid var(--color);
		cursor: pointer;
		border-radius: 10px;
		justify-content: space-between;
		margin: 15px 0;
		box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
		transition: all 0.3s ease;

		i {
			font-size: 24px;
			margin-left: auto;
			color: var(--color);
		}
	}

	.optionPayment:hover {
		border: 1px solid var(--color);
		border-left: 15px solid var(--color);
		background: var(--color);

		h5 {
			color: #fafafa;
		}

		i {
			color: #fafafa;
		}
	}

	.titleOption {
		color: #031b4e;
		font-family: "Roboto Black";
		font-size: 16px;
		margin-bottom: 5px;
	}

	.titleOption .subTitleOption {
		color: #5b6987;
		font-family: "Roboto";
		font-size: 12px;
	}
</style>
