<template>
	<div :class="`col-${cols}`" class="col">
		<label>
			{{ label }}
		</label>
		<div v-if="!base64" class="boxzin-img">
			<input @change="uploadImage" type="file" name="photo" accept="image/*" />
			<!--            Nenhuma imagem selecionada-->
		</div>
		<div v-else>
			<i @click="removeImage" class="fas fa-times" style="font-size: 35px; position: absolute; right: 30px; top: 30px; cursor: pointer; color: #595959" />
			<img :src="base64" />
		</div>
	</div>
</template>

<script>
export default {
	name: "ImageField",
	props: ["label", "cols", "valueField"],
	data() {
		return {
			imageSrc: null,
			base64: null
		}
	},
	mounted() {
		if (typeof this.valueField === "object" && this.valueField !== null && !Array.isArray(this.valueField)) this.base64 = this.valueField.campos
		else if (this.valueField && this.valueField.includes("https")) {
			this.urlToBase64(this.valueField)
		}
	},
	methods: {
		removeImage() {
			this.base64 = null
			this.imageSrc = null
			this.$emit("update:value", null)
		},
		urlToBase64(urlStorage) {
			let vm = this
			function toDataURL(url, callback) {
				var xhr = new XMLHttpRequest()
				xhr.onload = function() {
					var reader = new FileReader()
					reader.onloadend = function() {
						callback(reader.result)
					}
					reader.readAsDataURL(xhr.response)
				}
				xhr.open("GET", url)
				xhr.responseType = "blob"
				xhr.send()
			}

			toDataURL(urlStorage, function(dataUrl) {
				vm.base64 = dataUrl
				vm.$emit("update:value", {
					base64: dataUrl,
					name: null,
					format: "png"
				})
			})
		},
		uploadImage(event) {
			var reader = new FileReader()
			reader.readAsDataURL(event.target.files[0])
			reader.onload = () => {
				this.imageSrc = event.target.files[0]
				this.base64 = reader.result
				this.$emit("update:value", {
					base64: reader.result,
					name: this.imageSrc.name,
					format: this.imageSrc.type.replace("image/", "")
				})
			}
		}
	}
}
</script>

<style scoped>
label {
	display: block;
	color: var(--blackColor);
	font-size: 14px;
	font-family: "Roboto";
}

.boxzin-img {
	cursor: pointer;
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0;
	font-size: 14px;
	font-family: "Roboto Bold";
	border: 1px solid #ffffff !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

img {
	width: 100%;
	margin-top: 5px;
}
</style>
