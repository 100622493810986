<template>
    <h3>{{label}}</h3>
</template>

<script>
    export default {
        name: "HeaderLabel",
        props:[
            'label'
        ]
    }
</script>

<style scoped>
    h3{
        display: block;
        width: 100%;
        font-size: 24px;
        font-family: "Roboto Black";
        color: #050B17;
        margin-top: 30px;
        margin-bottom: 30px;
    }
</style>
