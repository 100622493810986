<template>
	<div class="page-reset">
		<div class="box-reset">
			<h3 :style="parceiroConfigCorSecundariaCorPrimaria">Novo telefone</h3>
			<p>Por favor, digite o seu novo telefone e confirme.</p>

			<the-mask
				v-model="phone"
				pattern="[0-9]*"
				inputmode="numeric"
				:mask="['(##) #####-####']"
				:placeholder="'Digite seu novo telefone'"
				@keyup.enter.native="register" ></the-mask>

			<the-mask
				v-model="confirmation"
				pattern="[0-9]*"
				inputmode="numeric"
				:mask="['(##) #####-####']"
				:placeholder="'Confirme seu novo telefone'"
				@keyup.enter.native="register" ></the-mask>

			<div style="margin-top: 30px; padding: 0 15px">
				<button-default
					:color="corPrimaria"
					@click.native="register"
					text="Cadastrar novo telefone" ></button-default>
			</div>

		</div>
	</div>
</template>

<script>
import JWT from "jwt-simple";
import { TheMask } from "vue-the-mask";

import { mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";

export default {
	name: "ResetPhone",

	components: { ButtonDefault, TheMask, JWT },

	mixins: [globalMethods, mixins],

	data() {
		return {
			phone: "",
			confirmation: "",
			pes_codigo: "",
			emp_codigo: ""
		};
	},

	created() {
		var secret = "BTDK77K6HOSI5YZYNXFVHSN3Q5V2F911";
		var decode = JWT.decode(this.$route.params.token, secret);
		this.pes_codigo = decode.pes_codigo;
		this.emp_codigo = decode.emp_codigo;
	},

	methods: {
		...mapActions(["action_atualizar_telefone"]),

		register() {
			if (this.phone == "") {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um novo telefone.`,
					styleMessage: "danger"
				});
				return -1;

			} else if (this.confirmation == "") {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Confirme o seu novo telefone.`,
					styleMessage: "danger"
				});
				return -1;

			} else if (this.phone != this.confirmation) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Os dois telefones informados são diferentes. Tente novamente.`,
					styleMessage: "danger"
				});
				return -1;

			} else {
				this.acaoSalvarEtapa({
					pes_codigo: this.pes_codigo,
					esn_fk_ets_codigo: 2,
					emp_codigo: this.parceiroConfig.emp_codigo,
					motivo_agendamento: 'Atualizou o telefone',
					data_agendamento: `${dataAtual.getFullYear()}-${dataAtual.getMonth() + 1}-${dataAtual.getDate()}`,
					hora_agendamento: `${dataAtual.getHours()}:${dataAtual.getMinutes()}:${dataAtual.getSeconds()}`
				});

				this.action_atualizar_telefone({
					pes_codigo: this.pes_codigo,
					emp_codigo: this.emp_codigo,
					pef_fone: this.phone
				}).then((_) => {
					this.SET_LOADING(false);

					if (response?.data?.retorno) {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Sucesso",
							message: "Operação realizada com sucesso",
							styleMessage: "success"
						});
						this.$router.push({ name: "LandPageParceiro" });

					} else {
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: "Ocorreu uma falha no cadastro do novo telefone.",
							styleMessage: "danger"
						});
						return -1;
					}
				}).catch((_) => {
					this.SET_LOADING(false);
				});
			}
		}
	}
};
</script>

<style scoped>
.page-reset {
	min-height: 100vh;
	flex: 1;
	padding: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-reset h3 {
	text-align: center;
	color: var(--secondaryColor);
	font-size: 32px;
	font-family: Montserrat-Bold;
	margin-bottom: 30px;
}

.page-reset p {
	text-align: center;
	font-size: 14px;
	line-height: 24px;
	font-family: Roboto;
	font-weight: normal !important;
	margin-bottom: 30px;
}

.box-reset {
	width: 400px;
}

.box-reset input {
	height: 60px;
	width: 400px;
	text-align: center;
	background: var(--whiteColor);
	border: 3px solid var(--whiteColor);
	border-radius: 25px;
	font-family: "Montserrat-Bold";
	font-size: 20px;
	margin-bottom: 30px;
	color: var(--secondaryColor);
	@media only screen and (max-width: 700px) {
		width: 75vw;
		border-radius: 25px;
		margin-bottom: 10px;
	}
}
</style>
