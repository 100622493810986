<template>
	<div class="box-register" :style="corPrimaria">

		<div style="display: flex; flex-direction: column; margin: 0px 100px">
			<h4 class="box-register-title" style="text-transform: uppercase">Sua privacidade deve ser sempre levada à sério.</h4>
			<h3 style="text-transform: none">Por isso, além de desenvolvermos soluções que facilitam os negócios de nossos clientes, tomamos o cuidado de oferecer para todos este “Portal da privacidade”.
				 Aqui, além de conhecer nossa política de privacidade, você poderá conhecer e exerce-os direitos que a Lei Geral de Privacidade garante.</h3>
		</div>

		<div class="button-group">

			<div class="button-box" :class="{ active: isActive('RightsRequestForm') }">
				<button class="button-page" @click="activeForm = 'RightsRequestForm'">
					<i class="far fa-file-alt"></i>
				</button>
				<h4 class="button-title">REQUISIÇÃO DE DIREITOS</h4>
			</div>

			<div class="button-box" :class="{ active: isActive('PrivacyPolicy') }">
				<button class="button-page" @click="activeForm = 'PrivacyPolicy'">
					<i class="fas fa-balance-scale-right"></i>
				</button>
				<h4 class="button-title">POLITICA DE PRIVACIDADE</h4>
			</div>

			<div class="button-box" :class="{ active: isActive('YourRights') }">
				<button class="button-page" @click="activeForm = 'YourRights'">
					<i class="fas fa-check"></i>
				</button>
				<h4 class="button-title">SEUS DIREITOS</h4>
			</div>

		</div>

		<rights-request-form
			v-if="activeForm == 'RightsRequestForm'"
			key="formulario-de-requisicao-de-direitos"></rights-request-form>

		<privacy-policy
			v-else-if="activeForm == 'PrivacyPolicy'"
			key="politica-de-privacidade"></privacy-policy>

		<your-rights
			v-else-if="activeForm == 'YourRights'"
			key="seus-direitos"></your-rights>
	</div>
</template>

<script>
import { mapState } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import RightsRequestForm from "./components/RightsRequestForm.vue";
import PrivacyPolicy from "./components/PrivacyPolicy.vue";
import YourRights from "./components/YourRights.vue";

export default {
	name: "PrivacyPortal",

	mixins: [globalMethods, mixins],

	components: { ButtonDefault, RightsRequestForm, PrivacyPolicy, YourRights },

	data() {
		return {
			fields: [],
			identification: "",
			inputsValues: {},
			activeForm: "RightsRequestForm"
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria
		})
	},

	methods: {
		isActive(name) {
			return this.activeForm == name;
		}
	}
};
</script>

<style scoped lang="scss">
.box-register {
	transition: all 0.5s ease-in-out;
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register-title {
	transition: all 0.5s ease-in-out;
	font-family: Montserrat-Bold;
	color: var(--hoverColor);
	font-size: 25px;
	text-align: center;
}

.box-register h2 {
	transition: all 0.5s ease-in-out;
	font-family: Montserrat-Bold;
	color: var(--color);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3 {
	transition: all 0.5s ease-in-out;
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 16px;
	}
}

.button-page {
	transition: all 0.5s ease-in-out;
	background-color: var(--color);
	border: none;
	color: white;
	padding: 20px;
	height: 125px;
	width: 125px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	border-radius: 100%;
	i {
		font-size: 50px;
	}
	@media only screen and (max-width: 990px) {
		height: 100px;
		width: 100px;
		i {
			font-size: 40px;
		}
	}
}

.button-title {
	transition: all 0.5s ease-in-out;
	font-family: Montserrat-Bold;
	color: var(--color);
	font-size: 25px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 20px;
	}
}

.button-box {
	transition: all 0.5s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.button-box:hover .button-page {
	transition: all 0.5s ease-in-out;
	opacity: 0.8;
	background-color: var(--hoverColor);
}

.button-box:hover .button-title {
	transition: all 0.5s ease-in-out;
	opacity: 0.8;
	color: var(--hoverColor);
}
.button-group {
	transition: all 0.5s ease-in-out;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	margin-bottom: 50px;
	@media only screen and (max-width: 990px) {
		flex-direction: column;
	}
	:hover {
		cursor: pointer;
	}
}
.active .button-page {
	background-color: var(--hoverColor);
}
.active .button-title {
	color: var(--hoverColor);
}
</style>
