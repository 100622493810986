<template>
  <div class="box-forms-regiter">

    <form-fields
        :fields="fields"
        @save="completeRegister"
        @update:values="inputsValues = $event" ></form-fields>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";
import StepperHeader from "@/components/StepperHeader";

export default {
    name: "ClientData",

    mixins: [globalMethods, mixins],

    components: { StepperHeader, ButtonLg, FormFields, ButtonDefault},

    props: ['client'],

    data() {
        return {
            currentlyStep: 1,
            fields: [],
            identification: '',
            inputsValues: {},
        }
    },

    computed: {
        ...mapState({
            showMessageModal: (estado) => estado.messageModal.show,
        })
    },

    created() {
        this.$root.$refs.Client = this;
        // if(!this.$route.params?.identification){
        //   this.parceiro
        //       ? this.$router.go(-1)
        //       : this.$router.push({name: 'LandPage'})
        // }
    },

    mounted() {
        if (Object.keys(this.client).length === 0) {
            this.fields = [
                {
                    variableName: 'razaoSocial',
                    fieldType: 'TextField',
                    label: 'Razão Social',
                    type: 'text',
                    cols: '8',
                },
                {
                    variableName: 'cnpj',
                    fieldType: 'TextFieldMask',
                    label: 'CNPJ',
                    type: 'text',
                    cols: '4',
                    maskFormat: "##.###.###/####-##",
                },
                {
                    variableName: 'nomeContato',
                    fieldType: 'TextField',
                    label: 'Nome para contato',
                    type: 'text',
                    cols: '12',
                },
                {
                    variableName: 'telefoneCel',
                    fieldType: 'TextFieldMask',
                    label: 'Celular',
                    maskFormat: "(##) #####-####",
                    type: 'text',
                    cols: '6',
                },
                {
                    variableName: 'telefoneFixo',
                    fieldType: 'TextFieldMask',
                    label: 'Telefone',
                    maskFormat: "(##) ####-####",
                    type: 'text',
                    cols: '6',
                },
                {
                    variableName: 'email',
                    fieldType: 'TextField',
                    label: 'E-mail',
                    type: 'text',
                    cols: '6',
                },
                {
                    variableName: 'website',
                    fieldType: 'TextField',
                    label: 'Site',
                    type: 'text',
                    cols: '6',
                }
            ]
        } else {
            this.fields = [
                {
                    variableName: 'razaoSocial',
                    fieldType: 'TextField',
                    label: 'Razão Social',
                    type: 'text',
                    cols: '8',
                    valueField: this.client?.razao_social
                },
                {
                    variableName: 'cnpj',
                    fieldType: 'TextFieldMask',
                    label: 'CNPJ',
                    type: 'text',
                    cols: '4',
                    maskFormat: "##.###.###/####-##",
                    valueField: this.client?.cnpj
                },
                {
                    variableName: 'nomeContato',
                    fieldType: 'TextField',
                    label: 'Nome para contato',
                    type: 'text',
                    cols: '12',
                    valueField: this.client?.nome_contato
                },
                {
                    variableName: 'telefoneCel',
                    fieldType: 'TextFieldMask',
                    label: 'Celular',
                    maskFormat: "(##) #####-####",
                    type: 'text',
                    cols: '6',
                    valueField: this.client?.telefone_cel
                },
                {
                    variableName: 'telefoneFixo',
                    fieldType: 'TextFieldMask',
                    label: 'Telefone',
                    maskFormat: "(##) ####-####",
                    type: 'text',
                    cols: '6',
                    valueField: this.client?.telefone_fixo
                },
                {
                    variableName: 'email',
                    fieldType: 'TextField',
                    label: 'E-mail',
                    type: 'text',
                    cols: '6',
                    valueField: this.client?.email
                },
                {
                    variableName: 'website',
                    fieldType: 'TextField',
                    label: 'Site',
                    type: 'text',
                    cols: '6',
                    valueField: this.client?.website
                }
            ]
        }
    },

    methods: {
        ...mapMutations(["CHANGE_MESSAGE_MODAL", "SET_LOADING"]),

        completeRegister() {
            const {
            razaoSocial,
            cnpj,
            email,
            telefoneFixo,
            telefoneCel,
            nomeContato,
            website,
            } = this.inputsValues;

            let required = false;
            Object.entries(this.inputsValues).forEach(([key, value]) => {
            if (
                !this.showMessageModal
                && key !== 'website'
                && key !== 'telefoneFixo'
                && (
                    !value
                    || value === "")) {

                required = true;
                this.CHANGE_MESSAGE_MODAL({
                    show: true,
                    title: 'Atenção',
                    message: `O campo ${this.fields.filter(field => field.variableName === key)[0].label} é obrigatório`,
                    styleMessage: 'danger'
                });
                }
            });

            if (!required) {
                if (/.+@.+\..+/.test(email) === false) {
                    this.CHANGE_MESSAGE_MODAL({
                        show: true,
                        title: 'Atenção',
                        message: `Digite um e-mail válido`,
                        styleMessage: 'danger'
                    });

                } else if (telefoneCel.length < 15) {
                    this.CHANGE_MESSAGE_MODAL({
                        show: true,
                        title: 'Atenção',
                        message: `Digite um celular válido`,
                        styleMessage: 'danger'
                    });

                } else if (this.inputsValues.telefoneFixo != null && this.inputsValues.telefoneFixo !== "" && telefoneFixo.length < 9) {
                    this.CHANGE_MESSAGE_MODAL({
                        show: true,
                        title: 'Atenção',
                        message: `Digite um telefone fixo válido`,
                        styleMessage: 'danger'
                    });

                } else {
                    let obj = {
                    razao_social: razaoSocial,
                    cnpj: this.removeMaskCharacters(cnpj),
                    email: email,
                    telefone_cel: this.removeMaskCharacters(telefoneCel),
                    telefone_fixo: this.removeMaskCharacters(telefoneFixo),
                    website: website,
                    nome_contato: nomeContato,
                    };

                    this.$emit("update:returnClient", obj);
                    return true;
                }
            }
            return false;
        },

        completaDados(client){
            this.fields[0].valueField = client.razao_social;
            this.fields[1].valueField = client.cnpj;
            this.fields[2].valueField = client.nome_contato;
            this.fields[3].valueField = client.telefone_cel;
            this.fields[4].valueField = client.telefone_fixo;
            this.fields[5].valueField = client.email;
            this.fields[6].valueField = client.website;
            this.$root.$refs.FormFields.$forceUpdate()
        }
    }
}
</script>

<style scoped lang="scss">
.box-register {
  min-height: 100vh;
  display: flex;
  flex: 1;
  padding: 50px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #F3F5F9;
}

.box-register h2 {
  font-family: Montserrat-Bold;
  color: var(--primaryColor);
  font-size: 32px;
  text-align: center;
  @media only screen and (max-width: 990px) {
    font-size: 28px
  }
}

.box-register h3 {
  font-family: Cabin;
  font-size: 18px;
  color: var(--grayColor);
  margin: 10px 0 30px 0;
  text-align: center;
}

.box-forms-regiter {
  width: 50vw;
  box-sizing: border-box;
  @media only screen and (max-width: 990px) {
    width: 90%;
  }

}

.box-button-register {
  width: 50vw;
  box-sizing: border-box;
  @media only screen and (max-width: 990px) {
    width: 90%;
  }
}
</style>
