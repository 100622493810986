<template>
	<div
		class="cant-pay-box"
		:style="parceiroConfig.sessoes.customization.campos.myDebits.flgAlterarTamanhoCardDivida ? 'max-width: 750px' : ''">
		<div class="cant-pay-page">
			<div class="cant-pay-title">
				<p>Por qual motivo você não quer ou não pode quitar este débito agora?</p>
			</div>
			<div class="cant-pay-reasons">

				<input
					id="desemprego"
					v-model="reason"
					type="radio"
					value="Desemprego" />

				<label for="desemprego">Desemprego</label>

				<input
					id="doenca"
					v-model="reason"
					type="radio"
					value="Doença Afastamento Licença" />

				<label for="doenca">Doença / Afastamento / Licença</label>

				<input
					id="juros"
					v-model="reason"
					type="radio"
					value="Juros Altos" />

				<label for="juros">Juros Altos</label>

				<input
					id="discorda"
					v-model="reason"
					type="radio"
					value="Discorda do Valor" />

				<label for="discorda">Discorda do Valor</label>

				<input
					id="reducao"
					v-model="reason"
					type="radio"
					value="Redução Perda de receita" />

				<label for="reducao">Redução / Perda de receita</label>

				<input
					id="emprestimo"
					v-model="reason"
					type="radio"
					value="Empréstimo realizado a terceiro" />

				<label for="emprestimo">Empréstimo realizado a terceiro</label>

				<input
					id="salario"
					v-model="reason"
					type="radio"
					value="Salário em atraso" />

				<label for="salario">Salário em atraso</label>

				<input
					id="prefiro"
					v-model="reason"
					type="radio"
					value="Prefiro negociar diretamente com a loja" />

				<label for="prefiro">Prefiro negociar diretamente com a loja</label>

				<input
					id="naoconfio"
					v-model="reason"
					type="radio"
					value="Não confio neste canal" />

				<label for="naoconfio">Não confio neste canal</label>

				<input
					id="antiga"
					v-model="reason"
					type="radio"
					value="Esta dívida é muito antiga" />

				<label for="antiga">Esta dívida é muito antiga</label>

				<input
					id="outromotivo"
					v-model="reason"
					type="radio"
					value="Outro motivo" />

				<label for="outromotivo">Outro motivo</label>

			</div>

			<p
				v-show="vazio"
				id="alert-message">Escolha um dos motivos para continuar.</p>

			<div class="box-buttons">

				<button-default
					:color="corPrimaria"
					@click.native="confirmar"
					text="Confirmar" ></button-default>

			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import mixins from "@/mixins/mixins"

import ButtonDefault from "@/components/buttons/ButtonDefault"
import ReceiveCall from "./ReceiveCall"

export default {
	name: "CantPay",

	components: { ButtonDefault, ReceiveCall },

	mixins: [mixins],

	props: {
		contrato: String,
		divida: Object
	},

	data() {
		return {
			reason: "",
			vazio: false
		}
	},

	computed: {
		...mapState({
			receberLigacao: (estado) => estado.receberLigacao
		})
	},

	methods: {
		...mapMutations(["SET_RECEBER_LIGACAO", "SET_MOTIVO_RECEBER_LIGACAO"]),

		confirmar() {
			if (this.reason == "") this.vazio = true
			else {
				this.SET_MOTIVO_RECEBER_LIGACAO(this.reason)
				this.SET_RECEBER_LIGACAO(true)
			}
		}
	},

	returnRouterName() {
		return this.$route.name
	}
}
</script>

<style lang="scss" scoped>
.cant-pay-box {
	background: #ffffff;
	width: 100%;
	max-width: 1024px;
	padding: 20px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	overflow-y: auto;
	@media only screen and (max-width: 1366px) {
		width: 26em;
	}
}

.cant-pay-reasons {
	margin: 2vh;
	display: grid;
	grid-template-columns: 10% 90%;
	justify-items: start;
	grid-auto-rows: minmax(2rem, auto);
}

.cant-pay-page {
	width: 100%;
}

.cant-pay-title {
	p {
		color: #031b4e;
		font-family: "Roboto Black";
		text-transform: uppercase;
		font-size: 1.3em;
		margin-bottom: 10px;
	}
}

.box-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	* {
		padding-right: 10px;
	}
}

#alert-message {
	color: hsl(0, 87%, 49%);
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 1.3em;
	margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
	.box-buttons {
		flex-direction: column;
	}
	.box-buttons button {
		margin: 5px 0;
	}
}
</style>
