<template>
	<div class="lead-page">
		<div class="lead-header">

			<h3>Lista de leads</h3>
			<div class="lead-fields">
				<date-field
					:required="false"
					label="Data inicial"
					@update:value="data_inicial = $event" ></date-field>

				<date-field
					:required="false"
					label="Data final"
					@update:value="data_final = $event" ></date-field>

				<button-default
					@click.native="getLead"
					text="Pesquisar" ></button-default>
			</div>

		</div>

		<div class="lead-box">
			<table class="lead-table">
				<caption>
					Detalhes das entradas para negociação
				</caption>

				<tr>
					<th id="Código">Código</th>
					<th id="Nome">Nome</th>
					<th id="E-mail">E-mail</th>
					<th id="Fone">Fone</th>
					<th id="Celular">Celular</th>
					<th id="Loja">Loja</th>
					<th id="Cidade">Cidade</th>
					<th id="Data">Data do cadastro</th>
					<th id="C.P.F/C.N.P.J.">C.P.F/C.N.P.J.</th>
					<th id="Observação">Observação</th>
					<th id="Valor">Valor</th>
					<th id="Forma">Forma de pagamento</th>
					<th id="Valor">Valor que pode pagar</th>
				</tr>

				<tr v-for="lead in leads" :key="lead.led_codigo">
					<td>{{ lead.led_codigo }}</td>
					<td>{{ lead.led_nome }}</td>
					<td>{{ lead.led_email }}</td>
					<td>{{ lead.led_fone }}</td>
					<td>{{ lead.led_celular }}</td>
					<td>{{ lead.led_loja }}</td>
					<td>{{ lead.led_cidade }}</td>
					<td>{{ lead.led_data_cadastro }}</td>
					<td>{{ lead.led_cpf_cnpj }}</td>
					<td>{{ lead.led_obs }}</td>
					<td>{{ lead.led_valor }}</td>
					<td>{{ lead.led_forma_pagamento }}</td>
					<td>{{ lead.led_valor_pode_pagar }}</td>
				</tr>
			</table>

			<div class="lead-sheets">

				<button-default
					@click.native="getPlanilha"
					text="Planilha" ></button-default>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import mixins from "@/mixins/mixins";

import DateField from "@/components/inputs/DateField";
import ButtonDefault from "@/components/buttons/ButtonDefault";

export default {
	name: "ListLeads",

	components: { ButtonDefault, DateField },

	mixins: [mixins],

	data() {
		return {
			data_inicial: "",
			data_final: "",
			planilha: ""
		}
	},

	computed: {
		...mapState({
			leads: state => state.leads
		})
	},

	methods: {
		...mapMutations(["SET_LEADS"]),

		...mapActions(["action_getLead"]),

		getLead() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Carregando leads");

			this.action_getLead({
				dataI: this.data_inicial,
				dataF: this.data_final

			}).then(resposta => {
					if (resposta.data.retorno && resposta.data.dados?.leads) {
						this.SET_LEADS(resposta.data.dados.leads)
						if (resposta.data.dados?.planilha) {
							this.planilha = resposta.data.dados.planilha
						}
					}
				}).catch((_) => {

					this.SET_LOADING(false)
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Ocorreu um erro ao gerar a lista de leads`,
						styleMessage: "danger"
					});
				});

			this.SET_LOADING_MESSAGE("");
			this.SET_LOADING(false);
		},

		getPlanilha() {
			window.open(this.planilha, "_blank");
		}
	}
}
</script>

<style scoped>
.lead-header h3 {
	font-size: 24px;
	font-family: "Roboto Black";
	color: #050b17;
	margin-bottom: 50px;
}

.lead-table {
	width: 100%;
	text-align: center;
}

.lead-table th {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 14px;
	height: 50px;
}

tr:nth-child(even) {
	background-color: #f9f9f9;
}

.lead-table td {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 14px;
	height: 30px;
}
.lead-fields {
	display: flex;
	align-items: center;
}
.lead-sheets {
	display: flex;
	justify-content: flex-end;
}
</style>
