<template>
	<div class="box-forms-regiter">

		<pricing-data
			v-for="plan in plans"
			:key="plan.codigo"
			:plan="plan"
			:planSelected="planSelected"
			@update:returnPlanSelected="planSelected = $event" ></pricing-data>

	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";
import StepperHeader from "@/components/StepperHeader";
import ClientData from "@/views/signIn/components/ClientData";
import PricingData from "./PricingData";

export default {
	name: "CompanyPlan",

	mixins: [globalMethods, mixins],

	components: { ClientData, StepperHeader, ButtonLg, FormFields, ButtonDefault, PricingData },

	props: ["companyPlan"],

	data() {
		return {
			fields: [],
			identification: "",
			plans: [
				{
					codigo: 1,
					item_1: "Relatórios de Acionamentos e Acordos",
					item_2: "Dashboard com resumo em tempo em tempo real da operação",
					item_3: "Mais de 60 filtros para elaborar sua estratégia",
					nome: "CRM - Sistema de cobrança",
					valor: "112.60",
					emp_usa_site: "N"
				},
				{
					codigo: 2,
					item_1: "Portal de atentimento virtual para impulsionar seus Acordos",
					item_2: "Relatórios de Acionamentos e Acordos",
					item_3: "Mais de 60 filtros para elaborar sua estratégia",
					nome: "Portal + CRM",
					valor: "151.11",
					emp_usa_site: "S"
				}
			],
			planSelected: ""
		};
	},

	computed: {
		...mapState({
			showMessageModal: (estado) => estado.messageModal.show,
		})
	},

	created() {
		this.$root.$refs.Company = this;
	},

	mounted() {
		if (Object.keys(this.companyPlan).length !== 0) {
			this.planSelected = this.plans.find((plan) => plan.emp_usa_site === this.companyPlan.emp_usa_site).codigo;
		}
	},

	methods: {
		...mapActions(["action_buscar_planos"]),

		completeRegister() {
			let required = false;

			if (this.planSelected === "") {
				required = true;
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `É necessário selecionar um plano antes de prosseguir.`,
					styleMessage: "danger"
				});
			}

			if (!required) {
				let obj = {
					emp_usa_site: this.plans.find((plan) => plan.codigo === this.planSelected).emp_usa_site
				};

				this.$emit("update:returnCompanyPlan", obj);
				return true;
			}
			return false;
		}
	}
};
</script>

<style scoped lang="scss">
.box-register {
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register h2 {
	font-family: Montserrat-Bold;
	color: var(--primaryColor);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3 {
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
}

.box-forms-regiter {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}

.box-button-register {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}
</style>
