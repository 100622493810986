<template>
	<div class="page-confirm">
		<h3 :style="{ color: parceiroConfigCorSecundariaCorPrimaria }">Olá,</h3>
		<p>
			Bem vindo ao portal de auto negociação {{ nomeParceiro }}. Aqui você consegue visualizar, simular, fazer acordos, gerar boletos e pagar suas dívidas.<br />
			Nesse primeiro acesso, precisamos identificar você, por favor, preencha os campos abaixo:<br /><br />
			<b class="error-message-confirm">* Lembramos que essa validação será somente no seu primeiro acesso.</b>
		</p>

		<div class="box-forms-regiter">
			<form-fields
				:fields="fields"
				@save="completeRegister"
				@update:values="inputsValues = $event" ></form-fields>
		</div>

		<div class="box-button-register">
			<button-lg
				:color="corPrimaria"
				:full="true"
				@click.native="completeRegister()"
				text="Acessar" ></button-lg>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex"
import globalMethods from "@/mixins/globalMethods"
import mixins from "@/mixins/mixins"

import FormFields from "@/components/FormFields"
import ButtonLg from "@/components/buttons/ButtonLg"

export default {
	name: "ConfirmUser",

	components: { ButtonLg, FormFields },

	mixins: [globalMethods, mixins],

	data() {
		return {
			fields: [],
			identification: "",
			birthDate: "",
			inputsValues: {}
		}
	},

	created() {
		if (this.$route.params.identification && this.$route.params.birthDate) {
			this.identification = this.$route.params.identification
			this.birthDate = this.$route.params.birthDate
		} else {
			this.$router.push({ name: "LandPageParceiro" })
		}
	},

	mounted() {
		this.fields = [
			{
				variableName: "nome",
				fieldType: "TextField",
				label: "Nome completo",
				type: "text",
				cols: "8"
			},
			{
				variableName: "nascimento",
				fieldType: "TextFieldMask",
				label: "Data nascimento",
				type: "text",
				cols: "4",
				maskFormat: "##/##/####",
				valueField: this.birthDate
			},
			{
				variableName: "sexo",
				fieldType: "SelectField",
				label: "Sexo",
				options: [
					{ id: "M", name: "Masculino" },
					{ id: "F", name: "Feminino" },
					{ id: "O", name: "Outro" },
					{ id: null, name: "Prefiro não responder" }
				],
				keyName: "name",
				keyValue: "id",
				cols: "7"
			},
			{
				variableName: "cpf_cnpj",
				fieldType: "TextFieldMask",
				label: "CPF",
				type: "text",
				cols: "5",
				maskFormat: "###.###.###-##",
				disabled: true,
				valueField: this.identification
			},
			{
				variableName: "telefone",
				fieldType: "TextFieldMask",
				label: "Celular",
				maskFormat: "(##) #####-####",
				type: "text",
				cols: "5"
			},
			{
				variableName: "email",
				fieldType: "TextField",
				label: "E-mail",
				type: "text",
				cols: "7"
			}
		]
	},

	methods: {
		...mapActions(["action_atualizar_dados_cadastrais"]),

		completeRegister() {
			let { nome, nascimento, sexo, cpf_cnpj, telefone, email } = this.inputsValues

			if (/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(nascimento) === false) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite sua data de nascimento`,
					styleMessage: "danger"
				})

			} else if (telefone.length < 15) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um celular válido`,
					styleMessage: "danger"
				})

			} else if (/.+@.+\..+/.test(email) === false) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um e-mail válido`,
					styleMessage: "danger"
				})

			} else if (nome.length < 4) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite seu nome completo`,
					styleMessage: "danger"
				})

			} else {
				this.SET_LOADING(true)
				this.SET_LOADING_MESSAGE("Cadastrando o novo usuário")
				let obj = {
					cpfCnpj: this.removeMaskCharacters(cpf_cnpj),
					dataNascimento: nascimento,
					telefone: this.removeMaskCharacters(telefone),
					email: email,
					sexo: sexo,
					nome: nome
				}

				this.action_atualizar_dados_cadastrais(obj).then(resposta => {
					if (resposta.data.retorno === true) {
						this.$router.push({ name: "LandPageParceiro" })
						this.SET_LOADING_MESSAGE("")
						this.SET_LOADING(false)
					}
				}).catch((_) => {
					this.SET_LOADING_MESSAGE("")
					this.SET_LOADING(false)
				})
			}
		}
	}
}
</script>

<style scoped>
.page-confirm {
	padding: 50px;
}

.page-confirm h3 {
	color: var(--secondaryColor);
	font-size: 32px;
	font-family: Montserrat-Bold;
	margin-bottom: 30px;
}

.page-confirm p {
	font-size: 14px;
	line-height: 24px;
	font-family: Roboto;
	text-align: justify;
	font-weight: normal !important;
	margin-bottom: 30px;
}

.error-message-confirm {
	font-family: "Roboto Bold";
	color: red;
}
</style>
