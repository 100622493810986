<template>
	<div
		class="bg-page"
		:style="mobileStyle">

		<div class="background-card" v-if="divida">
			<div class="debit-card">
				<div
					@click="showOrhideButtons(esconderBotoesNoCelular)"
					class="img-box"
					:style="larguraBoxImagem">
					<img v-if="divida.loja.loj_caminho_logo"
						key="portal-parceiro-logo" :src="imagemLogoLoja" alt="logo_loja" />

					<img v-else-if="parceiro && parceiroConfig.sessoes.logoForBackgroundWhite && parceiro && parceiroConfig.sessoes.logoForBackgroundWhite.campos != undefined"
						key="portal-parceiro-logo-para-fundo-branco"
						:src="parceiro && parceiroConfig.sessoes.logoForBackgroundWhite.campos" alt="logo_fundo_branco" />

					<img v-else-if="parceiro" key="portal-parceiro-logo-parceiro-config" :src="parceiroConfig.logo"
						alt="logo_parceiro" />

					<img v-else key="portal-parceiro-logo-teae-png" src="@/assets/images/teae.png" alt="logo_alternativa" />
				</div>

				<div class="container-info">
					<div
						@click="showOrhideButtons(esconderBotoesNoCelular)"
						class="info">
						<div class="inner-info">

							<div class="row-info">

								<div style="display: flex; align-items: baseline; text-align: start">
									<p v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
										key="portal-prefeitura-nome"
										style="margin-bottom: 5px; width: fit-content; margin-right: 5px">{{ pesNome }}</p>

									<p v-else key="portal-parceiro-nome"
										style="margin-bottom: 5px; width: fit-content; margin-right: 5px">
										{{ labelNomeLoja }}</p>
								</div>

								<h6 v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
									key="portal-prefeitura-cpf">
									{{ `CPF: ${cpfCnpj.substring(0, 3)}.***.***-**` }}
								</h6>

								<h6 v-else key="portal-parceiro-cnpj">
									{{ labelCnpj }}
								</h6>

								<div v-if="!parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode">

									<h6 v-if="agruparContratos" key="portal-parceiro-contratos-agrupados"
										style="line-break: auto">Contratos: {{ somado.con_contrato }}</h6>

									<h6 v-else key="portal-parceiro-contratos-nao-agrupados" style="line-break: auto">
										Contratos: {{ this.conContrato }}</h6>

									<h6 v-if="this.divida.integra_senff == 'S'" key="portal-parceiro-dias-em-atraso">
										{{ maiorFaseEmAtrasoPorContratos < 0 ? "Próximo vencimento em: " +
											(this.maiorFaseContratos * -1) + " dia(s)." : "Atraso: " +
											this.maiorFaseContratos + " dia(s)." }} </h6>
											<button :style="mobileStyle" class="dontRecogniseDebit"
												@click="dontRecogniseDebit">Não
												reconhece essa dívida?</button>

								</div>

							</div>
						</div>

						<button-default v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
							:color="parceiroConfigCorSecundariaCorSecundaria" @click.native="clickTownHall"
							text="Clique aqui para negociar o seu débito"></button-default>

						<div v-else class="row-totals" :style="mobileStyle">
							<div class="totals"
								v-if="parceiroConfig.sessoes.customization == undefined ? true : parceiroConfig.sessoes.customization.campos.myDebits.flgValorOriginal">
								<h6 style="color: #dadbdf">{{ lableDividaOriginal }} Principal</h6>
								<h2 style="color: #dadbdf">
									<span style="color: #dadbdf">R$</span>
									{{ dividaOriginal | formatarDinheiro }}
								</h2>
							</div>
							<div v-if="lancamentosFuturos.aVista.length > 0" class="totals mr-10">
								<h6>Lançamentos Futuros</h6>
								<h2>
									<span>R$</span>
									{{ valorTotalLancamentosFuturos | formatarDinheiro }}
								</h2>
							</div>

							<div class="totals">
								<div style="display: flex">
									<h6 style="margin-right: 5px">{{ lableDividaOriginal }} Atualizado</h6>

									<i :style="{ parceiroConfigCorSecundariaCorPrimaria }" class="fas fa-info-circle"
										title="O valor atualizado é referente ao valor da dívida com acréscimo de juros sobre o parcelamento."
										@click="infoUpdatedValueAlert"></i>
								</div>

								<h2>
									<span>R$</span>
									{{ divida.totalCorrigido | formatarDinheiro }}
								</h2>
							</div>
						</div>
					</div>

					<div class="box-buttons"
						v-if="!parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
						:style="mobileStyle">

						<div style="width: 40%; padding-right: 10px">
							<button-default :color="parceiroConfigCorSecundariaCorPrimaria" :secondaryColor="true"
								:active="this.dividaSelecionada === this.conContrato + ' ' + 'DetailsDebit'"
								@click.native="showDetailsDebit" :text="textoBotaoDetalhes"></button-default>
						</div>

						<div
							v-if="!parceiroConfig.sessoes.customization.campos.myDebits.flgDesativatBotaoNaoPossoPagar"
							style="width: 60%; padding-left: 10px">
							<button-default :color="parceiroConfigCorSecundariaCorPrimaria" :secondaryColor="true"
								:active="this.dividaSelecionada === this.conContrato + ' ' + 'CantPay'"
								@click.native="showCantPay" :text="textoBotaoNaoPossoPagar"></button-default>
						</div>

						<div style="width: 80%; padding-left: 10px">
							<button-default :color="parceiroConfigCorSecundariaCorPrimaria" :secondaryColor="true"
								:active="this.dividaSelecionada === this.conContrato + ' ' + 'PaymentOptions'"
								@click.native="showPaymentOptions"
								:text="textoBotaoMostrarOpcosDePagamento"></button-default>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="temDesconto" class="card-promotion-debit">
			<div class="card-promotion-debit">

				<p class="message-top" :style="parceiroConfigCorSecundariaCorPrimaria">

					<b>{{ divida.loja.loj_fantasia }}</b>
					lhe proporciona um <b>SUPER DESCONTO</b> de
					<b>{{ divida.porcentagem_desconto_total }}%</b>
					para você quitar sua dívida e voltar a ter seu CPF positivado!
				</p>

				<div class="amount-boleto">
					<div style="display: flex; flex-direction: column; justify-content: center">

						<h5>
							São
							<b :style="{ parceiroConfigCorSecundariaCorPrimaria }">
								R$ {{ valorTotalDesconto | formatarDinheiro }}
							</b>
							de desconto.
							<b :style="{ parceiroConfigCorSecundariaCorPrimaria }">
								É IMPERDÍVEL!
							</b>
						</h5>

						<p>Vencimento do boleto em 22/03/2021</p>
					</div>

					<div class="values-boleto">
						<div class="totals">
							<h6>Valor à vista</h6>
							<h2 :style="{ parceiroConfigCorSecundariaCorPrimaria }">
								<span>R$</span>
								{{ valorTotalDesconto | formatarDinheiro }}
							</h2>
						</div>
					</div>
				</div>
			</div>
		</div>

		<component :is="'PaymentOptionsDebit'" v-if="dividaSelecionada === this.conContrato + ' ' + 'PaymentOptions'"
			:divida="divida" :lancamentosFuturos="lancamentosFuturos"></component>

		<details-debit v-show="dividaSelecionada === this.conContrato + ' ' + 'DetailsDebit'" :divida="divida"
			:conContrato="conContrato" :lancamentosFuturos="lancamentosFuturos"
			:valorTotalLancamentosFuturos="valorTotalLancamentosFuturos"></details-debit>

		<cant-pay v-show="dividaSelecionada === this.conContrato + ' ' + 'CantPay'" :divida="divida"></cant-pay>

		<receive-call v-show="receberLigacao" :divida="divida"></receive-call>
	</div>
</template>

<script>
import _ from "lodash";
import { mapState, mapMutations, createNamespacedHelpers } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import DetailsDebit from "./DetailsDebit";
import CantPay from "./CantPay";
import PaymentOptionsDebit from "./PaymentOptionsDebit";
import ReceiveCall from "./ReceiveCall";

const {
	mapState: mapStatePassosRetornarDividas
} = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "DebitCard",

	components: {
		PaymentOptionsDebit,
		DetailsDebit,
		ButtonDefault,
		CantPay,
		ReceiveCall
	},

	mixins: [globalMethods, mixins],

	props: {
		divida: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			showComponent: {
				type: Number,
				default: 0
			},

			somado: {
				type: Object,
				default: {}
			},

			cantPay: {
				type: Boolean,
				default: false
			},

			imgError: {
				type: Boolean,
				default: false
			},

			detailsDebit: {
				type: Boolean,
				default: false
			},

			detailsUpdatedValue: {
				type: Boolean,
				default: false
			},

			alertActived: {
				type: Boolean,
				default: false
			},

			conContrato: "",
			maiorFaseContratos: 0,

			lancamentosFuturos: {
				aVista: [],
				aPrazo: []
			},

			esconderBotoesNoCelular: true
		};
	},

	computed: {
		...mapState({
			dividas: (estado) => estado.dividas,
			naoPossoPagar: (estado) => estado.naoPossoPagar,
			dividaSelecionada: (estado) => estado.dividaSelecionada,
			receberLigacao: (estado) => estado.receberLigacao,
			pesNome: (estado) => estado.passosRetornarDividas.pessoa.pesNome,
			portalCamec: (estado) => estado.portalCamec,
			pessoa: (estado) => estado.passosRetornarDividas.pessoa
		}),

		...mapStatePassosRetornarDividas({
			botaoConsultarParcelamentosClicado: (estado) => estado.botaoConsultarParcelamentosClicado
		}),

		mobileStyle() {
			return { "--hideButtons": this.hideButtons ? "none" : "flex" };
		},

		hideButtons() {
			return !this.dividaSelecionada.includes(this.conContrato);
		},

		dividaOriginal() {
			const valoresOriginaisPorContrato = [];

			Object.values(this.divida.contratos).forEach(contrato => {
				valoresOriginaisPorContrato.push(
					Object.values(contrato.parcelas).map(parcela => parcela.original)
						.reduce((valorOriginalDaParcela, resultado) => resultado + valorOriginalDaParcela));
			});

			return valoresOriginaisPorContrato.reduce((valorOriginalPorContrato, resultado) => resultado + valorOriginalPorContrato);
		},
		maiorFaseEmAtrasoPorContratos() {
			let maiorValor = 0;
			Object.values(this.divida.contratos).forEach(contrato => {
				maiorValor = Object.values(contrato.parcelas).reduce((maior, parcela) => {
					const valorAtual = parcela.fase;
					if (parcela.lancamento_futuro == 1) {
						return maior;
					}
					return valorAtual > maior ? valorAtual : maior;
				}, -Infinity);

			});
			this.maiorFaseContratos = maiorValor;
			return maiorValor;
		},

		lableDividaOriginal() {
			if (this.divida.integra_senff == "S") {
				return "Débito";
			}
			return "Valor";
		},

		labelCnpj() {
			return this.divida.integra_senff == "S" != "" ? '' : `CNPJ: ${this.cnpjMask(this.divida.loja.loj_cpf_cnpj)}`;
		},

		labelNomeLoja() {
			return this.divida.nome_loja_senff != "" && this.divida.nome_loja_senff != undefined && this.divida.nome_loja_senff != null ? this.divida.nome_loja_senff :this.divida.loja.loj_fantasia;
		},

		valorTotalLancamentosFuturos() {
			const valoresLancamentosFuturos = this.lancamentosFuturos.aVista.map(lancamentoFuturo => lancamentoFuturo.corrigido);

			if (valoresLancamentosFuturos.length <= 1) {
				return valoresLancamentosFuturos[0] ? valoresLancamentosFuturos[0] : 0;

			} else {
				return valoresLancamentosFuturos.reduce((valorLancamentoFuturo, resultado) => resultado + valorLancamentoFuturo);
			}
		},

		temDesconto() {
			return this.divida.porcentagem_desconto_total && this.divida.porcentagem_desconto_total > 0 &&
				!this.dividaSelecionada.includes(this.conContrato) && this.divida.saldo_contrato_com_desconto >= this.valor_total_computado;
		},

		agruparContratos() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.flgAgruparContratos;
		},

		valorTotalDesconto() {
			return this.agruparContratos ? this.somado.total_desconto : this.divida.total_desconto;
		},

		textoBotaoDetalhes() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebitsDetails ? this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebitsDetails : 'Detalhes';
		},

		textoBotaoNaoPossoPagar() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebitsCantPay ? this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebitsCantPay : 'Não posso pagar agora';
		},

		textoBotaoMostrarOpcosDePagamento() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebitsPaymentOptions ?
				this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebitsPaymentOptions : 'Opções de parcelamento e propostas';
		},

		imagemLogoLoja() {
			return `${process.env.VUE_APP_API_URL}/${this.divida.loja.loj_caminho_logo}`;
		},

		larguraBoxImagem() {
			return window.screen.width <= 760 ? 'width: 100%' : 'width: 110px';
		}
	},

	created() {
		if (this.parceiroConfig.sessoes.customization.campos.myDebits.flgPortalUsaStage) {
			this.conContrato = Object.keys(this.divida.contratos).join(', ');
		} else {
			let contratos = Object.values(this.divida.contratos);
			for (let i = 0; i < contratos.length; i++) {
				if (i == contratos.length - 1) {
					this.conContrato += contratos[i].con_contrato;
				} else {
					this.conContrato += `${contratos[i].con_contrato}, `;
				}
			}
		}

		if (!this.parceiroConfig.sessoes.customization.campos.myDebits.flgPortalUsaStage) {
			this.buscarLancamentosFuturos();
		}
	},

	mounted() {
		this.SET_NAO_POSSO_PAGAR(false);
		this.SET_DIVIDA_SELECIONADA("");
	},

	methods: {
		...mapMutations(["SET_NAO_POSSO_PAGAR", "SET_DIVIDA_SELECIONADA", "SET_RECEBER_LIGACAO", "SET_MOTIVO_RECEBER_LIGACAO"]),

		maisDeUmContratoMesmaLoja() {

			if (this.agruparContratos) {
				let codigo = this.divida.loj_codigo;
				let auxArray = _.filter(this.dividas, function (o) {
					return o.loj_codigo == codigo;
				});

				const searchRegExp = new RegExp("/", "g");
				this.somado.con_contrato = "";
				this.somado.con_observacao3 = "";
				this.somado.saldo_contrato = 0;
				this.somado.saldo_contrato_com_desconto = 0;
				this.somado.total_desconto = 0;

				if (this.divida.avista) {
					this.somado.saldo_contrato = this.divida.saldo_contrato;
					this.somado.con_observacao3 = this.filtraObservacaoPrefeitura(this.divida.con_observacao3);
					this.somado.saldo_contrato_com_desconto = this.divida.saldo_contrato_com_desconto;
					this.somado.total_desconto = this.divida.total_desconto;

				} else {
					auxArray.forEach((item) => {
						this.somado.saldo_contrato += item.saldo_contrato;
						this.somado.saldo_contrato_com_desconto += item.saldo_contrato_com_desconto;
						this.somado.total_desconto += item.total_desconto;
						this.somado.con_contrato = this.somado.con_contrato.concat(
							item.con_contrato
								.concat(" ")
								.replace(searchRegExp, "")
								.replace(/\| /g, "")
						);
					});
				}

				return auxArray[0].avista ? this.divida == auxArray[0] : this.divida == auxArray.pop();

			} else {
				return true;
			}
		},

		showCantPay() {

			if (this.dividaSelecionada === this.conContrato + " " + "CantPay") {
				this.SET_DIVIDA_SELECIONADA(window.screen.width > 760 ? "" : this.conContrato);

			} else {
				this.SET_DIVIDA_SELECIONADA(this.conContrato + " " + "CantPay");
			}
		},

		showPaymentOptions() {
			this.botaoConsultarParcelamentosClicado = true;
			if (this.dividaSelecionada === this.conContrato + " " + "PaymentOptions") {
				this.SET_DIVIDA_SELECIONADA(window.screen.width > 760 ? "" : this.conContrato);

			} else {
				this.SET_DIVIDA_SELECIONADA(this.conContrato + " " + "PaymentOptions");
			}
		},

		showDetailsDebit() {

			if (this.dividaSelecionada === this.conContrato + " " + "DetailsDebit") {
				this.SET_DIVIDA_SELECIONADA(window.screen.width > 760 ? "" : this.conContrato);

			} else {
				this.SET_DIVIDA_SELECIONADA(this.conContrato + " " + "DetailsDebit");
			}
		},

		dontRecogniseDebit() {
			this.SET_RECEBER_LIGACAO(true);
			this.SET_MOTIVO_RECEBER_LIGACAO("Não reconheço essa dívida");
		},

		clickTownHall() {
			this.acaoSalvarEtapa({
				cpfCnpj: this.pessoa.pesCpfCnpj,
				esn_fk_ets_codigo: 32,
				emp_codigo: this.parceiroConfig.emp_codigo,
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual,
				motivo_agendamento: 'Entrou no portal da prefeitura de Ponta Grossa'
			});
			window.open("https://pontagrossa.cidadao.oxy.elotech.com.br/");
		},

		infoUpdatedValueAlert() {
			alert("O valor atualizado é referente ao valor da dívida com acréscimo de juros sobre o parcelamento.");
			this.alertActived = true;
		},

		showOrhideButtons(value) {
			if (window.screen.width <= 760) {
				this.esconderBotoesNoCelular = !this.esconderBotoesNoCelular;
				this.SET_DIVIDA_SELECIONADA(value ? "" : this.conContrato);
			}
		},

		filtraObservacaoPrefeitura(observacao) {
			observacao = observacao.split("|");
			observacao = _.uniq(observacao);
			return observacao.join(", ");
		},

		buscarLancamentosFuturos() {

			//CERTO
			Object.values(this.divida.contratos_avista).forEach(contrato => {
				let lancamentos_futuros = Object.values(contrato.parcelas).filter(parcela => parcela.lancamento_futuro === "1");
				if (typeof lancamentos_futuros === "object" && Object.keys(lancamentos_futuros).length > 0) {
					Object.entries(lancamentos_futuros).forEach(([key, parcela]) => {
						this.lancamentosFuturos.aVista.push(parcela);
						delete this.divida.contratos_avista[contrato.con_codigo].parcelas[parcela.par_codigo];
					})
				}
			});
			Object.values(this.divida.contratos_aprazo).forEach(contrato => {
				let lancamentos_futuros = Object.values(contrato.parcelas).filter(parcela => parcela.lancamento_futuro === "1");
				if (typeof lancamentos_futuros === "object" && Object.keys(lancamentos_futuros).length > 0) {
					Object.entries(lancamentos_futuros).forEach(([key, parcela]) => {
						this.lancamentosFuturos.aPrazo.push(parcela);
						delete this.divida.contratos_aprazo[contrato.con_codigo].parcelas[parcela.par_codigo];
					})
				}
			});

			//ERRADO MAS N POSSO APAGAR AINDA, SO AJUSTEI
			Object.values(this.divida.contratos).forEach(contrato => {
				let lancamentos = Object.values(contrato.parcelas).filter(parcela => parcela.lancamento_futuro === "1");
				//ESSE ESTA ERRADO MAS N POSSO APAGAR AINDA
				if (typeof lancamentos === "object" && Object.keys(lancamentos).length > 0) {
					Object.entries(lancamentos).forEach(([key, parcela]) => {
						delete this.divida.contratos[contrato.con_codigo].parcelas[parcela.par_codigo];
					})
				}
			});

			//ERRADO
			// Object.values(this.divida.contratos).forEach(contrato => {
			// 	let lancamentos = Object.values(contrato.parcelas).filter(parcela => parcela.lancamento_futuro === "1");

			// 	if (lancamentos.length >= 1) {
			// 		lancamentos.forEach(lancamento => this.lancamentosFuturos.aPrazo.push(lancamento));
			// 		delete this.divida.contratos[contrato].parcelas[parcela];
			// 	}
			// });
		}
	}
};
</script>

<style scoped lang="scss">
.bg-page {
	box-sizing: border-box !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition-duration: 0.2s;
}

.background-card {
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-top: 15px;
	margin-bottom: 5px;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		padding: 5px 20px;
	}
}

.debit-card {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		flex-direction: column;
		height: auto;
		max-width: 100%;
		width: auto;
	}
}

.img-box {
	width: 110px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: contain;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		display: var(--hideButtons);
	}
}

.img-box img {
	object-fit: contain;
	width: 110px;
	transition-duration: 0.2s;
}

.container-info {
	flex: 1;
	height: auto;
	margin-left: 30px;
	padding-left: 30px;
	border-left: 1px solid #f0f0f0;
	width: 100%;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		border: 0;
		padding: 0;
		margin: 0;
	}
}

.info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		justify-content: space-around;
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		text-align: center;
	}
}

.info div,
.row-info,
.row-info p,
.row-info h6 {
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		width: 100% !important;
		text-align: start;
	}
}

.box-buttons {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		display: var(--hideButtons);
		margin-top: 0px;
		flex-direction: column;
		padding: 5px 20px;
	}
}

.box-buttons div {
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		flex-direction: column;
		width: 100% !important;
		margin: 3px 0;
		padding: 0 !important;
	}
}

.row-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	max-width: 250px;
	transition-duration: 0.2s;

	h6 {
		margin-right: 5px;
		color: #5b6987;
		font-family: "Roboto";
		font-size: 12px;
	}

	p {
		color: #031b4e;
		font-family: "Roboto Black";
		text-transform: uppercase;
		font-size: 16px;
		width: 230px;
	}

	button {
		background: none !important;
		border: none;
		padding: 0 !important;
		/*optional*/
		font-family: "Roboto";
		/*input has OS specific font-family*/
		color: #069;
		text-decoration: underline;
		cursor: pointer;

		@media only screen and (max-width: 760px) {
			display: var(--hideButtons);
		}
	}
}

.row-totals {
	display: flex;
	flex-direction: row;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		flex-direction: column;
		margin-left: 20px;
		display: var(--hideButtons);
		width: 100% !important;
		margin: 10px 0 !important;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding: 5px 20px;
	}
}

.totals {
	margin-left: 15px;
	transition-duration: 0.2s;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	@media only screen and (max-width: 760px) {
		margin-bottom: 20px;
	}

	@media only screen and (max-width: 760px) {
		margin-left: 0px;
		margin-bottom: 20px;
	}
}

.totals h6 {
	color: #5b6987;
	color: "Roboto";
	font-size: 11px;
	margin-bottom: 3px;
	transition-duration: 0.2s;
}

.totals h2 {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 24px;
	transition-duration: 0.2s;

	@media only screen and (min-width: 761px) and (max-width: 1120px) {
		font-size: 18px;
	}

	@media only screen and (max-width: 760px) {
		font-size: 18px;
	}
}

.box-options-payment {
	margin-top: 50px;
	transition-duration: 0.2s;
}

.box-options-payment h2 {
	font-size: 24px;
	text-align: center;
	font-family: "Roboto Black";
	color: #031b4e;
	transition-duration: 0.2s;
}

.column-options-payment {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	transition-duration: 0.2s;
}

.paymentFormRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 60px;
	align-items: center;
	padding: 0 20px;
	border-bottom: 1px solid var(--grayColor);
	transition-duration: 0.2s;
}

.paymentFormRow h4 {
	font-size: 18px;
	font-family: "Roboto Bold";
	color: #031b4e;
	transition-duration: 0.2s;
}

.paymentFormRow i {
	font-size: 24px;
	transition-duration: 0.2s;
}

.details-option {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	transition-duration: 0.2s;
}

.details-option h2 {
	font-size: 24px;
	text-align: center;
	font-family: "Roboto Black";
	color: var(--blackColor);
	transition-duration: 0.2s;
}

.detail-row {
	padding-top: 20px;
	display: flex;
	flex-direction: row;
	width: 500px;
	justify-content: space-between;
	align-items: center;
	transition-duration: 0.2s;
}

.detail-row-icon {
	width: 50px;
	display: flex;
	justify-content: center;
	transition-duration: 0.2s;
}

.detail-row-icon i {
	font-size: 38px;
	transition-duration: 0.2s;
}

.detail-row-label {
	font-size: 18px;
	font-family: "Roboto Bold";
	transition-duration: 0.2s;
}

.detail-row-value {
	font-size: 18px;
	font-family: "Roboto Bold";
	transition-duration: 0.2s;
}

.container-button {
	padding-left: 20px;
	width: 100%;
	transition-duration: 0.2s;
}

.totals span {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 11px;
	transition-duration: 0.2s;
}

span.tag-porcent {
	background: var(--primaryColor);
	padding: 3px 5px;
	color: #ffffff;
	font-family: "Roboto Bold";
	font-size: 10px;
	border-radius: 27px;
	transition-duration: 0.2s;
}

.message-top {
	margin: 0 0 20px 0;
	font-family: "Roboto Bold";
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	padding: 10px 10px;
	background: var(--primaryColor);
	color: #eeeeee;
	transition-duration: 0.2s;
}

.message-top b {
	font-family: "Roboto Black";
	font-size: 16px;
	transition-duration: 0.2s;
}

.amount-boleto {
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition-duration: 0.2s;
}

.amount-boleto .values-boleto {
	display: flex;
	align-items: center;
	width: 300px;
	justify-content: space-between;
	transition-duration: 0.2s;
}

.amount-boleto .values-boleto .totals {
	padding-right: 10px;
	min-width: 130px;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition-duration: 0.2s;
}

.values-boleto {
	justify-content: flex-end !important;
}

@media only screen and (max-width: 768px) {
	.amount-boleto {
		flex-direction: column;
		text-align: center;
	}

	.amount-boleto div {
		margin: 10px 0;
	}

	.amount-boleto .values-boleto {
		width: 100%;
		padding: 0px 20px;
	}

	.amount-boleto .values-boleto .totals {
		align-items: flex-start;
	}
}

.amount-boleto h5 {
	font-size: 16px;
	font-family: "Roboto Bold";
	color: var(--blackColor);
	transition-duration: 0.2s;
}

.amount-boleto h5 b {
	font-family: "Roboto Black";
	font-size: 18px;
	color: var(--primaryColor);
	transition-duration: 0.2s;
}

.amount-boleto p {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 12px;
	margin-top: 3px;
	transition-duration: 0.2s;
}

.card-promotion-debit {
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	padding: 15px 30px;
	background: #fff;
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	margin-bottom: 30px;
	transition-duration: 0.2s;

	@media only screen and (max-width: 760px) {
		padding: 20px;
	}
}

.inner-info {
	display: flex;
	flex-direction: column;

	i {
		display: none;
		transition-duration: 0.2s;

		@media only screen and (max-width: 760px) {
			display: inline;
		}
	}

	@media only screen and (max-width: 760px) {
		padding: 20px;
		transition-duration: 0.2s;
	}
}
</style>
