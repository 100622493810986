<template>
	<div :class="keyboardClass"></div>
</template>
	
	
<script>
import Keyboard from "simple-keyboard"

import "simple-keyboard/build/css/index.css"

export default {
	name: "SimpleKeyboard",

	props: {
		keyboardClass: {
			default: "simple-keyboard",
			type: String
		},
		input: {
			type: String
		}
	},
	data: () => ({
		keyboard: null
	}),
	mounted() {
		this.keyboard = new Keyboard(this.keyboardClass, {
			onChange: this.onChange,
			preventMouseDownDefault: true,
			maxLength: 6,
			layout: {
				default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"]
			},
			theme: "hg-theme-default  hg-layout-numeric numeric-theme",
			physicalKeyboardHighlight: true,
			syncInstanceInputs: true,
			mergeDisplay: true
		})
	},

	methods: {
		onChange(input) {
			this.$emit("onChange", input)
		}
	},

	watch: {
		input(input) {
			this.keyboard.setInput(input)
		}
	}
}
</script>
	
<style lang="scss" scoped>
.simple-keyboard {
	width: 100%;
	height: 265spx;
	position: flex;
}
</style>