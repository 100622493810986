<template>
	<div class="box-forms-regiter">

		<form-fields
			:fields="fields"
			@save="completeRegister"
			@update:values="inputsValues = $event" ></form-fields>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import globalMethods from "@/mixins/globalMethods"
import mixins from "@/mixins/mixins"

import ButtonDefault from "@/components/buttons/ButtonDefault"
import FormFields from "@/components/FormFields"
import ButtonLg from "@/components/buttons/ButtonLg"
import StepperHeader from "@/components/StepperHeader"
import ClientData from "@/views/signIn/components/ClientData"

export default {
	name: "AdressData",

	mixins: [globalMethods, mixins],

	components: { ClientData, StepperHeader, ButtonLg, FormFields, ButtonDefault },

	props: ["adress"],

	data() {
		return {
			fields: [],
			identification: "",
			inputsValues: {}
		}
	},

	computed: {
		...mapState({
			showMessageModal: (estado) => estado.messageModal.show,
		})
	},

	watch: {
		"inputsValues.cep": function(val) {
			if (val.length == 9) {
				this.action_buscar_cep({
					cep: val

				}).then(resposta => {
					if (resposta?.data?.retorno) {
						this.fields[1].valueField = resposta?.data?.dados?.logradouro
						this.fields[3].valueField = resposta?.data?.dados?.bairro
						this.fields[5].valueField = resposta?.data?.dados?.localidade
						this.fields[4].valueField = resposta?.data?.dados?.uf

					} else {
						this.fields[1].valueField = ""
						this.fields[3].valueField = ""
						this.fields[5].valueField = ""
						this.fields[4].valueField = ""
					}

					this.$root.$refs.FormFields.$forceUpdate();
					this.SET_LOADING_MESSAGE("");
					this.SET_LOADING(false);
				})
			}
		}
	},

	created() {
		this.$root.$refs.Adress = this
		// if(!this.$route.params?.identification){
		//   this.parceiro
		//       ? this.$router.go(-1)
		//       : this.$router.push({name: 'LandPage'})
		// }
	},

	mounted() {
		if (Object.keys(this.adress).length === 0) {
			this.fields = [
				{
					variableName: "cep",
					fieldType: "TextFieldMask",
					label: "CEP",
					type: "text",
					cols: "4",
					maskFormat: "#####-###"
				},
				{
					variableName: "endereco",
					fieldType: "TextField",
					label: "Endereço",
					type: "text",
					cols: "8",
					disabled: true
				},
				{
					variableName: "numeroEndereco",
					fieldType: "TextField",
					label: "Número",
					type: "text",
					cols: "4"
				},
				{
					variableName: "bairro",
					fieldType: "TextField",
					label: "Bairro",
					type: "text",
					cols: "8",
					disabled: true
				},
				{
					variableName: "estado",
					fieldType: "TextField",
					label: "Estado",
					type: "text",
					cols: "6",
					disabled: true
				},
				{
					variableName: "cidade",
					fieldType: "TextField",
					label: "Cidade",
					type: "text",
					cols: "6",
					disabled: true
				}
			]
		} else {
			this.fields = [
				{
					variableName: "cep",
					fieldType: "TextFieldMask",
					label: "CEP",
					type: "text",
					cols: "4",
					maskFormat: "#####-###",
					valueField: this.adress?.cep
				},
				{
					variableName: "endereco",
					fieldType: "TextField",
					label: "Endereço",
					type: "text",
					cols: "8",
					disabled: true,
					valueField: this.adress?.endereco
				},
				{
					variableName: "numeroEndereco",
					fieldType: "TextField",
					label: "Número",
					type: "text",
					cols: "4",
					valueField: this.adress?.numeroEndereco
				},
				{
					variableName: "bairro",
					fieldType: "TextField",
					label: "Bairro",
					type: "text",
					cols: "8",
					disabled: true,
					valueField: this.adress?.bairro
				},
				{
					variableName: "estado",
					fieldType: "TextField",
					label: "Estado",
					type: "text",
					cols: "6",
					disabled: true,
					valueField: this.adress?.estado
				},
				{
					variableName: "cidade",
					fieldType: "TextField",
					label: "Cidade",
					type: "text",
					cols: "6",
					disabled: true,
					valueField: this.adress?.cidade
				}
			]
		}
	},

	methods: {
		...mapActions(["action_buscar_cep"]),

		completeRegister() {
			const { cep, endereco, numeroEndereco, bairro, estado, cidade } = this.inputsValues

			let required = false
			if (this.inputsValues.cep == undefined) {
				required = true
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Preencher os campos requeridos`,
					styleMessage: "danger"
				})

			} else {
				Object.entries(this.inputsValues).forEach(([key, value]) => {
					if (!this.showMessageModal && key !== "website" && (!value || value === "")) {
						required = true

						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `O campo ${this.fields.filter(field => field.variableName === key)[0].label} é obrigatório`,
							styleMessage: "danger"
						})
					}
				})
			}

			if (!required) {
				if (this.inputsValues.cep.length <= 8) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um cep válido`,
						styleMessage: "danger"
					})

				} else {
					let obj = {
						cep: this.removeMaskCharacters(cep),
						endereco: endereco,
						numeroEndereco: numeroEndereco,
						bairro: bairro,
						estado: estado,
						cidade: cidade
					}
					this.$emit("update:returnAdress", obj)
					return true
				}
			}
			return false
		}
	}
}
</script>

<style scoped lang="scss">
.box-register {
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register h2 {
	font-family: Montserrat-Bold;
	color: var(--primaryColor);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3 {
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
}

.box-forms-regiter {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}

.box-button-register {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}
</style>
