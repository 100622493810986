import Vue from "vue";
import VueRouter from "vue-router";
import LandPage from "@/views/landPage/LandPage";
import LandPageParceiro from "@/views/landPage/LandPageParceiro";
import PrivacyPortal from "@/views/documentDebits/PrivacyPortal.vue";
import WorkWithUs from "@/views/documentDebits/WorkWithUs.vue";
import Login from "@/views/Login";
import Template from "@/views/admin/Template";
import TemplateDebts from "@/views/documentDebits/TemplateDebts";
import Register from "@/views/documentDebits/Register";
import RegisterCreditor from "@/views/documentDebits/RegisterCreditor";
import AccordionTabs from "@/views/documentDebits/AccordionTabs";
import MyDebits from "@/views/documentDebits/MyDebits/MyDebits";
import MyAgreements from "@/views/documentDebits/MyAgreements/MyAgreements";
import TalkToUs from "@/views/documentDebits/TalkToUs";
import FAQ from "@/views/documentDebits/FAQ";
import TermsLGPD from "@/views/TermsLGPD";
import ConfirmUser from "@/views/documentDebits/ConfirmUser";
import ConfirmBirthday from "@/views/documentDebits/ConfirmBirthday";
import ListPartners from "@/views/admin/Partners/ListPartners";
import ListQuestions from "@/views/admin/Questions/ListQuestions";
import ListLeads from "@/views/admin/Leads/ListLeads";
import PostPartners from "@/views/admin/Partners/PostPartners";
import PostQuestions from "@/views/admin/Questions/PostQuestions";
import ResetPassword from "@/views/admin/ResetPassword";
import UpdatePartner from "@/views/admin/Partners/UpdatePartner";
import UpdateQuestions from "@/views/admin/Questions/UpdateQuestions";
import ResetPhone from "@/views/documentDebits/ResetPhone";
import SignIn from "@/views/signIn/SignIn";
import NewPhone from "@/views/documentDebits/NewPhone";
import VerifyToken from "@/views/documentDebits/VerifyToken";

Vue.use(VueRouter);

let subdominio = process.env.VUE_APP_STANDARD_PARTNER;
if (window.location.host.split(".").length > 2) {
    subdominio =
        window.location.host.split(".")[0] === "www"
            ? null
            : window.location.host.split(".")[0];
}

const routes = [
    {
        path: "/",
        name: "LandPageParceiro",
        component: LandPageParceiro,
    },
    {
        path: "/politica-de-privacidade",
        name: "TermsLGPD",
        component: TermsLGPD,
    },
    {
        path: "/cadastre-sua-empresa/:id",
        name: "SignIn",
        component: SignIn,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/logout",
        name: "Logout",
        component: Login,
    },
    {
        path: "/admin",
        redirect: { name: "Login" },
        name: "Template",
        component: Template,
        meta: {
            permission: [],
        },
        children: [
            {
                path: "parceiros",
                name: "ListPartners",
                component: ListPartners,
                meta: {
                    permission: [],
                },
            },
            {
                path: "duvidas",
                name: "ListQuestions",
                component: ListQuestions,
                meta: {
                    permission: [],
                },
            },
            {
                path: "leads",
                name: "ListLeads",
                component: ListLeads,
                meta: {
                    permission: [],
                },
            },
            {
                path: "cadastrar-duvida",
                name: "PostQuestions",
                component: PostQuestions,
                meta: {
                    permission: [],
                },
            },
            {
                path: "atualizar-duvida",
                name: "UpdateQuestions",
                component: UpdateQuestions,
                meta: {
                    permission: [],
                },
            },
            {
                path: "cadastrar-parceiro",
                name: "PostPartners",
                component: PostPartners,
                meta: {
                    permission: [],
                },
            },
            {
                path: "atualizar-parceiro",
                name: "UpdatePartner",
                component: UpdatePartner,
                meta: {
                    permission: [],
                },
            },
            {
                path: "alterar-senha",
                name: "ResetPassword",
                component: ResetPassword,
                meta: {
                    permission: [],
                },
            },
        ],
    },
    {
        path: "/consulta",
        redirect: { name: "Home" },
        name: "TemplateDebts",
        component: TemplateDebts,
        meta: {
            permission: [],
        },
        children: [
            {
                path: "cadastro",
                name: "Register",
                component: Register,
                meta: {
                    permission: [],
                },
            },
            {
                path: "portal-de-privacidade",
                name: "PrivacyPortal",
                component: PrivacyPortal,
                meta: {
                    permission: [],
                },
            },
            {
                path: "trabalhe-conosco",
                name: "WorkWithUs",
                component: WorkWithUs,
                meta: {
                    permission: [],
                },
            },
            {
                path: "seja-parceiro-aquicob",
                name: "RegisterCreditor",
                component: RegisterCreditor,
                meta: {
                    permission: [],
                },
            },
            {
                path: "confirmacao",
                name: "ConfirmUser",
                component: ConfirmUser,
                meta: {
                    permission: [],
                },
            },
            {
                path: "confirmar-nascimento",
                name: "ConfirmBirthday",
                component: ConfirmBirthday,
                meta: {
                    permission: [],
                },
            },
            {
                path: "esqueceu-telefone",
                name: "ResetPhone",
                component: ResetPhone,
                meta: {
                    permission: [],
                },
            },
            {
                path: "novo-telefone/token/:token",
                name: "NewPhone",
                component: NewPhone,
                meta: {
                    permission: [],
                },
            },
            {
                path: "/",
                name: "AccordionTabs",
                component: AccordionTabs,
                meta: {
                    permission: [],
                },
            },
            {
                path: "minhas-dividas",
                name: "MyDebits",
                component: MyDebits,
                meta: {
                    permission: [],
                },
            },
            {
                path: "meus-acordos",
                name: "MyAgreements",
                component: MyAgreements,
                meta: {
                    permission: [],
                },
            },
            {
                path: "fale-conosco",
                name: "TalkToUs",
                component: TalkToUs,
                meta: {
                    permission: [],
                },
            },
            {
                path: "duvidas",
                name: "FAQ",
                component: FAQ,
                meta: {
                    permission: [],
                },
            },
            {
                path: "verficar-token",
                name: "VerifyToken",
                component: VerifyToken,
                meta: {
                    permission: [],
                },
            },
        ],
    },
    {
        path: "*",
        name: "LandPage",
        component: LandPage,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
