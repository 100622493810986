<template>
	<footer class="footer">
		<div class="container">
			{{ mensagemDireitosReservados }}
			 <br /><br />
			<a @click="openTermLGPD()"
				><b><u>Acessar Política de Privacidade</u></b></a>
		</div>
	</footer>
</template>

<script>
import mixins from "@/mixins/mixins";

export default {
	name: "LandPageFooter",

	mixins: [mixins],

	computed: {
		mensagemDireitosReservados() {
			return `© Todos os direitos reservados ${this.nomeParceiro}`;
		}
	},

	methods: {
		openTermLGPD() {
			let routeData = this.$router.resolve({ name: "TermsLGPD" });
			window.open(routeData.href, "_blank");
		}
	}
};
</script>

<style scoped>
a {
	cursor: pointer;
}
.footer {
	text-align: center;
	padding: 15px 0;
	color: var(--grayColor);
	font-family: Cabin;
}
</style>
