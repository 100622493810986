<template>

	<div>

		<div v-if="parceiroConfig">

			<land-page-slider :parceiro="parceiro" ></land-page-slider>

			<section
				v-if="parceiroConfig.sessoes.about && parceiroConfig.sessoes.about.campos"
				:ref="'about'"
				class="section">
				<land-page-about ></land-page-about></section>

			<section
				v-if="parceiroConfig.sessoes.tutorial && parceiroConfig.sessoes.tutorial.campos"
				:ref="'tutorial'"
				class="section" >
				<land-page-tutorial></land-page-tutorial>
			</section>

			<section
				v-if="parceiroConfig.sessoes.partners && parceiroConfig.sessoes.partners.arquivos.length > 0 && !portalCamec"
				class="section" >
				<land-page-partner></land-page-partner>
			</section>

			<section
				v-if="parceiroConfig.sessoes.contact && parceiroConfig.sessoes.contact.campos"
				:ref="'contact'" >
				<land-page-contact></land-page-contact>
			</section>

			<land-page-footer></land-page-footer>

		</div>
	
	</div>

</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

import LandPageHeader from "./components/LandPageHeader";
import LandPageSlider from "./components/LandPageSlider";
import LandPageAbout from "./components/LandPageAbout";
import LandPageTutorial from "./components/LandPageTutorial";
import LandPagePartner from "./components/LandPagePartner";
import LandPageContact from "./components/LandPageContact";
import LandPageFooter from "./components/LandPageFooter";

export default {
	name: "Home",

	components: {
		LandPageFooter,
		LandPageContact,
		LandPagePartner,
		LandPageTutorial,
		LandPageAbout,
		LandPageSlider,
		LandPageHeader
	},

	mixins: [mixins],

	computed: mapState({
		corPrimaria: (estado) => estado.corPrimaria,
		corSecundaria: (estado) => estado.corSecundaria,
		portalCamec: (estado) => estado.portalCamec
	})
};
</script>

<style scoped>
.section {
	padding-top: 60px;
	padding-bottom: 60px;
}
</style>
