<template>
    <div class="background">
        <div class="dialog-box">
            <i :class="iconMessage ? iconMessage : 'fas fa-times-circle'"></i>
            <h6>{{title}}</h6>
            <p>{{message}}</p>
            <div class="buttons-box">
                <button @click="$emit('close')" class="btn-modal cancel">Não</button>
                <button @click="$emit('confirm')" class="btn-modal confirm">Sim</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "QuestionModal",
        props:[
          'title',
          'message',
          'iconMessage'
        ],
        data(){
            return{
                modal: false
            }
        },
    }
</script>

<style scoped>
    .background{
        background-color: rgba(0, 0, 0, 0.9);
        width: 100%;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        z-index: 500;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dialog-box{
        background: #ffffff;
        width: 333px;
        padding: 40px;
        border-radius: 20px;
        text-align: center;
    }

    .dialog-box i{
        font-size: 100px;
        margin: 0 0 40px 0;
        color: #c1345e;
    }

    .dialog-box h6{
        font-family: "Roboto Black", sans-serif;
        font-weight: normal;
        font-size: 24px;
        color: var(--blackColor);
        margin-bottom: 15px;
        line-height: 28px;
    }

    .dialog-box p{
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        color: var(--blackColor);
        margin-bottom: 50px;
    }

    .btn-modal{
        height: 48px;
        padding: 0 38px;
        font-family: "Roboto Black", sans-serif;
        font-size: 18px;
        border-radius: 20px;
        margin: 0 5px;
        cursor: pointer;
    }

    .buttons-box{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-modal.confirm{
        background: #c1345e;
        color: #ffffff;
    }

    .btn-modal.cancel{
        background: var(--opacityGrayColor);
        color: var(--blackColor);
    }

    .btn-modal.cancel:hover{
        background: var(--opacityGrayColor);
    }

    .btn-modal.confirm:hover{
        background: #c1345e;
    }

</style>