export default {
    formatarData(data) {
        if (data.indexOf('T') != -1) {
            data = data.substring(0, data.indexOf('T'));
        }

        let arrayData = data.split("-");
        let dia = arrayData[2].length == 2 ? arrayData[2] : `0${arrayData[2]}`;
        let mes = arrayData[1].length == 2 ? arrayData[1] : `0${arrayData[1]}`;
        return `${dia}/${mes}/${arrayData[0]}`;
    },

    formatarDinheiro(valor) {
        return Number.parseFloat(valor).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatarCpf(cpf) {
        cpf = cpf.toString();
        return `${cpf.substring(0, 3)}.XXX.XXX-${cpf.substring(9)}`;
    }
}