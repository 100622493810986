<template>
	<div>
		<header-list
			title="Lista de dúvidas frequentes"
			routeNameButton="PostQuestions"
			:buttonLabel="'Nova dúvida'" ></header-list>

		<div class="list-partners">
			<h3 v-if="duvidas == null" class="flex-center mt-15">Nenhuma dúvida frequente adicionada</h3>

			<div v-else v-for="duvida in duvidas" class="card-patners">

				<div class="first-column">

					<div class="card-box-image">
						<img src="@/assets/images/teae.png" />
					</div>

					<div class="card-box-description">
						<h5>{{ duvida.titulo }}</h5>
					</div>

				</div>
				<div class="card-box-action">

					<small-button
						@click.native="visualizarDuvida(duvida.codigo)"
						title="Visualizar duvidas"
						icon="fas fa-search" ></small-button>

					<small-button
						:color="danger"
						title="Excluir dúvida"
						icon="fas fa-times"
						@click.native="questionDelete(duvida)" ></small-button>

				</div>
			</div>
		</div>

		<question-modal
			v-if="questionModal.show"
			:title="questionModal.title"
			:message="questionModal.message"
			@close="questionModal.show = false"
			@confirm="deleteDuvida(duvidaSelected.codigo)" ></question-modal>

	</div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import HeaderList from "@/components/HeaderList";
import SmallButton from "@/components/buttons/SmallButton";
import QuestionModal from "@/components/QuestionModal";

export default {
	name: "ListQuestions",

	components: { QuestionModal, SmallButton, HeaderList },

	data() {
		return {
			duvidas: [],

			questionModal: {
				show: false,
				title: "",
				message: ""
			},

			duvidaSelected: null,

			danger: {
				"--color": "#fff",
				"--hoverColor": "#f44336"
			}
		};
	},

	mounted() {
		this.getDuvidas();
	},

	methods: {
		...mapMutations(["SET_LOADING", "CHANGE_MESSAGE_SNACKBAR", "CHANGE_MESSAGE_MODAL"]),

		...mapActions(["action_buscar_duvidas", "action_deletar_duvida"]),

		getDuvidas() {
			this.SET_LOADING(true);

			this.action_buscar_duvidas()
				.then((response) => {
					if (response) {
						if (response.data.dados.duvidas) this.duvidas = response.data.dados.duvidas;
						else this.duvidas = null;
						this.SET_LOADING(false);
					}
				})
				.catch(() => {
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Dados não encontrados`,
						styleMessage: "danger"
					});
				});
		},

		questionDelete(duvida) {
			this.duvidaSelected = duvida;
			this.questionModal.title = "Atenção";
			this.questionModal.message = `Você tem certeza que deseja excluir a duvida ${duvida.titulo} ?`;
			this.questionModal.show = true;
		},

		deleteDuvida(codigo) {
			this.duvidaSelected = null;
			this.questionModal.show = false;
			this.SET_LOADING(true);
			this.action_deletar_duvida({ codigo: codigo }) .then((resposta) => {
				this.SET_LOADING(false);

				if (resposta) {
					this.getDuvidas();
					this.CHANGE_MESSAGE_SNACKBAR("Duvida excluída com sucesso!");
					setTimeout(() => this.CHANGE_MESSAGE_SNACKBAR(""), 5000);

				} else {
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Não foi possivel excluir esta dúvida`,
						styleMessage: "danger"
					});
				}
			}).catch((_) => {
				this.SET_LOADING(false);
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Ocorreu um erro",
					message: `Não foi possivel excluir esta dúvida`,
					styleMessage: "danger"
				});
			});
		},

		visualizarDuvida(codigo) {
			$router.push({
				name: 'UpdateQuestions',
				params: {
					codigo
				}
			});
		}
	}
};
</script>

<style scoped>
.list-partners {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.card-patners {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 15px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	flex-direction: row;
	margin-top: 15px;
	margin-bottom: 5px;
}

.card-box-image img {
	max-width: 70px;
	max-height: 70px;
	object-fit: contain;
}

.first-column {
	display: flex;
	align-items: center;
}

.card-box-description {
	padding-left: 20px;
}

.card-box-description h5 {
	font-size: 18px;
	font-family: "Roboto Bold";
	cursor: var(--primaryColor);
}

.card-box-action {
	display: flex;
	align-items: center;
}
</style>
