<template>
	<div>
		<div>
			<text-field-mask mask-format="(##) #####-####" :required="false" @update:value="phone = $event" label=" Ou informe o número de celular para contato" />
		</div>
	</div>
</template>

<script>
import TextFieldMask from "../../../../components/inputs/TextFieldMask"
import ButtonDefault from "../../../../components/buttons/ButtonDefault"
export default {
	name: "ScheduleCall",
	components: { ButtonDefault, TextFieldMask }
}
</script>

<style>
</style>