<template>
	<div class="form">
		<div class="fields">

			<component
				:is="field.fieldType"
				v-for="(field, index) in fields"
				v-show="!field.hideInput"
				:key="field.fieldType + index"
				:required="field.required"
				:variableName="field.variableName"
				:maskMoney="field.maskMoney"
				:title="field.title"
				:maskFormat="field.maskFormat"
				:disabled="field.disabled"
				:valueField="field.valueField"
				:keyValue="field.keyValue"
				:keyName="field.keyName"
				:options="field.options"
				:items="field.items"
				:rules="field.rules"
				:label="field.label"
				:placeholder="field.placeholder"
				:future="field.future"
				:plusEighteen="field.plusEighteen"
				:cols="isMobile ? '12' : field.cols"
				:type="field.type"
				@onEnter="
					{
						inputsValues[field.variableName] = $event,
						$emit('update:values', inputsValues),
						$emit('save')
					}
				"
				@update:value="
					{
						inputsValues[field.variableName] = $event,
						$emit('update:values', inputsValues)
					}
				" ></component>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import TextField from "./inputs/TextField";
import DateField from "./inputs/DateField";
import SelectField from "./inputs/SelectField";
import ImageField from "./inputs/ImageField";
import FileField from "./inputs/FileField";
import TimeField from "./inputs/TimeField";
import SearchField from "./inputs/SearchField";
import TextAreaField from "./inputs/TextAreaField";
import TextFieldMask from "./inputs/TextFieldMask";
import TextFieldMoney from "./inputs/TextFieldMoney";
import HeaderLabel from "./inputs/HeaderLabel";
import ColorField from "./inputs/ColorField";
import ToggleToken from "./inputs/ToggleToken";
import MultipleImagesField from "./inputs/MultipleImagesField";

export default {
	name: "FormFields",

	components: {
		TextField,
		DateField,
		TimeField,
		SelectField,
		FileField,
		SearchField,
		ImageField,
		TextAreaField,
		TextFieldMask,
		TextFieldMoney,
		HeaderLabel,
		ColorField,
		MultipleImagesField,
		ToggleToken
	},

	props: {
		fields: {
			type: Array,
			required: true
		},

		validate: {
			type: Boolean,
			required: false
		},

		codRef: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			windowWidth: window.innerWidth,
			inputsValues: {},
			formRef: false
		};
	},

	computed: {
		isMobile() {
			return this.windowWidth <= 768;
		}
	},

	watch: {
		fields() {
			this.fields.forEach((field) => {
				Vue.set(this.inputsValues, field.variableName, "");
			});
		},

		validate() {
			if (this.$refs[this.formRef].validate() !== false) {
				this.$emit("save", this.$refs[this.formRef].validate());
			}
		}
	},

	created() {
		this.$root.$refs.FormFields = this;
	},

	mounted() {
		window.addEventListener("resize", () => {
			this.windowWidth = window.innerWidth;
		});
		this.formRef = this.formRef ? this.formRef : "form";
	}
};
</script>

<style scoped>
.form {
	width: 100%;
	z-index: 3;
}

.fields {
	display: flex;
	flex-wrap: wrap;
	align-content: stretch;
	align-items: center;
	flex: 1;
}</style>
