<template>
	<div class="page-terms" :style="corPrimaria">
		<land-page-header :backgroundTransparent="false" ></land-page-header>

		<div class="box-term-options">

			<div
				class="term-option"
				:class="{ active: option === 'termoDeUso' }"
				@click="option = 'termoDeUso'">

				<i class="fas fa-file-alt" :style="corPrimaria" ></i>
				<h6 :style="corPrimaria">Termos de uso</h6>
			</div>

			<div
				class="term-option"
				:class="{ active: option === 'politicaDePrivacidade' }"
				:style="corPrimaria"
				@click="option = 'politicaDePrivacidade'">

				<i class="fas fa-file-alt" :style="corPrimaria" ></i>
				<h6 :style="corPrimaria">Política de Privacidade</h6>
			</div>
		</div>

		<section v-if="option === 'termoDeUso'">
			<h3>Termos de uso</h3>
			<p>
				Bem-vindo à <b>{{ nomeParceiro }}</b>. A seguir apresentamos a você (visitante) os Termos de Uso e Condições de Navegação, documento esse que relaciona as principais regras que devem ser observadas por todos que acessam este site ou utilizam suas ferramentas. Ao visitar ou utilizar este site pressupõe-se a leitura e concordância com todos os termos abaixo.<br />
				A <b>{{ nomeParceiro }}</b> é um Portal que possibilita a seus visitantes e usuários a capacidade de identificar débitos pendentes e oferecer formas facilitadas para o pagamento, caso haja interesse em saldar tais montantes. Esta plataforma tem como objetivo principal a aproximação entre credores e devedores.<br />
				Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:<br />
				<b>Anti-Spam:</b> Sistema que evita correspondências não desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as movendo para pasta específica.<br />
				<b>Conta de Acesso:</b> Credencial de um visitante cadastrado que permite o acesso à área restrita e às funcionalidades exclusivas, que é definida pelo nome de usuário e senha.<br />
				<b>Layout:</b> Conjunto compreendido entre aparência, design e fluxos do Portal.<br />
				<b>Link:</b> Terminologia para endereço de internet.<br />
				<b>Logs:</b> Registros de atividades do visitante efetuadas no Portal.<br />
				<b>Portal {{ nomeParceiro }}:</b> Designa o site de internet hospedado em {{ siteParceiro }} e seus subdomínios.<br />
				<b>Site:</b> Denominação para páginas de internet.<br />
				<b>Visitante:</b> Qualquer pessoa que navegar pelo Portal.<br />
				<b>Usuário:</b> Todo visitante cadastrado no Portal.<br />
				<b>Webmastering:</b> Compreende a criação, programação, desenvolvimento, controle e disponibilidade de páginas de internet.<br />
				<b>{{ nomeParceiro }}:</b> {{ dadosParceiro }}, proprietária e administradora do Portal {{ nomeParceiro }}.
			</p>
			<p>
				<b>1 &ndash; Acesso e Restrições de funcionalidade</b><br />
				<b>1.1.</b> Este Portal é dedicado à hospedagem do site de internet <b>{{ nomeParceiro }}</b>, cuja atividade é organizar a identificação de débitos existentes e oferecer formas facilitadas para pagamento conforme condições estabelecidas pelo credor correspondente.<br />
				<b>1.2.</b> Não é permitido aos visitantes e usuários acessarem as áreas de programação de Portal, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de Webmastering, ficando sujeito quem o fizer à legislação penal brasileira e obrigados a reparar os danos que causarem.<br />
				<b>1.3.</b> Também não é autorizado realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas de consulta deste Portal e de suas funcionalidades.<br />
				<b>1.4.</b> Neste Portal é proibida a utilização, de aplicativos spider, ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo automatizado, tanto para realizar operações massificadas ou para quaisquer outras finalidades, sob aplicação da legislação penal brasileira e de reparar os danos que decorrerem desta utilização.<br />
				<b>1.4.1.</b> Este Portal foi desenvolvido para a utilização por seres humanos com a finalidade de possibilitar a identificação de dívidas existentes em nome do interessado e oferecer formas mais vantajosas e facilitadas para pagamento.<br />
				<b>1.5.</b> É ônus dos visitantes e usuários arcar com os meios necessários para navegar neste Portal, incluindo os requisitos para o acesso à Internet.
			</p>
			<p>
				<b>2 &ndash; Informações gerais sobre o site e seu funcionamento</b><br />
				<b>2.1.</b> Este Portal é apresentado ao público da web na maneira como está disponível, podendo passar por constantes aprimoramentos e atualizações, não ficando obrigado a <b>{{ nomeParceiro }}</b> a manter uma estrutura ou layout, se não por sua própria conveniência.<br />
				<b>2.2.</b> A <b>{{ nomeParceiro }}</b> envida seus esforços para a disponibilidade contínua e permanente do Portal, ficando sujeito, todavia, a eventos extraordinários, como desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação e acesso à internet ou fatos de terceiro, que fogem de sua esfera de vigilância e responsabilidade.<br />
				<b>2.3.</b> A <b>{{ nomeParceiro }}</b> não se responsabiliza por qualquer dano, prejuízo ou perda no equipamento dos visitantes e usuários causados por falhas no sistema, no servidor ou na conexão à Internet, ainda que decorrentes de condutas de terceiros, inclusive por ações de softwares maliciosos como vírus, cavalos de tróia, e outros que possam, de algum modo, danificar o equipamento ou a conexão dos visitantes e usuários em decorrência do acesso, utilização ou navegação neste Portal, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos no mesmo.<br />
				<b>2.4.</b> Os visitantes e usuários não possuem qualquer direito para exigir a disponibilidade do Portal conforme melhor lhes convêm, tampouco poderão pleitear indenização ou reparação de danos em caso deste Portal permanecer fora do ar, independente da motivação.<br />
				<b>2.5.</b> A <b>{{ nomeParceiro }}</b> não detém qualquer responsabilidade pela navegação dos visitantes e usuários nos links externos contidos no Portal, sendo o dever deles a leitura dos Termos de Uso e Política de Privacidade do site acessado e agir conforme o determinado. Também serão os principais responsáveis caso sobrevier algum dano ou prejuízo, uma vez que a navegação segura cabe aos mesmos e que o Portal <b>{{ nomeParceiro }}</b> apenas apontou o link cabendo ao interessado a visita, ou não, ao site.<br />
				<b>2.5.1.</b> A <b>{{ nomeParceiro }}</b> não verifica, controla, aprova ou garante a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo, portanto, responsável por prejuízos, perdas ou danos ocorridos pela visita de tais sites, cabendo ao usuário verificar a confiabilidade das informações e dados ali exibidas antes de utilizá-los.<br />
				<b>2.5.2.</b> Perdas, danos ou prejuízos ocasionados por atrasos, defeitos, erros ou omissões que possam existir nos sistemas ou serviços disponibilizados pelos sites referidos acima, não são de responsabilidade da <b>{{ nomeParceiro }}</b>, mas sim de seu respectivo controlador.<br />
				<b>2.6.</b> A <b>{{ nomeParceiro }}</b> não se responsabiliza pelos atos praticados por visitantes e usuários em seu Portal, devendo cada um ser imputado de acordo com a qualidade do uso da plataforma.<br />
				<b>2.7.</b> O Portal <b>{{ nomeParceiro }}</b> não presta consultoria financeira ou comercial, tampouco aconselhamento jurídico a seus visitantes e usuários. Por favor, não insista.
			</p>
			<p>
				<b>3 &ndash; Comunicação do Portal com seus visitantes e usuários</b><br />
				<b>3.1.</b> A <b>{{ nomeParceiro }}</b> poderá utilizar todos os canais de comunicação fornecidos e autorizados com seus visitantes e usuários, nas suas mais diversas modalidades, seja email, ligação telefônica ou envio por SMS.<br />
				<b>3.1.1.</b> A responsabilidade pelo recebimento dos comunicados é exclusiva dos visitantes e usuários, por isso é indispensável que sempre forneçam os dados corretos e precisos, além de os manterem atualizados.<br />
				<b>3.1.2.</b> É igualmente de suas alçadas deixarem seus sistemas de anti-spam configurados de modo que não interfiram no recebimento dos comunicados e materiais do Portal <b>{{ nomeParceiro }}</b>, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail em virtude desse bloqueio ou filtro similar.<br />
				<b>3.1.2.1.</b> Recomenda-se que exista a inclusão dos domínios {{ siteParceiro }} nos endereços confiáveis após a realização do cadastro, para evitar situações prejudiciais ao usuário.
			</p>
			<p>
				<b>4 &ndash; Privacidade dos visitantes e usuários no Portal</b><br />
				<b>4.1.</b> O Portal possui documento próprio, denominado Política de Privacidade, que regula o tratamento dado às informações de cadastro e demais informações e dados coletados.<br />
				<b>4.2.</b> A Política de Privacidade é parte integrante e inseparável dos Termos de Uso e Condições de Navegação deste Portal, e pode ser acessada no link encontrado em seu rodapé.<br />
				<b>4.3.</b> Caso alguma disposição da Política de Privacidade conflitar com qualquer outra do presente documento, deverá prevalecer o descrito na norma mais específica.
			</p>
			<p>
				<b>5 &ndash; Obrigações do Portal</b><br />
				<b>5.1.</b> A {{ nomeParceiro }} se obriga com os visitantes e seus usuários a:<br />
				<b>5.1.1.</b> Manter o ambiente virtual seguro, tais quais suas operações de gerenciamento e dados registrados para tanto, salvo por ato destrutivo de terceiro que vá além dos esforços empenhados e do estado atual da tecnologia, hipótese que não se responsabilizará por danos oriundos dessa prática danosa.<br />
				<b>5.1.2.</b> Preservar a funcionalidade do site, com links não quebrados, utilizando layout que respeita a usabilidade, segurança e navegabilidade, facilitando a navegação sempre que possível e garantindo um ambiente confiável para processar suas operações.<br />
				<b>5.1.3.</b> Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas.<br />
				<b>5.1.4.</b> Garantir, por meio do estado da técnica disponível, o sigilo e confidencialidade dos dados inseridos nas funcionalidades oferecidas em seu Portal, somente sendo acessíveis a quem o usuário declinar, além de si próprio.
			</p>
			<p>
				<b>6 &ndash; Obrigações do visitante</b><br />
				<b>6.1.</b> Os visitantes e usuários se obrigam a realizar uma navegação com retidão ética, sempre respeitando as condições que regem a utilização do Portal.<br />
				<b>6.2.</b> Ao fornecer dados e informações os visitantes e usuários se obrigam a fazê-lo sempre com compromisso de veracidade e autenticidade, sob julgo da aplicação das penas da lei, de indenizar a quem causar dano e de ter a conta de acesso do presente Portal excluída.<br />
				<b>6.3.</b> Na incidência de danos ao Portal ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, não devendo a <b>{{ nomeParceiro }}</b> responder por tais prejuízos.<br />
				<b>6.4.</b> Os visitantes e usuários devem utilizar os recursos do presente Portal para as finalidades que foi constituído, sob pena da aplicação das penas da lei, de indenizarem a quem causarem dano e de terem a conta de acesso do presente Portal excluída.<br />
				<b>6.5.</b> Também, visitantes e usuários devem manter o sigilo dos dados de sua conta de acesso, sua senha e demais dispositivos de autenticação em relação a terceiros, não os divulgando ou compartilhando com quem quer que seja, sob pena da exclusão da conta e de ter de ressarcir os danos que sobrevierem dessa divulgação indevida.<br />
				<b>6.5.1.</b> Caso o usuário suspeite que a confidencialidade de sua senha foi quebrada ou que houve o comprometimento de algum dispositivo de autenticação, este deverá proceder sua troca ou atualização o mais rápido possível. Se não conseguir acessar a Internet para obter suporte, deverá buscar atendimento dentre os canais disponíveis.
			</p>
			<p>
				<b>7 - Direitos autorais e propriedade intelectual de Portal</b><br />
				<b>7.1.</b> O uso comercial da expressão <b>{{ nomeParceiro }}</b> como marca, nome empresarial ou nome de domínio, além dos conteúdos das telas de Portal, assim como os programas, bancos de dados, redes e seus arquivos são de propriedade da <b>{{ nomeParceiro }}</b> e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais.<br />
				<b>7.2.</b> Ao acessar este Portal, os visitantes e usuários declaram que irão respeitar todos os direitos de propriedade intelectual e os decorrentes da proteção de marcas, patentes e/ou desenhos industriais, depositados ou registrados em nome da <b>{{ nomeParceiro }}</b>, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, disponíveis no Portal. O simples acesso ao Portal não confere a estes qualquer direito ao uso dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, imagens, dados e informações, dentre outras, que nele estejam ou estiveram disponíveis.<br />
				<b>7.3.</b> A reprodução dos conteúdos descritos anteriormente está proibida, salvo com prévia autorização por escrito da <b>{{ nomeParceiro }}</b> ou caso se destinem ao uso exclusivamente pessoal e sem que em nenhuma circunstância os visitantes e usuários adquiram qualquer direito sobre os mesmos.<br />
				<b>7.4.</b> É permitido fazer somente o arquivo temporário deste Portal e de seu conteúdo, sendo vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido neste documento. Restam igualmente proibidas a reprodução, distribuição e divulgação, total ou parcial, dos textos, figuras, gráficos que compõem o presente Portal, sem prévia e expressa autorização da <b>{{ nomeParceiro }}</b>, sendo permitida somente a impressão de cópias para uso e arquivo pessoal, sem que sejam separadas as partes que permitam dar o fiel e real entendimento de seu conteúdo e objetivo.<br />
				<b>7.5.</b> Os visitantes e usuários assumem toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das informações, textos, gráficos, marcas, obras, imagens, enfim, de todo e qualquer direito de propriedade intelectual ou industrial deste Portal.<br />
				<b>7.6.</b> Qualquer outro tipo de utilização de material autorizado, inclusive para fins editoriais, comerciais ou publicitários, só poderá ser feito mediante prévio e expresso consentimento da <b>{{ nomeParceiro }}</b>. Os visitantes e usuários estão cientes, através deste item, que o uso comercial não autorizado poderá incorrer em infrações cíveis e criminais, por estar infringindo a lei dos direitos autorais.<br />
				<b>7.7.</b> Qualquer reutilização do material autorizado deverá ser objeto de uma nova autorização da <b>{{ nomeParceiro }}</b>.<br />
				<b>7.8.</b> A autorização para utilização do material solicitado não poderá ser transferida a terceiros, mesmo que vinculados ao sujeito autorizado por alguma razão.<br />
				<b>7.9.</b> Qualquer utilização não contemplada na mencionada autorização será considerada como uma violação dos direitos de autor e sujeita às sanções cabíveis na Lei 9.610, de 19 de fevereiro de 1998, que protege os direitos autorais no Brasil.<br />
				<b>7.10.</b> A utilização das funcionalidades oferecidas se dará na forma de prestação de serviço, não conferindo ao usuário nenhum direito sobre o software utilizado pela <b>{{ nomeParceiro }}</b> ou de suas estruturas de informática que sustentam as aplicações de internet.<br />
				<b>7.11.</b> A eventual retirada de qualquer conteúdo ou funcionalidade deste Portal, em decorrência de alguma reclamação, deverá ser sempre compreendida como uma demonstração de nossa intenção de evitar dissabores e, jamais, como reconhecimento de culpa ou de qualquer infração pela <b>{{ nomeParceiro }}</b> a direito de terceiro.<br />
				<b>7.12.</b> Salvo disposição específica em contrato existente entre os visitantes e usuários com a <b>{{ nomeParceiro }}</b>, esses retêm os direitos autorais dos conteúdos que já possuírem quando os enviam ao Portal, tais como, mas não limitadamente, a comentários, vídeos, imagens, áudio e mensagens, contudo, concedem uma licença irrevogável, perpétua, mundial, irrestrita, isenta de royalties e não exclusiva de reprodução, adaptação, modificação, tradução, publicação, distribuição ou exibição no próprio Portal, possibilitando a melhor utilização da ferramenta, maior divulgação de algum produto, serviço ou atividade ligada ao Portal <b>{{ nomeParceiro }}</b>.<br />
				<b>7.13.</b> Caso o visitante ou usuário enviar conteúdo para a <b>{{ nomeParceiro }}</b> por meio do Portal <b>{{ nomeParceiro }}</b> ou pelos canais divulgados que diga respeito a ideias para novos produtos, melhorias nos produtos já existentes, campanhas publicitárias ou qualquer outra ação de marketing do Portal, elas deixarão de pertencer ao patrimônio imaterial do visitante ou do usuário, ingressando no da <b>{{ nomeParceiro }}</b>, nada podendo reclamar neste sentido, sobretudo recompensa pecuniária pelo que foi enviado ou revisão nos modelos usados.
			</p>
			<p>
				<b>8 &ndash; Das funcionalidades do Portal {{ nomeParceiro }}</b><br />
				<b>8.1.</b> O Portal apresenta a seus visitantes e usuários uma plataforma que permite a identificação de débitos existentes com credores que adotarem o <b>{{ nomeParceiro }}</b> como forma de renegociação de dívidas.<br />
				<b>8.1.1.</b> A identificação dos débitos pendentes é realizada pelos dados fornecidos pelos credores cadastrados no site e as formas facilitadas para pagamento das dívidas existentes são ofertadas conforme parâmetros pré-determinados pelos próprios credores. A <b>{{ nomeParceiro }}</b> NÃO POSSUI QUALQUER INTERFERÊNCIA NOS DADOS INSERIDOS E NAS CONDIÇÕES EXPOSTAS PROPOSTAS PARA PAGAMENTO.<br />
				<b>8.1.2.</b> Somente é permitido aos usuários maiores de 18 anos utilizarem a plataforma.<br />
				<b>8.1.3.</b> Em caso de cadastro de pessoa jurídica, deverá ser indicada pessoa física responsável pela conta, que deve atender aos critérios de idade acima e possuir plenos poderes para a execução deste cadastro e atividades proporcionadas pelo Portal.<br />
				<b>8.1.4.</b> A identificação de débitos operará mediante fatores precisos de pesquisa em que eventual devedor não terá seus dados ou condição exposta a terceiros.<br />
				<b>8.2.</b> Após identificar débito existente o visitante poderá se cadastrar no site e explorar condições mais vantajosas para quitar o valor pendente escolhendo a forma de pagamento que melhor lhe convier.<br />
				<b>8.2.1.</b> O Portal não é responsável pela limitação no fornecimento de planos para pagamento ou se a experiência do usuário não lhe for a contento como o esperado, uma vez que depende de parametrização de cada credor para oferecer condições mais confortáveis para quitação dos débitos.<br />
				<b>8.2.2.</b> Ainda, não será a <b>{{ nomeParceiro }}</b> responsável por conferir a baixa das quantias quitadas, o envio do título de crédito ou documento que lastreou os débitos de volta ao devedor. Essas são obrigações do credor e a <b>{{ nomeParceiro }}</b> não as assume a qualquer título pelo oferecimento da plataforma <b>{{ nomeParceiro }}</b>, uma vez que sua responsabilidade alcança somente a aproximação entre o devedor e credor correspondente, não alterando ou interferindo nas relações jurídicas estabelecidas previamente.<br />
				<b>8.3.</b> A <b>{{ nomeParceiro }}</b> não poderá ser imputada por atrasos ou falta de pagamento por parte dos seus usuários perante os credores, tampouco possui legitimidade para ser cobrada de forma solidária ou subsidiária. Assim, estão de acordo tanto credores quanto devedores que são os únicos responsáveis pela relação de crédito estabelecida, figurando a <b>{{ nomeParceiro }}</b> como mera intermediária que facilita o adimplemento de obrigações não adimplidas anteriormente.<br />
				<b>8.4.</b> A <b>{{ nomeParceiro }}</b> poderá alterar os critérios de elegibilidade da plataforma <b>{{ nomeParceiro }}</b> a qualquer momento. Também, possui a faculdade de se recusar a conceder conta de acesso a qualquer pessoa ou entidade que tiver descumprido previamente os Termos de Uso ou documento legal do Portal e teve sua conta de acesso excluída por esta razão.<br />
				<b>8.5.</b> A <b>{{ nomeParceiro }}</b> não é responsável pela retirada, transporte, guarda, entrega ou depósito dos títulos de crédito e demais documentos que conferem exigibilidade ao montante devido ao credor.<br />
				<b>8.6.</b> As consultas disponíveis no Portal <b>{{ nomeParceiro }}</b> não devem ser interpretadas como mecanismo de verificação do nome do interessado em sistemas de proteção ao crédito, pois, tão somente checa se existe débito pendente com os credores filiados ao site.<br />
				<b>8.6.1.</b> Consultas e procedimentos controlados por terceiros inseridos no Portal <b>{{ nomeParceiro }}</b> poderão demandar pagamento de taxas ou tarifas. Tais encargos devem ser recolhidos pelo usuário conforme a exigência do site ou funcionalidade específica.<br />
				<b>8.6.2.</b> A <b>{{ nomeParceiro }}</b> não é responsável pelo resultado e experiência do usuário pela utilização dos serviços e produtos divulgados na plataforma <b>{{ nomeParceiro }}</b>. Recomenda-se a leitura atenta de cada contrato e Termos específicos de serviço antes da contratação.<br />
				<b>8.7.</b> A <b>{{ nomeParceiro }}</b> se reserva no direito de não publicar ou excluir conteúdos enviados pelos usuários, a qualquer tempo, sem prévio aviso e nenhum direito a indenização a quem quer que seja, que contiverem:<br />
				&bull; Ofensa à honra, imagem, reputação e dignidade de terceiros;<br />
				&bull; Pornografia, pedofilia, e outras modalidades de satisfação sexual;<br />
				&bull; Racismo ou discriminação de qualquer natureza;<br />
				&bull; Bullying, Stalking ou qualquer outra espécie de constrangimento ilegal ou assédio;<br />
				&bull; Manifesta violação a direito autoral ou direito de imagem;<br />
				&bull; Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;<br />
				&bull; Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro, homicídio, estelionato, dentre outros;<br />
				&bull; Manifesta prática de ato contrário à lei, à ordem pública ou aos bons costumes;<br />
				&bull; Uso da violência, disseminação de ódio ou qualquer outra forma de agressão ao bem estar físico de alguém;<br />
				&bull; Atividade comercial similar à {{ nomeParceiro }} ou que utilize a plataforma para angariar recursos por publicidade de terceiros ao invés de praticar a interação regular do ambiente;<br />
				&bull; Erros ou suspeita de equívocos.<br />
				<b>8.8.</b> Os visitantes poderão se valer do canal de &prime;Fale Conosco&prime; toda vez que presenciarem ou verificarem conteúdo impróprio no site.<br />
				<b>8.9.</b> Os conteúdos enviados pelos usuários e publicados no Portal {{ nomeParceiro }} não deverão ser interpretados como opinião da {{ nomeParceiro }}, tampouco declaração de concordância com o que foi publicado. Os usuários são inteiramente responsáveis pelos conteúdos que submeterem ao Portal.
			</p>
			<p>
				<b>9 &ndash; Atendimento do Portal {{ nomeParceiro }}</b><br />
				<b>9.1.</b> Caso os haja alguma dúvida sobre as condições estabelecidas neste termo ou qualquer documento do website, por favor, envie um e-mail para <a style="color: #0000ee"><u>{{ emailParceiro }}</u></a
				>. Será um prazer ajudar a esclarecer qualquer de suas dúvidas.
			</p>
			<p>
				<b>10 &ndash; Modificações destes Termos e Condições</b><br />
				<b>10.1.</b> Os presentes Termos de Uso e Condições de Navegação estão sujeitos a constante melhoria e aprimoramento. Assim, a <b>{{ nomeParceiro }}</b> se reserva o direito de modificar a qualquer momento, de forma unilateral, o presente documento e sua Política de Privacidade.<br />
				<b>10.2.</b> Ao navegar por este Portal e utilizar suas funcionalidades, você aceita guiar-se pelos Termos e Condições de Uso do Portal <b>{{ nomeParceiro }}</b>, e pela Política de Privacidade que se encontram vigentes na data e, portanto, deve verificar os mesmos cada vez que visitar este Portal.
			</p>
			<p>
				<b>11 &ndash; Disposições Gerais</b><br />
				<b>11.1.</b> A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.<br />
				<b>11.2.</b> Este Portal tem como base o horário oficial de Brasília.<br />
				<b>11.3.</b> Caso alguma disposição destes Termos de Uso seja considerada ilegítima por autoridade da localidade que o visitante ou usuário acessa o Portal, ou mantém vínculo, as demais condições permanecerão em pleno vigor e efeito até segunda ordem.
			</p>
			<p>
				<b>12 &ndash; Lei aplicável e Jurisdição</b><br />
				<b>12.1.</b> Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de Ponta Grossa, estado do Paraná para dirimir qualquer litígio, questão ou dúvida superveniente, com expressa renúncia de qualquer outro, por mais privilegiado que seja.
			</p>
		</section>
		<section v-if="option === 'politicaDePrivacidade'">
			<h3>Política de Privacidade</h3>
			<p>Como parte integrante dos Termos de Uso e Condições de Navegação no Portal da {{ nomeFantasiaParceiro }}, doravante denominada “{{ nomeParceiro }}”, este documento, denominado Política de Privacidade, tem por finalidade estabelecer as regras sobre a obtenção, uso e armazenamento dos dados e informações coletadas dos visitantes, além do registro de suas atividades.</p>
			<p>
				<b>1 &ndash; Definições:</b><br />
				<b>1.1.</b> Para fins desta Política de Privacidade, aplicam-se as seguintes definições:<br />
				&bull; <b>Cookies:</b> Arquivos enviados pelo servidor do Portal {{ nomeParceiro }} para o computador dos visitantes, com a finalidade de identificar o computador e obter dados de acesso, como páginas navegadas ou links clicados, permitindo, desta forma, personalizar a navegação dos visitantes de acordo com o seu perfil.<br />
				&bull; <b>IP:</b> Abreviatura de Internet Protocol. É um conjunto de números que identifica o computador dos visitantes na Internet.<br />
				&bull; <b>Logs:</b> Registros de atividades dos e visitantes efetuadas no Portal {{ nomeParceiro }}.<br />
				&bull; <b>Portal {{ nomeParceiro }}:</b> Designa o site de internet hospedado em {{ siteParceiro }} e seus subdomínios.<br />
				&bull; <b>Visitantes:</b> Pessoas que acessam ou interagem com as atividades oferecidas pelo Portal {{ nomeParceiro }}.<br />
				&bull; <b>Web beacons:</b> Linhas de programação em páginas HTML que tem como finalidade obter detalhes da navegação do visitante, a exemplo de quanto tempo ficou com um site aberto, qual endereço visitado em seguida, dentre outros.<br />
				&bull; <b>{{ nomeParceiro }}:</b> {{ dadosParceiro }}, proprietária e administradora do Portal {{ nomeParceiro }}.
			</p>
			<p>
				<b>2 &ndash; Obtenção dos dados e informações:</b><br />
				<b>2.1.</b> Os dados e informações serão obtidos quando os visitantes interagirem com as diversas funcionalidades existentes no Portal {{ nomeParceiro }}. É exemplo de interação quando fornecem informações voluntariamente durante o envio de formulários de contato ou realizarem cadastro, por exemplo.
			</p>
			<p>
				<b>3 &ndash; Armazenamento dos Dados e Informações:</b><br />
				<b>3.1.</b> Todos os dados e informações coletados dos visitantes serão incorporados ao banco de dados do Portal {{ nomeParceiro }}, sendo seu responsável a {{ dadosParceiro }}, proprietária e administradora do Portal {{ nomeParceiro }}.<br />
				<b>3.2.</b> Os dados e informações coletados estarão armazenados em ambiente seguro, observado o estado da técnica disponível, e somente poderão ser acessadas por pessoas qualificadas e autorizadas pela {{ nomeParceiro }}.<br />
				<b>3.3.</b> Considerando que nenhum sistema de segurança é absolutamente seguro, a {{ nomeParceiro }} se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados do Portal {{ nomeParceiro }}, salvo nos casos de dolo ou culpa da mesma.
			</p>
			<p>
				<b>4. Uso dos Dados e Informações:</b><br />
				<b>4.1.</b> Os dados e informações coletados dos visitantes poderão ser utilizados para as seguintes finalidades:<br />
				&bull; Responder a eventuais dúvidas e solicitações dos visitantes;<br />
				&bull; Cumprimento de ordem legal ou judicial;<br />
				&bull; Atendimento à requisição de Autoridades Policiais ou Ministeriais, na forma da lei;<br />
				&bull; Constituir, defender ou exercer regularmente direitos em âmbito judicial ou administrativo;<br />
				&bull; Elaborar estatísticas gerais, para identificação do perfil dos visitantes e desenvolvimento de campanhas da {{ nomeParceiro }}, mas sem realizar identificação pessoal. Terceiros poderão efetuar o registro das informações e o agrupamento dos dados;<br />
				&bull; Garantir a segurança dos visitantes;<br />
				&bull; Manter atualizados os cadastros dos visitantes para fins de contato por telefone, correio eletrônico, SMS, mala direta ou por outros meios de comunicação;<br />
				&bull; Informar a respeito de novidades, promoções e eventos da {{ nomeParceiro }};<br />
				&bull; Confirmação e atualização de cadastro dos usuários pelos parceiros do Portal quando impossibilitados de serem contatados ou quando houver dados desatualizados em seus bancos de dados.<br />
				<b>4.2.</b> A base de dados formada pela {{ nomeParceiro }} através da coleta dos dados do Portal {{ nomeParceiro }} não será compartilhada, vendida, cedida, transferida, informada ou alugada a terceiros.<br />
				<b>4.3.</b> Os dados adquiridos somente poderão ser acessados por profissionais devidamente autorizados pela {{ nomeParceiro }}, respeitando a necessidade a que serão submetidos, a relevância para os objetivos do Portal {{ nomeParceiro }} e aos interesses dos visitantes, além de preservar a privacidade destes.<br />
				<b>4.4.</b> Caso o visitante deixe de utilizar os serviços oferecidos pelo Portal {{ nomeParceiro }}, a {{ nomeParceiro }} poderá, para fins de auditoria e preservação de direitos, permanecer com o registro de dados e informações do visitante, pelo período máximo de 5 (cinco) anos, com a faculdade de excluí-los definitivamente segundo sua conveniência.<br />
				<b>4.5.</b> O visitante poderá exigir da {{ nomeParceiro }} os dados que estão registrados que lhe dizem respeito, da mesma forma que poderá solicitar a alteração ou exclusão destes. O visitante deve entrar em contato através dos meios disponibilizados pelo Portal {{ nomeParceiro }} para solicitar essas providências caso não ofereça a funcionalidade correspondente.<br />
				<b>4.6.</b> A {{ nomeParceiro }} somente divulgará as informações ou dados pessoais que estiverem sob seu poder caso exista consentimento expresso do visitante quando determinação legal ou judicial expressamente assim impuserem.
			</p>
			<p>
				<b>5. Do Registro de Atividades:</b><br />
				<b>5.1.</b> A {{ nomeParceiro }} registrará todas as atividades efetuadas pelos visitantes no Portal {{ nomeParceiro }}, por meio de logs, incluindo:<br />
				&bull; Endereço IP dos visitantes;<br />
				&bull; Ações efetuadas pelos visitantes no Portal {{ nomeParceiro }};<br />
				&bull; Páginas acessadas pelos visitantes;<br />
				&bull; Datas e horários de cada ação e de acesso a cada página do Portal {{ nomeParceiro }};<br />
				&bull; Informações sobre o dispositivo utilizado, versão de sistema operacional, navegador, dentre outros aplicativos instalados;<br />
				&bull; Session ID, quando disponível.<br />
				<b>5.2.</b> Os registros mencionados no item 5.1 poderão ser utilizados pela {{ nomeParceiro }} em casos de investigação de fraudes ou de alterações indevidas em seus sistemas e cadastros.
			</p>
			<p>
				<b>6. Cookies e outras tecnologias de obtenção de dados:</b><br />
				<b>6.1.</b> O Portal {{ nomeParceiro }} poderá fazer o uso de cookies, cabendo aos visitantes configurarem o seu navegador de Internet, caso deseje bloqueá-los. Nesta hipótese, algumas funcionalidades do Portal {{ nomeParceiro }} poderão ser limitadas.<br />
				<b>6.2.</b> Também, o Portal {{ nomeParceiro }} poderá utilizar web beacons para coletar dados de comportamento dos visitantes das páginas, onde a instalação de arquivos nos equipamentos dos visitantes não é necessária.<br />
				<b>6.3.</b> Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação pelo visitante, no entanto, respeitarão sempre aos termos desta política e as opções do visitante a respeito de sua coleta e armazenamento.<br />
				<b>6.4.</b> A {{ nomeParceiro }} não se responsabiliza pelo uso de dados que seus parceiros e anunciantes destinarem aos dados obtidos através do Portal {{ nomeParceiro }}, pois, não possui qualquer poder de supervisão dessas atividades.
			</p>
			<p>
				<b>7. Disposições Gerais:</b><br />
				<b>7.1.</b> As disposições constantes desta Política de Privacidade poderão ser atualizadas ou modificadas a qualquer momento, cabendo aos visitantes verificá-la sempre que efetuar o acesso ao Portal {{ nomeParceiro }}. Navegar ou interagir com o Portal {{ nomeParceiro }}, logo após sua atualização, ou modificação, será interpretado como uma concordância tácita às novas regras adotadas.<br />
				<b>7.2.</b> Os visitantes deverão entrar em contato em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade através da Seção de “FALE CONOSCO” do Portal ou, se preferir, poderá contar com o atendimento por meio dos seguintes canais:<br />
				&bull; E-mail: <a style="color: #0000ee"><u>{{ emailParceiro }}</u></a
				><br />
				&bull; Telefone: <b>{{ telefoneParceiro }}</b> de segunda à sexta das 08:00hs às 18:00hs<br />
			</p>
			<p>
				<b>8. Lei Aplicável e Jurisdição</b><br />
				<b>8.1.</b> A presente Política de Privacidade será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de Ponta Grossa, Estado do Paraná, para dirimir qualquer litígio, questão ou dúvida superveniente, com expressa renúncia de qualquer outro, por mais privilegiado que seja.
			</p>
		</section>
	</div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

import LandPageHeader from "./landPage/components/LandPageHeader";

export default {
	name: "TermsLGPD",

	components: { LandPageHeader },

	mixins: [mixins],

	data() {
		return {
			option: "termoDeUso"
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria
		}),

		emailParceiro() {
			return this.parceiroConfig.identificador === "aqui" ? "contato@negociaronline.com.br" : "contato@aquicob.com.br";
		},

		dadosParceiro() {
			return this.parceiroConfig.identificador === "aqui" ?
			"NEGOCIAR ON-LINE ASSESSORIA LTDA, CNPJ 37.918.628/0001-80, situada à Rua Rafael Dias Pacheco, 155 - Recanto - Cachoeiro de Itapemirim - ES - VOUCEP 29303-120" :
			"AQUICOB SOLUCOES TECNOLOGICAS, CNPJ 25.258.843/0001-13, situada à Rua Professor Kamal Tebcherani, 444 Sala 03 - Oficinas - Ponta Grossa - PR - CEP 84036-150";
		},

		siteParceiro() {
			return this.parceiroConfig.identificador === "aqui" ? "www.aqui.negociaronline.com.br" : "www.aquicob.com.br";
		},

		telefoneParceiro() {
			return this.parceiroConfig.identificador === "aqui" ? "(28) 99905-1747" : "(42) 99114-1884";
		},

		nomeFantasiaParceiro() {
			return this.parceiroConfig.identificador === "aqui" ? "NEGOCIAR ON-LINE ASSESSORIA LTDA" : "AQUICOB SOLUCOES TECNOLOGICAS";
		}
	},

	created() {
		if (this.parceiroConfig.identificador === "aqui") {
			document.title = "Negociar on-line";

			if (document.domain === "localhost") {
				document.querySelector("link[rel='icon']").href = "http://localhost:8080/logo-negociar.png";

			} else {
				document.querySelector("link[rel='icon']").href = process.env.VUE_APP_API_URL + "/logo-negociar.png";
			}

		}
	}
};
</script>


<style scoped lang="scss">
.page-terms {
	section {
		padding: 20px 25%;
		h3 {
			color: var(--hoverColor);
			font-size: 32px;
			font-family: Montserrat-Bold;
			margin-bottom: 30px;
		}
		p {
			font-size: 14px;
			line-height: 24px;
			font-family: Roboto;
			text-align: justify;
			font-weight: normal !important;
			margin-bottom: 30px;
			b {
				font-family: "Roboto Bold";
			}
		}
		@media only screen and (max-width: 768px) {
			padding: 30px 10%;
		}
	}
}
.box-term-options {
	display: flex;
	align-items: center;
	padding: 40px 25% 20px 25%;
	@media only screen and (max-width: 768px) {
		padding: 30px;
	}
}

.term-option {
	margin-right: 20px;
	width: 200px;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: var(--whiteColor);
	color: var(--color);
	transition: 0.3s;
	box-shadow: 3px 3px 5px rgba(3, 27, 78, 0.16);
	border-radius: 15px;
	cursor: pointer;
	h6 {
		font-size: 18px;
		text-align: center;
		font-family: Montserrat-Bold;
	}
	i {
		font-size: 42px;
		margin-bottom: 10px;
	}
	&:hover,
	&:active {
		color: var(--whiteColor);
		background-color: var(--color);
		transition: 0.3s;
		h6,
		i {
			color: var(--whiteColor) !important;
		}
	}
}
</style>
