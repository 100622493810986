<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="container-modal">
                <img
                    v-if="loja.loj_caminho_logo && !imgErro"
                    key="portal-parceiro-logo"
                    :src="imagemLogoLoja"
                    alt="logo_loja"
                    @error="imgErro = true">

                <img
                    v-else-if="parceiro && parceiroConfig.sessoes.logoForBackgroundWhite && parceiroConfig.sessoes.logoForBackgroundWhite.campos"
                    key="portal-parceiro-logo-para-fundo-branco"
                    :src="parceiroConfig.sessoes.logoForBackgroundWhite.campos"
                    alt="logo_fundo_branco"
                    style="width: 200px"/>

                <img
                    v-else-if="parceiro && parceiroConfig.logo && parceiroConfig.logo.campos"
                    key="portal-parceiro-parceiro-config"
                    :src="parceiroConfig.logo"
                    alt="logo_normal"
                    style="width: 200px"/>

                <img
                    v-else
                    key="portal-parceiro-sem-logo"
                    src="@/assets/images/teae.png"
                    alt="sem_logo"
                    style="width: 200px" />

                <h1>Comprovante</h1>
                <p>Código do acordo: {{ acoCodigo }}</p>
                <p>Empresa Credora: {{ loja.loj_razao }}</p>
                <p>Empresa Negociadora: {{ comprovanteCartao.empresa }}</p>
                <p>Cliente: {{ nome }}</p>
                <p>CPF: {{ cpf | formatarCpf }}</p>
                <template v-if="formaDePagamento == 'cartao'">
                    <p>Cartão: {{ comprovanteCartao.numero_cartao }}</p>
                    <p>Validade: {{ comprovanteCartao.vencimento_cartao }}</p>
                </template>
                <p>Parcela: {{ `${parcela}/${quantidadeDeParcelas}` }}</p>
                <hr style="width: 70%;"/>
                <p>Forma de pagamento: {{ formaDePagamentoFormatada }}</p>
                <p>Data pagamento: {{ comprovanteCartao.data_de_pagamento }}</p>
                <p>Hora pagamento: {{ comprovanteCartao.horario_de_pagamento }}</p>
                <p v-if="formaDePagamento == 'cartao'">Código autorização: {{ comprovanteCartao.codigo_de_autorizacao }}</p>
                <p>Status: {{ comprovanteCartao.estado_da_transacao }}</p>

                <button-default
                    style="margin-top: 20px;"
                    @click.native="$emit('fechar')"
                    text="FECHAR"></button-default>

                <button-default
                    style="margin-top: 20px;"
                    @click.native="gerarPdf"
                    text="GERAR PDF"></button-default>
            </div>
        </div>
    </div>
</template>

<script>
import jsPDF from "jspdf";
import ButtonDefault from '@/components/buttons/ButtonDefault.vue';

export default {
    props: {
        cpf: {
            type: String,
            require: true
        },

        nome: {
            type: String,
            require: true
        },

        comprovanteCartao: {
            type: Object,
            require: true
        },

        aciCodigo: {
            type: String,
            require: true
        },

        loja: {
            type: Object,
            require: true
        },

        formaDePagamento: {
            type: String,
            require: true
        },

        parcela: {
            type: Number,
            require: true
        },

        quantidadeDeParcelas: {
            type: Number,
            require: true
        },

        acoCodigo: {
            type: String,
            require: true
        }
    },

    components: {ButtonDefault},

    data() {
        return {
            imgErro: false
        }
    },

    computed: {
        imagemLogoLoja() {
			return `${process.env.VUE_APP_API_URL}/${this.loja.loj_caminho_logo}`;
		},

        formaDePagamentoFormatada() {
            if (this.formaDePagamento != 'cartao') {
                return this.formaDePagamento.toUpper();
            } else {
                return 'CARTÃO DE CRÉDITO';
            }
        },

        formatarCpf() {
            return `${this.cpf.substring(0, 3)}.XXX.XXX-${this.cpf.substring(9)}`;
        }
    },

    methods: {
        gerarPdf() {
            const pdf = new jsPDF();

            if (this.loja.loj_caminho_logo) {
                pdf.addImage(this.imagemLogoLoja, 'JPEG', 10, 0, 50, 50);
            } else {
                pdf.addImage(this.parceiroConfig.sessoes.logoForBackgroundWhite.campos, 'JPEG', 10, 0, 50, 50);
            }

            let posY = 60;

            pdf.text('Comprovante', 10, posY);
            posY += 10;
            pdf.text(`Código acordo: ${this.acoCodigo}`, 10, posY);
            posY += 10;
            pdf.text(`Empresa Credora: ${this.loja.loj_razao}`, 10, posY);
            posY += 10;
            pdf.text(`Empresa Negociadora: ${this.comprovanteCartao.empresa}`, 10, posY);
            posY += 10;
            pdf.text(`Cliente: ${this.nome}`, 10, posY);
            posY += 10;
            pdf.text(`CPF: ${this.formatarCpf}`, 10, posY);

            if (this.formaDePagamento == 'cartao') {
                posY += 10;
                pdf.text(`Cartão: ${this.comprovanteCartao.numero_cartao}`, 10, posY);
                posY += 10;
                pdf.text(`Validade: ${this.comprovanteCartao.vencimento_cartao}`, 10, posY);
            } 

            posY += 7;
            pdf.line(10, posY, 200, posY);
            posY += 13;
            pdf.text(`Parcela: ${this.parcela}/${this.quantidadeDeParcelas}`, 10, posY);
            posY += 10;
            pdf.text(`Forma de pagamento: ${this.formaDePagamentoFormatada}`, 10, posY);
            posY += 10;
            pdf.text(`Data pagamento: ${this.comprovanteCartao.data_de_pagamento}`, 10, posY);
            posY += 10;
            pdf.text(`Hora pagamento: ${this.comprovanteCartao.horario_de_pagamento}`, 10, posY);
            
            if (this.formaDePagamento == 'cartao') {
                posY += 10;
                pdf.text(`Código autorização: ${this.comprovanteCartao.codigo_de_autorizacao}`, 10, posY);
            }

            posY += 10;
            pdf.text(`Status: ${this.comprovanteCartao.estado_da_transacao}`, 10, posY);

            pdf.save(`comp_cartao_${this.aciCodigo}.pdf`);
        }
    }
}
</script>
