<template>
	<div class="containerDebitDetail">

		<h3>{{ tituloPagina }}</h3>

		<p v-show="!parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreementsPayment">Método de pagamento:
			Boleto</p>

		<div v-if="parceiroConfig.sessoes.customization.campos.myDebits.textChavePix"
			style="display: flex; flex-direction: column; align-items: center">
			<img src="@/assets/images/pix-106.svg" style="max-width: 13em" />
			<p style="margin-bottom: 0px">Aceitamos pagamentos por PIX.</p>
			<p>Nossa chave é PIX: {{ parceiroConfig.sessoes.customization.campos.myDebits.textChavePix }}</p>
		</div>

		<div class="container-table">
			<table class="table-payments">

				<caption v-show="!parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreementsPayment">
					Informações das parcelas do seu acordo
				</caption>

				<tr>
					<th v-if="!portalCamec" id="Selecionar">Selecionar</th>
					<th id="Visualizar">Visualizar</th>
					<th id="Vencimento">Vencimento</th>
					<th id="Valor">Valor</th>
					<th id="Parcela">Parcela</th>
					<th id="Status">Status</th>
				</tr>

				<tr v-for="(parcela, indice) in acordo.dadosAcordo.parcelas" :key="indice">

					<template v-if="!portalCamec">
						<td v-if="parcela.status.toLowerCase() != 'a'" key="portal-parcela-acordo-paga">PAGO</td>
	
						<td v-else key="portal-parcela-em-aberto">
							<input type="checkbox" :value="parcela" v-model="selected" />
						</td>
					</template>

					<td>
						<i v-if="acordo.dadosAcordo.forma_pagamento == 'boleto' && parcela.status.toLowerCase() == 'a'"
							key="icone-mostrar-boleto" class="fas fa-file-pdf"
							:style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
							@click="abrirParcela(parcela)"></i>
							
						<i v-else-if="acordo.dadosAcordo.forma_pagamento == 'pix'" key="icone-mostrar-pix"
							class="fas fa-qrcode"
							:style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
							@click="parcelaSelecionada = indice + 1"></i>
							
							<i v-else-if="acordo.dadosAcordo.forma_pagamento == 'cartao' && parcela.status.toLowerCase() != 'a'"
							key="icone-mostrar-cartao" class="fas fa-credit-card"
							:style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
							@click="parcelaSelecionada = indice + 1"></i>
							
							<i v-else-if="acordo.dadosAcordo.forma_pagamento == 'link'" key="icone-mostrar-link"
							class="fas fa-external-link-alt"
							:style="{ color: parceiro ? parceiroConfig.estilo.corPrimaria : '#003e6c' }"
							@click="abrirParcela(parcela)"></i>
						</td>

						<td>{{ parcela.vencimento }}</td>
						<td>R$ {{ parcela.valor | formatarDinheiro }}</td>
						<td>{{ `${indice + 1}/${acordo.dadosAcordo.parcelas.length}` }}</td>
						<td>{{ retornarPalavraStatus(parcela) }}</td>

					<tela-qr-code-pix v-if="parcelaSelecionada == indice + 1 && acordo.dadosAcordo.forma_pagamento == 'pix'"
						:pixCopiaECola="parcela.pdf" @fechar="parcelaSelecionada = 0"></tela-qr-code-pix>

					<comprovante-pagamento-cartao v-if="parcelaSelecionada == indice + 1 && parcela.status == 'B'"
						:nome="pessoa.pesNome" :cpf="pessoa.pesCpfCnpj" :comprovanteCartao="parcela.comprovante_cartao"
						:aciCodigo="parcela.aci_codigo" :formaDePagamento="acordo.dadosAcordo.forma_pagamento"
						:loja="acordo.loja" :parcela="indice + 1" :quantidadeDeParcelas="acordo.dadosAcordo.parcelas.length"
						:acoCodigo="acordo.dadosAcordo.aco_codigo"
						@fechar="parcelaSelecionada = 0"></comprovante-pagamento-cartao>
				</tr>

				<tr v-if="!portalCamec">
					<td><input type="checkbox" id="todos" v-model="selectAll" /></td>
					<td>Todos</td>
				</tr>

			</table>
		</div>

		<div v-if="acordo.dadosAcordo.forma_pagamento == 'boleto' && !portalCamec" class="box-buttons">

			<div style="width: 35%; padding-right: 10px">

				<button-default :disable="doesNotHaveBoleto" :color="corPrimaria" @click.native="openSelected"
					text="Ver os selecionados"></button-default>
			</div>

			<div style="width: 35%; padding-right: 10px">

				<button-default :color="corPrimaria" :disable="doesNotHaveBoleto" @click.native="downloadSelected"
					text="Baixar os selecionados"></button-default>
			</div>

			<div v-if="!parceiroConfig.sessoes.customization.campos.myAgreements.flgPagueMe && parceiroConfig.identificador === 'Senff'"
				style="width: 30%; padding-left: 10px">

				<button-default
					@click.native="abrirModalEnvioBoletos()"
					:color="corPrimaria"
					text="Enviar boletos"></button-default>
			</div>
		</div>

		<receive-call
			v-if="abrirModalEnvioDeBoletos"
			:enviarBoletos="true"
			:acordo="acordo"
			:parcelasSelecionadas="selected"
			:urlParcelasSelecionadas="selectedURL"
			@close="abrirModalEnvioDeBoletos = false" ></receive-call>

	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixins from "@/mixins/mixins";


import ButtonDefault from "@/components/buttons/ButtonDefault";
import TelaQrCodePix from "./TelaQrCodePix.vue";
import ComprovantePagamentoCartao from "./ComprovantePagamentoCartao.vue";
import ReceiveCall from "@/views/documentDebits/MyDebits/components/ReceiveCall.vue";

export default {
	name: "PaymentOptionsAgreement",

	components: { ButtonDefault, TelaQrCodePix, ComprovantePagamentoCartao, ComprovantePagamentoCartao, ReceiveCall },

	mixins: [mixins],

	props: {
		acordo: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			selected: [],
			selectedURL: [],
			numeroSelecionados: 0,
			mostrarCodigoQR: false,
			parcelaSelecionada: 0,
			abrirModalEnvioDeBoletos: false
		};
	},

	computed: {
		...mapState({
			pessoa: (estado) => estado.passosRetornarDividas.pessoa,
			motivoReceberLigacao: (estado) => estado.motivoReceberLigacao
		}),
		selectAll: {
			get() {
				return this.acordo.dadosAcordo.parcelas ? this.selectedURL.length == this.acordo.dadosAcordo.parcelas.length : false;
			},

			set(value) {
				this.selectedURL = [];
				this.selected = [];

				if (value) {
					this.acordo.dadosAcordo.parcelas.forEach(parcela => {
						this.selectedURL.push(parcela.pdf);
						this.selected.push(parcela);
					});
				}
			}
		},

		doesNotHaveBoleto: function () {
			let naoTemBoleto = true;
			this.acordo.dadosAcordo.parcelas.forEach((parcela) => {
				if (parcela.status && parcela.status.toLowerCase() === "a") naoTemBoleto = false;
			});
			return naoTemBoleto;
		},

		tituloPagina() {
			return this.parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreementsPayment ?
				this.parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreementsPayment : "Pagamentos";
		}
	},

	watch: {
		selected() {
			this.selectedURL = [];
			this.selected.forEach((parcela) => {
				this.selectedURL.push(parcela.pdf);
			});
		}
	},

	mounted() {
		console.log(this.pessoa);
		this.acaoSalvarEtapa({
			cpfCnpj: this.pessoa.pesCpfCnpj,
			pes_codigo: this.pessoa.pes_codigo,
			esn_fk_ets_codigo: 35,
			loj_codigo: this.acordo.loja.loj_codigo,
			emp_codigo: this.parceiroConfig.emp_codigo,
			hora_agendamento: this.horaAtual,
			data_agendamento: this.dataAtual,
			motivo_agendamento: 'Vizualizou os detalhes do acordo'
		});
	},

	methods: {
		...mapMutations(["SET_MOTIVO_RECEBER_LIGACAO"]),

		abrirParcela(parcela) {
			if (parcela.pdf == 'boleto_vai_ser_gerado' && this.parceiroConfig.identificador == 'mw') {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Informação",
					message: `O boleto será gerado na data de vencimento: ${parcela.vencimento}`,
					styleMessage: "info"
				});
			} else if (this.portalCamec && (parcela.pdf == 'boleto_vai_ser_gerado' || parcela.pdf == undefined)) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Informação",
					message: 'O boleto será gerado após o pagamento da parcela anterior.',
					styleMessage: "info"
				});
			} else {
				window.open(parcela.pdf, "_blank");
			}
		},

		openSelected() {
			if (this.selected.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Erro",
					message: "Selecione pelo menos uma parcela!",
					styleMessage: "danger"
				});

			} else {
				this.acaoSalvarEtapa({
					cpfCnpj: this.pessoa.pesCpfCnpj,
					pes_codigo: this.pessoa.pes_codigo,
					esn_fk_ets_codigo: 4,
					emp_codigo: this.parceiroConfig.emp_codigo,
					motivo_agendamento: "Visualizou o boleto",
					data_agendamento: this.dataAtual,
					hora_agendamento: this.horaAtual
				});

				this.selected.forEach((parcela) => {
					this.abrirParcela(parcela);
				});
			}
		},

		downloadSelected() {
			if (this.selected.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Erro",
					message: "Selecione pelo menos uma parcela!",
					styleMessage: "danger"
				});

			} else {
				this.acaoSalvarEtapa({
					cpfCnpj: this.pessoa.pesCpfCnpj,
					pes_codigo: this.pessoa.pes_codigo,
					esn_fk_ets_codigo: 4,
					emp_codigo: this.parceiroConfig.emp_codigo,
					motivo_agendamento: "Baixou o(s) boleto(s)",
					data_agendamento: this.dataAtual,
					hora_agendamento: this.horaAtual
				});

				this.selectedURL.forEach((value, key) => {
					if (value.includes('https')) {
						let ptrn = RegExp(/[\w]+[.]pdf/);
						let filename = ptrn.exec(value) != null ? ptrn.exec(value) : "Boleto" + key + ".pdf";
						fetch(value).then((t) => {
							return t.blob().then((b) => {
								var a = document.createElement("a");
								a.href = URL.createObjectURL(b);
								a.setAttribute("download", filename);
								a.click();
							}).catch(_ => {
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Erro",
									message: "Não foi possível baixar os boletos selecionados!",
									styleMessage: "danger"
								});
							});
						}).catch(_ => {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Erro",
								message: "Não foi possível baixar os boletos selecionados!",
								styleMessage: "danger"
							});
						});
					}
				});
			}
		},

		retornarPalavraStatus(parcela) {
			return parcela.status === "A" ? "Aberto" : "Pago";
		},

		abrirModalEnvioBoletos() {
			this.SET_MOTIVO_RECEBER_LIGACAO('');
			this.abrirModalEnvioDeBoletos = true;
		}
	}
};
</script>

<style scoped lang="scss">
.containerDebitDetail {
	width: 1024px;
	box-sizing: border-box;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-bottom: 40px;

	@media only screen and (max-width: 1470px) {
		width: 81%;
	}
}

.header-debits {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	cursor: pointer;
}

h3 {
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
}

p {
	text-align: center;
	margin-bottom: 30px;
	margin-top: 3px;
	color: #5b6987;
	font-family: "Roboto";
	font-size: 12px;
}

.header-debits i {
	font-size: 48px;
	color: #031b4e;
}

.payment {
	width: 100%;
	height: 45px;
	background: #eee;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.table-payments {
	text-align: center;

	caption {
		@media only screen and (max-width: 760px) {
			text-align: left;
		}
	}
}

.container-table {
	margin-top: 20px;

	@media only screen and (max-width: 1470px) {
		overflow-x: scroll;
	}
}

table {
	border-collapse: collapse;
	width: 100%;
}

th {
	border: 1px solid #f2f2f2;
	padding: 8px;
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 16px;
	text-align: center;
	background: #f8f8f8;
}

td {
	border: 1px solid #f8f8f8;
	padding: 8px;
	height: 45px;
	text-align: center;
	color: #5b6987;
	font-family: "Roboto";
	font-size: 12px;
	border-collapse: collapse;
}

table td i:hover {
	cursor: pointer;
}

.box-buttons {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

	@media only screen and (max-width: 470px) {
		flex-direction: column;
	}

	div {
		@media only screen and (max-width: 470px) {
			width: 100% !important;
			margin: 5px 0;
			padding: 0 !important;
		}
	}
}
</style>
