<template>
	<div class="containerEmailSender">
		<div class="content-page">
			<h3>Informe o e-mail pelo qual você deseja receber os seus boletos:</h3>

			<text-field
				:valueField="email"
				:value.sync="email"
				:variableName="'email'"
				:type="'email'"
				:disabled="false" ></text-field>

			<button-default
				:color="corPrimaria"
				@click.native="send"
				text="Enviar boleto por e-mail" ></button-default>
		</div>
	</div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";
import mixins from "@/mixins/mixins";

import TextField from "@/components/inputs/TextField";
import ButtonDefault from "@/components/buttons/ButtonDefault";
import globalMethods from "@/mixins/globalMethods";

export default {
	name: "EmailSender",

	components: { TextField, ButtonDefault, ValidationObserver },

	mixins: [globalMethods, mixins],

	props: {
		acordo: {
			type: Object,
			required: true
		},

		paymentsSelected: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			email: ""
		};
	},

	computed: {
		...mapState({
			pessoa: (estado) => estado.passosRetornarDividas.pessoa
		}),

		tratamentoEmail() {
			let fullName = this.pessoa.pesNome.split(" ");
			return this.parceiroConfig.sessoes.customization.campos.myAgreements.textEmailBoleto.replace(
				"[primeironome]",
				this.capitalizeFirstLetter(fullName[0])
			);
		}
	},

	methods: {
		...mapActions(["action_enviar_email"]),

		send() {
			if (
				!this.email ||
				!this.email.includes("@") ||
				!this.email.includes(".") ||
				this.email.charAt(this.email.length - 1) == "."
			) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um e-mail válido`,
					styleMessage: "danger"
				});
				return -1;
			}

			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Enviando por e-mail");

			this.acaoSalvarEtapa({
				cpfCnpj: this.pessoa.pesCpfCnpj,
				esn_fk_ets_codigo: 24,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: "Enviou o(s) boleto(s) por e-mail",
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual
			});

			let aciCodigoParcelas = [];
			this.paymentsSelected.forEach(parcela => {
				aciCodigoParcelas.push(parcela.aci_codigo);
			});
			aciCodigoParcelas = aciCodigoParcelas.join(',');

			this.action_enviar_email({
				emp_codigo: this.parceiroConfig.emp_codigo,
				cpfCnpj: this.pessoa.pesCpfCnpj,
				pem_email: this.email,
				loj_codigo: this.acordo.loja.loj_codigo,
				aci_codigo: aciCodigoParcelas,
				email_assunto: 'Envio do(s) seu(s) boleto(s) do seu acordo.',
				email_texto: this.tratamentoEmail

			}).then((response) => {
				this.$emit('close');
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
				if (response.data.retorno) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: response.data.mensagem ?? `E-mail enviado com sucesso. Caso não encontre na sua caixa de entrada, verifique senão está na sua pasta de spam.`,
						styleMessage: "success"
					});
					return -1;

				} else {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: response.data.mensagem ?? `Erro ao enviar o e-mail`,
						styleMessage: "danger"
					});
					return -1;
				}
			}).catch(_ => {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Erro ao enviar o e-mail`,
					styleMessage: "danger"
				});
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.iconBoxBack {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: "Roboto Black";
	color: var(--primaryColor);
	text-transform: uppercase;
	cursor: pointer;
}
.iconBoxBack i {
	margin-right: 10px;
	font-size: 22px;
}

h3 {
	margin-top: 20px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	background: #f5f6fa;
	padding: 10px 0;
}

.content-page h3 {
	margin-bottom: 20px;
}
.proposal_text {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
}
.containerEmailSender {
	width: 100%;
	max-width: 1024px;
	box-sizing: border-box;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-bottom: 40px;
}
</style>
