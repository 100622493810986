var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"fields"},_vm._l((_vm.fields),function(field,index){return _c(field.fieldType,{directives:[{name:"show",rawName:"v-show",value:(!field.hideInput),expression:"!field.hideInput"}],key:field.fieldType + index,tag:"component",attrs:{"required":field.required,"variableName":field.variableName,"maskMoney":field.maskMoney,"title":field.title,"maskFormat":field.maskFormat,"disabled":field.disabled,"valueField":field.valueField,"keyValue":field.keyValue,"keyName":field.keyName,"options":field.options,"items":field.items,"rules":field.rules,"label":field.label,"placeholder":field.placeholder,"future":field.future,"plusEighteen":field.plusEighteen,"cols":_vm.isMobile ? '12' : field.cols,"type":field.type},on:{"onEnter":function($event){{
					_vm.inputsValues[field.variableName] = $event,
					_vm.$emit('update:values', _vm.inputsValues),
					_vm.$emit('save')
				}},"update:value":function($event){{
					_vm.inputsValues[field.variableName] = $event,
					_vm.$emit('update:values', _vm.inputsValues)
				}}}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }