<template>
	<div
		class="box-register"
		:style="corPrimaria">

		<div style="display: flex; flex-direction: column; margin: 0px 30px">
			<h4 class="box-register-title" style="text-transform: uppercase">Trabalhe conosco</h4>
			<h3 style="text-transform: none">
				Tem interesse em saber mais e gostaria de compartilhar conhecimentos e práticas relacionadas ao mercado de tecnologia na área de cobrança?
				<p>Acredita que consiga oferecer a possibilidade de alcançar grandes resultados na área por conta de suas habilidades e experiências?</p>
				<p>Nós adoraríamos ter a chance de conversar mais sobre tudo isso com você, por favor deixe os seus dados para contato, área de interesse de atuação,
					 uma breve mensagem e se quiser o seu currículo (PDF) para nós analisarmos e entrar em contato com você.</p>
			</h3>
		</div>

		<form-fields
			:fields="fields"
			@update:values="inputsValues = $event" ></form-fields>

		<div id="userAgreement">
			<input
				type="checkbox"
				v-model="agreement" />

			<p>Concordo em enviar meu CV para concorrer a vaga acima descrita bem como estou ciente de que meus dados serão utilizados exclusivamente para a fase de seleção, sendo descartado ao fim do processo. *</p>
		</div>

		<div class="box-button-register">
			<p style="margin: 10px 0px">(*) Campos obrigatórios.</p>
			<button-default
				text="Enviar"
				:full="true"
				:disable="!agreement"
				@click.native="enviarProposta" ></button-default>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import mixins from "@/mixins/mixins";

import FormFields from "@/components/FormFields";
import ButtonDefault from "@/components/buttons/ButtonDefault";

export default {
	name: "WorkWithUs",

	components: { FormFields, ButtonDefault },

	mixins: [mixins],

	data() {
		return {
			title: "Trabalhe conosco",
			inputsValues: {},
			fields: [],
			agreement: false
		};
	},

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria
		}),

		textoEmail() {
			return `Vaga: ${this.inputsValues.tipoDeAtuacao.toUpperCase()}<br>
					Área de atuação: ${this.inputsValues.interesse.toUpperCase()}<br>
					Nome: ${this.inputsValues.nome}<br>
					Telefone: ${this.inputsValues.telefone}<br>
					E-mail: ${this.inputsValues.email}<br>
					Mensagem: ${this.inputsValues.mensagem}<br>`;
		}
	},

	mounted() {

		this.SET_LOADING_MESSAGE("");
		this.SET_LOADING(false);
		this.fields = [
			{
				variableName: "nome",
				fieldType: "TextField",
				label: "Nome Completo: *",
				type: "text",
				required: true,
				cols: "8",
				valueField: this.inputsValues.nome
			},
			{
				variableName: "telefone",
				fieldType: "TextFieldMask",
				label: "Telefone: *",
				maskFormat: "(##) #####-####",
				type: "text",
				required: true,
				cols: "4",
				valueField: this.inputsValues.telefone
			},
			{
				variableName: "email",
				fieldType: "TextField",
				label: "E-mail: *",
				type: "email",
				required: true,
				cols: "4",
				valueField: this.inputsValues.email
			},
			{
				variableName: "tipoDeAtuacao",
				fieldType: "SelectField",
				label: "Selecione o tipo de vaga: *",
				options: [
					{ label: "Selecione...", value: false },
					{ label: "Estagiário", value: "estagiário" },
					{ label: "Trainee", value: "trainee" },
					{ label: "Júnior", value: "júnior" },
					{ label: "Pleno", value: "pleno" },
					{ label: "Sênior", value: "sênior" }
				],
				keyName: "label",
				keyValue: "value",
				type: "text",
				required: true,
				cols: "4",
				valueField: this.inputsValues.tipoDeAtuacao
			},
			{
				variableName: "interesse",
				fieldType: "SelectField",
				label: "Areá de interesse: *",
				options: [
					{ label: "Selecione...", value: false },
					{ label: "Analista de negócio", value: "analista de negócio" },
					{ label: "Analista de sistemas", value: "analista de sistemas" },
					{ label: "Desenvolvedor Back-end", value: "desenvolvedor back-end" },
					{ label: "Desenvolvedor Front-end", value: "desenvolvedor front-end" },
					{ label: "Desenvolvedor Fullstack", value: "desenvolvedor fullstack" },
					{ label: "Marketing", value: "marketing" },
					{ label: "Recursos Humanos", value: "recursos humanos" },
					{ label: "Suporte", value: "suporte" },
					{ label: "Teste", value: "teste" },
					{ label: "Vendas", value: "vendas" },
					{ label: "Outros", value: "outros" }
				],
				keyName: "label",
				keyValue: "value",
				type: "text",
				required: true,
				cols: "4",
				valueField: this.inputsValues.interesse
			},
			{
				variableName: "mensagem",
				fieldType: "TextAreaField",
				label: "Mensagem: ",
				type: "text",
				cols: "12",
				valueField: this.inputsValues.mensagem
			},
			{
				variableName: "curriculo",
				fieldType: "FileField",
				label: "Cúrriculo (PDF): ",
				type: "text",
				cols: "12",
				valueField: this.inputsValues.curriculo
			}
		];
	},

	methods: {
		...mapActions(["action_enviar_email"]),

		enviarProposta() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Enviando...");

			if (this.inputsValues.nome && this.inputsValues.telefone && this.inputsValues.email && this.inputsValues.tipoDeAtuacao && this.inputsValues.interesse) {
				this.action_enviar_email({
					emp_codigo: "262",
					pes_codigo: "138",
					loj_codigo: "2",
					pem_email: "contato@aquicob.com.br",
					assunto: "Envio de proposta de trabalho na área de " + this.inputsValues.interesse,
					texto_email: this.textoEmail,
					termo: this.inputsValues.curriculo != "",
					anexo: this.inputsValues.curriculo == "" ? "" : this.inputsValues.curriculo.base64.replace("data:application/pdf;base64,", ""),
					nome_anexo: this.inputsValues.curriculo == "" ? "" : "curriculo.pdf"
				}).then((resposta) => {

						this.SET_LOADING_MESSAGE("");
						this.SET_LOADING(false);

						if (resposta?.data?.mensagem == "E-mail enviado com sucesso.") {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `Sua proposta foi enviada com sucesso. Entraremos em contato em breve, para falar com você sobre uma oportunidade de ingressar na nossa equipe.`,
								styleMessage: "success"
							});
							return -1;

						} else {
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `Erro ao enviar a sua proposta. Tente novamente mais tarde.`,
								styleMessage: "danger"
							});
						}
					}).catch((_) => {

						this.SET_LOADING_MESSAGE("");
						this.SET_LOADING(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Atenção",
							message: `Erro ao enviar a sua proposta. Tente novamente mais tarde.`,
							styleMessage: "danger"
						});
						return -1;
					});

			} else {

				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Preencha todos os campos obrigatórios.`,
					styleMessage: "danger"
				});
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
			}
		}
	}
};
</script>

<style  lang="scss">
.box-register {
	transition: all 0.5s ease-in-out;
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register h2 {
	transition: all 0.5s ease-in-out;
	font-family: Montserrat-Bold;
	color: var(--color);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3,
.box-register p {
	transition: all 0.5s ease-in-out;
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 16px;
	}
}

.box-register p {
	margin: 0px;
}

.box-register-title {
	transition: all 0.5s ease-in-out;
	font-family: Montserrat-Bold;
	color: var(--hoverColor);
	font-size: 25px;
	text-align: center;
}

#userAgreement {
	display: flex;
	align-items: center;
	margin: 10px 0;
	p {
		text-align: justify;
	}
	input {
		background-color: #f9f9f9;
		border: 6px solid #4d90fe;
		border-radius: 40px;
		border-bottom-color: transparent;
		border-left-color: transparent;
		height: 40px;
		left: -4px;
		outline: 0;
		top: -4px;
		width: 40px;
		box-sizing: border-box;
		animation: spinner-spin linear 2.5s infinite;
		animation-play-state: paused;
		transition-duration: 1s;
		margin-right: 6px;
	}
}
</style>