<template>
	<ValidationProvider :class="`col-${cols}`" class="col" tag="div" :name="label" :rules="rules" v-slot="{ errors }">
		<label :class="{ 'solid-color': solidColor, 'label-default': !errors[0], 'label-red': errors[0] }">
			{{ label }}
		</label>
		<input
			:class="{
				'solid-color': solidColor,
				'border-default': !errors[0],
				'border-red': errors[0],
				'has-value': hasValue
			}"
			:type="type"
			ref="inputMask"
			:id="variableName"
			v-mask="maskFormat"
			autocomplete="new-password"
			:required="required"
			:disabled="disabled"
			:rules="rules ? rules : []"
			:placeholder="placeholder"
			class="text-field"
			@input="$emit('update:value', text)"
			@keyup.enter="$emit('onEnter', text)"
			v-model="text" />
		<span class="text-error" v-if="errors[0]">
			{{ errors[0] }}
		</span>
	</ValidationProvider>
</template>

<script>
import { mask } from "vue-the-mask";
import { ValidationProvider } from "vee-validate";
export default {
	name: "TextFieldMask",
	components: {
		ValidationProvider
	},
	props: [
		"label",
		"placeholder",
		"type",
		"cols",
		"prependInnerIcon",
		"appendIcon",
		"rules",
		"clearValue",
		"valueField",
		"required",
		"disabled",
		"withButton",
		"buttonColor",
		"borderSuccess",
		"borderDanger",
		"maskMoney",
		"maskFormat",
		"solidColor",
		"variableName"
	],
	data() {
		return {
			showInput: false,
			showAppend: false,
			text: ""
		};
	},
	directives: { mask },
	watch: {
		clearValue() {
			this.text = "";
		},
		valueField() {
			this.text = this.valueField;
			this.$emit("update:value", this.text);
		}
	},
	mounted() {
		if (this.valueField) {
			this.text = this.valueField;
			this.$emit("update:value", this.text);
		}
		this.showInput = true;
	},
	computed: {
		hasValue() {
			return !!this.text;
		}
	}
};
</script>

<style scoped>
label {
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}

label.label-default {
	color: #1d1d1b;
}

label.label-red {
	color: var(--dangerColor);
}

label.solid-color {
	color: var(--whiteColor);
}

input {
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #878785 !important;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
}

input::placeholder {
	color: #bbbbbb;
}

input.solid-color {
	background: var(--whiteColor);
}

input.border-default,
input.has-value {
	border: 0;
}

input.border-red {
	border: thin solid var(--dangerColor);
}

span.text-error {
	color: var(--dangerColor);
	font-size: 12px;
	font-family: "Roboto";
}
</style>
