import configAxios from 'axios';
import store from '../store';

const apiInstance = configAxios.create({
    headers: {
        "Content-type": "application/x-www-form-urlencoded"
    }
});

apiInstance.interceptors.request.use(requisicao => {
    const data = requisicao.data ?? {};
    const params = requisicao.params ?? {};

    if (requisicao.url.includes('stage')) {
        requisicao.baseURL = '';
        requisicao.headers = {
            "Content-type": "application/json"
        };
    } else {
        requisicao.baseURL = process.env.VUE_APP_API_URL;
    }

    if (!params.b && !requisicao.url.includes('stage')) {
        data.token = process.env.VUE_APP_GLOBAL_TOKEN;
    }

    requisicao.data = data;

    return requisicao;
});

apiInstance.interceptors.response.use(async resposta => {
    if (resposta.data.retorno === false) {
        await configAxios.request({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/api.php?portal=1&a=portal&b=salvarEtapaErro`,
            data: {
                emp_codigo: store.state.ultimoSalvaEtapa.emp_codigo,
                cpfCnpj: store.state.ultimoSalvaEtapa.cpfCnpj,
                ets_codigo: store.state.ultimoSalvaEtapa.esn_fk_ets_codigo,
                data_agendamento: store.state.ultimoSalvaEtapa.dataAtual,
                hora_agendamento: store.state.ultimoSalvaEtapa.horaAtual,
                motivo_agendamento: store.state.ultimoSalvaEtapa.motivo_agendamento,
                json: JSON.stringify(resposta.data),
                erro: resposta.data.mensagem,
                token: process.env.VUE_APP_GLOBAL_TOKEN
            },
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            }
        });
    }

    return resposta;
});

export default apiInstance;
