<template>
  <div class="token-page">
    <div class="token-box">
      <h3 class="token-title">Informe o código de verificação</h3>
      <form id="token-form">
        <div class="label">
          <span>Você recebeu um número de 6 dígitos por SMS.</span>
          <span
            >Informe o código de verificação. Você recebeu um número de 6
            dígitos por SMS.
          </span>
          <span>Se precisar que podemos enviar um novo código para você.</span>

          <input maxlength="6" v-model="input" />
        </div>
        <div class="button-box">
          <input
            type="button"
            value="Continuar"
            class="btn-token token-button"
            :style="corPrimaria"
            @click="continuar"
          />

          <input
            type="button"
            value="Reenviar código"
            class="btn-token token-button secondaryColor"
            :style="corSecundaria"
            :disabled="buttonState"
            @click="espere"
          />

        </div>
		<div style="display: flex; justify-content: center;">
			<p v-if="mostrarCountdownHabilitarBotao">Botão será reabilitado em {{ tempoHabilitarBotao }} segundo(s)</p>
		</div>
      </form>

      <simple-keyboard
        v-if="temToken"
        :input="input"
        @onChange="onChange"
      ></simple-keyboard>
    </div>
  </div>
</template>

<script>
import "simple-keyboard/build/css/index.css";
import { mapState, mapActions, createNamespacedHelpers } from "vuex";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import SimpleKeyboard from "@/components/inputs/SimpleKeyboard";

const { mapState: mapStatePassosRetornarDividas } = createNamespacedHelpers(
  "passosRetornarDividas"
);

export default {
  name: "VerifyToken",

  components: { ButtonDefault, SimpleKeyboard },

  mixins: [mixins],

  data() {
    return {
      input: "",
      buttonState: true,
	  tempoHabilitarBotao: 30,
	  mostrarCountdownHabilitarBotao: true
    };
  },

  computed: {
    ...mapState({
      corPrimaria: (estado) => estado.corPrimaria,
      corSecundaria: (estado) => estado.corSecundaria,
    }),

    ...mapStatePassosRetornarDividas({
      lojas: (estado) => estado.lojas,
    }),

    temToken() {
      return this.parceiroConfig.sessoes.customization.campos.confirmBirthday
        .flgTotem == undefined
        ? false
        : this.parceiroConfig.sessoes.customization.campos.confirmBirthday
            .flgTotem;
    },
  },

  async mounted() {
    this.SET_LOADING(true);
    this.SET_LOADING_MESSAGE("Gerando o seu token.");
    let promises = [];
    const emp_codigo =
      this.parceiro == "aquicob" ? "" : this.parceiroConfig.emp_codigo;

    this.acaoSalvarEtapa({
      cpfCnpj: this.cpfCnpj,
      esn_fk_ets_codigo: 27,
      emp_codigo: this.parceiroConfig.emp_codigo,
      data_agendamento: this.dataAtual,
      hora_agendamento: this.horaAtual,
      motivo_agendamento: "Gerou um novo token",
    });

    promises.push(
      this.action_enviar_token_portal_sms({
        cpfCnpj: this.cpfCnpj,
        fone: this.$route.params.fone,
        emp_codigo: emp_codigo,
        gerar_token: true,
      })
        .then((resposta) => {
          if (resposta?.data?.retorno) {
            this.CHANGE_MESSAGE_MODAL({
              show: true,
              title: "Atenção",
              message: resposta?.data?.mensagem,
              styleMessage: "success",
            });
          } else {
            this.CHANGE_MESSAGE_MODAL({
              show: true,
              title: "Atenção",
              message: resposta?.data?.mensagem,
              styleMessage: "danger",
            });
            return -1;
          }
        })
        .catch((_) => {
          this.CHANGE_MESSAGE_MODAL({
            show: true,
            title: "Atenção",
            message: resposta?.data?.mensagem,
            styleMessage: "danger",
          });
          return -1;
        })
    );
    await Promise.all(promises);

	let intervalo = setInterval(() => {
		if (this.tempoHabilitarBotao == 0) {
			this.tempoHabilitarBotao = 30;
			this.mostrarCountdownHabilitarBotao = false;
			this.buttonState = false;
			clearInterval(intervalo);
		} else {
			this.tempoHabilitarBotao--;
		}
	}, 1000);
		
    this.SET_LOADING_MESSAGE("");
    this.SET_LOADING(false);
  },

  watch: {
	buttonState() {
		if (this.buttonState) {
			let intervalo = setInterval(() => {
				if (this.tempoHabilitarBotao == 0) {
					this.tempoHabilitarBotao = 30;
					this.mostrarCountdownHabilitarBotao = false;
					this.buttonState = false;
					clearInterval(intervalo);
				} else {
					this.tempoHabilitarBotao--;
				}
			}, 1000);
		}
	}
  },

  methods: {
    ...mapActions(["action_enviar_token_portal_sms", "action_consulta_token"]),

    espere() {
      const emp_codigo =
        this.parceiro == "aquicob" ? "" : this.parceiroConfig.emp_codigo;

      this.acaoSalvarEtapa({
        cpfCnpj: this.cpfCnpj,
        esn_fk_ets_codigo: 27,
        emp_codigo: this.parceiroConfig.emp_codigo,
        motivo_agendamento: "Gerou um novo token",
        data_agendamento: this.dataAtual,
        hora_agendamento: this.horaAtual,
      });

      this.action_enviar_token_portal_sms({
        cpfCnpj: this.cpfCnpj,
        emp_codigo: emp_codigo,
        gerar_token: true,
        fone: this.$route.params.fone,
      })
        .then((resposta) => {
          if (resposta?.data?.retorno) {
            this.CHANGE_MESSAGE_MODAL({
              show: true,
              title: "Atenção",
              message: resposta?.data?.mensagem,
              styleMessage: "success",
            });
          } else {
            this.CHANGE_MESSAGE_MODAL({
              show: true,
              title: "Atenção",
              message: resposta?.data?.mensagem,
              styleMessage: "danger",
            });
            return -1;
          }
        })
        .catch((erro) => {
          this.CHANGE_MESSAGE_MODAL({
            show: true,
            title: "Atenção",
            message: erro,
            styleMessage: "danger",
          });
          return -1;
        });

      this.buttonState = true;
	  this.mostrarCountdownHabilitarBotao = true;
    },

    onChange(input) {
      this.input = input;
    },

    onInputChange(input) {
      this.input = input.target.value;
    },

    continuar() {
      if (this.input.length == 6) {
        const emp_codigo =
          this.parceiro == "aquicob" ? "" : this.parceiroConfig.emp_codigo;

        this.acaoSalvarEtapa({
          cpfCnpj: this.cpfCnpj,
          esn_fk_ets_codigo: 14,
          emp_codigo: this.parceiroConfig.emp_codigo,
          motivo_agendamento: "Consultou o token",
          data_agendamento: this.dataAtual,
          hora_agendamento: this.horaAtual,
        });

        this.action_consulta_token({
          cpfCnpj: this.cpfCnpj,
          emp_codigo: emp_codigo,
          token_cliente: this.input,
        })
          .then((respsota) => {
            if (respsota.data.dados) {
              this.$router.push({ name: "AccordionTabs" });
            } else {
              this.CHANGE_MESSAGE_MODAL({
                show: true,
                title: "Atenção",
                message: `Token informado inválido. Por favor verificar o número do token que você recebeu por SMS.`,
                styleMessage: "danger",
              });
            }
          })
          .catch((_) => {
            this.CHANGE_MESSAGE_MODAL({
              show: true,
              title: "Atenção",
              message: `Ocorreu um erro ao tentar consultar o seu token. Por favor, entre em contato com o nosso suporte.`,
              styleMessage: "danger",
            });
          });
      } else {
        this.CHANGE_MESSAGE_MODAL({
          show: true,
          title: "Atenção",
          message: `Por favor digitar um número de token de 6 dígitos`,
          styleMessage: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.token-page {
  min-height: 100vh;
  flex: 1;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-box {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.token-title {
  text-align: center;
  color: var(--secondaryColor);
  font-size: 25px;
  font-family: Montserrat-Bold;
  margin-bottom: 25px;
}

.btn-modal {
  height: 48px;
  padding: 0 48px;
  color: #ffffff;
  font-family: "Roboto Black";
  font-size: 18px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
}

.button-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-token {
  margin: 10px;
}

.label {
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-family: Roboto;
    font-weight: normal !important;
  }

  input {
    letter-spacing: 10px;
    margin: 35px 0;
    width: 100%;
    height: 46px;
    border-radius: 10px;
    background: var(--whiteColor);
    padding: 0 10px;
    border: 1px solid #4d4d4d !important;
    color: #031b4e;
    font-family: "Roboto Black";
    font-size: 16px;
    max-width: fit-content;
    text-align: center;
  }
}

.token-button {
  padding: 15px 25px;
  background: var(--color);
  font-family: "Roboto Bold";
  color: #ffffff;
  border: 3px solid var(--color);
  border-radius: 27px;
  transition: all 0.2s ease-in-out;

  @media only screen and (max-width: 900px) {
    margin-top: 24px;
  }

  &:hover {
    background-color: var(--hoverColor);
    border-color: var(--hoverColor);
    transition: all 0.2s ease-in-out;
  }

  &:disabled {
    color: #888888;
    border: 3px solid #888888;
    background: var(--whiteColor);

    &:hover {
      background: var(--whiteColor);
      color: #888888;
      border: 3px solid #888888;
      transition: all 0.2s ease-in-out;
    }
  }
}

.secondaryColor {
  background: transparent;
  color: var(--color);
  border: 3px solid var(--color);
  background: var(--whiteColor);
  transition: all 0.2s ease-in-out;

  &:hover {
    background: var(--color);
    color: var(--whiteColor);
    border: 3px solid var(--color);
    transition: all 0.2s ease-in-out;
  }

  &:disabled {
    color: #888888;
    border: 3px solid #888888;
    background: var(--whiteColor);

    &:hover {
      background: var(--whiteColor);
      color: #888888;
      border: 3px solid #888888;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
