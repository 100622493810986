<template>
	<div
		class="tutorial"
		:style="imagemDeFundo">

		<div
			v-if="parceiroConfig != null"
			class="container"
			:style="corPrimaria">

			<h3>{{ tituloTutorial }}</h3>

			<div class="tutorial-row">

				<flip-card
					v-for="step in parceiroConfig.sessoes.tutorial.campos.steps"
					:key="step.code"
					:title="step.title"
					:codigo="step.code"
					:text="step.text"
					></flip-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

import FlipCard from "@/components/buttons/FlipCard.vue";

export default {
	name: "LandPageTutorial",

	components: { FlipCard },

	mixins: [mixins],

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria,
			corSecundaria: (estado) => estado.corSecundaria
		}),

		imagemDeFundo() {
			return {
				"--fundoFull": this.parceiro && this.parceiroConfig.sessoes.tutorial.arquivos &&
					this.parceiroConfig.sessoes.tutorial.arquivos.length > 0 ?
					"url(" + this.parceiroConfig.sessoes.tutorial.arquivos[0].arquivo + ")" :
					"url(" + require(`@/assets/images/background-gray-lightgray.png`) + ")"
			};
		},

		tituloTutorial() {
			return this.parceiroConfig.sessoes.tutorial && this.parceiroConfig.sessoes.tutorial.campos.title;
		}
	}
};
</script>

<style scoped lang="scss">
.tutorial {
	padding: 30px 0;
	width: 100%;

	/* Create the parallax scrolling effect */

	background-image: var(--fundoFull);
	background-size: 100% 100%;
	background-attachment: fixed;
	background-position: absolute;
	background-repeat: no-repeat;
	@media only screen and (max-width: 450px) {
		background-size: 100% 100%;
		background-attachment: initial;
	}

	h3 {
		padding: 50px 0;
		text-align: center;
		font-size: 32px;
		font-family: "Montserrat-Bold";
		color: var(--hoverColor);
		line-height: 48px;
		@media only screen and (max-width: 766px) {
			font-size: 28px;
		}
	}
	p {
		color: var(--lightGrayColor);
		font-family: Cabin;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
		text-align: center;
		margin-top: 30px;
		padding: 0 10vw;
		@media only screen and (max-width: 766px) {
			padding: 0;
		}
	}
}

.tutorial-row {
	display: flex;
	justify-content: space-between;
	padding: 0 0 60px 0;
	flex-wrap: wrap;
	align-items: center;
	@media only screen and (max-width: 766px) {
		justify-content: center;
	}
}
.tutorial-item {
	h2 {
		color: var(--hoverColor);
		font-size: 91px;
		font-family: "Montserrat-Bold";
	}
	h5 {
		color: var(--hoverColor);
		font-size: 18px;
		font-family: "Montserrat-Bold";
		margin-bottom: 10px;
	}
	h6 {
		font-size: 16px;
		font-family: "Montserrat-Bold";
		color: var(--whiteColor);
	}
}

@media only screen and (max-width: 1460px) {
	.tutorial-row {
		justify-content: center;
	}

	.tutorial-item {
		margin: 60px;
	}
}

.flip-card-inner {
	position: relative;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	text-align: center;
	width: 250px;
	background: var(--whiteColor);
	height: 300px;
	border-radius: 7px;
	box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07);
	margin-left: 10px;
	margin-right: 10px;
	@media only screen and (max-width: 766px) {
		margin: 10px;
	}
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	align-items: center;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.flip-card-front {
	background-color: var(--whiteColor);
}

.flip-card-back {
	background-color: var(--color);
	color: white;
	transform: rotateY(180deg);
}
</style>
