<template>
	<div id="app">

		<AlertLGPD
			v-if="!getLgpd && !hideLgpdAlert"
			@accept="hideLgpdAlert = true" ></AlertLGPD>

		<message-modal
			v-if="showMessageModal"
			:styleMessage="styleMessageModal"
			:title="titleMessageModal"
			:message="messageModal" ></message-modal>

		<message-snackbar
			v-if="showMessageSnackbar"
			:message="messageSnackbar" ></message-snackbar>

		<div v-if="loading" class="loadingBackground">

			<img
				v-if="parceiro || (getSubdominio && parceiroConfig.logo != null)"
				key="portal-parceiro-logo"
				:src="parceiroConfig.logo" />

			<img
				v-else-if="!getSubdominio"
				key="portal-parceiro-sem-subdominio"
				src="./assets/images/logo.png" />

			<loading-message :message="loadingMessage" ></loading-message>

			<div class="loader" ></div>
		</div>

		<router-view ></router-view>
	</div>
</template>

<script>
import { mapMutations, mapState, createNamespacedHelpers } from "vuex"
const {
	mapActions : mapActionsPassosRetornarDividas,
	mapMutations: mapMutationsPassosRetornarDividas
} = createNamespacedHelpers('passosRetornarDividas');

import MessageModal from "./components/MessageModal"
import MessageSnackbar from "./components/MessageSnackbar"
import LoadingMessage from "./components/LoadingMessage"
import AlertLGPD from "./components/AlertLGPD"

export default {
	name: "App",

	components: { AlertLGPD, MessageSnackbar, MessageModal, LoadingMessage },

	data() {
		return {
			hideLgpdAlert: false
		}
	},

	errorCaptured: function(err) {
		if (err == "TypeError: _vm.parceiroConfig is null") {
			this.CHANGE_MESSAGE_MODAL({
				show: true,
				title: "Atenção",
				message: `Subdomínio não encontrado!`,
				styleMessage: "danger"
			})
			this.$router.push({ name: "LandPageParceiro" })
		}
	},

	computed: {
		...mapState({
			loading: (estado) => estado.loading,
			loadingMessage: (estado) => estado.loadingMessage,
			showMessageSnackbar: (estado) => estado.messageSnackbar.show,
			messageSnackbar: (estado) => estado.messageSnackbar.message,
			styleMessageModal: (estado) => estado.messageModal.styleMessage,
			titleMessageModal: (estado) => estado.messageModal.title,
			messageModal: (estado) => estado.messageModal.message,
			parceiroConfig: (estado) => estado.parceiroConfig
		}),

		getLgpd() {
			return localStorage.getItem("acceptCookies") || localStorage.getItem("refuseCookies")
		},

		getSubdominio() {
			return window.location.host.split(".").length > 2
		}
	},

	async created() {
		this.acaoSalvarEtapa({
			emp_codigo: this.parceiroConfig.emp_codigo,
			cpfCnpj: this.removeMaskCharacters(this.identification),
			esn_fk_ets_codigo: 31,
			data_agendamento: this.dataAtual,
			hora_agendamento: this.horaAtual,
			motivo_agendamento: "Acessou o portal",
		});

		this.acaoRetornarTokenEmpresa().then(resposta => {
			if (resposta.data.dados === null) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Erro Fatal",
					message: "Portal não está funcionando!",
					styleMessage: "danger"
				})
			}
		});

		this.SET_FEZ_ACORDO_POR_PORTAL(false);
	},

	methods: {
		...mapMutations(['CHANGE_MESSAGE_MODAL']),

		...mapMutationsPassosRetornarDividas(["SET_FEZ_ACORDO_POR_PORTAL"]),

		...mapActionsPassosRetornarDividas(['acaoRetornarTokenEmpresa'])
	}
}
</script>

<style lang="scss">
* {
	box-sizing: border-box !important;
}
.loadingBackground {
	position: fixed;
	top: 0;
	left: 0;
	background: var(--blackColor);
	width: 100%;
	height: 100vh;
	z-index: 99999999999999;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loadingBackground img {
	max-width: 250px;
}

.loader {
	font-size: 10px;
	margin: 50px auto;
	text-indent: -9999em;
	width: 5em;
	height: 5em;
	border-radius: 50%;
	background: #ffffff;
	background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0.5) 42%);
	background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0.5) 42%);
	background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0.5) 42%);
	background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0.5) 42%);
	background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0.5) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader:before {
	width: 50%;
	height: 50%;
	background: #ffffff;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}
.loader:after {
	background: var(--blackColor);
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: "";
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
</style>
