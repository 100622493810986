<template>
  <div class="top-header">
    <h3>{{ title }}</h3>
    <div style="width: 200px">
      <ButtonDefault @click.native="$router.push({name: routeNameButton})" :text="buttonLabel"/>
    </div>
  </div>
</template>

<script>
import ButtonDefault from "./buttons/ButtonDefault";

export default {
  name: "HeaderList",
  components: {ButtonDefault},
  props: {
    title: {
      default: 'Titulo'
    },
    routeNameButton: {
      default: ''
    },
    buttonLabel: {
      default: 'Novo parceiro'
    }
  }
}
</script>

<style scoped>
.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-header h3 {
  font-size: 24px;
  font-family: "Roboto Black";
  color: var(--blackColor);
}
</style>
