<template>
	<div class="page-reset">
		<div class="box-reset">

			<h3 :style="{ color: corPrimaria }">Esqueceu o seu telefone?</h3>

			<p>Confirme o seu CPF/CNPJ mandaremos para o e-mail cadastrado um link para você mudar o seu telefone"</p>

			<the-mask
				ref="inputIdentification"
				v-model="identification"
				pattern="[0-9]*"
				inputmode="numeric"
				:mask="['###.###.###-##', '##.###.###/####-##']"
				:placeholder="'Digite seu CPF ou CNPJ'"
				@keyup.enter.native="validIdentification" ></the-mask>

			<div style="display: flex; justify-content: center">
				<span v-show="messageError"> Digite um CPF ou CNPJ válido! </span>
			</div>

			<div style="margin-top: 30px; padding: 0 15px">
				<button-default
					:color="corPrimaria"
					@click.native="validIdentification"
					text="Enviar link por e-mail."></button-default>
			</div>
		</div>
	</div>
</template>

<script>
import JWT from "jwt-simple";
import { TheMask } from "vue-the-mask";
import { mapState, mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";

export default {
	name: "ResetPhone",

	components: { ButtonDefault, TheMask, JWT },

	mixins: [globalMethods, mixins],

	data() {
		return {
			identification: "",
			messageError: false
		};
	},

	computed: {
		...mapState({
			empresas: (estado) => estado.empresas
		}),
	},

	created() {
		if (this.$route.params.identification) {
			this.identification = this.$route.params.identification;
		}
	},

	mounted() {
		this.$refs.inputIdentification.$el.focus();
	},

	methods: {
		...mapActions(["action_getCpfCnpj", "action_enviar_email"]),

		searchIdentification() {
			const emp_codigo = this.parceiro == "aquicob" ? "" : this.parceiroConfig.emp_codigo;

			this.acaoSalvarEtapa({
				cpfCnpj: this.removeMaskCharacters(this.identification),
				esn_fk_ets_codigo: 25,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: 'Enviou por email o link para mudar o telefone',
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual
			});

			this.action_getCpfCnpj({
				cpfCnpj: this.removeMaskCharacters(this.identification),
				emp_codigo: emp_codigo
			}).then((resposta) => {

				if (resposta.data.retorno && resposta.data.dados) {

					Object.values(resposta.data.dados.empresa).forEach((empresa) => {
						var payload = { emp_codigo: empresa.emp_codigo, pes_codigo: empresa.pessoa.pes_codigo };
						var secret = "BTDK77K6HOSI5YZYNXFVHSN3Q5V2F911";
						var token = JWT.encode(payload, secret);
						var url = window.location.toString();
						url = url.replace("esqueceu-telefone", "novo-telefone") + "/token/" + token;

						this.action_enviar_email({
							emp_codigo: empresa.emp_codigo,
							pes_codigo: empresa.pessoa.pes_codigo,
							pem_email: empresa.pessoa.email,
							loj_codigo: empresa.lojas[0].loj_codigo,
							anexo: "",
							assunto: "Recuperação do telefone",
							texto_email: url
						}).then((resposta) => {

							if (resposta?.data?.mensagem == "E-mail enviado com sucesso.") {
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Atenção",
									message: `E-mail enviado com sucesso.`,
									styleMessage: "success"
								});
								return -1;

							} else {
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Atenção",
									message: `Erro ao enviar o e-mail. Verifique se você tem um e-mail cadastrado junto com o seu CPF.`,
									styleMessage: "danger"
								});
								return -1;
							}
						});
					});

				} else {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `CPF/CNPJ não encontrado.`,
						styleMessage: "danger"
					});
					return -1;
				}
			});
		},

		validIdentification() {
			if (this.identification.length == 11) {
				var Soma;
				var Resto;
				Soma = 0;
				var strCPF = this.identification;

				if (strCPF == "00000000000") {
					this.messageError = true;
					return false;
				}

				for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
				Resto = (Soma * 10) % 11;

				if (Resto == 10 || Resto == 11) Resto = 0;
				if (Resto != parseInt(strCPF.substring(9, 10))) {
					this.messageError = true;
					return false;
				}

				Soma = 0;
				for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
				Resto = (Soma * 10) % 11;

				if (Resto == 10 || Resto == 11) Resto = 0;
				if (Resto != parseInt(strCPF.substring(10, 11))) {
					this.messageError = true;
					return this.messageError;
				}

				this.searchIdentification();
			} else if (this.identification.length == 14) {
				var c = this.identification;
				var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

				if ((c = c.replace(/[^\d]/g, "")).length != 14) return false;

				if (/0{14}/.test(c)) return false;

				for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
				if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

				for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
				if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;
				this.searchIdentification();
			} else {
				this.messageError = true;
				return this.messageError;
			}
		}
	}
};
</script>

<style scoped>
.page-reset {
	min-height: 100vh;
	flex: 1;
	padding: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-reset h3 {
	text-align: center;
	color: var(--secondaryColor);
	font-size: 32px;
	font-family: Montserrat-Bold;
	margin-bottom: 30px;
}

.page-reset p {
	text-align: center;
	font-size: 14px;
	line-height: 24px;
	font-family: Roboto;
	font-weight: normal !important;
	margin-bottom: 30px;
}

.page-reset span {
	margin-top: 10px;
	color: var(--dangerColor);
	text-align: center;
}

.box-reset {
	width: 400px;
}

.box-reset input {
	height: 60px;
	width: 400px;
	text-align: center;
	background: var(--whiteColor);
	border: 3px solid var(--whiteColor);
	border-radius: 25px;
	font-family: "Montserrat-Bold";
	font-size: 20px;
	margin-bottom: 30px;
	color: var(--secondaryColor);
	@media only screen and (max-width: 700px) {
		width: 75vw;
		border-radius: 25px;
		margin-bottom: 10px;
	}
}
</style>
