<template>
    <div class="snackbar">
        <i class="fas fa-check-circle"/>
        {{message}}
    </div>
</template>

<script>
    export default {
        name: "MessageSnackbar",
        props:[
            'message'
        ]
    }
</script>

<style scoped>
    .snackbar{
        margin: 10px;
        top:0;
        right: 0;
        position: fixed;
        padding: 20px 30px;
        background: #49a294;
        color: #ffffff;
        font-family: "Roboto Medium";
        font-size: 14px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999999999999999;
    }

    .snackbar i{
        margin-right: 7px;
        font-size: 18px;
    }

    .snackbar:hover{
        cursor: pointer;
        background: #49a294;
    }
</style>
