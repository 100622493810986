<template>
	<div class="box-forms-regiter">
		<div style="display: flex; flex-direction: column; margin: 0px 100px">
			<h3 style="text-transform: none">Faça a sua requisição em relação aos seus dados. Por favor, preencha todos os campos abaixo e nós enviaremos a sua requisição que responderemos o mais rápido possível.</h3>
		</div>

		<form-fields
			:fields="fields"
			@save=""
			@update:values="inputsValues = $event" ></form-fields>

		<div class="box-button-register">
			<p style="margin: 10px 0px">(*) Campos obrigatórios.</p>

			<button-lg
				:color="parceiro ? parceiroConfig.estilo.corPrimaria : 'var(color)'"
				:full="true"
				@click.native="enviarProposta"
				text="Enviar Solicitação de Dados" ></button-lg>
		</div>

</div>
</template>

<script>
import { mapActions } from "vuex";
import mixins from "@/mixins/mixins";

import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";

export default {
	name: "RightsRequestForm",

	components: { FormFields, ButtonLg },

	mixins: [mixins],

	data() {
		return {
			fields: [],
			inputsValues: {}
		};
	},

	mounted() {
		this.fields = [
			{
				variableName: "nome",
				fieldType: "TextField",
				label: "Nome Completo: *",
				type: "text",
				cols: "8"
			},
			{
				variableName: "telefone",
				fieldType: "TextFieldMask",
				label: "Telefone: *",
				type: "text",
				cols: "4",
				maskFormat: "(##) #####-####"
			},
			{
				variableName: "email",
				fieldType: "TextField",
				label: "E-mail: *",
				type: "text",
				cols: "6"
			},
			{
				variableName: "tipoRequisicao",
				fieldType: "SelectField",
				label: "Selecione o tipo de requisição: *",
				options: [
					{ label: "Acesso aos dados", value: "Acesso aos dados" },
					{ label: "Confirmação da existência de tratamento", value: "Confirmação da existência de tratamento" },
					{ label: "Correção de dados", value: "Correção de dados" },
					{ label: "Anonimização, bloqueio ou eliminação de dados", value: "Anonimização, bloqueio ou eliminação de dados" },
					{ label: "Portabilidade dos dados", value: "Portabilidade dos dados" },
					{ label: "Eliminação de dados", value: "Eliminação de dados" },
					{ label: "Informação sobre compartilhamento", value: "Informação sobre compartilhamento" },
					{ label: "Revisão de decisões automatizadas", value: "Revisão de decisões automatizadas" },
					{ label: "Revogação de consentimento", value: "Revogação de consentimento" },
					{ label: "Outros", value: "Outros" }
				],
				keyName: "label",
				keyValue: "value",
				type: "text",
				cols: "6"
			},
			{
				variableName: "solicitacao",
				fieldType: "TextAreaField",
				label: "Solicitação: *",
				type: "text",
				cols: "12"
			}
		];
	},

	methods: {
		...mapActions(["action_enviar_email"]),

		enviarProposta() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Enviando...");
			let emails = [];

			if (this.parceiroConfig.identificador === "aqui") {
				emails.push("contato@negociaronline.com.br");

			} else {
				emails.push(...["contato@aquicob.com.br", "moises@aquicob.com.br"]);
			}

			if (this.inputsValues.nome && this.inputsValues.telefone && this.inputsValues.email && this.inputsValues.tipoRequisicao && this.inputsValues.solicitacao) {

				emails.forEach((email) => {
					this.action_enviar_email({
						emp_codigo: "262",
						pes_codigo: "138",
						loj_codigo: "2",
						pem_email: email,
						assunto: "Requisição de dados",
						texto_email: `Nome: ${this.inputsValues.nome}<br>Telefone: ${this.inputsValues.telefone}<br>E-mail: ${this.inputsValues.email}<br>Tipo de requisição:
						 ${this.inputsValues.tipoRequisicao}<br>Solicitação: ${this.inputsValues.solicitacao}<br>$emit('update:activeComponent', 'PaymentOptionsAgreement')`,
						termo: false

					}).then((resposta) => {
							this.SET_LOADING_MESSAGE("");
							this.SET_LOADING(false);

							if (resposta?.data?.mensagem == "E-mail enviado com sucesso.") {
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Atenção",
									message: `Sua solicitação foi enviada com sucesso. Entraremos em contato em breve, para falar com você sobre a resolução da sua solicitação.`,
									styleMessage: "success"
								});
								return -1;

							} else {
								this.CHANGE_MESSAGE_MODAL({
									show: true,
									title: "Atenção",
									message: `Erro ao enviar a sua solicitação. Tente novamente mais tarde.`,
									styleMessage: "danger"
								});
							}
						}).catch((_) => {
							this.SET_LOADING_MESSAGE("");
							this.SET_LOADING(false);
							this.CHANGE_MESSAGE_MODAL({
								show: true,
								title: "Atenção",
								message: `Erro ao enviar a sua solicitação. Tente novamente mais tarde.`,
								styleMessage: "danger"
							});
							return -1;
						});
				});
			} else {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Preencha todos os campos, por favor.`,
					styleMessage: "danger"
				});
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.box-forms-regiter,
.box-button-register {
	width: 100%;
	box-sizing: border-box;
	padding: 0px 70px;
	@media only screen and (max-width: 990px) {
		width: 100%;
		padding: 10px;
	}
}
</style>