import { mapState, mapMutations, mapActions } from "vuex";

export default {
    computed: {
        ...mapState({
            parceiro: (estado) => estado.parceiroConfig.nome,
            parceiroConfig: (estado) => estado.parceiroConfig,
            cpfCnpj: (estado) => estado.passosRetornarDividas.pessoa.pesCpfCnpj,
			showMessageModal: (estado) => estado.messageModal.show,
            nomeParceiroContratos: (estado) => estado.nomeParceiroContratos,
            portalCamec: (estado) => estado.portalCamec
        }),

        parceiroConfigCorSecundariaCorPrimaria() {
            return this.parceiro ? this.parceiroConfig.estilo.corSecundaria : 'var(primaryColor)';
        },

        parceiroConfigCorSecundariaCorSecundaria() {
            return this.parceiro ? this.parceiroConfig.estilo.corSecundaria : 'var(secondaryColor)';
        },

        corPrimaria() {
            return this.parceiro ? this.parceiroConfig.estilo.corPrimaria : 'var(primaryColor)';
        },

        nomeParceiro() {
            return this.parceiroConfig.identificador === "aqui" ? "Negociar" : "Aquicob";
        },

        dataAtual() {
            let data = new Date();
            const mes = data.getMonth() + 1 > 9 ? data.getMonth() + 1 : `0${data.getMonth() + 1}`;
            const dia = data.getDate() > 9 ? data.getDate() : `0${data.getDate()}`;

            return `${data.getFullYear()}-${mes}-${dia}`;
        },

        horaAtual() {
            let data = new Date();

            const hora = data.getHours() > 9 ? data.getHours() : `0${data.getHours()}`;
            const minutos = data.getMinutes() > 9 ? data.getMinutes() : `0${data.getMinutes()}`;
            const segundos = data.getSeconds() > 9 ? data.getSeconds() : `0${data.getSeconds()}`;

            return `${hora}:${minutos}:${segundos}`;
        }
    },

    methods: {
        ...mapMutations(["CHANGE_MESSAGE_MODAL", "CHANGE_MESSAGE_SNACKBAR", "SET_LOADING_MESSAGE", "SET_LOADING"]),

        ...mapActions(["acaoSalvarEtapa"]),

        formatarDinheiro(valor) {
            return Number.parseFloat(valor).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },

        formatarData(data) {
            if (data.indexOf('T') != -1) {
                data = data.substring(0, data.indexOf('T'));
            }

            let arrayData = data.split("-");
            let dia = arrayData[2].length == 2 ? arrayData[2] : `0${arrayData[2]}`;
            let mes = arrayData[1].length == 2 ? arrayData[1] : `0${arrayData[1]}`;
            return `${dia}/${mes}/${arrayData[0]}`;
        },

        removeMaskCharacters(string) {
            if (!string) {
                return null
            }
            return string.toString().split('(').join('').split(')').join('').split('.').join('').split(',').join('').split('R$ ').join('').split(' ').join('').split('-').join('');
        },

        capitalizeFirstLetter(string) {
            return string.replace(string[0], string[0].toUpperCase());
        },

        cnpjMask(value) {
            if (value && value.length === 14) {
                return (
                    value.substring(0, 2) +
                    "." +
                    value.substring(2, 5) +
                    "." +
                    value.substring(5, 8) +
                    "/" +
                    value.substring(8, 12) +
                    "-" +
                    value.substring(12, 14)
                );
            }
            return "---";
        }
    }
}