<template>
	<div class="warning-page">
		<div class="warning-background">

			<div class="warning-header">
				<img
					v-if="parceiro"
					:src="parceiroConfig.logo"
					alt="logo" />

				<img
					src="@/assets/images/parceiros/prefeitura-ponta-grossa.jpg"
					alt="logo" />

				<div class="warning-info">
					<h1>Aviso importante!</h1>
					<h2>Prefeitura de Ponta Grossa</h2>
				</div>

			</div>

			<div>
				<p class="warning-text">Verificamos que constam em aberto, junto ao Município de Ponta Grossa os débitos abaixo transcritos, favor confirmar recolhimento para que seja baixado do sistema,
					 mediante protocolo online, anexando respectivo comprovante de pagamento no endereço 
					 <a
					 	href="https://pontagrossa.oxy.elotech.com.br/cidadao/minhaconta"
						class="warning-url">https://pontagrossa.oxy.elotech.com.br/cidadao/minhaconta</a>.</p>

				<p class="warning-text">Caso o débito não esteja quitado, o Município de Ponta Grossa estará disponibilizando um Programa de Regularização de Débitos,
					 com descontos de 90% no valor da multa e juros para pagamento à vista e também 70% descontos para parcelamentos em até 60 meses com juros de 0,5% ao mês, acessa o site da prefeitura
					  <a
						href="https://pontagrossa.oxy.elotech.com.br/cidadao/minhaconta"
						class="warning-url">https://pontagrossa.oxy.elotech.com.br/cidadao/minhaconta</a>, praça de atendimento ou diretamente no setor responsável pelo débito.</p>
			</div>

			<div class="warning-footer">
				<img
					src="@/assets/images/parceiros/logo-local.jpg"
					alt="logo" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "WarningCard",
	computed: {
		...mapState({
			parceiro: (estado) => estado.parceiroConfig.nome,
			parceiroConfig: (estado) => estado.parceiroConfig
		})
	}
};
</script>

<style lang="scss" scoped>
.warning-page {
	box-sizing: border-box !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.warning-background {
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-top: 15px;
	margin-bottom: 5px;
}
.warning-text {
	color: #5b6987;
	font-size: 18px;
	margin-bottom: 10px;
	text-align: justify;
}
.warning-url {
	font-size: 18px;
	margin-bottom: 10px;
	text-align: justify;
}
.warning-header {
	display: flex;
	border-bottom: 1px solid #f0f0f0;
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.warning-header img {
	margin: 0 10px;
	object-fit: contain;
	width: 110px;
}
.warning-info {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	height: auto;
	margin-left: 30px;
	padding-left: 30px;
	border-left: 1px solid #f0f0f0;
	width: 100%;
	align-items: center;
}

h2 {
	color: var(--backgroundColor);
	font-family: Montserrat-Bold;
	font-size: 18px;
	margin-bottom: 10px;
	text-transform: uppercase;
}

h1 {
	color: var(--backgroundColor);
	font-family: Montserrat-Bold;
	font-size: 34px;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.warning-footer {
	margin-top: 50px;
}
</style>
