<template>
	<menu>
		<div>
			<div class="menu-logo">
				<img src="@/assets/images/logo.png" />
			</div>

			<div class="menu-scroll">
				<ul>
					<item-menu
						:name="'Parceiros'"
						:class="activeMenu('ListPartners')"
						:route_name="'ListPartners'"
						:icon="'fas fa-users'" ></item-menu>

					<item-menu
						:name="'Dúvidas Frequentes'"
						:class="activeMenu('ListQuestions')"
						:route_name="'ListQuestions'"
						:icon="'fas fa-question-circle'" ></item-menu>

					<item-menu
						:name="'Alterar senha'"
						:class="activeMenu('ResetPassword')"
						:route_name="'ResetPassword'"
						:icon="'fas fa-key'" ></item-menu>

					<item-menu
						:name="'Leads'"
						:class="activeMenu('ListLeads')"
						:route_name="'ListLeads'"
						:icon="'fas fa-user-plus'" ></item-menu>

				</ul>
			</div>
		</div>

		<router-link
			:key="$route.fullPath"
			class="menu-item-end"
			:to="{ name: 'Logout' }"
			tag="li"
			:style="corPrimaria">

			<i class="fas fa-times" ></i>
			Sair
		</router-link>
	</menu>
</template>

<script>
import { mapState } from "vuex";
import globalMethods from "@/mixins/globalMethods";

import ItemMenu from "@/components/ItemMenu";

export default {
	name: "Menu",

	mixins: [globalMethods],

	components: { ItemMenu },

	computed: {
		...mapState({
			corPrimaria: (estado) => estado.corPrimaria
		})
	},

	methods: {
		childrensActive(rotas) {
			let val = "inactive";

			rotas.forEach((rota) => {
				if (this.$route.name === rota) {
					val = "active";
				}
			});
			return val;
		},

		activeMenu(rota) {
			if (this.$route.name === rota) {
				return "active";
			}

			return "inactive";
		}
	}
};
</script>

<style scoped>
menu {
	background: var(--blackColor);
	width: 290px;
	min-height: 100vh;
	height: 100vh;
	max-height: 100vh;
	-webkit-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	-moz-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	overflow-y: hidden;
	overflow-x: hidden;
	z-index: 300;
	margin: 0;
	padding: 0;
}

menu::-webkit-scrollbar {
	width: 0;
	height: 0;
}

menu::-webkit-scrollbar-thumb {
	background: transparent;
}

.menu-logo {
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-logo img {
	width: 200px;
}

li.menu-item-end {
	padding: 10px 0 10px 20px;
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: "Roboto Bold";
	cursor: pointer;
	color: var(--whiteColor);
	background: var(--color);
	border-radius: 0 25px 25px 0;
	transition: all 0.2s ease-in-out;
}
li.menu-item-end:hover {
	background-color: var(--hoverColor);
	transition: all 0.2s ease-in-out;
}

li.menu-item-end i {
	margin-right: 10px;
	font-size: 18px;
	width: 28px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
