<template>
	<div class="container-faq">

		<div class="header-debits">
			<i
				v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
				key="portal-prefeitura-icone-cabecalho"
				:style="parceiroConfigCorSecundariaCorPrimaria"
				class="fas fa-question"
				@click="$router.push({ name: 'MyDebits' })" ></i>

			<i
				v-else
				key="portal-parceiro-icone-cabecalho"
				:style="parceiroConfigCorSecundariaCorPrimaria"
				class="fas fa-times"
				@click="$router.push({ name: 'AccordionTabs' })" ></i>
		</div>

		<h3 :style="parceiroConfigCorSecundariaCorPrimaria">dúvidas</h3>

		<p
			v-if="parceiroConfig.sessoes.customization.campos.menuDebits.flgPrefeituraMode"
			key="portal-prefeitura-paragrafo-duvida"
			class="message-sub">Você possui alguma dúvida sobre a Prefeitura ?</p>
			
			<p
			v-else
			key="portal-aquicob-paragrafo-duvida"
			class="message-sub">Você possui alguma dúvida sobre o {{ nomeParceiro }} ?</p>

		<div v-if="parceiroConfig.emp_codigo != 128"
			 class="row-questions">

			<div
				v-for="duvida in duvidas"
				:key="duvida.codigo"
				class="question">

				<div @click="alteraDuvidaSelecionada(duvida)">
					<h4>{{ duvida.titulo }}</h4>
					<i
						class="fas"
						:class="retornarTipoIcone(duvida)" ></i>
				</div>

				<p v-show="selected === duvida.codigo">
					{{ duvida.descricao }}
				</p>
			</div>

		</div>
		<div v-else>
			<div class="question">
				<div>
					<h4>Meu cartão vai cancelar por eu ter realizado uma negociação ?</h4>
					<i id="icone1"
					   class="fas fa-plus"
					   @click="mostrarMensagem(1)"></i>
				</div>
				<span id="mensagem1" class="hide">
					<p>
						Não, o seu cartão ficará liberado para utilização após a quitação de todos os débitos que possui com a Senff.
					</p>
					<p>
						Seu cartão apenas não poderá ser utilizado caso tenha realizado o cancelamento deste pelo motivo de desistência.
					</p>
				</span>
			</div>
			<div class="question">
				<div>
					<h4>Quais os locais que posso pagar meus acordos ?</h4>
					<i id="icone2"
					   class="fas fa-plus"
					   @click="mostrarMensagem(2)"></i>
				</div>
				<span id="mensagem2" class="hide">
					<p>
						Os pagamentos podem ser realizados em Lotéricas, bancos, correspondentes bancários, internet banking e caixa eletrônico.
					</p>
					<p>
						Os mesmos locais são válidos para pagamentos em atraso, neste caso terá correção de juros no ato do pagamento, para verificar o prazo máximo para pagamento em atraso consulte seu boleto.
					</p>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import mixins from "@/mixins/mixins"

export default {
	name: "FAQ",

	mixins: [mixins],

	data() {
		return {
			selected: null,
			duvidas: null
		};
	},

	mounted() {
		this.getDuvidas();
	},

	methods: {
		...mapActions(["action_buscar_duvidas"]),

		getDuvidas() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Coletando as dúvidas");

			this.action_buscar_duvidas().then((resposta) => {
				if (resposta) {
					if (resposta.data.dados.duvidas) this.duvidas = resposta.data.dados.duvidas;
					else this.duvidas = null;
					this.SET_LOADING_MESSAGE("");
					this.SET_LOADING(false);
				}
			}).catch((_) => {
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Ocorreu um erro",
					message: `Dados não encontrados`,
					styleMessage: "danger"
				});
			});
		},

		alteraDuvidaSelecionada(duvida) {
			this.selected === duvida.codigo ? (this.selected = null) : (this.selected = duvida.codigo);
		},

		retornarTipoIcone(duvida) {
			return this.selected === duvida.codigo ? 'fa-minus' : 'fa-plus';
		},

		mostrarMensagem(numeroMensagem) {
			if (this.selected != null && this.selected != numeroMensagem) {
				let divSelecionada = document.getElementById(`mensagem${this.selected}`);
				divSelecionada.setAttribute('class', 'hide');
				let iconeSelecionado = document.getElementById(`icone${this.selected}`);
				iconeSelecionado.setAttribute('class', 'fas fa-plus');
			}

			let divMensagem = document.getElementById(`mensagem${numeroMensagem}`);
			let icone = document.getElementById(`icone${numeroMensagem}`);
			if (divMensagem.getAttribute('class') == 'hide') {
				divMensagem.removeAttribute('class');
				icone.setAttribute('class', 'fas fa-minus');
				this.selected = numeroMensagem;
			} else {
				divMensagem.setAttribute('class', 'hide');
				icone.setAttribute('class', 'fas fa-plus');
			}
		}
	}
};
</script>

<style scoped>
.container-faq {
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #f3f5f9;
	flex: 1;
	box-sizing: border-box;
}

.header-debits {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	cursor: pointer;
}

h3 {
	color: #031b4e;
	font-family: Montserrat-Bold;
	font-size: 28px;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.header-debits i {
	font-size: 48px;
	color: #031b4e;
}

.message-sub {
	color: #5b6987;
	font-size: 14px;
	margin-bottom: 10px;
}

.row-questions {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.question {
	background: #fff;
	width: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	padding: 20px 30px;
	border-radius: 5px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
}

.question div {
	width: 100%;
	display: flex;
	height: 60px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.question div:hover .question p {
	background-color: red !important;
}

.question h4 {
	font-family: "Montserrat-Bold";
	color: #031b4e;
	font-size: 24px;
}

.question i {
	color: #031b4e;
	font-size: 24px;
}

.question p {
	padding: 20px 0;
	font-family: "Roboto";
	color: var(--blackColor);
	letter-spacing: -0.01em;
	font-size: 1.125rem;
	line-height: 1.32;
	transition: all 0.5s ease-in-out;
}

.hide {
	display: none;
}
</style>