<template>
	<div class="background" @keypress.enter="continuar()">
		<div class="dialog-box" :class="styleMessage">
			<i :class="iconeModal" />
			<h6>{{ title }}</h6>
			<p>{{ message }}</p>
			<button @click="continuar()" class="btn-modal">Entendi</button>
		</div>
	</div>
</template>

<script>
import ButtonLarge from "./buttons/ButtonLg";
import { mapMutations } from "vuex";
export default {
	name: "MessageModal",
	components: { ButtonLarge },
	props: ["styleMessage", "title", "message"],
	data() {
		return {
			modal: false
		};
	},

	computed: {
		iconeModal() {
			switch (this.styleMessage) {
				case 'danger':
					return 'fas fa-times-circle';
				case 'success':
					return 'fas fa-check-circle';
				case 'info':
					return 'fas fa-info-circle';
			}
		}
	},

	methods: {
		...mapMutations(["CHANGE_MESSAGE_MODAL"]),
		myHandler(e) {
			if (e.keyCode === 13) {
				this.continuar();
			}
		},
		continuar() {
			this.CHANGE_MESSAGE_MODAL({
				show: false,
				title: "",
				message: "",
				styleMessage: "danger"
			});
		}
	},
	mounted() {
		window.addEventListener("keyup", this.myHandler);
	}
};
</script>

<style scoped>
.background {
	background-color: rgba(0, 0, 0, 0.9);
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dialog-box {
	background: #ffffff;
	width: 333px;
	padding: 40px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.dialog-box i {
	font-size: 100px;
	margin: 0 0 40px 0;
}

.dialog-box.danger i {
	color: #c1345e;
}

.dialog-box.success i {
	color: rgb(18, 146, 18);
}
.dialog-box.info i {
	color: rgb(45, 76, 148);
}

.dialog-box.alert i {
	color: rgb(197, 197, 13);
}

.dialog-box h6 {
	font-family: "Roboto Black";
	font-weight: normal;
	font-size: 24px;
	color: var(--blackColor);
	margin-bottom: 15px;
	line-height: 28px;
}

.dialog-box p {
	white-space: pre-line;
	font-size: 15px;
	font-family: "Roboto";
	color: var(--blackColor);
	margin-bottom: 30px;
}

.btn-modal {
	height: 48px;
	padding: 0 48px;
	color: #ffffff;
	font-family: "Roboto Black";
	font-size: 18px;
	border-radius: 20px;
	border: 0;
	cursor: pointer;
}

.dialog-box.danger .btn-modal {
	background: #c1345e;
}

.dialog-box.success .btn-modal {
	background: rgb(18, 146, 18);
}

.dialog-box.info .btn-modal {
	background: rgb(45, 76, 148);
}

.dialog-box.alert .btn-modal {
	background: rgb(197, 197, 13);
}
</style>
