<template>
	<div>
		<land-page-header ></land-page-header>

		<div class="box-register">

			<h2 :style="{color: parceiroConfigCorSecundariaCorSecundaria}">Precisamos que complete seus dados cadastrais</h2>
			<h3>Você está no {{ nomeParceiro }}, complete os seus dados e comece a ajudar ainda hoje seus clientes a saírem das dívidas</h3>

			<stepper-header
				:currently-step="currentlyStep"
				:steps="steps"></stepper-header>

			<div>
				<login-data
					v-if="currentlyStep == 1"
					:loginData="loginData"
					@update:returnLoginData="loginData = $event"></login-data>

				<company-plan
					v-else
					:companyPlan="companyPlan"
					@update:returnCompanyPlan="companyPlan = $event"></company-plan>
			</div>

			<div class="box-button-register">
				<button-lg
					v-if="currentlyStep > 1"
					:color="corPrimaria"
					@click.native="currentlyStep = currentlyStep != 1 ? (currentlyStep = currentlyStep -= 1) : currentlyStep"
					text="Voltar" ></button-lg>

				<button-lg
					:color="corPrimaria"
					@click.native="nextStep()"
					:full="true"
					:text="currentlyStep == steps.length ? 'Finalizar Cadastro' : 'Próximo'" ></button-lg>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";
import LandPageHeader from "../landPage/components/LandPageHeader";
import StepperHeader from "@/components/StepperHeader";
import LoginData from "@/views/signIn/components/LoginData";
import AdressData from "@/views/signIn/components/AdressData";
import CompanyPlan from "@/views/signIn/components/CompanyPlan";

export default {
	name: "SignIn",
	mixins: [globalMethods, mixins],
	components: { CompanyPlan, AdressData, LoginData, StepperHeader, ButtonLg, FormFields, ButtonDefault, LandPageHeader },
	data() {
		return {
			steps: [{ title: "Dados do Usuário" }, { title: "Plano Empresa" }],
			currentlyStep: 1,
			loginData: {},
			adress: {},
			companyPlan: {},
			identification: "",
			inputsValues: {}
		};
	},

	created() {

		if (this.$route.params.id != undefined) {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Buscando empresas credoras");
			this.action_buscar_empresa_credora({
				codigo: this.$route.params.id

			}).then((response) => {
				this.$root.$refs.LoginData.completaDados(response?.data?.dados?.credores[0]);
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
			});
		}
	},
	methods: {
		...mapActions(["action_buscar_empresa_credora", "action_cadastrar_novo_credor"]),

		nextStep() {
			let canContinue;

			if (this.currentlyStep == 1) {
				canContinue = this.$root.$refs.LoginData.completeRegister();

			} else {
				canContinue = this.$root.$refs.Company.completeRegister();
			}

			if (canContinue) this.currentlyStep = this.currentlyStep != this.steps.length ? (this.currentlyStep += 1) : this.cadastraParceiro();
		},

		cadastraParceiro() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Castrando um novo parceiro");
			this.action_cadastrar_novo_credor({
				...this.loginData,
				...this.companyPlan

			}).then((response) => {
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);

				if (response?.data?.retorno == true) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Cadastrado com sucesso",
						message: `Você já pode acessar 'Acesso Credo' e começar agora a organizar o seu negócio.`,
						styleMessage: "success"
					});

					this.$router.push({ name: "LandPageParceiro" });
					return this.currentlyStep;

				} else {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: response?.data?.mensagem,
						styleMessage: "danger"
					});
					return 1;
				}
			});
		}
	}
};
</script>

<style scoped lang="scss">
.box-register {
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register h2 {
	font-family: Montserrat-Bold;
	color: var(--primaryColor);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3 {
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
}

.box-forms-regiter {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}

.box-button-register {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
	display: flex;
}
</style>
