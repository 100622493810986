<template>
	<div class="box-lgpd">
		<span>
			Nós utilizamos cookies para melhorar a experiência do usuário. Incluindo cookies analiticos e de publicidade. Você pode escolher se deseja ou não ativar esses cookies. Para mais informações, consulte nossa
			<a @click="openTermLGPD()"
				><b><u>Portal da privacidade</u></b></a
			>
		</span>

		<div class="box-buttons">
			<button-default
				text="Aceitar todos cookies"
				@click.native="acceptCookies()" ></button-default>

			<button-default
				:secondary-color="true"
				@click.native="refuseCookies()"
				text="Aceitar cookies obrigatórios (segurança)" ></button-default>

		</div>
	</div>
</template>

<script>
import moment from "moment";
import ButtonDefault from "./buttons/ButtonDefault";

export default {
	name: "AlertLGPD",

	components: { ButtonDefault },

	methods: {

		acceptCookies() {
			localStorage.setItem("acceptCookies", moment().format("DD/MM/YYYY HH:MM:SS"));
			localStorage.setItem("refuseCookies", false);
			this.$emit("accept", true);
			this.$gtm.enable(true);
		},

		refuseCookies() {
			localStorage.setItem("refuseCookies", moment().format("DD/MM/YYYY HH:MM:SS"));
			localStorage.setItem("acceptCookies", false);
			this.$emit("accept", false);
			this.$gtm.enable(false);
		},

		openTermLGPD() {
			let routeData = this.$router.resolve({ name: "PrivacyPortal" });
			window.open(routeData.href, "_blank");
		}
	}
};
</script>

<style scoped>
a {
	cursor: pointer;
}
.box-lgpd {
	padding: 10px 11% 10px 11%;
	width: 100vw;
	min-height: 90px;
	background: #fff;
	position: fixed;
	bottom: 0;
	-webkit-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	-moz-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.9);
	z-index: 999999 !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.box-lgpd span {
	font-family: Roboto;
	padding-right: 10px;
}

.box-buttons {
	width: auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.box-buttons * {
	padding-right: 10px;
}

@media only screen and (max-width: 768px) {
	.box-buttons {
		flex-direction: column;
	}
	.box-buttons button {
		margin: 5px 0;
	}
}
</style>
