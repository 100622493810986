<template>
	<template-post
		title="Atualizar parceiro"
		@close="$router.back()"
		@post="completeRegister">

		<div>

			<form-fields
				@save="completeRegister"
				@update:values="inputsValues = $event"
				:fields="fields" ></form-fields>
		</div>

	</template-post>
</template>

<script>
import { mapActions, mapMutations, createNamespacedHelpers } from "vuex";
import mixins from "@/mixins/mixins";

const { mapActions: mapActionsPassosRetornarDividas } = createNamespacedHelpers('passosRetornarDividas');

import TemplatePost from "@/components/TemplatePost";
import FormFields from "@/components/FormFields";

export default {
	name: "UpdatePartner",

	components: { FormFields, TemplatePost },

	mixins: [mixins],

	data() {
		return {
			fields: [],
			inputsValues: {}
		};
	},

	mounted() {
		this.SET_LOADING(true);
		this.action_buscar_empresas().then((respostaEmpresa) => {
			if (respostaEmpresa) {
				if (this.$route.params.identificador) {
					this.action_buscar_parceiro({ id: this.$route.params.identificador }).then((resposta) => {
						if (resposta) {
							let parceiro = resposta.data.data;
							let header = parceiro.sessoes.find((sessao) => sessao.nome === "header");
							let about = parceiro.sessoes.find((sessao) => sessao.nome === "about");
							let partners = parceiro.sessoes.find((sessao) => sessao.nome === "partners");
							let contact = parceiro.sessoes.find((sessao) => sessao.nome === "contact");
							let tutorial = parceiro.sessoes.find((sessao) => sessao.nome === "tutorial");

							let logoForBackgroundWhite = parceiro.sessoes.find(
								(sessao) => sessao.nome === "logoForBackgroundWhite"
								);

								let customization = parceiro.sessoes.find(
									(sessao) => sessao.nome === "customization"
							);

							let omniChannel = parceiro.sessoes.find((sessao) => sessao.nome === "omniChannel");
							parceiro.sessoes = {
								header: header,
								about: about,
								partners: partners,
								contact: contact,
								tutorial: tutorial,
								logoForBackgroundWhite: logoForBackgroundWhite,
								customization: customization,
								omniChannel: omniChannel
							};

							this.fields = [
								{
									variableName: "nomeParceiro",
									fieldType: "TextField",
									label: "Nome do parceiro",
									type: "text",
									cols: "6",
									valueField: parceiro?.nome
								},
								{
									variableName: "subdominio",
									fieldType: "TextField",
									label: "Nome do subdomínio",
									type: "text",
									cols: "6",
									valueField: parceiro?.identificador
								},
								{
									variableName: "emp_codigo",
									fieldType: "SearchField",
									label: "Selecione a empresa parceira",
									options: respostaEmpresa.data.dados.empresas,
									keyName: "nome_empresa",
									keyValue: "codigo",
									type: "text",
									cols: "12",
									valueField: parceiro?.emp_codigo
								},
								{
									variableName: "hotjarId",
									fieldType: "TextField",
									label: "Hotjar ID",
									type: "text",
									cols: "12",
									valueField: parceiro?.hotjar_id
								},
								{
									variableName: "loj_codigo",
									fieldType: "TextField",
									label: "Código da loja",
									type: "text",
									cols: "12",
									valueField: parceiro?.loj_codigo
								},
								{
									variableName: "loj_codigo_nao_negocia",
									fieldType: "TextField",
									label: "Código das lojas não negociáveis",
									type: "text",
									cols: "12",
									valueField: parceiro?.loj_codigo_nao_negocia
								},
								{
									variableName: "logo",
									fieldType: "ImageField",
									label: "Logotipo do parceiro",
									type: "text",
									cols: "3",
									valueField: parceiro?.logo
								},
								{
									variableName: "logoForBackgroundWhite",
									fieldType: "ImageField",
									label: "Logotipo para fundo branco",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.logoForBackgroundWhite
								},
								{
									variableName: "corPrimaria",
									fieldType: "ColorField",
									label: "Cor primária",
									type: "text",
									cols: "3",
									valueField: parceiro?.estilo?.corPrimaria
								},
								{
									variableName: "corSecundaria",
									fieldType: "ColorField",
									label: "Cor secundária",
									type: "text",
									cols: "3",
									valueField: parceiro?.estilo?.corSecundaria
								},
								{
									variableName: "corFundoMenuDebits",
									fieldType: "ColorField",
									label: "Cor de fundo do lado esquerdo da tela de negociação",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.customization?.campos?.menuDebits?.corFundoMenuDebits
								},
								{
									fieldType: "HeaderLabel",
									label: "Cabeçalho"
								},
								{
									variableName: "imagemFundo",
									fieldType: "ImageField",
									label: "Imagem de fundo",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.header?.arquivos &&
										parceiro?.sessoes?.header?.arquivos.length > 0
											? parceiro?.sessoes?.header?.arquivos[0].arquivo
											: undefined
								},
								{
									variableName: "imagemFundoMobile",
									fieldType: "ImageField",
									label: "Imagem de fundo para disposítivos móveis",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.header?.arquivos &&
										parceiro?.sessoes?.header?.arquivos.length > 1
											? parceiro?.sessoes?.header?.arquivos[1].arquivo
											: undefined
								},
								{
									variableName: "tituloCabecalho",
									fieldType: "TextField",
									label: "Título do cabeçalho",
									type: "text",
									cols: "12",
									valueField: parceiro?.sessoes?.header?.campos?.title
								},
								{
									variableName: "firstTitleColor",
									fieldType: "ColorField",
									label: "Cor da primeira parte do título do cabeçalho",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.header?.campos?.firstTitleColor
								},
								{
									variableName: "secondTitleColor",
									fieldType: "ColorField",
									label: "Cor da segunda parte do título do cabeçalho",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.header?.campos?.secondTitleColor
								},
								{
									variableName: "headerButtonsColor",
									fieldType: "ColorField",
									label: "Cor dos botões superiores do cabeçalho",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.header?.campos?.headerButtonsColor
								},
								{
									variableName: "subtituloCabecalho",
									fieldType: "TextField",
									label: "Subtítulo do cabeçalho",
									type: "text",
									cols: "9",
									valueField: parceiro?.sessoes?.header?.campos?.subtitle
								},
								{
									variableName: "subTitleColor",
									fieldType: "ColorField",
									label: "Cor do subtítulo do cabeçalho",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.header?.campos?.subTitleColor
								},
								{
									fieldType: "HeaderLabel",
									label: "Sobre"
								},
								{
									variableName: "tituloSobre",
									fieldType: "TextField",
									label: "Título",
									type: "text",
									cols: "8",
									valueField: parceiro?.sessoes?.about?.campos?.title
								},
								{
									variableName: "imagemSobre",
									fieldType: "ImageField",
									label: "Imagem da empresa",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.about?.arquivos &&
										parceiro?.sessoes?.about?.arquivos.length > 0
											? parceiro?.sessoes?.about?.arquivos[0].arquivo
											: undefined
								},
								{
									variableName: "textoSobre",
									fieldType: "TextAreaField",
									label: "Texto",
									type: "text",
									cols: "12",
									valueField: parceiro?.sessoes?.about?.campos?.text
								},
								{
									fieldType: "HeaderLabel",
									label: "Passo a passo"
								},
								{
									variableName: "imagemTutorial",
									fieldType: "ImageField",
									label: "Imagem de fundo de passo a passo",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.tutorial?.arquivos &&
										parceiro?.sessoes?.tutorial?.arquivos.length > 0
											? parceiro?.sessoes?.tutorial?.arquivos[0].arquivo
											: undefined
								},
								{
									variableName: "tituloTutorial",
									fieldType: "TextField",
									label: "Título passo a passo",
									type: "text",
									cols: "8",
									valueField: parceiro?.sessoes?.tutorial?.campos?.title
								},
								{
									variableName: "FirstTutorialTitle",
									fieldType: "TextField",
									label: "Título da 1ª etapa",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[0].title
											: undefined
								},
								{
									variableName: "FirstTutorialSubtitle",
									fieldType: "TextField",
									label: "Texto da 1ª etapa",
									type: "text",
									cols: "8",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[0].text
											: undefined
								},
								{
									variableName: "SecondTutorialTitle",
									fieldType: "TextField",
									label: "Título da 2ª etapa",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[1].title
											: undefined
								},
								{
									variableName: "SecondTutorialSubtitle",
									fieldType: "TextField",
									label: "Texto da 2ª etapa",
									type: "text",
									cols: "8",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[1].text
											: undefined
								},
								{
									variableName: "ThirdTutorialTitle",
									fieldType: "TextField",
									label: "Título da 3ª etapa",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[2].title
											: undefined
								},
								{
									variableName: "ThirdTutorialSubtitle",
									fieldType: "TextField",
									label: "Texto da 3ª etapa",
									type: "text",
									cols: "8",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[2].text
											: undefined
								},
								{
									variableName: "FourthTutorialTitle",
									fieldType: "TextField",
									label: "Título da 4ª etapa",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[3].title
											: undefined
								},
								{
									variableName: "FourthTutorialSubtitle",
									fieldType: "TextField",
									label: "Texto da 4ª etapa",
									type: "text",
									cols: "8",
									valueField:
										parceiro?.sessoes?.tutorial?.campos?.steps &&
										parceiro?.sessoes?.tutorial?.campos?.steps.length > 0
											? parceiro?.sessoes?.tutorial?.campos?.steps[3].text
											: undefined
								},
								{
									fieldType: "HeaderLabel",
									label: "Parceiros"
								},
								{
									variableName: "textoParceiros",
									fieldType: "TextAreaField",
									label: "Texto parceiros",
									type: "text",
									cols: "12",
									valueField: parceiro?.sessoes?.partners?.campos?.text
								},
								{
									variableName: "logosParceiros",
									fieldType: "MultipleImagesField",
									label: "LogoTipo parceiro",
									type: "text",
									cols: "12",
									valueField: parceiro?.sessoes?.partners?.arquivos || []
								},
								{
									fieldType: "HeaderLabel",
									label: "Contato"
								},
								{
									variableName: "imagemFundoContato",
									fieldType: "ImageField",
									label: "Imagem de fundo contato",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.contact?.arquivos &&
										parceiro?.sessoes?.contact?.arquivos.length > 0
											? parceiro?.sessoes?.contact?.arquivos[0].arquivo
											: undefined
								},
								{
									variableName: "endereco",
									fieldType: "TextField",
									label: "Endereço",
									type: "text",
									cols: "12",
									valueField: parceiro?.sessoes?.contact?.campos?.address
								},
								{
									variableName: "dadosEmpresa",
									fieldType: "TextField",
									label: "Dados empresa",
									type: "text",
									cols: "12",
									valueField: parceiro?.sessoes.contact?.campos?.dadosEmpresa
								},
								{
									variableName: "facebook",
									fieldType: "TextField",
									label: "Facebook (endereço completo)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.url_facebook
								},
								{
									variableName: "instagram",
									fieldType: "TextField",
									label: "Instagram (endereço completo)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.url_instagram
								},
								{
									variableName: "whatsapp",
									fieldType: "TextFieldMask",
									required: false,
									maskFormat: "(##) #####-####",
									label: "Whatsapp",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.whatsapp
								},
								{
									variableName: "telefone",
									fieldType: "TextFieldMask",
									required: false,
									maskFormat: "(##) ####-####",
									label: "Telefone",
									type: "text",
									cols: "2",
									valueField: parceiro?.sessoes?.contact?.campos?.phone
								},
								{
									variableName: "phoneZeroEightHundred",
									fieldType: "TextFieldMask",
									required: false,
									maskFormat: "####-###-####",
									label: "Telefone 0800",
									type: "text",
									cols: "2",
									valueField: parceiro?.sessoes?.contact?.campos?.phoneZeroEightHundred
								},
								{
									variableName: "email",
									fieldType: "TextField",
									label: "E-mail",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.email
								},
								{
									variableName: "linkedin",
									fieldType: "TextField",
									label: "LinkedIn",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.linkedin
								},
								{
									variableName: "telegram",
									fieldType: "TextField",
									label: "Telegram (inserir nome do usuário)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.telegram
								},
								{
									variableName: "site",
									fieldType: "TextField",
									label: "Site",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.contact?.campos?.site
								},
								{
									fieldType: "HeaderLabel",
									label: "Horário de atendimento"
								},
								{
									variableName: "initialTime",
									fieldType: "TimeField",
									label: "Horário de início (dias úteis)",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.contact?.campos?.initialTime
								},
								{
									variableName: "finalTime",
									fieldType: "TimeField",
									label: "Horário de final (dias úteis)",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.contact?.campos?.finalTime
								},
								{
									variableName: "initialTimeWeekend",
									fieldType: "TimeField",
									label: "Horário de início (fim de semana)",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.contact?.campos?.initialTimeWeekend
								},
								{
									variableName: "finalTimeWeekend",
									fieldType: "TimeField",
									label: "Horário de final (fim de semana)",
									type: "text",
									cols: "3",
									valueField: parceiro?.sessoes?.contact?.campos?.finalTimeWeekend
								},
								{
									fieldType: "HeaderLabel",
									label: "Omni Channel"
								},
								{
									variableName: "whatsappOmni",
									fieldType: "TextFieldMask",
									required: false,
									maskFormat: "(##) #####-####",
									label: "Whatsapp",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.whatsappOmni
								},
								{
									variableName: "whatsappLinkOmni",
									fieldType: "TextField",
									required: false,
									label: "Whatsapp link completo (tem prioridade sobre o número)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.whatsappLinkOmni
								},
								{
									variableName: "instagramOmni",
									fieldType: "TextField",
									label: "Instagram (endereço completo)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.instagramOmni
								},
								{
									variableName: "telegramOmni",
									fieldType: "TextField",
									label: "Telegram (inserir nome do usuário)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.telegramOmni
								},
								{
									variableName: "messengerOmni",
									fieldType: "TextField",
									label: "Messenger (inserir nome da página)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.messengerOmni
								},
								{
									variableName: "skypeOmni",
									fieldType: "TextField",
									label: "Skype (nome do usuário)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.skypeOmni
								},
								{
									variableName: "linkedinOmni",
									fieldType: "TextField",
									label: "Linked in (inserir link completo)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.linkedinOmni
								},
								{
									variableName: "emailOmni",
									fieldType: "TextField",
									label: "E-mail",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.emailOmni
								},
								{
									variableName: "chatOmni",
									fieldType: "TextField",
									label: "Chat (inserir link completo)",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.chatOmni
								},
								{
									variableName: "siteOmni",
									fieldType: "TextField",
									label: "Site",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.siteOmni
								},
								{
									variableName: "flgdiscadoraOmni",
									fieldType: "ToggleToken",
									label: "Possui discadora para o portal?",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.flgdiscadoraOmni
								},
								{
									fieldType: "HeaderLabel",
									label: "Customizações"
								},
								{
									variableName: "textAbaixoLogo",
									fieldType: "TextAreaField",
									label: "Texto do menu abaixo da logo",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.menuDebits &&
										parceiro?.sessoes?.customization?.campos?.menuDebits?.textAbaixoLogo
											? parceiro?.sessoes?.customization?.campos?.menuDebits
													?.textAbaixoLogo
											: "Negocie seus débitos com descontos e volte a ter crédito para comprar novamente!"
								},
								{
									variableName: "textConfirmaAcordo",
									fieldType: "TextAreaField",
									label: "Texto de confirmação de acordo",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits
											.detailsMonthlyPayment &&
										parceiro.sessoes.customization.campos.myDebits.detailsMonthlyPayment
											.textConfirmaAcordo
											? parceiro.sessoes.customization.campos.myDebits
													.detailsMonthlyPayment.textConfirmaAcordo
											: `Ao clicar em ‘Confirmar Acordo’ o cliente CONCORDA com os valores, datas e forma de pagamento apresentados bem como, com as regras de negociação do Credor. O Portal ${parceiro.sessoes.customization.campos.landPage.nomeParceiroContratos} envia diariamente informações de pagamento das parcelas ao Credor, para que o mesmo cumpra com as determinações legais junto aos órgãos de proteção ao crédito.`
								},
								{
									variableName: "textSalvarAcordos",
									fieldType: "TextAreaField",
									label: "Texto de sucesso ao firmar um novo acordo",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.textSalvarAcordos
											? parceiro.sessoes.customization.campos.myDebits
													.textSalvarAcordos
											: ""
								},
								{
									variableName: "textEmailBoleto",
									fieldType: "TextAreaField",
									label:
										"Texto para o e-mail do boleto. (Caso precise de alguma variável, como nome, pergunte para o desenvolvedor se existe alguma equivalente e coloque ela entre chaves.)",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements &&
										parceiro.sessoes.customization.campos.myAgreements.textEmailBoleto
											? parceiro.sessoes.customization.campos.myAgreements
													.textEmailBoleto
											: ""
								},
								{
									variableName: "textBotaoBuscar",
									fieldType: "TextField",
									label: "Texto para o botão de busca na tela inicial",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.landPage &&
										parceiro.sessoes.customization.campos.landPage.textBotaoBuscar
											? parceiro.sessoes.customization.campos.landPage.textBotaoBuscar
											: "Buscar minhas dívidas "
								},
								{
									variableName: "textMyDebits",
									fieldType: "TextField",
									label: "Texto para o botão de abertura da tela Minhas Dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.textMyDebits
											? parceiro.sessoes.customization.campos.myDebits.textMyDebits
											: "Minhas Dívidas"
								},
								{
									variableName: "subtextMyDebits",
									fieldType: "TextField",
									label: "Legenda para o botão de abertura da tela Minhas Dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.subtextMyDebits
											? parceiro.sessoes.customization.campos.myDebits.subtextMyDebits
											: "Você possui dívidas?"
								},
								{
									variableName: "subtextPanelMyDebits",
									fieldType: "TextField",
									label: "Palavra para substituir dívida(s) na tela Minhas Dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.subtextPanelMyDebits
											? parceiro.sessoes.customization.campos.myDebits
													.subtextPanelMyDebits
											: ""
								},
								{
									variableName: "subtextPanelMyDebitsNotFound",
									fieldType: "TextField",
									label: "Legenda para a tela Minhas Dívidas quando não há dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits
											.subtextPanelMyDebitsNotFound
											? parceiro.sessoes.customization.campos.myDebits
													.subtextPanelMyDebitsNotFound
											: ""
								},
								{
									variableName: "textMyAgreements",
									fieldType: "TextField",
									label: "Texto para o botão de abertura da tela Meus Acordos",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements &&
										parceiro.sessoes.customization.campos.myAgreements.textMyAgreements
											? parceiro.sessoes.customization.campos.myAgreements
													.textMyAgreements
											: "Meus Acordos"
								},
								{
									variableName: "subtextMyAgreements",
									fieldType: "TextField",
									label: "Legenda para o botão de abertura da tela Meus Acordos",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements &&
										parceiro.sessoes.customization.campos.myAgreements.subtextMyAgreements
											? parceiro.sessoes.customization.campos.myAgreements
													.subtextMyAgreements
											: "Consulte seus acordos e pagamentos."
								},
								{
									variableName: "subtextPanelMyAgreements",
									fieldType: "TextField",
									label: "Palavra para substituir acordo(s) na tela Meus Acordos",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements &&
										parceiro.sessoes.customization.campos.myAgreements
											.subtextPanelMyAgreements
											? parceiro.sessoes.customization.campos.myAgreements
													.subtextPanelMyAgreements
											: ""
								},
								{
									variableName: "subtextPanelMyAgreementsNotFound",
									fieldType: "TextField",
									label: "Legenda para a tela Meus Acordos quando não há acordos",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements &&
										parceiro.sessoes.customization.campos.myAgreements
											.subtextPanelMyAgreementsNotFound
											? parceiro.sessoes.customization.campos.myAgreements
													.subtextPanelMyAgreementsNotFound
											: ""
								},
								{
									variableName: "textMyDebitsDetails",
									fieldType: "TextField",
									label:
										"Texto para o botão de abertura da tela de Detalhes dentro da Minhas Dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.textMyDebitsDetails
											? parceiro.sessoes.customization.campos.myDebits
													.textMyDebitsDetails
											: "Detalhes"
								},
								{
									variableName: "textMyDebitsCantPay",
									fieldType: "TextField",
									label:
										"Texto para o botão de abertura da tela de Não posso pagar dentro da Minhas Dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.textMyDebitsCantPay
											? parceiro.sessoes.customization.campos.myDebits
													.textMyDebitsCantPay
											: "Não posso pagar agora"
								},
								{
									variableName: "textMyDebitsPaymentOptions",
									fieldType: "TextField",
									label:
										"Texto para o botão de abertura da tela de Opções de pagamentos e propostas dentro da Minhas Dívidas",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits
											.textMyDebitsPaymentOptions
											? parceiro.sessoes.customization.campos.myDebits
													.textMyDebitsPaymentOptions
											: "Opções de parcelamento e propostas"
								},
								{
									variableName: "textMyAgreementsPayment",
									fieldType: "TextField",
									label:
										"Texto para o botão de abertura da tela de Pagamentos dentro dos Meus Acordos",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements &&
										parceiro.sessoes.customization.campos.myAgreements
											.textMyAgreementsPayment
											? parceiro.sessoes.customization.campos.myAgreements
													.textMyAgreementsPayment
											: "Visualizar pagamentos"
								},
								{
									variableName: "textChavePix",
									fieldType: "TextField",
									label: "Chave PIX",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits &&
										parceiro.sessoes.customization.campos.myDebits.textChavePix
											? parceiro.sessoes.customization.campos.myDebits.textChavePix
											: ""
								},
								{
									variableName: "textNomeParceiroContratos",
									fieldType: "TextField",
									label: "Nome Parceiro Contratos",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.landPage &&
										parceiro.sessoes.customization.campos.landPage.textNomeParceiroContratos
											? parceiro.sessoes.customization.campos.landPage.textNomeParceiroContratos
											: ""
								},
								{
									variableName: "flgAcessoCredor",
									fieldType: "ToggleToken",
									label: "Acesso Credor",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.landPage?.flgAcessoCredor
								},
								{
									variableName: "flgAgruparContratos",
									fieldType: "ToggleToken",
									label: "Agrupar Boletos",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits
											?.flgAgruparContratos
								},
								{
									variableName: "flgValorOriginal",
									fieldType: "ToggleToken",
									label: "Valor Original",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits?.flgValorOriginal
								},
								{
									variableName: "flgTermoCofissao",
									fieldType: "ToggleToken",
									label: "Termo Cofissão",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements
											?.flgTermoCofissao
								},
								{
									variableName: "flgReceberLigacao",
									fieldType: "ToggleToken",
									label: "Canais de atendimento ao sair",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.menuDebits
											?.flgReceberLigacao
								},
								{
									variableName: "flgValidarDataNasc",
									fieldType: "ToggleToken",
									label: "Validar Data de Nascimento",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgValidarDataNasc
								},
								{
									variableName: "flgValidarTelefone",
									fieldType: "ToggleToken",
									label: "Validar Telefone",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgValidarTelefone
								},
								{
									variableName: "flgNaoUtilizaLGPD",
									fieldType: "ToggleToken",
									label: "Não validar LGPD",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgNaoUtilizaLGPD
								},
								{
									variableName: "flgValidarToken",
									fieldType: "ToggleToken",
									label: "Validar Token",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgValidarToken
								},
								{
									variableName: "flgTotem",
									fieldType: "ToggleToken",
									label: "Totem",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday?.flgTotem
								},
								{
									variableName: "flgOverlay",
									fieldType: "ToggleToken",
									label: "Desativar overlay",
									type: "text",
									cols: "6",
									valueField: parceiro?.sessoes?.customization?.campos?.landPage?.flgOverlay
								},
								{
									variableName: "flgPagueMe",
									fieldType: "ToggleToken",
									label: "Utiliza o Pague Me",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements?.flgPagueMe
								},
								{
									variableName: "flgDesativarEnviarProposta",
									fieldType: "ToggleToken",
									label: "Desativar o envio de uma proposta",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits
											?.flgDesativarEnviarProposta
								},
								{
									variableName: "flgEsqueceuTelefone",
									fieldType: "ToggleToken",
									label: "Ativar o Esqueceu o telefone",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgEsqueceuTelefone
								},
								{
									variableName: "flgFaleConosco",
									fieldType: "ToggleToken",
									label: "Ativar o Fale Conosco",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.menuDebits?.flgFaleConosco
								},
								{
									variableName: "flgDesativarCartaoCredito",
									fieldType: "ToggleToken",
									label: "Desativar Cartão Credito",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits
											?.flgDesativarCartaoCredito
								},
								{
									variableName: "flgDesativarPix",
									fieldType: "ToggleToken",
									label: "Desativar PIX",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits
											?.flgDesativarPix
								},
								{
									variableName: "flgAtivarEscolhaDoVencimento",
									fieldType: "ToggleToken",
									label: "Ativar a escolha da data de vencimento",
									type: "text",
									cols: "4",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myDebits
											?.flgAtivarEscolhaDoVencimento
								},
								{
									variableName: "flgModoServico",
									fieldType: "ToggleToken",
									label: "Ativar Modo Serviço",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.landPage?.flgModoServico
								},
								{
									variableName: "flgIncluirHistorico",
									fieldType: "ToggleToken",
									label: "Ativar Histórico de Acordos",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements
											?.flgIncluirHistorico
								},
								{
									variableName: "flgPaginar",
									fieldType: "ToggleToken",
									label: "Ativar Paginação",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.myAgreements
											?.flgPaginar
								},
								{
									variableName: "flgDesativaCadastro",
									fieldType: "ToggleToken",
									label: "Desativa o Cadastro",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgDesativaCadastro
								},
								{
									variableName: "flgPrefeituraMode",
									fieldType: "ToggleToken",
									label: "Modo Prefeitura",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.confirmBirthday
											?.flgPrefeituraMode
								},
								{
									variableName: "flgStretch",
									fieldType: "ToggleToken",
									label: "Fundo esticado",
									type: "text",
									cols: "6",
									valueField: parceiro?.sessoes?.customization?.campos?.landPage?.flgStretch
								},
								{
									variableName: "flgIntegraRobo",
									fieldType: "ToggleToken",
									label: "Atalho para o robô",
									type: "text",
									cols: "6",
									valueField:
										parceiro?.sessoes?.customization?.campos?.landPage?.flgIntegraRobo
								},
								{
									variableName: "flgMaxWidth",
									fieldType: "ToggleToken",
									label: "Aumentar o logo da home",
									type: "text",
									cols: "6",
									valueField: parceiro?.sessoes?.customization?.campos?.landPage?.flgMaxWidth
								},
								{
									variableName: "flgAgendarChamada",
									fieldType: "ToggleToken",
									label: "Agendar chamada",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.omniChannel?.campos?.scheduleCall
								},
								{
									variableName: "flgDesativatBotaoNaoPossoPagar",
									fieldType: "ToggleToken",
									label: "Desativar Botao Não Posso Pagar",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.customization?.campos?.myDebits?.flgDesativatBotaoNaoPossoPagar
								},
								{
									variableName: "flgPortalUsaStage",
									fieldType: "ToggleToken",
									label: "Portal usa Stage para calcular",
									type: "text",
									cols: "4",
									valueField: parceiro?.sessoes?.customization?.campos?.myDebits?.flgPortalUsaStage
								}
							];
							this.SET_LOADING(false);

						} else {
							this.SET_LOADING(false);
							this.$router.push({ name: "ListPartners" });
						}
					});

				} else {
					this.SET_LOADING(false);
					this.$router.push({ name: "ListPartners" });
				}
			}
		}).catch((_) => {
			this.SET_LOADING(false);
			this.CHANGE_MESSAGE_MODAL({
				show: true,
				title: "Ocorreu um erro",
				message: `Empresas não encontradas`,
				styleMessage: "danger"
			});
		});
	},

	methods: {
		...mapMutations(["SET_LOADING", "CHANGE_MESSAGE_SNACKBAR", "CHANGE_MESSAGE_MODAL"]),

		...mapActions(["action_buscar_parceiro", "action_editar_parceiro", "action_buscar_empresas"]),

		...mapActionsPassosRetornarDividas(["acaoRetornarTokenEmpresa"]),

		async completeRegister() {
			let {
				nomeParceiro,
				hotjarId,
				subdominio,
				emp_codigo,
				loj_codigo,
				loj_codigo_nao_negocia,
				logo,
				logoForBackgroundWhite,
				corPrimaria,
				corSecundaria,
				corFundoMenuDebits,
				imagemFundo,
				imagemFundoMobile,
				tituloCabecalho,
				subtituloCabecalho,
				tituloSobre,
				imagemSobre,
				textoSobre,
				logosParceiros,
				textoParceiros,
				imagemFundoContato,
				endereco,
				dadosEmpresa,
				whatsapp,
				facebook,
				instagram,
				telefone,
				phoneZeroEightHundred,
				email,
				linkedin,
				telegram,
				site,
				whatsappOmni,
				whatsappLinkOmni,
				instagramOmni,
				telegramOmni,
				messengerOmni,
				skypeOmni,
				linkedinOmni,
				flgdiscadoraOmni = false,
				emailOmni,
				chatOmni,
				siteOmni,
				imagemTutorial,
				tituloTutorial,
				FirstTutorialTitle,
				FirstTutorialSubtitle,
				SecondTutorialTitle,
				SecondTutorialSubtitle,
				ThirdTutorialTitle,
				ThirdTutorialSubtitle,
				FourthTutorialTitle,
				FourthTutorialSubtitle,
				textAbaixoLogo,
				textConfirmaAcordo,
				textSalvarAcordos,
				textBotaoBuscar,
				textMyDebits,
				textChavePix,
				textNomeParceiroContratos,
				subtextMyDebits,
				textMyAgreements,
				textEmailBoleto,
				subtextMyAgreements,
				subtextPanelMyDebits,
				subtextPanelMyAgreements,
				subtextPanelMyDebitsNotFound,
				subtextPanelMyAgreementsNotFound,
				textMyDebitsDetails,
				textMyDebitsCantPay,
				textMyDebitsPaymentOptions,
				textMyAgreementsPayment,
				flgAcessoCredor,
				flgAgruparContratos,
				flgValorOriginal,
				flgValidarDataNasc,
				flgValidarTelefone,
				flgNaoUtilizaLGPD,
				flgTermoCofissao,
				flgValidarToken,
				flgReceberLigacao,
				flgTotem,
				flgOverlay,
				flgPagueMe,
				flgDesativarEnviarProposta,
				flgEsqueceuTelefone,
				flgFaleConosco,
				flgDesativarCartaoCredito,
				flgDesativarPix,
				flgAtivarEscolhaDoVencimento,
				flgModoServico,
				flgIncluirHistorico,
				flgPaginar,
				flgDesativaCadastro,
				flgPrefeituraMode,
				flgStretch,
				flgIntegraRobo,
				flgMaxWidth,
				firstTitleColor,
				secondTitleColor,
				headerButtonsColor,
				subTitleColor,
				initialTime,
				finalTime,
				initialTimeWeekend,
				finalTimeWeekend,
				flgDesativatBotaoNaoPossoPagar,
				flgPortalUsaStage
			} = this.inputsValues;

			if (!nomeParceiro || nomeParceiro.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo nome do parceiro é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!subdominio || subdominio.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo nome do subdomínio é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!logo || logo.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo do logotipo do parceiro é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!corPrimaria || corPrimaria.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo cor primária é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!corSecundaria || corSecundaria.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo cor sercundária é obrigatório`,
					styleMessage: "danger"
				});

			} else if (
				(imagemSobre && imagemSobre?.base64 && !tituloSobre.length > 0 && textoSobre.length > 0) ||
				(imagemSobre && imagemSobre?.base64 && tituloSobre.length > 0 && !textoSobre.length > 0) ||
				(!imagemSobre && tituloSobre.length > 0 && textoSobre.length > 0) ||
				(!imagemSobre && !tituloSobre.length > 0 && textoSobre.length > 0) ||
				(!imagemSobre && tituloSobre.length > 0 && !textoSobre.length > 0) ||
				(imagemSobre && !tituloSobre.length > 0 && !textoSobre.length > 0)
			) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o Sobre deve ser inserido a imagem da empresa, o título e o texto do sobre.`,
					styleMessage: "danger"
				});

			} else if ((initialTime != "" && finalTime == "") || (initialTime == "" && finalTime != "")) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos dias úteis, deve ser inserido o horário inicial e o horário final.`,
					styleMessage: "danger"
				});

			} else if (
				(initialTimeWeekend != "" && finalTimeWeekend == "") ||
				(initialTimeWeekend == "" && finalTimeWeekend != "")
			) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos fins de semana, deve ser inserido o horário inicial e o horário final.`,
					styleMessage: "danger"
				});

			} else if (initialTime != "" && finalTime != "" && initialTime > finalTime) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos dias úteis, o horário inicial deve ser menor que o horário final.`,
					styleMessage: "danger"
				});

			} else if (initialTimeWeekend != "" && finalTimeWeekend != "" && (initialTime == "" || finalTime == "")) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos fins de semana, primeiro deve ser registrado os horários de funcionamento nos dias úteis.`,
					styleMessage: "danger"
				});

			} else if (initialTimeWeekend != "" && finalTimeWeekend != "" && initialTimeWeekend > finalTimeWeekend) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos fins de semana, o horário inicial deve ser menor que o horário final.`,
					styleMessage: "danger"
				});

			} else {
				this.SET_LOADING(true);
				let header = null;
				let about = null;
				let tutorial = null;
				let partners = null;
				let contact = null;
				let omniChannel = null;
				let customization = null;

				if (
					(imagemFundo && imagemFundo?.base64) ||
					(imagemFundoMobile && imagemFundoMobile?.base64) ||
					subtituloCabecalho ||
					tituloCabecalho
				) {
					let images = [];

					if (imagemFundo?.base64) {
						images.push({
							identificador: 1,
							tipoImagem: imagemFundo.format,
							arquivo: imagemFundo.base64,
							adicionais: {
								name: imagemFundo.name
							}
						});
					}

					if (imagemFundoMobile?.base64) {
						images.push({
							identificador: 2,
							tipoImagem: imagemFundoMobile.format,
							arquivo: imagemFundoMobile.base64,
							adicionais: {
								name: imagemFundoMobile.name
							}
						});
					}

					header = {
						images,
						background: 1,
						title: tituloCabecalho || null,
						firstTitleColor: firstTitleColor || null,
						secondTitleColor: secondTitleColor || null,
						headerButtonsColor: headerButtonsColor || null,
						subtitle: subtituloCabecalho || null,
						subTitleColor: subTitleColor || null
					};
				}

				if (imagemSobre && imagemSobre?.base64 && tituloSobre.length > 0 && textoSobre.length > 0) {
					about = {
						title: tituloSobre || null,
						text: textoSobre || null,
						image: 1,
						images: imagemSobre?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemSobre.format,
										arquivo: imagemSobre.base64
									}
							  ]
							: []
					};
				}

				if (imagemTutorial && imagemTutorial?.base64) {
					tutorial = {
						title: tituloTutorial ?? "",
						steps: [
							{ code: 0, title: FirstTutorialTitle, text: FirstTutorialSubtitle },
							{ code: 1, title: SecondTutorialTitle, text: SecondTutorialSubtitle },
							{ code: 2, title: ThirdTutorialTitle, text: ThirdTutorialSubtitle },
							{ code: 3, title: FourthTutorialTitle, text: FourthTutorialSubtitle }
						],
						images: imagemTutorial?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemTutorial.format,
										arquivo: imagemTutorial.base64
									}
							  ]
							: []
					};
				}

				if (textoParceiros || logosParceiros) {
					partners = {
						text: textoParceiros || null,
						images: logosParceiros || null
					};
				}

				if (
					imagemFundoContato?.base64 ||
					whatsapp ||
					telefone ||
					email ||
					facebook ||
					instagram ||
					endereco ||
					dadosEmpresa ||
					phoneZeroEightHundred ||
					site ||
					linkedin ||
					telegram ||
					initialTime ||
					finalTime ||
					initialTimeWeekend ||
					finalTimeWeekend
				) {
					contact = {
						phone: telefone,
						phoneZeroEightHundred: phoneZeroEightHundred,
						whatsapp: whatsapp,
						email: email,
						address: endereco,
						dadosEmpresa: dadosEmpresa,
						site: site,
						linkedin: linkedin,
						telegram: telegram,
						url_facebook: facebook,
						url_instagram: instagram,
						initialTime: initialTime,
						finalTime: finalTime,
						initialTimeWeekend: initialTimeWeekend,
						finalTimeWeekend: finalTimeWeekend,
						image: 1,
						images: imagemFundoContato?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemFundoContato.format,
										arquivo: imagemFundoContato.base64
									}
							  ]
							: []
					};
				}

				if (
					whatsappOmni ||
					whatsappLinkOmni ||
					instagramOmni ||
					telegramOmni ||
					messengerOmni ||
					skypeOmni ||
					linkedinOmni ||
					flgdiscadoraOmni ||
					emailOmni ||
					chatOmni ||
					siteOmni
				) {
					omniChannel = {
						whatsappOmni: whatsappOmni,
						whatsappLinkOmni: whatsappLinkOmni,
						instagramOmni: instagramOmni,
						telegramOmni: telegramOmni,
						messengerOmni: messengerOmni,
						skypeOmni: skypeOmni,
						linkedinOmni: linkedinOmni,
						flgdiscadoraOmni: flgdiscadoraOmni,
						emailOmni: emailOmni,
						chatOmni: chatOmni,
						siteOmni: siteOmni
					};
				}

				customization = {
					myDebits: {
						flgValorOriginal: flgValorOriginal,
						flgAgruparContratos: flgAgruparContratos,
						flgDesativarEnviarProposta: flgDesativarEnviarProposta,
						textSalvarAcordos: textSalvarAcordos,
						textMyDebits: textMyDebits,
						subtextMyDebits: subtextMyDebits,
						subtextPanelMyDebits: subtextPanelMyDebits,
						subtextPanelMyDebitsNotFound: subtextPanelMyDebitsNotFound,
						textMyDebitsDetails: textMyDebitsDetails,
						textMyDebitsCantPay: textMyDebitsCantPay,
						textMyDebitsPaymentOptions: textMyDebitsPaymentOptions,
						textChavePix: textChavePix,
						detailsMonthlyPayment: {
							textConfirmaAcordo: textConfirmaAcordo
						},
						flgDesativarCartaoCredito: flgDesativarCartaoCredito,
						flgDesativarPix: flgDesativarPix,
						flgAtivarEscolhaDoVencimento: flgAtivarEscolhaDoVencimento,
						flgDesativatBotaoNaoPossoPagar: flgDesativatBotaoNaoPossoPagar,
						flgPortalUsaStage: flgPortalUsaStage
					},
					myAgreements: {
						textMyAgreements: textMyAgreements,
						textEmailBoleto: textEmailBoleto,
						subtextMyAgreements: subtextMyAgreements,
						subtextPanelMyAgreements: subtextPanelMyAgreements,
						subtextPanelMyAgreementsNotFound: subtextPanelMyAgreementsNotFound,
						textMyAgreementsPayment: textMyAgreementsPayment,
						flgTermoCofissao: flgTermoCofissao,
						flgPagueMe: flgPagueMe,
						flgIncluirHistorico: flgIncluirHistorico,
						flgPaginar: flgPaginar
					},
					confirmBirthday: {
						flgValidarDataNasc: flgValidarDataNasc,
						flgValidarTelefone: flgValidarTelefone,
						flgNaoUtilizaLGPD: flgNaoUtilizaLGPD,
						flgValidarToken: flgValidarToken,
						flgTotem: flgTotem,
						flgEsqueceuTelefone: flgEsqueceuTelefone,
						flgDesativaCadastro: flgDesativaCadastro
					},
					menuDebits: {
						textAbaixoLogo: textAbaixoLogo,
						flgReceberLigacao: flgReceberLigacao,
						flgFaleConosco: flgFaleConosco,
						flgPrefeituraMode: flgPrefeituraMode,
						corFundoMenuDebits: corFundoMenuDebits
					},
					landPage: {
						textBotaoBuscar: textBotaoBuscar,
						textNomeParceiroContratos: textNomeParceiroContratos,
						flgAcessoCredor: flgAcessoCredor,
						flgOverlay: flgOverlay,
						flgModoServico: flgModoServico,
						flgStretch: flgStretch,
						flgIntegraRobo: flgIntegraRobo,
						flgMaxWidth: flgMaxWidth
					}
				};

				let obj = {
					estilo: {
						corPrimaria: corPrimaria,
						corSecundaria: corSecundaria
					},
					nome: nomeParceiro,
					logo: logo.base64,
					identificador: subdominio,
					emp_codigo: emp_codigo,
					hotjar_id: hotjarId,
					loj_codigo: loj_codigo,
					loj_codigo_nao_negocia: loj_codigo_nao_negocia,
					tipoLogo: logo.format,
					sessoes: {
						logoForBackgroundWhite: logoForBackgroundWhite.base64,
						header: header,
						about: about,
						partners: partners,
						contact: contact,
						tutorial: tutorial,
						customization: customization,
						omniChannel: omniChannel
					}
				};

				this.action_editar_parceiro({ ...obj, id: this.$route.params.id }).then((resposta) => {
					if (resposta) {
						this.$router.push({ name: "ListPartners" });
						this.CHANGE_MESSAGE_SNACKBAR("Parceiro atualizado com sucesso!");
						setTimeout(() => this.CHANGE_MESSAGE_SNACKBAR(""), 5000);
						this.SET_LOADING(false);
					} else {
						this.SET_LOADING(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Ocorreu um erro",
							message: `Não foi possivel atualizar este parceiro`,
							styleMessage: "danger"
						});
					}
				}).catch((_) => {
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Não foi possivel atualizar este parceiro`,
						styleMessage: "danger"
					});
				});
			}
		}
	}
};
</script>

<style scoped></style>
