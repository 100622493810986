<template>
	<div class="template-post">
		<h3>{{ title }}</h3>
		<div>
			<slot ></slot>
		</div>

		<div class="rows-buttons">

			<button-default
				style="margin-right: 10px; width: 150px"
				@click.native="$emit('post')"
				text="Salvar" ></button-default>

			<button-default
				style="width: 150px"
				:secondary-color="true"
				@click.native="$emit('close')"
				text="Cancelar" ></button-default>
		</div>
	</div>
</template>

<script>
import ButtonDefault from "./buttons/ButtonDefault";

export default {
	name: "TemplatePost",

	components: { ButtonDefault },

	props: {
		title: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped>
.template-post h3 {
	font-size: 24px;
	font-family: "Roboto Black";
	color: #050b17;
	margin-bottom: 50px;
}

.rows-buttons {
	display: flex;
	justify-content: flex-end;
	text-align: right;
	margin-top: 50px;
}
</style>
