<template>
	<div class="accordionTabs">

		<div
			class="accordionRow bg-left-bottom"
			:style="style"
			@click="acessarSubmenu('MyDebits', 5)">

			<div class="avatarAccordion">
				<i class="fas fa-dollar-sign" ></i>
			</div>

			<div class="infoAccordion">
				<h4>{{ tituloMinhasDividas }}</h4>
				<h5>{{ subTituloMinhasDividas }}</h5>
			</div>
		</div>

		<div
			class="accordionRow bg-center-center"
			:style="style"
			@click="acessarSubmenu('MyAgreements', 3)">

			<div class="avatarAccordion">
				<i class="fas fa-handshake" ></i>
			</div>

			<div class="infoAccordion">
				<h4>{{ tituloMeusAcordos }}</h4>
				<h5>{{ subTituloMeusAcordos }}</h5>
			</div>

		</div>

		<div
			v-if="mostrarFaleConosco"
			class="accordionRow bg-right-top"
			:style="style"
			@click="acessarSubmenu('TalkToUs', 40)">

			<div class="avatarAccordion">
				<i class="fas fa-comment-dots" ></i>
			</div>

			<div class="infoAccordion">
				<h4>Fale conosco</h4>
				<h5>Entre em contato com a gente escolhendo um dos canais.</h5>
			</div>

		</div>

		<div
			v-if="!portalCamec"
			class="accordionRow bg-right-top"
			:style="style"
			@click="acessarSubmenu('FAQ', 6)">

			<div class="avatarAccordion">
				<i class="fas fa-question-circle" ></i>
			</div>

			<div class="infoAccordion">
				<h4>Dúvidas</h4>
				<h5>Possui alguma dúvida ?</h5>
			</div>

		</div>

		<div
			class="accordionRow bg-left-top"
			:style="style"
			@click="sair">

			<div class="avatarAccordion">
				<i
					class="fas fa-times"
					:style="tamanhoFonteCamec"></i>
			</div>

			<div class="infoAccordion">
				<h4 :style="tamanhoFonteCamec">Sair</h4>
			</div>

		</div>

		<receive-call
			v-show="receberLigacao"
			:saindo="true" ></receive-call>

	</div>
</template>

<script>
import mixins from "@/mixins/mixins";

import { mapMutations, mapState, createNamespacedHelpers } from "vuex";

import ReceiveCall from "./MyDebits/components/ReceiveCall.vue";

const { mapGetters: mapGettersPassosRetornarDividas } = createNamespacedHelpers("passosRetornarDividas");

export default {
	name: "AccordionTabs",

	components: { ReceiveCall },

	mixins: [mixins],

	data() {
		return {
			style: {
				"--color": null,
				"--hoverColor": null
			}
		};
	},

	computed: {
		...mapState({
			receberLigacao: (estado) => estado.receberLigacao,
			portalCamec: (estado) => estado.portalCamec,
			lojas: (estado) => estado.passosRetornarDividas.lojas
		}),

		...mapGettersPassosRetornarDividas(["getPesCpf", "getFezAcordoPorPortal"]),

		tituloMinhasDividas() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebits ?
					this.parceiroConfig.sessoes.customization.campos.myDebits.textMyDebits : "Minhas Dívidas";
		},

		subTituloMinhasDividas() {
			return this.parceiroConfig.sessoes.customization.campos.myDebits.subtextMyDebits ?
					this.parceiroConfig.sessoes.customization.campos.myDebits.subtextMyDebits : "Você possui dívidas?";
		},

		tituloMeusAcordos() {
			return this.parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreements ?
					this.parceiroConfig.sessoes.customization.campos.myAgreements.textMyAgreements : "Meus Acordos";
		},

		subTituloMeusAcordos() {
			return this.parceiroConfig.sessoes.customization.campos.myAgreements.subtextMyAgreements ?
					this.parceiroConfig.sessoes.customization.campos.myAgreements.subtextMyAgreements : "Consulte seus acordos e pagamentos.";
		},

		mostrarFaleConosco() {
			return this.parceiroConfig.sessoes.omniChannel && this.parceiroConfig.sessoes.omniChannel.campos && (this.parceiroConfig.sessoes.customization == undefined ||
				this.parceiroConfig.sessoes.customization.campos.menuDebits == undefined || this.parceiroConfig.sessoes.customization.campos.menuDebits.flgFaleConosco);
		},

		tamanhoFonteCamec() {
			if (this.parceiroConfig.emp_codigo == 350) {
				return {'font-size': '26px'};
			}
		}
	},

	watch: {
		parceiroConfig() {
			this.style = {
				"--color": this.parceiro ? this.parceiroConfig.estilo.corSecundaria : "#003e6c",
				"--hoverColor": this.parceiro ? this.parceiroConfig.estilo.corSecundaria : "#003e6c"
			};
		}
	},

	mounted() {
		if (this.getPesCpf == "") {
			this.$router.push({ name: "LandPageParceiro" });
		}

		this.style = {
			"--color": this.parceiro ? this.parceiroConfig.estilo.corSecundaria : "#003e6c",
			"--hoverColor": this.parceiro ? this.parceiroConfig.estilo.corSecundaria : "#003e6c"
		};

		this.acaoSalvarEtapa({
			cpfCnpj: this.getPesCpf,
			esn_fk_ets_codigo: 41,
			emp_codigo: this.parceiroConfig.emp_codigo,
			motivo_agendamento: 'Menu Principal',
			data_agendamento: this.dataAtual,
			hora_agendamento: this.horaAtual
		});

		this.SET_MOTIVO_RECEBER_LIGACAO("Saída");
	},

	methods: {
		...mapMutations(["SET_RECEBER_LIGACAO", "SET_MOTIVO_RECEBER_LIGACAO"]),

		acessarSubmenu(submenu, ets_codigo = "") {
			this.acaoSalvarEtapa({
				cpfCnpj: this.getPesCpf,
				esn_fk_ets_codigo: ets_codigo,
				emp_codigo: this.parceiroConfig.emp_codigo,
				motivo_agendamento: `Acessou o submenu ${submenu}`,
				data_agendamento: this.dataAtual,
				hora_agendamento: this.horaAtual
			});
			this.$router.push({ name: submenu });
		},

		sair() {
			if (this.parceiroConfig?.sessoes?.customization?.campos?.menuDebits?.flgReceberLigacao && !this.getFezAcordoPorPortal) {
				this.SET_RECEBER_LIGACAO(true);

			} else {
				this.$router.push({ name: "LandPageParceiro" });
			}
		}
	}
};
</script>

<style scoped lang="scss">
.accordionTabs {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #f5f7f9;
	justify-content: space-evenly;
	align-items: center;

	@media only screen and (max-width: 1269px) {
		height: 85vh;
		min-height: auto;
	}
}

.accordionRow {
	box-sizing: border-box;
	width: 100%;
	background: var(--whiteColor);
	display: flex;
	flex: 1;
	margin: 0.5px 0;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-left: 3vw;
	transition: 0.3s;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);

	@media only screen and (max-width: 1269px) {
		padding-left: 45px;
	}
}

.accordionRow:hover {
	background: var(--hoverColor);
	cursor: pointer;
}

.accordionRow:hover i,
.accordionRow:hover h4,
.accordionRow:hover h5 {
	color: var(--whiteColor);
}

.avatarAccordion {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 1269px) {
		width: 50px;
		height: 50px;
		border-radius: 100%;
	}
}

.avatarAccordion i {
	font-size: 48px;
	color: var(--color);
}

.infoAccordion {
	@media only screen and (max-width: 1269px) {
		padding-left: 20px;
	}
}

.infoAccordion h4 {
	color: var(--color);
	font-size: 32px;
	font-family: Montserrat-Bold;

	@media only screen and (max-width: 1269px) {
		font-size: 24px;
	}
}

.infoAccordion h5 {
	color: #969191;
	font-size: 16px;
	font-family: Montserrat-Regular;

	@media only screen and (max-width: 1269px) {
		font-size: 14px;
	}
}

.bg-left-bottom {
	background-position: left bottom;
}

.bg-center-center {
	background-position: center center;
}

.bg-right-top {
	background-position: right top;
}

.bg-left-top {
	background-position: left top;
}</style>
