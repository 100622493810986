<template>

	<template-post
		title="Alterar senha"
		@close="$router.push({ name: 'ListPartners' })"
		@post="completeRegister">

		<div>
			<form-fields
				:fields="fields"
				@save="completeRegister"
				@update:values="inputsValues = $event" ></form-fields>

		</div>
	</template-post>
</template>

<script>
import { mapActions, mapMutations } from "vuex"

import TemplatePost from "@/components/TemplatePost"
import FormFields from "@/components/FormFields"

export default {
	name: "ResetPassword",

	components: { FormFields, TemplatePost },

	data() {
		return {
			fields: [],
			inputsValues: {}
		}
	},

	mounted() {
		this.fields = [
			{
				variableName: "oldPassword",
				fieldType: "TextField",
				label: "Senha atual",
				type: "password",
				cols: "4"
			},
			{
				variableName: "newPassword",
				fieldType: "TextField",
				label: "Nova senha",
				type: "password",
				cols: "4"
			},
			{
				variableName: "confirmNewPassord",
				fieldType: "TextField",
				label: "Confirmar nova senha",
				type: "password",
				cols: "4"
			}
		];
	},

	methods: {
		...mapMutations(["CHANGE_MESSAGE_MODAL", "SET_LOADING"]),

		...mapActions(["action_alterar_senha"]),

		completeRegister() {
			let { oldPassword, newPassword, confirmNewPassord } = this.inputsValues;

			if (!oldPassword || oldPassword.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo senha atual é obrigatória`,
					styleMessage: "danger"
				});

			} else if (!newPassword || newPassword.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo nova senha é obrigatória`,
					styleMessage: "danger"
				});

			} else if (!confirmNewPassord || confirmNewPassord.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo confirmar nova senha é obrigatória`,
					styleMessage: "danger"
				});

			} else {
				this.SET_LOADING(true);
				let obj = {
					old_password: oldPassword,
					password: newPassword,
					password_confirmation: confirmNewPassord
				}

				this.action_alterar_senha(obj).then(resposta => {

					this.SET_LOADING(false)
					if (resposta) {
						this.$router.push({ name: "ListPartners" });
					}
				}).catch((_) => {
					this.SET_LOADING(false);
				})
			}
		}
	}
}
</script>
