<template>

	<template-post
		title="Cadastrar novo parceiro"
		@close="$router.back()"
		@post="completeRegister">

		<div>

			<form-fields
				@save="completeRegister"
				@update:values="inputsValues = $event"
				:fields="fields" ></form-fields>
		</div>

</template-post>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import mixins from "@/mixins/mixins";

import TemplatePost from "@/components/TemplatePost";
import FormFields from "@/components/FormFields";

export default {
	name: "PostPartners",

	components: { FormFields, TemplatePost },

	mixins: [mixins],

	data() {
		return {
			fields: [],
			inputsValues: {}
		};
	},

	mounted() {
		this.SET_LOADING(true);

		this.action_buscar_empresas().then((resposta) => {
			if (resposta) {
				this.fields = [
					{
						variableName: "nomeParceiro",
						fieldType: "TextField",
						label: "Nome do parceiro",
						type: "text",
						cols: "6"
					},
					{
						variableName: "subdominio",
						fieldType: "TextField",
						label: "Nome do subdomínio",
						type: "text",
						cols: "6"
					},
					{
						variableName: "emp_codigo",
						fieldType: "SearchField",
						label: "Selecione a empresa parceira",
						options: resposta.data.dados.empresas,
						keyName: "nome_empresa",
						keyValue: "codigo",
						type: "text",
						cols: "12"
					},
					{
						variableName: "hotjarId",
						fieldType: "TextField",
						label: "Hotjar ID",
						type: "text",
						cols: "12"
					},
					{
						variableName: "logo",
						fieldType: "ImageField",
						label: "Logotipo do parceiro",
						type: "text",
						cols: "3"
					},
					{
						variableName: "logoForBackgroundWhite",
						fieldType: "ImageField",
						label: "Logotipo para fundo branco",
						type: "text",
						cols: "3"
					},
					{
						variableName: "corPrimaria",
						fieldType: "ColorField",
						label: "Cor primária",
						type: "text",
						cols: "3"
					},
					{
						variableName: "corSecundaria",
						fieldType: "ColorField",
						label: "Cor secundária",
						type: "text",
						cols: "3"
					},
					{
						fieldType: "HeaderLabel",
						label: "Cabeçalho"
					},
					{
						variableName: "imagemFundo",
						fieldType: "ImageField",
						label: "Imagem de fundo",
						type: "text",
						cols: "6"
					},
					{
						variableName: "imagemFundoMobile",
						fieldType: "ImageField",
						label: "Imagem de fundo para disposítivos móveis",
						type: "text",
						cols: "6"
					},
					{
						variableName: "tituloCabecalho",
						fieldType: "TextField",
						label: "Título do cabeçalho",
						type: "text",
						cols: "12",
						valueField: "Negocie aqui suas dívidas"
					},
					{
						variableName: "firstTitleColor",
						fieldType: "ColorField",
						label: "Cor da primeira parte do título do cabeçalho",
						type: "text",
						cols: "4"
					},
					{
						variableName: "secondTitleColor",
						fieldType: "ColorField",
						label: "Cor da segunda parte do título do cabeçalho",
						type: "text",
						cols: "4"
					},
					{
						variableName: "headerButtonsColor",
						fieldType: "ColorField",
						label: "Cor ddos botões superiores do cabeçalho",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtituloCabecalho",
						fieldType: "TextField",
						label: "Subtítulo do cabeçalho",
						type: "text",
						cols: "9",
						valueField: "É seguro, rápido e online!"
					},
					{
						variableName: "subTitleColor",
						fieldType: "ColorField",
						label: "Cor do subtítulo do cabeçalho",
						type: "text",
						cols: "3"
					},
					{
						fieldType: "HeaderLabel",
						label: "Sobre"
					},
					{
						variableName: "tituloSobre",
						fieldType: "TextField",
						label: "Título",
						type: "text",
						cols: "6"
					},
					{
						variableName: "imagemSobre",
						fieldType: "ImageField",
						label: "Imagem da empresa",
						type: "text",
						cols: "6"
					},
					{
						variableName: "textoSobre",
						fieldType: "TextAreaField",
						label: "Texto",
						type: "text",
						cols: "12"
					},
					{
						fieldType: "HeaderLabel",
						label: "Passo a passo"
					},
					{
						variableName: "imagemTutorial",
						fieldType: "ImageField",
						label: "Imagem de fundo de passo a passo",
						type: "text",
						cols: "4"
					},
					{
						variableName: "tituloTutorial",
						fieldType: "TextField",
						label: "Título passo a passo",
						type: "text",
						cols: "8"
					},
					{
						variableName: "FirstTutorialTitle",
						fieldType: "TextField",
						label: "Título da 1ª etapa",
						type: "text",
						cols: "4"
					},
					{
						variableName: "FirstTutorialSubtitle",
						fieldType: "TextField",
						label: "Texto da 1ª etapa",
						type: "text",
						cols: "8"
					},
					{
						variableName: "SecondTutorialTitle",
						fieldType: "TextField",
						label: "Título da 2ª etapa",
						type: "text",
						cols: "4"
					},
					{
						variableName: "SecondTutorialSubtitle",
						fieldType: "TextField",
						label: "Texto da 2ª etapa",
						type: "text",
						cols: "8"
					},
					{
						variableName: "ThirdTutorialTitle",
						fieldType: "TextField",
						label: "Título da 3ª etapa",
						type: "text",
						cols: "4"
					},
					{
						variableName: "ThirdTutorialSubtitle",
						fieldType: "TextField",
						label: "Texto da 3ª etapa",
						type: "text",
						cols: "8"
					},
					{
						variableName: "FourthTutorialTitle",
						fieldType: "TextField",
						label: "Título da 4ª etapa",
						type: "text",
						cols: "4"
					},
					{
						variableName: "FourthTutorialSubtitle",
						fieldType: "TextField",
						label: "Texto da 4ª etapa",
						type: "text",
						cols: "8"
					},
					{
						fieldType: "HeaderLabel",
						label: "Parceiros"
					},
					{
						variableName: "textoParceiros",
						fieldType: "TextAreaField",
						label: "Texto parceiros",
						type: "text",
						cols: "12"
					},
					{
						variableName: "logosParceiros",
						fieldType: "MultipleImagesField",
						label: "LogoTipo parceiro",
						type: "text",
						cols: "12"
					},
					{
						fieldType: "HeaderLabel",
						label: "Contato"
					},
					{
						variableName: "imagemFundoContato",
						fieldType: "ImageField",
						label: "Imagem de fundo contato",
						type: "text",
						cols: "8"
					},
					{
						variableName: "endereco",
						fieldType: "TextField",
						label: "Endereço",
						type: "text",
						cols: "8"
					},
					{
						variableName: "facebook",
						fieldType: "TextField",
						label: "Facebook (endereço completo)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "instagram",
						fieldType: "TextField",
						label: "Instagram (endereço completo)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "whatsapp",
						fieldType: "TextFieldMask",
						required: false,
						maskFormat: "(##) #####-####",
						label: "Whatsapp",
						type: "text",
						cols: "4"
					},
					{
						variableName: "telefone",
						fieldType: "TextFieldMask",
						required: false,
						maskFormat: "(##) ####-####",
						label: "Telefone",
						type: "text",
						cols: "2"
					},
					{
						variableName: "phoneZeroEightHundred",
						fieldType: "TextFieldMask",
						required: false,
						maskFormat: "####-###-####",
						label: "Telefone 0800",
						type: "text",
						cols: "2"
					},
					{
						variableName: "email",
						fieldType: "TextField",
						label: "E-mail",
						type: "text",
						cols: "4"
					},
					{
						variableName: "linkedin",
						fieldType: "TextField",
						label: "LinkedIn",
						type: "text",
						cols: "4"
					},
					{
						variableName: "telegram",
						fieldType: "TextField",
						label: "Telegram",
						type: "text",
						cols: "4"
					},
					{
						variableName: "site",
						fieldType: "TextField",
						label: "Site",
						type: "text",
						cols: "4"
					},
					{
						fieldType: "HeaderLabel",
						label: "Horário de atendimento"
					},
					{
						variableName: "initialTime",
						fieldType: "TimeField",
						label: "Horário de início (dias úteis)",
						type: "text",
						cols: "3"
					},
					{
						variableName: "finalTime",
						fieldType: "TimeField",
						label: "Horário de final (dias úteis)",
						type: "text",
						cols: "3"
					},
					{
						variableName: "initialTimeWeekend",
						fieldType: "TimeField",
						label: "Horário de início (fim de semana)",
						type: "text",
						cols: "3"
					},
					{
						variableName: "finalTimeWeekend",
						fieldType: "TimeField",
						label: "Horário de final (fim de semana)",
						type: "text",
						cols: "3"
					},
					{
						fieldType: "HeaderLabel",
						label: "Omni Channel"
					},
					{
						variableName: "whatsappOmni",
						fieldType: "TextFieldMask",
						required: false,
						maskFormat: "(##) #####-####",
						label: "Whatsapp",
						type: "text",
						cols: "4"
					},
					{
						variableName: "whatsappLinkOmni",
						fieldType: "TextField",
						required: false,
						label: "Whatsapp link completo (tem prioridade sobre o número)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "instagramOmni",
						fieldType: "TextField",
						label: "Instagram (endereço completo)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "telegramOmni",
						fieldType: "TextField",
						label: "Telegram (inserir nome do usuário)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "messengerOmni",
						fieldType: "TextField",
						label: "Messenger (inserir nome da página)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "skypeOmni",
						fieldType: "TextField",
						label: "Skype (nome do usuário)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "linkedinOmni",
						fieldType: "TextField",
						label: "Linked in (inserir link completo)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "emailOmni",
						fieldType: "TextField",
						label: "E-mail",
						type: "text",
						cols: "4"
					},
					{
						variableName: "chatOmni",
						fieldType: "TextField",
						label: "Chat (inserir link completo)",
						type: "text",
						cols: "4"
					},
					{
						variableName: "siteOmni",
						fieldType: "TextField",
						label: "Site",
						type: "text",
						cols: "4"
					},
					{
						variableName: "flgdiscadoraOmni",
						fieldType: "ToggleToken",
						label: "Possui discadora para o portal?",
						type: "text",
						cols: "4"
					},
					{
						fieldType: "HeaderLabel",
						label: "Customizações"
					},
					{
						variableName: "textAbaixoLogo",
						fieldType: "TextAreaField",
						label: "Texto do menu abaixo da logo",
						type: "text",
						cols: "6",
						valueField:
							"Negocie seus débitos com descontos e volte a ter crédito para comprar novamente!"
					},
					{
						variableName: "textConfirmaAcordo",
						fieldType: "TextAreaField",
						label: "Texto de confirmação de acordo",
						type: "text",
						cols: "6",
						valueField:
							`Ao clicar em ‘Confirmar Acordo’ o cliente CONCORDA com os valores, datas e forma de pagamento apresentados bem como, com as regras de negociação do Credor. O Portal Aquicob envia diariamente informações de pagamento das parcelas ao Credor, para que o mesmo cumpra com as determinações legais junto aos órgãos de proteção ao crédito.`
					},
					{
						variableName: "textSalvarAcordos",
						fieldType: "TextAreaField",
						label: "Texto de sucesso ao firmar um novo acordo",
						type: "text",
						cols: "6",
						valueField: ""
					},
					{
						variableName: "textEmailBoleto",
						fieldType: "TextAreaField",
						label:
							"Texto para o e-mail do boleto. (Caso precise de alguma variável, como nome, pergunte para o desenvolvedor se existe alguma equivalente e coloque ela entre chaves.)",
						type: "text",
						cols: "6",
						valueField: ""
					},
					{
						variableName: "textBotaoBuscar",
						fieldType: "TextField",
						label: "Texto para o botão de busca na tela inicial",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textMyDebits",
						fieldType: "TextField",
						label: "Texto para o botão de abertura da tela Minhas Dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtextMyDebits",
						fieldType: "TextField",
						label: "Legenda para o botão de abertura da tela Minhas Dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtextPanelMyDebits",
						fieldType: "TextField",
						label: "Palavra para substituir dívida(s) na tela Minhas Dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtextPanelMyDebitsNotFound",
						fieldType: "TextField",
						label: "Legenda para a tela Minhas Dívidas quando não há dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textMyAgreements",
						fieldType: "TextField",
						label: "Texto para o botão de abertura da tela Meus Acordos",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtextMyAgreements",
						fieldType: "TextField",
						label: "Legenda para o botão de abertura da tela Meus Acordos",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtextPanelMyAgreements",
						fieldType: "TextField",
						label: "Palavra para substituir acordo(s) na tela Meus Acordos",
						type: "text",
						cols: "4"
					},
					{
						variableName: "subtextPanelMyAgreementsNotFound",
						fieldType: "TextField",
						label: "Legenda para a tela Meus Acordos quando não há acordos",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textMyDebitsDetails",
						fieldType: "TextField",
						label: "Texto para o botão de abertura da tela de Detalhes dentro da Minhas Dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textMyDebitsCantPay",
						fieldType: "TextField",
						label:
							"Texto para o botão de abertura da tela de Não posso pagar dentro da Minhas Dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textMyDebitsPaymentOptions",
						fieldType: "TextField",
						label:
							"Texto para o botão de abertura da tela de Opções de pagamentos e propostas dentro da Minhas Dívidas",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textMyAgreementsPayment",
						fieldType: "TextField",
						label: "Texto para o botão de abertura da tela de Pagamentos dentro dos Meus Acordos",
						type: "text",
						cols: "4"
					},
					{
						variableName: "textChavePix",
						fieldType: "TextField",
						label: "Chave PIX",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgAcessoCredor",
						fieldType: "ToggleToken",
						label: "Acesso Credor",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgAgruparContratos",
						fieldType: "ToggleToken",
						label: "Agrupar Boletos",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgValorOriginal",
						fieldType: "ToggleToken",
						label: "Valor Original",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgTermoCofissao",
						fieldType: "ToggleToken",
						label: "Termo Cofissão",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgReceberLigacao",
						fieldType: "ToggleToken",
						label: "Canais de atendimento ao sair",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgValidarDataNasc",
						fieldType: "ToggleToken",
						label: "Validar Data de Nascimento",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgValidarTelefone",
						fieldType: "ToggleToken",
						label: "Validar Telefone",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgNaoUtilizaLGPD",
						fieldType: "ToggleToken",
						label: "Não validar LGPD",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgValidarToken",
						fieldType: "ToggleToken",
						label: "Validar Token",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgTotem",
						fieldType: "ToggleToken",
						label: "Totem",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgOverlay",
						fieldType: "ToggleToken",
						label: "Desativar overlay",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgPagueMe",
						fieldType: "ToggleToken",
						label: "Utiliza o Pague Me",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgDesativarEnviarProposta",
						fieldType: "ToggleToken",
						label: "Desativar o envio de uma proposta",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgEsqueceuTelefone",
						fieldType: "ToggleToken",
						label: "Ativar o Esqueceu o telefone",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgFaleConosco",
						fieldType: "ToggleToken",
						label: "Ativar o Fale Conosco",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgDesativarCartaoCredito",
						fieldType: "ToggleToken",
						label: "Desativar Cartão Credito",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgAtivarEscolhaDoVencimento",
						fieldType: "ToggleToken",
						label: "Ativar a escolha da data de vencimento",
						type: "text",
						cols: "4"
					},
					{
						variableName: "flgModoServico",
						fieldType: "ToggleToken",
						label: "Ativar Modo Serviço",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgIncluirHistorico",
						fieldType: "ToggleToken",
						label: "Ativar Histórico de Acordos",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgDesativaCadastro",
						fieldType: "ToggleToken",
						label: "Desativa o Cadastro",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgPrefeituraMode",
						fieldType: "ToggleToken",
						label: "Modo Prefeitura",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgStretch",
						fieldType: "ToggleToken",
						label: "Fundo esticado",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgIntegraRobo",
						fieldType: "ToggleToken",
						label: "Atalho para o robô",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgMaxWidth",
						fieldType: "ToggleToken",
						label: "Aumentar o logo da home",
						type: "text",
						cols: "6"
					},
					{
						variableName: "flgAgendarChamada",
						fieldType: "ToggleToken",
						label: "Agendar chamada",
						type: "text",
						cols: "4"
					}
				];
				this.SET_LOADING(false);
			}
		}).catch((_) => {
			this.SET_LOADING(false);

			this.CHANGE_MESSAGE_MODAL({
				show: true,
				title: "Ocorreu um erro",
				message: `Empresas não encontradas`,
				styleMessage: "danger"
			});
			this.$router.push({ name: "ListPartners" });
		});
	},

	methods: {
		...mapMutations(["SET_LOADING", "CHANGE_MESSAGE_SNACKBAR", "CHANGE_MESSAGE_MODAL"]),

		...mapActions(["action_cadastro_parceiro", "action_buscar_empresas"]),

		async completeRegister() {
			let {
				nomeParceiro,
				hotjarId,
				subdominio,
				emp_codigo,
				logo,
				logoForBackgroundWhite,
				corPrimaria,
				corSecundaria,
				imagemFundo,
				imagemFundoMobile,
				tituloCabecalho,
				subtituloCabecalho,
				tituloSobre,
				imagemSobre,
				textoSobre,
				logosParceiros,
				textoParceiros,
				imagemFundoContato,
				endereco,
				dadosEmpresa,
				whatsapp,
				facebook,
				instagram,
				telefone,
				phoneZeroEightHundred,
				email,
				linkedin,
				telegram,
				site,
				whatsappOmni,
				whatsappLinkOmni,
				instagramOmni,
				telegramOmni,
				messengerOmni,
				skypeOmni,
				linkedinOmni,
				flgdiscadoraOmni = false,
				emailOmni,
				chatOmni,
				siteOmni,
				imagemTutorial,
				tituloTutorial,
				FirstTutorialTitle,
				FirstTutorialSubtitle,
				SecondTutorialTitle,
				SecondTutorialSubtitle,
				ThirdTutorialTitle,
				ThirdTutorialSubtitle,
				FourthTutorialTitle,
				FourthTutorialSubtitle,
				textAbaixoLogo,
				textConfirmaAcordo,
				textSalvarAcordos,
				textBotaoBuscar,
				textMyDebits,
				textChavePix,
				subtextMyDebits,
				textMyAgreements,
				textEmailBoleto,
				subtextMyAgreements,
				subtextPanelMyDebits,
				subtextPanelMyAgreements,
				subtextPanelMyDebitsNotFound,
				subtextPanelMyAgreementsNotFound,
				textMyDebitsDetails,
				textMyDebitsCantPay,
				textMyDebitsPaymentOptions,
				textMyAgreementsPayment,
				flgAcessoCredor,
				flgAgruparContratos,
				flgValorOriginal,
				flgValidarDataNasc,
				flgValidarTelefone,
				flgNaoUtilizaLGPD,
				flgTermoCofissao,
				flgValidarToken,
				flgReceberLigacao,
				flgTotem,
				flgOverlay,
				flgPagueMe,
				flgDesativarEnviarProposta,
				flgEsqueceuTelefone,
				flgFaleConosco,
				flgDesativarCartaoCredito,
				flgAtivarEscolhaDoVencimento,
				flgModoServico,
				flgIncluirHistorico,
				flgDesativaCadastro,
				flgPrefeituraMode,
				flgStretch,
				flgIntegraRobo,
				flgMaxWidth,
				firstTitleColor,
				secondTitleColor,
				headerButtonsColor,
				subTitleColor,
				initialTime,
				finalTime,
				initialTimeWeekend,
				finalTimeWeekend
			} = this.inputsValues;

			if (!nomeParceiro || nomeParceiro.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo nome do parceiro é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!subdominio || subdominio.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo nome do subdomínio é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!logo || logo.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo do logotipo do parceiro é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!corPrimaria || corPrimaria.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo cor primária é obrigatório`,
					styleMessage: "danger"
				});

			} else if (!corSecundaria || corSecundaria.length === 0) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `O campo cor sercundária é obrigatório`,
					styleMessage: "danger"
				});

			} else if (
				(imagemSobre && imagemSobre?.base64 && !tituloSobre.length > 0 && textoSobre.length > 0) ||
				(imagemSobre && imagemSobre?.base64 && tituloSobre.length > 0 && !textoSobre.length > 0) ||
				(!imagemSobre && tituloSobre.length > 0 && textoSobre.length > 0) ||
				(!imagemSobre && !tituloSobre.length > 0 && textoSobre.length > 0) ||
				(!imagemSobre && tituloSobre.length > 0 && !textoSobre.length > 0) ||
				(imagemSobre && !tituloSobre.length > 0 && !textoSobre.length > 0)
			) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o Sobre deve ser inserido a imagem da empresa, o título e o texo do sobre.`,
					styleMessage: "danger"
				});

			} else if (
				(imagemTutorial && imagemTutorial?.base64 && !tituloTutorial) ||
				(!imagemTutorial && tituloTutorial)
			) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o Passo a Passo deve ser inserido a imagem de fundo e o título do passo a passo.`,
					styleMessage: "danger"
				});

			} else if ((initialTime != "" && finalTime == "") || (initialTime == "" && finalTime != "")) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos dias úteis, deve ser inserido o horário inicial e o horário final.`,
					styleMessage: "danger"
				});

			} else if (
				(initialTimeWeekend != "" && finalTimeWeekend == "") ||
				(initialTimeWeekend == "" && finalTimeWeekend != "")
			) {

				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos fins de semana, deve ser inserido o horário inicial e o horário final.`,
					styleMessage: "danger"
				});

			} else if (initialTime != "" && finalTime != "" && initialTime > finalTime) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos dias úteis, o horário inicial deve ser menor que o horário final.`,
					styleMessage: "danger"
				});

			} else if (initialTimeWeekend != "" && finalTimeWeekend != "" && (initialTime == "" || finalTime == "")) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos fins de semana, primeiro deve ser registrado os horários de funcionamento nos dias úteis.`,
					styleMessage: "danger"
				});

			} else if (initialTimeWeekend != "" && finalTimeWeekend != "" && initialTimeWeekend > finalTimeWeekend) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Para registar o horário de funcionamento nos fins de semana, o horário inicial deve ser menor que o horário final.`,
					styleMessage: "danger"
				});

			} else {
				this.SET_LOADING(true);
				let header = null;
				let about = null;
				let tutorial = null;
				let partners = null;
				let contact = null;
				let omniChannel = null;
				let customization = null;

				if (
					(imagemFundo && imagemFundo?.base64) ||
					(imagemFundoMobile && imagemFundoMobile?.base64) ||
					subtituloCabecalho ||
					tituloCabecalho
				) {
					header = {
						images: imagemFundo?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemFundo.format,
										arquivo: imagemFundo.base64,
										adicionais: {
											name: imagemFundo.name
										}
									}
							  ]
							: [],
						imagesMobile: imagemFundoMobile?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemFundoMobile.format,
										arquivo: imagemFundoMobile.base64,
										adicionais: {
											name: imagemFundoMobile.name
										}
									}
							  ]
							: [],
						background: 1,
						title: tituloCabecalho || null,
						firstTitleColor: firstTitleColor || null,
						secondTitleColor: secondTitleColor || null,
						headerButtonsColor: headerButtonsColor || null,
						subtitle: subtituloCabecalho || null,
						subTitleColor: subTitleColor || null
					};
				}

				if (imagemSobre && imagemSobre?.base64 && tituloSobre.length > 0 && textoSobre.length > 0) {
					about = {
						title: tituloSobre || null,
						text: textoSobre || null,
						image: 1,
						images: imagemSobre?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemSobre.format,
										arquivo: imagemSobre.base64
									}
							  ]
							: []
					};
				}

				if (imagemTutorial && imagemTutorial?.base64 && tituloTutorial) {
					tutorial = {
						title: tituloTutorial,
						steps: [
							{ code: 0, title: FirstTutorialTitle, text: FirstTutorialSubtitle },
							{ code: 1, title: SecondTutorialTitle, text: SecondTutorialSubtitle },
							{ code: 2, title: ThirdTutorialTitle, text: ThirdTutorialSubtitle },
							{ code: 3, title: FourthTutorialTitle, text: FourthTutorialSubtitle }
						],
						images: imagemTutorial?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemTutorial.format,
										arquivo: imagemTutorial.base64
									}
							  ]
							: []
					};
				}

				if (textoParceiros || logosParceiros) {
					partners = {
						text: textoParceiros || null,
						images: logosParceiros || null
					};
				}

				if (
					imagemFundoContato?.base64 ||
					whatsapp ||
					telefone ||
					email ||
					facebook ||
					instagram ||
					endereco ||
					phoneZeroEightHundred ||
					site ||
					linkedin ||
					telegram ||
					initialTime ||
					finalTime ||
					initialTimeWeekend ||
					finalTimeWeekend
				) {
					contact = {
						phone: telefone,
						phoneZeroEightHundred: phoneZeroEightHundred,
						whatsapp: whatsapp,
						email: email,
						address: endereco,
						dadosEmpresa: dadosEmpresa,
						site: site,
						linkedin: linkedin,
						telegram: telegram,
						url_facebook: facebook,
						url_instagram: instagram,
						initialTime: initialTime,
						finalTime: finalTime,
						initialTimeWeekend: initialTimeWeekend,
						finalTimeWeekend: finalTimeWeekend,
						image: 1,
						images: imagemFundoContato?.base64
							? [
									{
										identificador: 1,
										tipoImagem: imagemFundoContato.format,
										arquivo: imagemFundoContato.base64
									}
							  ]
							: []
					};
				}

				if (
					whatsappOmni ||
					whatsappLinkOmni ||
					instagramOmni ||
					telegramOmni ||
					messengerOmni ||
					skypeOmni ||
					linkedinOmni ||
					flgdiscadoraOmni ||
					emailOmni ||
					chatOmni ||
					siteOmni
				) {
					omniChannel = {
						whatsappOmni: whatsappOmni,
						whatsappLinkOmni: whatsappLinkOmni,
						instagramOmni: instagramOmni,
						telegramOmni: telegramOmni,
						messengerOmni: messengerOmni,
						skypeOmni: skypeOmni,
						linkedinOmni: linkedinOmni,
						flgdiscadoraOmni: flgdiscadoraOmni,
						emailOmni: emailOmni,
						chatOmni: chatOmni,
						siteOmni: siteOmni
					};
				}

				customization = {
					myDebits: {
						flgValorOriginal: flgValorOriginal,
						flgAgruparContratos: flgAgruparContratos,
						flgDesativarEnviarProposta: flgDesativarEnviarProposta,
						textSalvarAcordos: textSalvarAcordos,
						textMyDebits: textMyDebits,
						subtextMyDebits: subtextMyDebits,
						subtextPanelMyDebits: subtextPanelMyDebits,
						subtextPanelMyDebitsNotFound: subtextPanelMyDebitsNotFound,
						textMyDebitsDetails: textMyDebitsDetails,
						textMyDebitsCantPay: textMyDebitsCantPay,
						textMyDebitsPaymentOptions: textMyDebitsPaymentOptions,
						textChavePix: textChavePix,
						detailsMonthlyPayment: {
							textConfirmaAcordo: textConfirmaAcordo
						},
						flgDesativarCartaoCredito: flgDesativarCartaoCredito,
						flgAtivarEscolhaDoVencimento: flgAtivarEscolhaDoVencimento
					},
					myAgreements: {
						textMyAgreements: textMyAgreements,
						textEmailBoleto: textEmailBoleto,
						subtextMyAgreements: subtextMyAgreements,
						subtextPanelMyAgreements: subtextPanelMyAgreements,
						subtextPanelMyAgreementsNotFound: subtextPanelMyAgreementsNotFound,
						textMyAgreementsPayment: textMyAgreementsPayment,
						flgTermoCofissao: flgTermoCofissao,
						flgPagueMe: flgPagueMe,
						flgIncluirHistorico: flgIncluirHistorico
					},
					confirmBirthday: {
						flgValidarDataNasc: flgValidarDataNasc,
						flgValidarTelefone: flgValidarTelefone,
						flgNaoUtilizaLGPD: flgNaoUtilizaLGPD,
						flgValidarToken: flgValidarToken,
						flgTotem: flgTotem,
						flgEsqueceuTelefone: flgEsqueceuTelefone,
						flgDesativaCadastro: flgDesativaCadastro
					},
					menuDebits: {
						textAbaixoLogo: textAbaixoLogo,
						flgReceberLigacao: flgReceberLigacao,
						flgFaleConosco: flgFaleConosco,
						flgPrefeituraMode: flgPrefeituraMode
					},
					landPage: {
						textBotaoBuscar: textBotaoBuscar,
						flgAcessoCredor: flgAcessoCredor,
						flgOverlay: flgOverlay,
						flgModoServico: flgModoServico,
						flgStretch: flgStretch,
						flgIntegraRobo: flgIntegraRobo,
						flgMaxWidth: flgMaxWidth
					}
				};

				let obj = {
					estilo: {
						corPrimaria: corPrimaria,
						corSecundaria: corSecundaria
					},
					nome: nomeParceiro,
					logo: logo.base64,
					identificador: subdominio,
					emp_codigo: emp_codigo,
					hotjar_id: hotjarId,
					tipoLogo: logo.format,
					sessoes: {
						logoForBackgroundWhite: logoForBackgroundWhite.base64,
						header: header,
						about: about,
						partners: partners,
						contact: contact,
						tutorial: tutorial,
						customization: customization,
						omniChannel: omniChannel
					}
				};

				this.action_cadastro_parceiro(obj).then((resposta) => {

					this.SET_LOADING(false);

					if (resposta) {
						this.$router.push({ name: "ListPartners" });
						this.CHANGE_MESSAGE_SNACKBAR("Parceiro cadastrado com sucesso!");
						setTimeout(() => this.CHANGE_MESSAGE_SNACKBAR(""), 5000);
						this.SET_LOADING(false);

					} else {
						this.SET_LOADING(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Ocorreu um erro",
							message: `Não foi possivel cadastrar este parceiro`,
							styleMessage: "danger"
						});
					}
				}).catch((_) => {
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Não foi possivel cadastrar este parceiro`,
						styleMessage: "danger"
					});
				});
			}
		}
	}
};
</script>
