<template>
    <div
        :class="(plan.codigo == planSelected)?'col-selected':'col'"
        @click="$emit('update:returnPlanSelected', (plan.codigo == planSelected)? '' : plan.codigo)">

        <div class="price-tag">
            <label>{{ plan.nome }}</label>
            <h3>R$ {{ ((plan.valor / 9) * 10) | formatarDinheiro }} mensais</h3>

            <div class="main-price">
                <h2>R$ {{ plan.valor | formatarDinheiro }} mensais</h2>
                <h3>(desconto de 10% aderindo hoje)</h3>
            </div>
        </div>

        <div class="itens-group">

            <div :class="(plan.codigo == planSelected)?'item-plan-selected':'item-plan'">
                <i class="fas fa-check"></i>
                <h3>{{ plan.item_1 }}</h3>
            </div>

            <div :class="(plan.codigo == planSelected)?'item-plan-selected':'item-plan'">
                <i class="fas fa-check"></i>
                <h3>{{ plan.item_2 }}</h3>
            </div>

            <div :class="(plan.codigo == planSelected)?'item-plan-selected':'item-plan'">
                <i class="fas fa-check"></i>
                <h3>{{ plan.item_3 }}</h3>
            </div>

            <div
                v-if="plan.emp_usa_site == 'S'"
                class="portal-ad">

                <h3 class="portal-ad-text">Portal exclusivo com a sua cara, para gerar novos Acordos com os seus clientes</h3>
                <div class="item-plan-selected">
                    <h3 style="font-size: 16px">Tudo que nosso plano de Gestão Operacional fornece e muito mais</h3>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import globalMethods from "@/mixins/globalMethods";

export default {
    name: "PricingData",

    mixins: [globalMethods],

    props: [
        'cols',
        'plan',
        'planSelected'
    ],

    data() {
        return {
            text: {},
            showOptions: false,
        }
    },

    watch: {
        options() {
            this.selectOption(this.options[0] || {});
        },

        text() {
            this.$emit('update:value', this.text[this.keyValue]);
        }
    },

    methods: {
        selectOption(option) {
            this.showOptions = false;
            this.text = option;
        }
    }
}
</script>

<style scoped>
.col {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 12px 6px;
  position: relative;

  background-color: white;
  border-radius: 25px;
  box-shadow: 5px 5px 2px -2px #a5a5a5;
  margin-top: 56px;
}

.col:hover > .price-tag {
  background-image: linear-gradient(to bottom, #4292ff, #388cff, #2e86ff, #2380ff, #177aff);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.col:hover {
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.col-selected {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 12px 6px;
  position: relative;

  background-image: linear-gradient(to top, #4292ff, #388cff, #2e86ff, #2380ff, #177aff);
  border-radius: 25px;
  box-shadow: 5px 5px 2px -2px #a5a5a5;
  margin-top: 56px;
}

.col-selected:hover > .price-tag {
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.col-selected:hover {
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

label {
  display: block;
  color: white;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: bold;
  padding: 8px;
}

.col-selected label {
  display: block;
  color: #4292ff;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: bold;
  padding: 8px;
}

.itens-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
}

.item-plan {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.item-plan-selected {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.item-plan i {
  color: #4292ff;
  margin-right: 16px;
  font-size: 25px;
}

.item-plan h3 {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: bold;
  color: #2D3436;
}

.item-plan-selected i {
  color: white;
  margin-right: 16px;
  font-size: 25px;
}

.item-plan-selected h3 {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: bold;
  color: white;
}

.price-tag {
  width: 60%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  bottom: 16px;
  position: relative;

  background-image: linear-gradient(to top, #4292ff, #388cff, #2e86ff, #2380ff, #177aff);
  border-radius: 30px;
}


.price-tag > h3 {
  display: block;
  color: white;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: bold;
  text-decoration: line-through;
  opacity: 0.7;
  padding: 8px;
}

.price-tag > div > h3 {
  display: block;
  color: white;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  padding: 8px;
}

.price-tag > div > h2 {
  display: block;
  color: white;
  font-size: 32px;
  font-family: "Roboto";
  font-weight: bold;
}

.col-selected > .price-tag {
  width: 60%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  bottom: 16px;
  position: relative;

  background-image: linear-gradient(to top, white, white, white);
  border-radius: 30px;
}

.col-selected > .price-tag > h3 {
  display: block;
  color: #4292ff;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: bold;
  text-decoration: line-through;
  opacity: 0.7;
  padding: 8px;
}

.col-selected > price-tag > div > h3 {
  display: block;
  color: #4292ff;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  padding: 8px;
}

.col-selected > .price-tag > div > h2 {
  display: block;
  color: #4292ff;
  font-size: 32px;
  font-family: "Roboto";
  font-weight: bold;
}

.col-selected > .price-tag > div > h3 {
  display: block;
  color: #4292ff;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
  padding: 8px;
}

.main-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portal-ad{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 24px;
  padding: 25px;
  background-color: #18BD00;
  border-radius: 50px;

}

.portal-ad-text{
  color: white;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: bold;
}

</style>
