<template>
	<div class="your-rights-body">
		<p class="bold">SEUS DIREITOS:</p>
		<p>Além de regulamentar as diretrizes que as empresas devem seguir ao lidar com dados pessoais, a LGPD (Lei Geral de Proteção de Dados) também assegura os direitos dos titulares de dados.</p>
		<p>Para um processo de adequação à lei, é fundamental conhecer o que, exatamente, a LGPD elenca como sendo esses direitos.</p>
		<p>Afinal, com a lei em vigor os titulares já podem exercer e cobrar seus direitos, e nós da {{ nomeParceiro }} precisamos nos preparar para lidar com eventuais requisições.</p>
		<p>Antes de citar ponto a ponto quais são seus direitos, vale lembrar que dado pessoal é qualquer informação que identifique ou que, em conjunto com outros dados, permita identificar uma pessoa.</p>
		<p>Além disso, a lei deixa clara que os dados pertencem a você e não a nós (empresas). Por isso, o termo “titular dos dados” refere-se sempre à pessoa a quem os dados dizem respeito.</p>
		<p>Esse ponto, aliás, está presente no Artigo 17 da LGPD:</p>
		<p>“Toda pessoa natural tem assegurada a titularidade de seus dados pessoais e garantidos os direitos fundamentais de liberdade, de intimidade e de privacidade, nos termos desta Lei. ”</p>
		<p>Em seguida, a lei aborda justamente os direitos dos titulares dos dados, sobre os quais vamos falar agora.</p>
		<p class="bold">LGPD: quais são os direitos dos titulares de dados?</p>
		<p class="bold">1. Confirmação da existência de tratamento</p>
		<p>O tratamento de dados é qualquer atividade relacionada a dados pessoais, como coleta, armazenamento, uso e classificação. Por lei, o titular dos dados tem o direito de confirmar se uma empresa realiza o tratamento de seus dados pessoais.</p>
		<p>A LGPD estabelece ainda que a resposta pode ser feita de forma imediata e de maneira simplificada, ou por meio de declaração “clara e completa”, que indique a origem dos dados, os critérios usados e a finalidade do tratamento.</p>
		<p>O prazo para a resposta no formato completo é de até 15 dias contado a partir da data do requerimento.</p>
		<p class="bold">2. Acesso aos dados</p>
		<p>Além de saber se a empresa trata seus dados pessoais, o titular também pode pedir acesso aos dados. Ou seja, é possível obter uma cópia dos dados pessoais que a empresa possui em seus arquivos.</p>
		<p>Da mesma forma que a confirmação de tratamento, o acesso também pode ser respondido de forma imediata e simplificada ou por meio de declaração completa no prazo de até 15 dias contado da data do requerimento.</p>
		<p class="bold">3. Correção de dados.</p>
		<p>Outro direito do titular de dados é solicitar à empresa a correção de dados pessoais incompletos, inexatos ou desatualizados.</p>
		<p>É o caso, por exemplo, de uma atualização de endereço, número de telefone ou estado civil.</p>
		<p class="bold">4. Anonimização, bloqueio ou eliminação de dados</p>
		<p>Caso queira, o titular de dados também tem o direito de solicitar a anonimização (processo que torna um dado impossível de ser vinculado a um indivíduo), bloqueio ou eliminação de dados quando eles forem “desnecessários, excessivos ou tratados em desconformidade” com a lei.</p>
		<p>Por exemplo, se a empresa trata dados que não são necessários para alcançar a finalidade do tratamento ou se o tratamento não é enquadrado em nenhuma das bases legais previstas na lei.</p>
		<p class="bold">5. Portabilidade dos dados</p>
		<p>A LGPD prevê ainda que o titular de dados pode solicitar a portabilidade dos dados, ou seja, a transferência das suas informações pessoais a outro fornecedor de serviço ou produto.</p>
		<p>Neste caso, é preciso uma requisição expressa, seguindo uma regulamentação que deverá ser feita pela ANPD (Autoridade Nacional de Proteção de Dados). Além disso, a portabilidade não inclui dados que já tenham sido anonimizados pelo controlador – dados anonimizados, aliás, ficam de fora do escopo da LGPD.</p>
		<p class="bold">6. Eliminação dos dados tratados com consentimento</p>
		<p>Se o titular dos dados consentiu com o tratamento, mas mudou de ideia e não quer mais que a empresa trate seus dados pessoais, ele pode solicitar a eliminação desses dados.</p>
		<p>No entanto, há situações em que esse direito não pode ser exercido, como quando a empresa precisa conservar os dados para cumprir obrigação legal ou regulatória.</p>
		<p class="bold">7. Informações sobre o compartilhamento de dados</p>
		<p>A LGPD preza, neste e em outros pontos, pela transparência, que é um dos princípios da lei que devem ser respeitados pelas empresas.</p>
		<p>Desta forma, é direito do titular saber exatamente com quem o controlador está compartilhando seus dados. Isto inclui entidades públicas e privadas, que devem ser expressamente nomeadas, e não mencionadas apenas de forma genérica.</p>
		<p class="bold">8. Informação sobre a possibilidade de não fornecer consentimento</p>
		<p>A premissa do consentimento é que ele seja pedido e concedido de forma clara, transparente e totalmente livre. Para isso, o titular de dados tem o direito de ser informado sobre a possibilidade de não fornecer o consentimento e de quais as consequências caso o consentimento seja negado.</p>
		<p>É o caso, por exemplo, de um usuário que é convidado a consentir ou não com o uso de cookies em um site. Se o não consentimento for prejudicar a experiência de navegação ou impedir o acesso a algumas ferramentas, o usuário deve ser informado disso.</p>
		<p class="bold">9. Revogação do consentimento</p>
		<p>Por fim, qualquer consentimento dado para o tratamento de dados pessoais pode ser revogado. Este é um direito do titular de dados, que pode fazer uma solicitação revogando o consentimento.</p>
		<p>No entanto, vale lembrar que para que os dados tratados até então sejam de fato eliminados é preciso fazer uma requisição específica, conforme mencionamos no item 6.</p>
		<p class="bold">Outros direitos</p>
		<p>Além dos 9 direitos principais dos titulares de dados previstos em seu artigo 18, a LGPD menciona outros, como, por exemplo:</p>
		<p>Direito do titular de dados de se manifestar contra o controlador na ANPD e nos órgãos de defesa do consumidor.</p>
		<p>O direito de opor-se ao tratamento realizado com dispensa de consentimento, caso não esteja em conformidade com a lei.</p>
		<p class="bold">É importante ressaltar, no entanto, que nenhum direito é absoluto e que há situações em que as empresas podem não conseguir atender aos requerimentos do titular, devendo indicar os motivos -como, por exemplo, o cumprimento de obrigações legais ou regulatórias.</p>
	</div>
</template>

<script>
import mixins from "@/mixins/mixins";

export default {
	name: "YourRights",

	mixins: [mixins]
};
</script>

<style>
.your-rights-body {
	padding: 50px;
	line-height: 1.5;
	text-align: justify;
	margin: 0px 70px;
	border: 2px solid #e6e6e6;
}
.your-rights-body p {
	font-size: 16px;
	margin-bottom: 10px;
	font-family: "Arial", sans-serif;
}
.bold {
	font-weight: bold;
}
@media only screen and (max-width: 990px) {
	.your-rights-body {
		padding: 20px;
		line-height: 1.25;
		margin: 0px 20px;
	}
}
</style>