<template>
	<div>
		<div
			v-if="parceiro != 'uol'"
			class="iconBoxBack"
			:style="corPrimaria"
			@click="$emit('close')">

			<i class="fas fa-chevron-left" />
			Voltar
		</div>
		<div class="content-page">
			<h3>Envie sua proposta</h3>
			<div class="proposal_text">

				<div>
					<text-field-money
						cols="12"
						label="Entrada"
						@update:value="entrada = $event" ></text-field-money>
				</div>

				<div>
					<text-field-mask
						mask-format="####"
						value-field="1"
						cols="12"
						label="Quantidade parcelas"
						@update:value="parcelas = $event"></text-field-mask>
				</div>

				<div>
					<text-field-money
						cols="12"
						label="Valor das parcelas"
						@update:value="valorParcela = $event" ></text-field-money>
				</div>

			</div>

			<button-default
				:color="corPrimaria"
				@click.native="send"
				text="Enviar proposta" ></button-default>
		</div>
	</div>
</template>

<script>
import { mapActions, createNamespacedHelpers } from "vuex";
import mixins from "@/mixins/mixins";

import TextAreaField from "@/components/inputs/TextAreaField";
import ButtonDefault from "@/components/buttons/ButtonDefault";
import TextField from "@/components/inputs/TextField";
import TextFieldMask from "@/components/inputs/TextFieldMask";
import TextFieldMoney from "@/components/inputs/TextFieldMoney";

const { mapState: mapStatePassosRetornarDividas } = createNamespacedHelpers('passosRetornarDividas');

export default {
	name: "SendProposal",

	props: {
		divida: {
			type: Object,
			required: true
		}
	},

	components: { TextFieldMoney, TextFieldMask, TextField, ButtonDefault, TextAreaField },

	mixins: [mixins],

	data() {
		return {
			entrada: "",
			parcelas: 1,
			valorParcela: ""
		};
	},

	computed: {
		...mapStatePassosRetornarDividas({
			pessoa: (estado) => estado.pessoa,
		}),
	},

	methods: {
		...mapActions(["action_cadastrar_proposta"]),

		send() {
			if (!this.entrada) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite uma entrada válida`,
					styleMessage: "danger"
				});

			} else if (this.parcelas.length === 0 || this.parcelas.length === "0") {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite uma quantidade de parcelas válida`,
					styleMessage: "danger"
				});

			} else if (!this.valorParcela && !this.entrada) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um valor de parcela válido`,
					styleMessage: "danger"
				});

			} else {
				this.SET_LOADING(true);
				this.SET_LOADING_MESSAGE("Enviando a sua proposta");

				this.acaoSalvarEtapa({
					pes_codigo: this.pessoa.pes_codigo,
					esn_fk_ets_codigo: 9,
					emp_codigo: this.parceiroConfig.emp_codigo,
					data_agendamento: this.dataAtual,
					hora_agendamento: this.horaAtual,
					motivo_agendamento: 'Enviou uma nova proposta'
				});

				let obj = {
					pes_codigo: this.pessoa.pesCodigo,
					loj_codigo: this.divida.loja.loj_codigo,
					emp_codigo: this.parceiroConfig.emp_codigo,
					entrada: this.entrada,
					quantidade_parcelas: this.parcelas,
					valor_parcela: this.valorParcela
				};

				this.action_cadastrar_proposta(obj).then((_) => {
					this.SET_LOADING_MESSAGE("");
					this.SET_LOADING(false);
					this.$emit("close");
				}).catch((_) => {
					this.SET_LOADING_MESSAGE("");
					this.SET_LOADING(false);
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.iconBoxBack {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: "Roboto Black";
	color: var(--primaryColor);
	text-transform: uppercase;
	cursor: pointer;
}
.iconBoxBack i {
	margin-right: 10px;
	font-size: 22px;
}

h3 {
	margin-top: 20px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	background: #f5f6fa;
	padding: 10px 0;
}

.content-page h3 {
	margin-bottom: 20px;
}
.proposal_text {
	display: flex;
	margin-bottom: 20px;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
		width: 100%;
	}
}
</style>
