<template>
	<div>

		<header-list
			title="Lista de parceiros"
			routeNameButton="PostPartners" ></header-list>

		<input
			v-model="pesquisa"
			placeholder="Pesquisar parceiros" />

		<div class="list-partners">

			<div
				v-show="parceiro.nome.toLowerCase().includes(pesquisa.toLowerCase())"
				v-for="parceiro in orderedParceiros"
				:key="parceiro.id"
				class="card-patners" >

				<div class="first-column">
					<div class="card-box-image">
						<img
							v-if="parceiro.logo"
							key="portal-parceiro-logo"
							:src="parceiro.logo"
							alt="logo" />

						<img
							v-else
							key="portal-parceiro-sem-logo"
							src="@/assets/images/teae.png"
							alt="logo-aquicob" />
					</div>

					<div class="card-box-description">
						<h5>{{ parceiro.nome }}</h5>
					</div>
				</div>

				<div class="card-box-action">
					<small-button
						title="Visualizar parceiro"
						icon="fas fa-search"
						@click.native="atualizarParceiro(parceiro)" ></small-button>

					<small-button
						v-if="parceiro.id !== 1"
						:color="danger"
						title="Excluir parceiro"
						icon="fas fa-times"
						@click.native="questionDelete(parceiro)" ></small-button>

				</div>
			</div>
		</div>

		<question-modal
			v-if="questionModal.show"
			:title="questionModal.title"
			:message="questionModal.message"
			@close="questionModal.show = false"
			@confirm="deleteParceiro(parceiroSelected.id)" ></question-modal>

	</div>
</template>

<script>
import _ from "lodash/orderBy";

import { mapActions, mapMutations } from "vuex";

import HeaderList from "@/components/HeaderList";
import SmallButton from "@/components/buttons/SmallButton";
import QuestionModal from "@/components/QuestionModal";
import TextField from "@/components/inputs/TextField";

export default {
	name: "ListPartners",

	components: { QuestionModal, SmallButton, HeaderList, TextField },

	data() {
		return {
			parceiros: [],

			questionModal: {
				show: false,
				title: "",
				message: ""
			},

			parceiroSelected: null,

			currentlyPage: 1,

			pagesQuantity: 1,

			pesquisa: "",

			danger: {
				"--color": "#fff",
				"--hoverColor": "#f44336"
			}
		};
	},

	mounted() {
		this.getParceiros();
	},


	computed: {
		orderedParceiros() {
			return _(this.parceiros, [(parceiro) => parceiro.nome.toLowerCase()]);
		}
	},

	watch: {
		pagesQuantity() {
			for (let index = 1; index <= this.pagesQuantity; index++) {

				this.action_buscar_todos_parceiros({ params: { page: index } })
					.then((resposta) => {
						if (resposta?.data?.data) {
							this.parceiros = this.parceiros.concat(resposta.data.data);
						}
						this.SET_LOADING(false);

					}).catch((_) => {
						this.SET_LOADING(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Ocorreu um erro",
							message: `Usuário ou senha inválido`,
							styleMessage: "danger"
						});
					});
			}
			this.SET_LOADING(false);
		}
	},

	methods: {
		...mapMutations(["SET_LOADING", "CHANGE_MESSAGE_SNACKBAR", "CHANGE_MESSAGE_MODAL"]),

		...mapActions(["action_buscar_todos_parceiros", "action_deletar_parceiro"]),

		getParceiros() {
			this.SET_LOADING(true);

			this.action_buscar_todos_parceiros({ params: { page: this.currentlyPage } })
				.then((response) => {
					this.pagesQuantity = response.data.meta.last_page;
				}).catch((_) => {
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Usuário ou senha inválido`,
						styleMessage: "danger"
					});
				});
			if (this.pagesQuantity == 1) {
				this.SET_LOADING(false);
			}
		},

		questionDelete(parceiro) {
			this.parceiroSelected = parceiro;
			this.questionModal.title = "Atenção";
			this.questionModal.message = `Você tem certeza que deseja excluir o parceiro ${parceiro.nome} ?`;
			this.questionModal.show = true;
		},

		deleteParceiro(id) {
			this.parceiroSelected = null;
			this.questionModal.show = false;
			this.SET_LOADING(true);
			this.action_deletar_parceiro({ id: id })
				.then((response) => {
					this.SET_LOADING(false);

					if (response) {
						this.getParceiros();
						this.CHANGE_MESSAGE_SNACKBAR("Parceiro excluído com sucesso!");
						setTimeout(() => this.CHANGE_MESSAGE_SNACKBAR(""), 5000);

					} else {
						this.SET_LOADING(false);
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Ocorreu um erro",
							message: `Não foi possivel excluir este parceiro`,
							styleMessage: "danger"
						});
					}
				}).catch((_) => {
					this.SET_LOADING(false);
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Ocorreu um erro",
						message: `Não foi possivel excluir este parceiro`,
						styleMessage: "danger"
					});
				});
			this.SET_LOADING(false);
		},

		atualizarParceiro(parceiro) {
			this.$router.push({
				name: 'UpdatePartner',
				params: {
					identificador: parceiro.identificador,
					id: parceiro.id
				}
			});
		}
	}
};
</script>

<style scoped>
.list-partners {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.card-patners {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 15px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	flex-direction: row;
	margin-top: 15px;
	margin-bottom: 5px;
}

.card-box-image img {
	max-width: 70px;
	max-height: 70px;
	object-fit: contain;
}

.first-column {
	display: flex;
	align-items: center;
}

.card-box-description {
	padding-left: 20px;
}

.card-box-description h5 {
	font-size: 18px;
	font-family: "Roboto Bold";
	cursor: var(--primaryColor);
}

.card-box-action {
	display: flex;
	align-items: center;
}

input {
	margin: 10px 0px;
	height: 46px;
	width: 100%;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #eff1f5 !important;
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 16px;
}
</style>
