<template>
	<div :class="{ solo: !childrens || childrens.length === 0 }">
		<router-link :key="name" tag="li" :to="route_name ? { name: route_name } : ''" @click.native="childrens && childrens.length > 0 && (showChildrens = !showChildrens)" :style="corPrimaria">
			<div id="icon"><i :class="icon"></i>{{ name }}</div>
		</router-link>
		<ul v-show="showChildrens">
			<router-link :key="children.name + '-' + children.route_name" :to="{ name: children.route_name }" tag="li" :class="$router.currentRoute.name === children.route_name && activeMenu()" v-show="children.show" v-for="children in childrens" :style="corPrimaria">
				<div id="icon">
					<i :class="children.icon"></i>
					{{ children.name }}
				</div>
			</router-link>
		</ul>
	</div>
</template>

<script>
import { mapState } from "vuex"
export default {
	name: "ItemMenu",
	props: ["name", "route_name", "icon", "childrens"],
	data() {
		return {
			showChildrens: false
		}
	},
	methods: {
		activeMenu() {
			this.showChildrens = true
			return "active"
		}
	},
	computed: {
		...mapState({
			corPrimaria: state => state.corPrimaria
		})
	}
}
</script>

<style scoped>
li {
	padding: 0 20px;
	display: flex;
	align-items: center;
	height: 48px;
	font-size: 14px;
	color: var(--whiteColor);
	justify-content: space-between;
	cursor: pointer;
	background: var(--blackColor);
	transition: all 0.2s ease-in-out;
}

li div {
	display: flex;
	align-items: center;
	font-family: "Roboto";
	line-height: 18px;
	background: var(--blackColor);
	transition: all 0.2s ease-in-out;
}

li div i {
	margin-right: 10px;
	font-size: 18px;
	width: 28px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

div.active > li div,
li.active div {
	font-family: "Roboto Bold" !important;
	color: var(--color);
	background: var(--whiteColor);
}

ul > li.active {
	background: var(--whiteColor);
	color: var(--color);
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

div.solo.active > li {
	background: var(--whiteColor);
	color: var(--color);
}

li:hover {
	cursor: pointer;
	background: var(--whiteColor);
	color: var(--color);
	transition: all 0.2s ease-in-out;
}

li:hover #icon {
	cursor: pointer;
	background: var(--whiteColor);
	color: var(--color);
	transition: all 0.2s ease-in-out;
}

.menu-item-float:hover {
	cursor: pointer;
	background: var(--whiteColor);
	color: var(--color);
	transition: all 0.2s ease-in-out;
}

ul ul {
	margin-left: 30px !important;
}
</style>
