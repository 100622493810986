<template>
	<button
		class="button"
		:style="styleObject"
		@click="goToSite()">

		<i :class="icon"></i>
		<p class="bttitle">{{ text }}</p>
	</button>
</template>
<script>
export default {
	name: "OutlineButton",

	props: {
		text: {
			type: String,
			required: true
		},

		icon: {
			type: String,
			required: true
		},

		color: {
			type: String,
			required: true
		},

		colorHover: {
			type: String,
			required: true
		},

		url: {
			type: String,
			required: false
		}
	},

	computed: {
		styleObject() {
			return {
				"--color": this.color,
				"--border-color": this.color,
				"--color--hover": "white",
				"--background--hover": this.colorHover == "none" ? this.color : this.colorHover
			}
		}
	},

	methods: {
		goToSite() {
			if (this.url != undefined) {
				let win = window.open(this.url, "_blank")
				win.focus()
			}
		}
	}
}
</script>
<style>
.button {
	background-color: white; /* Green */
	width: 12em;
	height: 8em;
	text-align: center;
	display: inline-block;
	margin: 4px 10px;
	transition-duration: 0.4s;
	border-radius: 16px;
	border-width: "2px";
	border-style: solid;
	border-color: var(--border-color);
	color: var(--color);
	min-width: 65px;
	min-height: 80px;
}
.button:hover {
	color: var(--color--hover);
	background: var(--background--hover);
}
.bttitle {
	margin-top: 0.5vw;
	font-size: 1.5em;
}
@media screen and (max-width: 1640px) {
	.button {
		width: 6em;
		height: 6em;
	}
	.bttitle {
		margin-top: 0.5vw;
		font-size: 0.7em;
	}
}
</style>
