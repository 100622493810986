<template>
	<div class="containerDebitDetail">
		<h4>PARCELAS ORIGINAIS</h4>

		<div class="infos-row parcelas-box">
			<table class="table-parcelas">

				<caption>
					Detalhes das parcelas do seus acordos.
				</caption>

				<tr>
					<th id="Parcela">Parcela</th>
					<th id="Vencimento">Vencimento</th>
					<th id="Valor">Valor</th>
				</tr>

				<tr v-for="par_acordo in orderParelas"
					:key="par_acordo.prestacao">
					<td>{{ par_acordo.prestacao === "0" ? "À vista" : par_acordo.prestacao }}</td>
					<td>{{ par_acordo.vencimento_original }}</td>
					<td>R$ {{ par_acordo.valor_original }}</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import globalMethods from "@/mixins/globalMethods"

export default {
	name: "DetailsAgreement",

	mixins: [globalMethods],

	props: {
		acordo: {
			type: Object,
			required: true
		}
	},

	computed: {
		orderParelas() {
			return this.acordo.detalhes.sort((a, b) => a.prestacao - b.prestacao)
		}
	}
}
</script>

<style scoped>
.containerDebitDetail {
	width: 100%;
	box-sizing: border-box;
	max-width: 1024px;
	padding: 30px;
	background: var(--whiteColor);
	border-radius: 40px;
	box-shadow: 0 1px 2px rgba(3, 27, 78, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-bottom: 100px;
}

.header-debits {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	max-width: 1024px;
	cursor: pointer;
}

h3 {
	margin-bottom: 30px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
}

.header-debits i {
	font-size: 48px;
	color: #031b4e;
}

.infos-row {
	margin: 10px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.infos {
	padding: 10px 20px;
}

.infos h6 {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 11px;
	margin-bottom: 3px;
}

.infos h2 {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 14px;
}

h4 {
	width: 100%;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	background: #f5f6fa;
	padding: 10px 0;
}

.table-parcelas {
	width: 100%;
	text-align: center;
}

.table-parcelas th {
	color: #031b4e;
	font-family: "Roboto Black";
	font-size: 14px;
	height: 50px;
}

tr:nth-child(even) {
	background-color: #f9f9f9;
}

.table-parcelas td {
	color: #5b6987;
	font-family: "Roboto";
	font-size: 14px;
	height: 30px;
}

.parcelas-box {
	max-height: 400px;
	overflow-y: auto;
}
</style>
