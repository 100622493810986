<template>
	<div class="small-button-totals" :class="menuSideButton ? 'menu-side-button' : ''" style="margin-right: 30px" :style="color">
		<div :title="title" class="small-button-totals">
			<span>{{ title }}</span>
			<i :class="icon" />
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "ExpandingButton",
	props: {
		icon: {
			type: String,
			default: "fas fa-phone"
		},
		title: {
			type: String,
			default: "Fale com nosso atendimento"
		},
		color: {
			type: Object,
			default: {
				"--color": "#ffffff",
				"--hoverColor": "#5938c8"
			}
		},
		menuSideButton: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		...mapState({
			parceiro: (state) => state.parceiroConfig.nome,
			parceiroConfig: (state) => state.parceiroConfig,
			corPrimaria: (state) => state.corPrimaria,
			corSecundaria: (state) => state.corSecundaria
		})
	}
};
</script>

<style lang="scss">
.small-button-totals {
	width: 50px;
	height: 50px;
	background: var(--color);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
	transition: all 0.8s ease-in;

	&:hover {
		width: 18rem;
		background: var(--hoverColor);
		span {
			visibility: visible;
			opacity: 1;
			width: fit-content;
			overflow: visible;
		}
		i {
			visibility: hidden;
		}
	}

	span {
		transition: all 0.5s ease-in 0.6s;
		visibility: hidden;
		opacity: 0;
		width: 0px;
		font-size: 14px;
		font-family: "Montserrat-Bold";
		color: var(--whiteColor);
		overflow: hidden;
		position: absolute;
	}

	i {
		transition: visibility 0.3s ease-in 0.6s;
		visibility: visible;
		font-size: 25px;
		margin: 0 10px;
		color: var(--hoverColor);
	}
}
.menu-side-button {
	@media only screen and (max-width: 1695px) and (min-width: 1270px) {
		&:hover {
			width: 50px !important;
			span {
				visibility: hidden !important;
			}
			i {
				visibility: visible !important;
				color: var(--whiteColor) !important;
			}
		}
	}
}
</style>
