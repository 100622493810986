<template>
  <div class="row-steppers">
    <div v-for="(step, index) in steps" class="step">
      <div :class="((index+1) == currentlyStep)? 'circle': ((index+1) < currentlyStep)? 'check-circle':'opaque-circle'">
        <i v-if="((index+1) < currentlyStep)" class="fas fa-check icon-circle"></i>
        <h5 v-else class="icon-circle">{{ index+1 }}</h5>
      </div>
      <h3 :class="((index+1) <= currentlyStep)? 'title-step':'title-step-gray'">{{ step.title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepperHeader",
  props: [
    'steps',
    'currentlyStep'
  ],
  data() {
    return {
      modal: false
    }
  },
}
</script>

<style scoped>
.row-steppers{
  display: flex;
}

.step{
  display: flex;
  margin-right: 15px;
  align-items: center;
}

.circle {
  height: 40px;
  width: 40px;
  background-color: #00131E;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.check-circle {
  height: 40px;
  width: 40px;
  background-color: #31DE95;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.opaque-circle {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon-circle{
  color: white;
}

.title-step {
  font-family: Cabin;
  font-size: 18px;
  color: var(--grayColor);
  text-align: center;
}

.title-step-gray {
  font-family: Cabin;
  font-size: 18px;
  color: #DFE6E9;
  text-align: center;
}


</style>
