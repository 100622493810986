<template>
	<div class="tutorial-item flip-card" >

		<div class="flip-card-inner" :style="parceiroConfig.identificador === 'mw' ? { 'border': '5px rgb(215, 219, 8) solid'} : ''">
			<div
				class="flip-card-front"
				:style="corDeFundo">

				<div v-if="parceiroConfig.identificador === 'aqui'" :style="estiloCardPasso2">
					<img
						id="imagem-titulo"
						:src="require(`@/assets/images/parceiros/negociar/imagens_passos/passo_${codigo}.png`)"
						:alt="`@/assets/images/parceiros/negociar/imagens_passos/passo_${codigo}.png`" />
				</div>

				<div v-else>
					<h2>{{ codigo + 1 }}</h2>
					<h5>{{ title }}</h5>
				</div>
			</div>
			<div
				class="flip-card-back"
				:style="parceiroConfig.identificador === 'mw' ? {'background-color': '#fff'} : ''">
				<h6
					:style="parceiroConfig.identificador === 'mw' ? {'color': 'black'} : ''">{{ text }}</h6>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "FlipCard",

	props: {
		codigo: Number,

		title: String,

		text: String
	},

	computed: {
		...mapState({
			parceiro: (estado) => estado.parceiroConfig.nome,
			parceiroConfig: (estado) => estado.parceiroConfig,
			corPrimaria: (estado) => estado.corPrimaria
		}),

		corDeFundo() {
			if (this.parceiroConfig.identificador === "aqui") {
				return {
					'background-color': "#2E363D"
				}
			}
		},

		estiloCardPasso2() {
			if (this.parceiroConfig.identificador === "aqui" && this.codigo === 2) {
				return {
					'margin-top': '100px'
				}	
			}
		}
	}
};
</script>

<style lang="scss">
.flip-card {
	background-color: transparent;
	width: 250px;
	height: 300px;
	perspective: 1000px;
	h2 {
		color: var(--hoverColor);
		font-size: 91px;
		font-family: "Montserrat-Bold";
	}
	h5 {
		color: var(--hoverColor);
		font-size: 18px;
		font-family: "Montserrat-Bold";
		margin-bottom: 10px;
	}
	h6 {
		font-size: 16px;
		font-family: "Montserrat-Bold";
		color: var(--whiteColor);
	}
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	margin-left: 10px;
	margin-right: 10px;
	@media only screen and (max-width: 766px) {
		margin: 10px;
	}
	@media only screen and (max-width: 240px) {
		width: 200px;
	}
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	align-items: center;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.flip-card-front {
	background-color: var(--whiteColor);
}

.flip-card-back {
	background-color: var(--color);
	color: white;
	transform: rotateY(180deg);
}
</style>