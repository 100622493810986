import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/app.scss";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pt_BR from "vee-validate/dist/locale/pt_BR";
import VueGtm from "@gtm-support/vue2-gtm";

import filtros from "./filtros.js";
import mixins from "@/mixins/mixins"

import axios from './services/axiosAdmin';

let subdominio = process.env.VUE_APP_STANDARD_PARTNER;
if (window.location.host.includes("portalexecutivarecuperacao")) {
    subdominio = "executiva";
} else if (window.location.host.includes("resolvaomni")) {
    subdominio = "validu";
} else if (window.location.host.split(".")[0] == 'www' && window.location.host.split(".")[1] != 'aquicob') {
    subdominio = window.location.host.split(".")[1];
} else if (window.location.host.split(".").length > 2) {
    subdominio = window.location.host.split(".")[0] === "www" ? process.env.VUE_APP_STANDARD_PARTNER : window.location.host.split(".")[0];
}

if (window.location.host == 'negociar.senff.com.br') {
    subdominio = 'senff';
}

axios.get(`/api/parceiro/` + subdominio + `/info`).then((resposta) => {
    if (resposta.data.data) {
        store.state.portalCamec = resposta.data.data.emp_codigo == 350;

        let header = resposta.data.data.sessoes.find((sessao) => sessao.nome === "header");
        let about = resposta.data.data.sessoes.find((sessao) => sessao.nome === "about");
        let partners = resposta.data.data.sessoes.find((sessao) => sessao.nome === "partners");
        let contact = resposta.data.data.sessoes.find((sessao) => sessao.nome === "contact");
        let tutorial = resposta.data.data.sessoes.find((sessao) => sessao.nome === "tutorial");
        let logoForBackgroundWhite = resposta.data.data.sessoes.find((sessao) => sessao.nome === "logoForBackgroundWhite");
        let customization = resposta.data.data.sessoes.find((sessao) => sessao.nome === "customization");
        let omniChannel = resposta.data.data.sessoes.find((sessao) => sessao.nome === "omniChannel");

        if (subdominio == "aquicob") {
            resposta.data.data.sessoes = {
                header: header,
                about: about,
                partners: partners,
                contact: contact,
                tutorial: tutorial,
                creditor: true,
                logoForBackgroundWhite: logoForBackgroundWhite,
                customization: customization,
                omniChannel: omniChannel
            };

        } else {
            resposta.data.data.sessoes = {
                header: header,
                about: about,
                partners: partners,
                contact: contact,
                tutorial: tutorial,
                creditor: null,
                logoForBackgroundWhite: logoForBackgroundWhite,
                customization: customization,
                omniChannel: omniChannel
            };
        }

        store.state.nomeParceiroContratos = resposta.data.data.sessoes.customization.campos.landPage.textNomeParceiroContratos != '' &&
                                            resposta.data.data.sessoes.customization.campos.landPage.textNomeParceiroContratos != null &&
                                            resposta.data.data.sessoes.customization.campos.landPage.textNomeParceiroContratos != undefined ?
                                            resposta.data.data.sessoes.customization.campos.landPage.textNomeParceiroContratos : 'Aquicob';

        store.commit('SET_PARCEIRO_CONFIG', resposta.data.data);

        if (resposta?.data?.data?.hotjar_id != null) {
            Vue.use(Hotjar, {
                id: resposta?.data?.data?.hotjar_id
            });
        }

        store.commit('SET_COR_PRIMARIA', {
            "--color": resposta.data.data.estilo.corPrimaria,
            "--hoverColor": resposta.data.data.estilo.corSecundaria
        });

        store.commit('SET_COR_SECUNDARIA', {
            "--color": resposta.data.data.estilo.corSecundaria,
            "--hoverColor": resposta.data.data.estilo.corPrimaria
        });

        store.commit('CHANGE_MESSAGE_SNACKBAR', '');

        if (resposta.data.data.identificador === "aqui") {
            document.title = "Negociar on-line";

            document.querySelector("link[rel='icon']").href = document.baseURI + "logo-negociar.png"
        }

        Vue.use(VueGtm, {
            id: "GTM-N58G92Z",
            enabled: true,
            debug: false,
            vueRouter: router,
        });
        
        Object.keys(rules).forEach((rule) => {
            extend(rule, rules[rule]);
        });
        
        localize("pt_BR", pt_BR);
        
        Vue.config.productionTip = false;
        
        Vue.filter("formatarData", filtros.formatarData);
        
        Vue.filter("formatarDinheiro", filtros.formatarDinheiro);
        
        Vue.filter("formatarCpf", filtros.formatarCpf);
        
        Vue.mixin(mixins);
        
        new Vue({
            router,
            store,
            render: (h) => h(App),
        }).$mount("#app");
    } else {
        store.commit('CHANGE_MESSAGE_MODAL', {
            show: true,
            title: 'Erro Crítico',
            message: 'Erro crítico ao carregar dados do portal.',
            style: 'danger'
        });
    }
}).catch((_) => {
    store.commit('CHANGE_MESSAGE_MODAL', {
        show: true,
        title: 'Erro Crítico',
        message: 'Erro crítico ao carregar dados do portal.',
        style: 'danger'
    });
});
