<template>
	<div class="about container">
		<div class="about-text">
			<div>
				<h3 :style="parceiroConfigCorSecundariaCorPrimaria">{{ titulo }}</h3>

				<div v-if="parceiro && parceiroConfig.sessoes.about.campos">
					<ul v-for="text in parceiroConfig.sessoes.about.campos.text.split('\n')">
						<li>{{ text }}</li>
					</ul>
				</div>

				<p v-else>{{ nomeParceiroContratos }} é uma empresa de autonegociação inovadora que reabilita clientes desde 2015 ao mercado de crédito.
					 Trazendo facilidades e comodidade ao consumidor com interesse em reabilitar seu cadastro ao mercado.
					 Em uma plataforma segura, basta 4 passos para você ter acesso a uma proposta, identificando valor e empresa constante e optar pela liquidação ou parcelamento de forma rápida e pratica.
					 Milhares de pessoas já estão ativas novamente no mercado após quitarem seus débitos utilizando nossa plataforma.</p>

				<button-lg
					:color="corPrimaria"
					style="margin-top: 20px"
					@click.native="goToSection('contact')"
					text="Fale Conosco" ></button-lg>
			</div>
		</div>

		<div class="about-img">
			<img
				v-if="parceiro && parceiroConfig.sessoes.about.arquivos.length > 0"
				key="portal-parceiro-tem-foto"
				:src="parceiroConfig.sessoes.about.arquivos[0].arquivo" />

			<img
				v-else
				key="portal-parceiro-sem-foto"
				src="@/assets/images/stock-photo.jpg" />
		</div>

	</div>
</template>

<script>
import mixins from "@/mixins/mixins";
import ButtonLg from "@/components/buttons/ButtonLg";

export default {
	name: "LandPageAbout",

	components: { ButtonLg },

	mixins: [mixins],
	
	computed: {
		titulo() {
			return this.parceiro && this.parceiroConfig.sessoes.about.campos ? this.parceiroConfig.sessoes.about.campos.title : `Conheça mais sobre o ${this.nomeParceiroContratos}`;
		}
	},

	methods: {
		goToSection(secao) {
			var element = this.$parent.$refs[secao];
			var top = element.offsetTop;
			element.scrollIntoView({ behavior: "smooth", top });
		}
	}
};
</script>

<style scoped lang="scss">
.about {
	background: var(--whiteColor);
	display: flex;
}

.about-text {
	width: 450px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	@media only screen and (max-width: 1024px) {
		width: 100%;
	}
}

.about-text p {
	color: var(--grayColor);
	font-family: Cabin;
	font-size: 16px;
	line-height: 24px;
	text-align: justify;
}

.about-img {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.about-img img {
	width: 600px;
	border-radius: 15px;
	@media only screen and (max-width: 1370px) {
		width: 500px;
		padding-left: 50px;
	}
	@media only screen and (max-width: 1180px) {
		width: 400px;
		padding-left: 50px;
	}
	@media only screen and (max-width: 1024px) {
		display: none;
		padding-left: 0;
	}
}

.about-text h3 {
	font-size: 32px;
	font-family: "Montserrat-Bold";
	color: var(--secondaryColor);
	line-height: 48px;
	margin-bottom: 30px;
	font-weight: normal;
	@media only screen and (max-width: 766px) {
		font-size: 28px;
	}
}

@media only screen and (min-width: 1400px) {
	.about-text {
		flex: 1;
		padding-right: 50px;
		align-items: flex-end;
	}

	.about-text div {
		width: 450px;
	}

	.about-img {
		flex: 1;
		justify-content: flex-start;
		padding-left: 50px;
	}
}

li {
	list-style-type: disc;
	color: var(--grayColor);
	font-family: Cabin;
	font-size: 16px;
	line-height: 24px;
	text-align: justify;
}
</style>
