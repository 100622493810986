<template>
	<div class="login-background flex-center">

		<validation-observer
			class="login-box"
			v-slot="{ passes }">

			<img src="../assets/images/logo.png" />

			<form>
				<text-field
					:solidColor="true"
					label="Usuário"
					:type="'text'"
					:rules="'required'"
					:validate="validate"
					@update:value="username = $event"
					@onEnter="passes(postLogin)" ></text-field>

				<text-field
					:solidColor="true"
					label="Senha"
					:type="'password'"
					:rules="'required'"
					:validate="validate"
					@update:value="password = $event"
					@onEnter="passes(postLogin)" ></text-field>
			</form>

			<button-default
				class="btn-login"
				@click.native="passes(postLogin)"
				text="Acessar Conta" ></button-default>

		</validation-observer>
	</div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import { mapActions, mapMutations } from "vuex";
import mixins from "@/mixins/mixins";

import TextField from "../components/inputs/TextField";
import ButtonDefault from "../components/buttons/ButtonDefault";

export default {
	name: "Login",

	components: { ButtonDefault, TextField, ValidationObserver },

	mixins: [mixins],

	data() {
		return {
			username: "",
			password: "",
			validate: false
		};
	},

	mounted() {
		this.SET_COR_PRIMARIA({
			"--color": "#00c0fb",
			"--hoverColor": "#5938c8"
		});

		this.SET_COR_SECUNDARIA({
			"--color": "#5938c8",
			"--hoverColor": "#00c0fb"
		});

		if (localStorage.getItem("access_token")) {
			localStorage.removeItem("access_token");
			location.reload();
		}
	},

	methods: {
		...mapMutations(["SET_COR_PRIMARIA", "SET_COR_SECUNDARIA"]),

		...mapActions(["action_login"]),

		postLogin() {
			this.SET_LOADING(true);
			this.SET_LOADING_MESSAGE("Realizando login.");
			this.action_login({
				username: this.username,
				password: this.password

			}).then((resposta) => {
				localStorage.setItem("access_token", resposta.data.access_token);
				localStorage.setItem("user_id", resposta.userId);
				this.$router.push({ name: "ListPartners" });
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);

			}).catch((_) => {
				this.SET_LOADING_MESSAGE("");
				this.SET_LOADING(false);
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Ocorreu um erro",
					message: `Usuário ou senha inválido`,
					styleMessage: "danger"
				});
			});
		}
	}
};
</script>

<style scoped>
.login-box {
	width: 320px;
	display: flex;
	flex-direction: column;
}

.login-box img {
	margin-bottom: 40px;
}

.login-background {
	width: 100%;
	height: 100vh;
	background: #050b17;
}

.btn-login {
	margin-top: 20px !important;
}
</style>
