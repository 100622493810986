<template>
	<div>
		<div>

			<land-page-slider ></land-page-slider>

			<section
				:ref="'about'"
				class="section">
				<land-page-about ></land-page-about>
			</section>

			<section :ref="'tutorial'">
				<land-page-tutorial ></land-page-tutorial>
			</section>

			<section class="section">
				<land-page-partner ></land-page-partner>
			</section>

			<section :ref="'contact'">
				<land-page-contact ></land-page-contact>
			</section>

			<section>
				<land-page-footer ></land-page-footer>
			</section>
		</div>
	</div>
</template>

<script>
import mixins from "@/mixins/mixins";

import LandPageHeader from "./components/LandPageHeader";
import LandPageSlider from "./components/LandPageSlider";
import LandPageAbout from "./components/LandPageAbout";
import LandPageTutorial from "./components/LandPageTutorial";
import LandPagePartner from "./components/LandPagePartner";
import LandPageContact from "./components/LandPageContact";
import LandPageFooter from "./components/LandPageFooter";

export default {
	name: "Home",

	components: {
		LandPageFooter,
		LandPageContact,
		LandPagePartner,
		LandPageTutorial,
		LandPageAbout,
		LandPageSlider,
		LandPageHeader
	},

	mixins: [mixins]
};
</script>

<style scoped>
.section {
	padding-top: 60px;
	padding-bottom: 60px;
}
</style>
