<template>

	<div style="display: flex; flex-direction: column" >

		<div class="btnIntegraRobo" >

			<expanding-button
				v-if="parceiroConfig.sessoes.customization.campos.landPage.flgIntegraRobo"
				:icon="'fab fa-whatsapp'"
				:title="'Fale com nosso atendimento'"
				:color="color"
				@click.native="connectWithBot()"
			></expanding-button>

		</div>

		<div
			v-if="parceiroConfig.identificador == 'mw'"
			class="btnRoboMw">

			<small-button
				:icon="'fab fa-whatsapp'"
				:title="'Fale com nosso atendimento'"
				:color="color"
				:botaoWhatsapp="true"
				@click.native="connectWithBot()" ></small-button>

		</div>

		<div
			v-if="!parceiro || parceiro === 'aquicob'"
			class="total-box container"
		>

			<div class="total">
				<h3>+1.3 milhão</h3>
				<h4>Negociações efetuadas</h4>
			</div>

			<div class="total">
				<h3>+4 mil</h3>
				<h4>Empresas cadastradas</h4>
			</div>

			<div class="total">
				<h3>+3.6 milhões</h3>
				<h4>CPF's cadastrados</h4>
			</div>

		</div>

	</div>

</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";

import ExpandingButton from "@/components/buttons/ExpandingButton.vue";
import SmallButton from "@/components/buttons/SmallButton.vue";

export default {
	name: "LandPageTotals",

	components: { ExpandingButton, SmallButton },

	mixins: [mixins],

	data() {
		return {
			color: {
				"--color": "#ffffff",
				"--hoverColor": "#25D366"
			}
		};
	},

	computed: {
		...mapState({parceiroConfig: (estado) => estado.parceiroConfig})
	},

	methods: {
		connectWithBot() {
			if (this.parceiroConfig.sessoes.contact.campos.whatsapp) {
				let janela = window.open(`https://api.whatsapp.com/send?phone=55${this.removeMaskCharacters(this.parceiroConfig.sessoes.contact.campos.whatsapp)}
											&text=Olá estou vindo do portal ${this.parceiro.toUpperCase()}.`, "_blank");
				janela.focus();
			} else {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Ocorreu um erro",
					message: 'Não foi possível encontrar o número de Whatsapp da assessoria! ',
					styleMessage: "danger"
				});
			}
		}
	}
};
</script>

<style scoped lang="scss">
.total-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 120px;
	width: 100%;
	padding-right: 5%;
	@media only screen and (max-width: 700px) {
		display: none;
	}
	@media only screen and (max-height: 430px) {
		display: none;
	}
}

.total {
	text-align: center;
	color: var(--whiteColor);
}

.total h3 {
	color: var(--whiteColor);
	font-size: 38px;
	font-family: "Montserrat-Bold";
}

.total h4 {
	font-size: 16px;
	font-family: Cabin;
	color: var(--lightGrayColor);
}

.btnIntegraRobo {
	align-self: end;
	margin: 0 8% 10px 0;
	display: flex;

	@media screen and (max-width: 700px) {
		display: none;
	}
}

.btnRoboMw {
	@media (min-width: 700px) {
		display: none;
	}

	@media (max-width: 700px) {
		display: inline;
	}
}
</style>
