import configAxios from "axios";
import store from "../store";

const apiInstanceAdmin = configAxios.create({
	baseURL: process.env.VUE_APP_API_URL_ADMIN,
});

apiInstanceAdmin.interceptors.request.use((config) => {
	const bearerToken = localStorage.getItem("access_token");
	if (
		bearerToken ||
		bearerToken !== "undefined" ||
		bearerToken !== "" ||
		bearerToken != null
	) {
		config.headers.Authorization = `Bearer ${bearerToken}`;
	}
	return config;
});

apiInstanceAdmin.interceptors.response.use(
	(response) => response,
	(error) => {
		const { response } = error;
		let msg = {
			show: true,
			title: "Atenção",
			styleMessage: "danger",
			message:
				response.data.message ===
				"Call to a member function toArray() on null"
					? "URL não encontrada."
					: response.data?.message ||
					  "Ocorreu um erro. Tente novamente",
		};
		if (msg.message != "Unauthenticated")
			store.dispatch("action_openMessageModal", msg);
	}
);

export default apiInstanceAdmin;
