import Vue from "vue";
import Vuex from "vuex";
import axios from "../services/axios";
import axiosAdmin from "../services/axiosAdmin";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import passosRetornarDividas from "./passosRetornarDividas";

const ls = new SecureLS({
  isCompression: false
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  modules: {
    passosRetornarDividas
  },
  state: {
    globalToken: process.env.VUE_APP_GLOBAL_TOKEN,
    corPrimaria: {
      "--color": null,
      "--hoverColor": null,
    },
    corSecundaria: {
      "--color": null,
      "--hoverColor": null,
    },
    parceiroConfig: null,
    AquiCobConfig: null,
    slider: null,
    escolhaFiltro: null,
    dividaSelecionada: "",
    acordoSelecionado: "",
    receberLigacao: false,
    naoPossoPagar: false,
    empresas: [],
    loading: false,
    token: false,
    loadingMessage: "",
    leads: [],
    messageModal: {
      show: false,
      title: "",
      message: "",
      styleMessage: "danger",
    },
    messageSnackbar: {
      show: false,
      message: "",
    },
    user: {
      id: null,
      name: null,
    },
    motivoReceberLigacao: "",
    portalCamec: false,
    nomeParceiroContratos: "",
    ultimoSalvaEtapa: {}
  },
  mutations: {
    SET_ACERTE_AQUI_CONFIG(state, payload) {
      state.AquiCobConfig = payload;
    },
    SET_PARCEIRO_CONFIG(state, payload) {
      state.parceiroConfig = payload;
    },
    SET_COR_PRIMARIA(state, payload) {
      state.corPrimaria = payload;
    },
    SET_COR_SECUNDARIA(state, payload) {
      state.corSecundaria = payload;
    },
    SET_RECEBER_LIGACAO(state, payload) {
      state.receberLigacao = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_ACORDO_SELECIONADO(state, payload) {
      state.acordoSelecionado = payload;
    },
    SET_NAO_POSSO_PAGAR(state, payload) {
      state.naoPossoPagar = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADING_MESSAGE(state, payload) {
      state.loadingMessage = payload;
    },
    SET_SLIDER(state, payload) {
      state.slider = payload;
    },
    SET_ESCOLHA_FILTRO(state, payload) {
      state.escolhaFiltro = payload;
    },
    SET_DIVIDA_SELECIONADA(state, payload) {
      state.dividaSelecionada = payload;
    },
    SET_EMPRESAS(state, payload) {
      state.empresas = payload;
    },
    SET_LEADS(state, payload) {
      state.leads = payload;
    },
    SET_MOTIVO_RECEBER_LIGACAO(state, payload) {
      state.motivoReceberLigacao = payload;
    },
    CHANGE_MESSAGE_SNACKBAR(state, payload) {
      state.messageSnackbar.show = !state.messageSnackbar.show;
      state.messageSnackbar.message = payload;
      if (state.messageSnackbar.message == "")
        state.messageSnackbar.show = false;
    },
    CHANGE_MESSAGE_MODAL(state, payload) {
      state.messageModal = payload;
    },
  },
  actions: {
    action_openMessageModal(context, payload) {
      const msg = {
        show: true,
        styleMessage: payload.styleMessage,
        title: payload.title,
        message: payload.message,
      };
      if (payload.title === undefined && payload.message === undefined) {
        msg.title = "Ocorreu um erro!";
        msg.message =
          "Verifique a sua conexão com a internet e tente novamente mais tarde.";
      } else if (
        (payload.message === undefined || payload.message === null) &&
        payload.title !== undefined
      ) {
        msg.title = "Atenção !";
        msg.message = title;
      }
      context.commit("CHANGE_MESSAGE_MODAL", msg);
    },
    async action_getTelefone(context, payload) {
      context.commit("SET_ACORDOS", []);
      context.commit("SET_LOADING", true);
      return await axios
        .post(`/api.php?portal=1&a=portal&b=getTelefone`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_cadastrarLead(context, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=cadastrarLead`, payload)
        .then((response) => {
          if (response.data.retorno) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Proposta cadastrada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          } else {
            context.commit("CHANGE_MESSAGE_MODAL", {
              show: true,
              title: "Atenção",
              message: response.data.mensagem,
              styleMessage: "danger",
            });
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_login(_, payload) {
      return await axiosAdmin
        .post(`/api/login`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_alterar_senha(context, payload) {
      return await axiosAdmin
        .put(`/api/editar_senha`, payload)
        .then((response) => {
          if (response) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Senha atualizada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          context.commit("CHANGE_MESSAGE_MODAL", {
            show: true,
            title: "Atenção",
            message: error.response.data.message,
            styleMessage: "danger",
          });
        });
    },
    async action_cadastro_parceiro(_, payload) {
      return await axiosAdmin
        .post(`/api/parceiro`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_editar_parceiro(_, payload) {
      return await axiosAdmin
        .put(`/api/parceiro/` + payload.id, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_deletar_parceiro(_, payload) {
      return await axiosAdmin
        .delete(`/api/parceiro/` + payload.id, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_buscar_parceiro(_, payload) {
      return await axiosAdmin
        .get(`/api/parceiro/` + payload.id + `/info`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_buscar_todos_parceiros(_, payload) {
      return await axiosAdmin
        .get(`/api/parceiro`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_buscar_dados_pagina_parceiro(_, payload) {
      return await axiosAdmin
        .get(`api/parceiro/${payload.parceiro}/info`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_atualizar_dados_cadastrais(context, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=atualizarDadosCadastrais`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Dados atualizados com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_cadastrar_proposta(context, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=cadastrarProposta`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit('CHANGE_MESSAGE_MODAL', {
              show: true,
              title: 'Sucesso',
              message: 'Proposta enviado com sucesso',
              styleMessage: 'success'
            });
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          } else {
            context.commit('CHANGE_MESSAGE_MODAL', {
              show: true,
              title: 'Erro',
              message: 'Não foi possível enviar a proposta',
              styleMessage: 'danger'
            });
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_solicitar_ligacao(context, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=solicitarLigacao`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Solicitação enviada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_contato_whatsapp(context, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=contatoWhatsapp`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Solicitação enviada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_buscar_duvidas(context, payload) {
      return await axios
        .post(`/api.php?a=portal&b=getDuvidas`, payload)
        .then((response) => {
          if (response.data === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Dados recebidos com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_cadastrar_duvida(context, payload) {
      return await axios
        .post(`/api.php?a=portal&b=cadastrarDuvida`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Duvida frequente adicionada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_deletar_duvida(context, payload) {
      return await axios
        .post(`/api.php?a=portal&b=deletarDuvida`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Duvida frequente deletada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_editar_duvida(context, payload) {
      return await axios
        .post(`/api.php?a=portal&b=atualizarDuvida`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Duvida frequente deletada com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_cadastrar_credor(context, payload) {
      return await axios
        .post(`/api.php?a=portal&b=cadastrarEmpresaCredora`, payload)
        .then((response) => {
          if (response.data.retorno === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Credor adicionado com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_buscar_empresas(context, payload) {
      return await axios
        .post(`/api.php?a=portal&b=getEmpresas`, payload)
        .then((response) => {
          if (response.data === true) {
            context.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Dados recebidos com sucesso!"
            );
            setTimeout(
              () => context.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_buscar_cep(contexto, payload) {
      return await axios
        .post(`/api.php?a=portal&b=consultarCEP`, payload)
        .then((response) => {
          return response;
        })
        .catch(erro => {
          contexto.commit("CHANGE_MESSAGE_MODAL", {
            show: true,
            title: "Erro",
            message: erro,
            styleMessage: "danger"
          });
          return erro;
        });
    },
    async action_buscar_planos(contexto, payload) {
      return await axios
        .post(`/api.php?a=portal&b=getPlanosAtivos`, payload)
        .then((response) => {
          if (response.data === true) {
            contexto.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Dados recebidos com sucesso!"
            );
            setTimeout(
              () => contexto.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch(erro => {
          contexto.commit("CHANGE_MESSAGE_MODAL", {
            show: true,
            title: "Erro",
            message: erro,
            styleMessage: "danger"
          });
          return erro;
        });
    },
    async action_buscar_empresa_credora(contexto, payload) {
      return await axios
        .post(`/api.php?a=portal&b=getEmpresasCredoras`, payload)
        .then((response) => {
          if (response.data === true) {
            contexto.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Dados recebidos com sucesso!"
            );
            setTimeout(
              () => contexto.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch(erro => {
          contexto.commit("CHANGE_MESSAGE_MODAL", {
            show: true,
            title: "Erro",
            message: erro,
            styleMessage: "danger"
          });
          return erro;
        });
    },
    async action_cadastrar_novo_credor(contexto, payload) {
      return await axios
        .post(`/api.php?a=portal&b=cadastrarCliente`, payload)
        .then((response) => {
          if (response.data === true) {
            contexto.commit(
              "CHANGE_MESSAGE_SNACKBAR",
              "Dados recebidos com sucesso!"
            );
            setTimeout(
              () => contexto.commit("CHANGE_MESSAGE_SNACKBAR", ""),
              5000
            );
          }
          return response;
        })
        .catch(erro => {
          contexto.commit("CHANGE_MESSAGE_MODAL", {
            show: true,
            title: "Erro",
            message: erro,
            styleMessage: "danger"
          });
          return erro;
        });
    },
    async acaoSalvarEtapa(contexto, payload) {
      if (contexto.state.parceiroConfig.loj_codigo != null &&
        contexto.state.parceiroConfig.loj_codigo != '') {
        if (contexto.state.parceiroConfig.loj_codigo.includes(',')) {
          contexto.state.parceiroConfig.loj_codigo.split(',').forEach(async loj_codigo => {
            payload.loj_codigo = loj_codigo;
            await axios
              .post(`/api.php?portal=1&a=portal&b=salvarEtapa`, payload)
              .then((response) => {
                contexto.state.ultimoSalvaEtapa = payload;
                return response;
              })
              .catch((error) => {
                return error;
              });
          });
        } else {
          payload.loj_codigo = contexto.state.parceiroConfig.loj_codigo;
          await axios
            .post(`/api.php?portal=1&a=portal&b=salvarEtapa`, payload)
            .then((response) => {
              contexto.state.ultimoSalvaEtapa = payload;
              return response;
            })
            .catch((error) => {
              return error;
            });
        }
      } else if (contexto.state.lojas != null && contexto.state.lojas != '') {
        contexto.state.lojas.forEach(async loja => {
          payload.loj_codigo = loja.loj_codigo;
          await axios
            .post(`/api.php?portal=1&a=portal&b=salvarEtapa`, payload)
            .then((response) => {
              contexto.state.ultimoSalvaEtapa = payload;
              return response;
            })
            .catch((error) => {
              return error;
            });
        });
      } else {
        await axios
          .post(`/api.php?portal=1&a=portal&b=salvarEtapa`, payload)
          .then((response) => {
            contexto.state.ultimoSalvaEtapa = payload;
            return response;
          })
          .catch((error) => {
            return error;
          });
      }
    },
    async action_enviar_email(_, payload) {
      return await axios
        .post(`/api.php?a=mail&b=envioPortal`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_enviar_sms(_, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=enviarSMS`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_consulta_token(_, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=consultaToken`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_atualizar_telefone(_, payload) {
      return await axios
        .post(`/api.php?portal=1&a=portal&b=atualizarTelefone`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    async action_enviar_token_portal_sms(_, payload) {
      return await axios
        .post('/api.php?portal=1&a=portal&b=enviarTokenPortalSMS', payload)
        .then(response => {
          return response;
        }).catch(error => {
          return error;
        })
    },
    async action_enviar_boletos_portal_sms(_, payload) {
      return await axios
        .post('/api.php?portal=1&a=portal&b=enviarBoletosPortalSMS', payload)
        .then(response => {
          return response;
        }).catch(error => {
          return error;
        })
    }
  }
});