<script>
import moment from "moment";
export default {
    methods: {
        dateNowFormat(format) {
            return moment().format(format);
        },
        dateTimeTransform(date, format) {
            return moment(date).format(format);
        },
        dateISOFormat(date, format) {
            return moment(date, format).toISOString();
        },

        dateFormat(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        dateTimeFormat(date) {
            return moment(date).format('DD/MM/YYYY HH:mm:ss');
        },

        dateEnFormat(date, format) {
            return moment(date, format).format('YYYY-MM-DD');
        },
        convertStringFilter(value) {
            return value && value.length > 0 ? value : null
        },
        convertArrayObjectFilter(object, key) {
            return object && object[key] && object[key] !== null && object[key] !== '' ? object[key] : null;
        },
        money2(value) {
            let val = value;
            return parseFloat(val).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        removeMaskMoney(string) {
            if (!string) {
                return null
            }
            let value = string.toString().split('.').join('').split(',').join('').split('R$ ').join('').split(' ').join('');
            return parseInt(value);
        },
        removeMaskCharacters(string) {
            if (!string) {
                return null
            }
            return string.toString().split('(').join('').split(')').join('').split('.').join('').split(',').join('').split('R$ ').join('').split(' ').join('').split('-').join('');

        },
        removeObjectFromArray(array, key, value) {
            return array.filter(e => e[key] !== value)
        },
        findObjectFromArray(array, key, value) {
            return array.filter(e => e[key] === value)[0]
        },
        hasAnyPermission(requiredPermissions) {
            return [].includes(requiredPermissions);
        },
        hasPermission(requiredPermissions) {
            let n = requiredPermissions.length;
            requiredPermissions.forEach(perm => {
                if ([].includes(perm)) {
                    n--;
                }
            });
            return (n === 0);
        },
        validateCpf(value) {
            if (value.length === 14) {
                value = value.split('.').join('').split('-').join('').split('/').join('');
                var Soma;
                var Resto;
                var i;
                Soma = 0;
                var strCPF = value;
                if (strCPF == "00000000000" ||
                    strCPF == "11111111111" ||
                    strCPF == "22222222222" ||
                    strCPF == "33333333333" ||
                    strCPF == "44444444444" ||
                    strCPF == "55555555555" ||
                    strCPF == "66666666666" ||
                    strCPF == "77777777777" ||
                    strCPF == "88888888888" ||
                    strCPF == "99999999999")
                    return false;
                for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                Resto = (Soma * 10) % 11;
                if ((Resto == 10) || (Resto == 11)) Resto = 0;
                if (Resto != parseInt(strCPF.substring(9, 10))) return false;
                Soma = 0;
                for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                Resto = (Soma * 10) % 11;
                if ((Resto == 10) || (Resto == 11)) Resto = 0;
                if (Resto != parseInt(strCPF.substring(10, 11))) return false;
                return true;
            }
            return false;
        },
        cpfMask(value) {
            if (value && value.length === 11) {
                return value.substring(0, 3) + '.' + value.substring(3, 6) + '.' + value.substring(6, 9) + '-' + value.substring(9, 11);
            }
            return '---';
        },
        cnpjMask(value) {
            if (value && value.length === 14) {
                return (
                    value.substring(0, 2) +
                    "." +
                    value.substring(2, 5) +
                    "." +
                    value.substring(5, 8) +
                    "/" +
                    value.substring(8, 12) +
                    "-" +
                    value.substring(12, 14)
                );
            }
            return "---";
        },
        phoneMask(v) {
            return v.replace(/(\d)(\d{4})$/, "$1-$2");
        }
    }
};
</script>

