<template>
	<div class="box-register">

		<h2 :style="parceiroConfigCorSecundariaCorPrimaria">Ops! Dados não encontrados</h2>
		<h3>Preencha seus dados abaixo para cadastrar sua proposta</h3>

		<div class="box-forms-regiter">
			<form-fields
				:fields="fields"
				@save="completeRegister"
				@update:values="inputsValues = $event" ></form-fields>
		</div>

		<div class="box-button-register">
			<button-lg
				:color="corPrimaria"
				:full="true"
				@click.native="completeRegister()"
				text="Cadastrar Proposta" ></button-lg>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";

export default {
	name: "Register",

	components: { ButtonLg, FormFields, ButtonDefault },

	mixins: [globalMethods, mixins],

	data() {
		return {
			fields: [],
			identification: "",
			inputsValues: {}
		};
	},

	computed: {
		...mapState({
			showMessageModal: (estado) => estado.messageModal.show
		})
	},

	created() {
		if (!this.$route.params?.identification) {
			this.parceiro ? this.$router.go(-1) : this.$router.push({ name: "LandPage" });
		}
		this.acaoSalvarEtapa({
			cpfCnpj: this.removeMaskCharacters(this.identification),
			esn_fk_ets_codigo: 36,
			emp_codigo: this.parceiroConfig.emp_codigo,
			motivo_agendamento: 'Entrou no cadastro',
			data_agendamento: this.dataAtual,
			hora_agendamento: this.horaAtual
		});
	},

	mounted() {
		this.fields = [
			{
				variableName: "nome",
				fieldType: "TextField",
				label: this.$route.params.identification.length > 11 ? "Razão Social" : "Nome",
				type: "text",
				cols: "8"
			},
			{
				variableName: "cpf_cnpj",
				fieldType: "TextFieldMask",
				label: this.$route.params.identification.length > 11 ? "CNPJ" : "CPF",
				type: "text",
				cols: "4",
				maskFormat: this.$route.params.identification.length > 11 ? "##.###.###/####-##" : "###.###.###-##",
				disabled: true,
				valueField: this.$route.params?.identification ? this.$route.params.identification : undefined
			},
			{
				variableName: "email",
				fieldType: "TextField",
				label: "E-mail",
				type: "text",
				cols: "8"
			},
			{
				variableName: "cidade",
				fieldType: "TextField",
				label: "Cidade",
				type: "text",
				cols: "4"
			},
			{
				variableName: "celular",
				fieldType: "TextFieldMask",
				label: "Celular",
				maskFormat: "(##) #####-####",
				type: "text",
				cols: "6"
			},
			{
				variableName: "fone",
				fieldType: "TextFieldMask",
				label: "Telefone",
				maskFormat: "(##) ####-####",
				type: "text",
				cols: "6"
			},
			{
				variableName: "loja",
				fieldType: "TextField",
				label: "Nome do estabelecimento em que possui a dívida",
				type: "text",
				cols: "8"
			},
			{
				variableName: "valor",
				fieldType: "TextFieldMoney",
				label: "Valor da sua dívida",
				maskMoney: true,
				type: "text",
				cols: "4"
			},
			{
				variableName: "valor_pode_pagar",
				fieldType: "TextFieldMoney",
				label: "Valor máximo que pretende pagar",
				maskMoney: true,
				type: "text",
				cols: "5"
			},
			{
				variableName: "forma_pagamento",
				fieldType: "SelectField",
				label: "Forma de pagamento",
				options: [
					{ id: "A", name: "Boleto" },
					{ id: "P", name: "Cartão de crédito" }
				],
				keyName: "name",
				keyValue: "id",
				cols: "7"
			},
			{
				variableName: "obs",
				fieldType: "TextAreaField",
				label: "Observações",
				cols: "12"
			}
		];
	},

	methods: {
		...mapActions(["action_cadastrarLead"]),

		completeRegister() {
			const { nome, cpf_cnpj, email, cidade, celular, fone, loja, valor, valor_pode_pagar, forma_pagamento, obs } = this.inputsValues;

			let required = false;
			Object.entries(this.inputsValues).forEach(([key, value]) => {
				if (!this.showMessageModal && key !== "obs" && key !== "fone" && (!value || value === "")) {
					required = true;
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `O campo ${this.fields.filter((field) => field.variableName === key)[0].label} é obrigatório`,
						styleMessage: "danger"
					});
				}
			});

			if (!required) {

				if (/.+@.+\..+/.test(email) === false) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um e-mail válido`,
						styleMessage: "danger"
					});

				} else if (celular.length < 15) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um celular válido`,
						styleMessage: "danger"
					});

				} else if (valor_pode_pagar < 0 || valor < 0 || valor_pode_pagar.toString().length > 9 || valor.toString().length > 9) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um valor R$ válido`,
						styleMessage: "danger"
					});

				} else {
					this.SET_LOADING(true);
					this.SET_LOADING_MESSAGE("Registrando sua proposta");
					let obj = {
						nome: nome,
						cpf_cnpj: this.removeMaskCharacters(cpf_cnpj),
						email: email,
						cidade: cidade,
						celular: this.removeMaskCharacters(celular),
						fone: this.removeMaskCharacters(fone) || this.removeMaskCharacters(celular),
						loja: loja,
						valor: parseFloat(valor),
						valor_pode_pagar: this.money2(valor_pode_pagar),
						forma_pagamento: forma_pagamento,
						obs: obs || ""
					};

					this.acaoSalvarEtapa({
						cpfCnpj: this.removeMaskCharacters(cpf_cnpj),
						esn_fk_ets_codigo: 11,
						emp_codigo: this.parceiroConfig.emp_codigo,
						data_agendamento: this.dataAtual,
						hora_agendamento: this.horaAtual,
						motivo_agendamento: 'Cadastrou uma nova lead'
					});

					this.action_cadastrarLead(obj).then(() => {
						this.SET_LOADING_MESSAGE("");
						this.SET_LOADING(false);
						this.parceiro ? this.$router.go(-1) : this.$router.push({ name: "LandPage" });
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Parabéns",
							message: `Sua proposta foi cadastrada com sucesso. Em algumas horas enviaremos uma resposta para o seu e-mail`,
							styleMessage: "success"
						});
					}).catch(() => {
						this.SET_LOADING_MESSAGE("");
						this.SET_LOADING(false);
					});
				}
			}
		}
	}
};
</script>

<style scoped lang="scss">
.box-register {
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register h2 {
	font-family: Montserrat-Bold;
	color: var(--primaryColor);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3 {
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
}

.box-forms-regiter {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}

.box-button-register {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}
</style>
