<template>
	<div :class="`col-${cols}`" class="col">
		<label>
			{{ label }}
		</label>
		<div class="boxzin-img">
			<input @change="changeColor" :id="variableName" type="color" v-model="color" />
			<!--            Nenhuma imagem selecionada-->
		</div>
	</div>
</template>

<script>
export default {
	name: "ColorField",
	props: ["label", "cols", "valueField", "variableName"],
	data() {
		return {
			color: null
		};
	},
	mounted() {
		if (this.valueField) {
			this.color = this.valueField;
			this.$emit("update:value", this.valueField);
		}
	},
	watch: {
		valueField() {
			this.color = this.valueField;
			this.$emit("update:value", this.valueField);
		}
	},
	methods: {
		changeColor() {
			this.$emit("update:value", this.color);
		}
	}
};
</script>

<style scoped>
label {
	display: block;
	color: var(--blackColor);
	font-size: 14px;
	font-family: "Roboto";
}

.boxzin-img {
	cursor: pointer;
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0;
	font-size: 14px;
	font-family: "Roboto Bold";
	border: 1px solid #ffffff !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
</style>
