<template>
	<div>
		<div
			class="iconBoxBack"
			:style="{color: corPrimaria}"
			@click="$emit('close')">

			<i class="fas fa-chevron-left" ></i>
			Voltar
		</div>

		<div class="content-page">
			<h3>Receber contato digital para negociação</h3>
			<text-field-mask
				mask-format="(##) #####-####"
				label="Informe o número de celular para contato WhatsApp"
				@update:value="phone = $event" ></text-field-mask>

			<button-default
				:color="corPrimaria"
				text="Receber contato digital para negociação"
				@click.native="send" ></button-default>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex"
import globalMethods from "@/mixins/globalMethods"
import mixins from "@/mixins/mixins"

import TextFieldMask from "@/components/inputs/TextFieldMask"
import ButtonDefault from "@/components/buttons/ButtonDefault"

export default {
	name: "DigitalContact",

	props: {
		divida : {
			type: Object,
			required: true
		}
	},

	components: { ButtonDefault, TextFieldMask },

	mixins: [globalMethods, mixins],

	data() {
		return {
			phone: ""
		}
	},
	methods: {
		...mapActions(["action_contato_whatsapp"]),

		send() {
			if (this.phone.length < 15) {
				this.CHANGE_MESSAGE_MODAL({
					show: true,
					title: "Atenção",
					message: `Digite um celular válido`,
					styleMessage: "danger"
				})

			} else {
				let obj = {
					pes_codigo: this.divida.pes_codigo,
					emp_codigo: this.divida.emp_codigo,
					loj_codigo: this.divida.loj_codigo,
					telefone: this.removeMaskCharacters(this.phone)
				}

				this.SET_LOADING(true)
				this.SET_LOADING_MESSAGE("Contatando o seu Whatsapp")
				this.action_contato_whatsapp(obj).then((_) => {
					this.SET_LOADING_MESSAGE("")
					this.SET_LOADING(false)
					this.$emit("close")
				}).catch((_) => {
					this.SET_LOADING_MESSAGE("")
					this.SET_LOADING(false)
				});
			}
		}
	}
}
</script>

<style scoped>
.iconBoxBack {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: "Roboto Black";
	color: var(--primaryColor);
	text-transform: uppercase;
	cursor: pointer;
}
.iconBoxBack i {
	margin-right: 10px;
	font-size: 22px;
}

h3 {
	margin-top: 20px;
	color: #031b4e;
	font-family: "Roboto Black";
	text-transform: uppercase;
	font-size: 16px;
	text-align: center;
	background: #f5f6fa;
	padding: 10px 0;
}

.content-page h3 {
	margin-bottom: 20px;
}
</style>
