<template>
	<div class="col">
		<label>{{ label }}</label>
		<div @click="showOptions = true" style="display: flex; flex-direction: column">
			<input v-model="search" type="text" @blur="showOptions = false" />
			<i class="fas fa-search iconsearch"></i>
		</div>
		<div class="select-box" v-show="showOptions">
			<div class="option" @mousedown="selectOption(option)" v-for="option in orderOptions" :key="option[keyValue] + option[keyName]" v-show="option.nome_empresa.toLowerCase().includes(search.toLowerCase())">
				{{ option[keyName] }}
			</div>
			<div v-if="options.length === 0" class="option">Nenhum dado encontrado</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
export default {
	name: "SearchField",
	props: ["cols", "label", "value", "options", "keyName", "keyValue", "valueField"],
	data() {
		return {
			text: {},
			search: "",
			showOptions: false
		};
	},
	mounted() {
		if (this.valueField) {
			this.selectOption(this.options.find((option) => option.codigo === this.valueField) || {});
		}
	},
	methods: {
		selectOption(option) {
			this.showOptions = false;
			this.text = option;
			this.search = option.nome_empresa;
		}
	},
	computed: {
		orderOptions: function() {
			return _.orderBy(this.options, [(option) => option.nome_empresa.toLowerCase()]);
		}
	},
	watch: {
		options() {
			this.selectOption(this.options[0] || {});
		},
		text() {
			this.$emit("update:value", this.text[this.keyValue]);
		}
	}
};
</script>

<style scoped>
.col {
	display: flex;
	flex-direction: column;
	position: relative;
}

label {
	display: block;
	color: #1d1d1b;
	font-size: 14px;
	font-family: "Roboto";
}

input {
	margin: 5px 0;
	height: 46px;
	border-radius: 10px;
	background: var(--whiteColor);
	padding: 0 10px;
	border: 1px solid #878785 !important;
	color: #1d1d1b;
	font-family: "Roboto Black";
	font-size: 16px;
}

.select-box {
	background: #ffffff;
	border-radius: 10px;
	-webkit-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	-moz-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
	position: absolute;
	margin-top: 65px;
	width: 93%;
	z-index: 10;
	border: 1px solid #878785;
	border-top: 0px;
}

.select-box .option {
	height: 46px;
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 10px;
	font-family: "Roboto Bold";
	background: #ffffff;
}

.select-box .option:hover {
	cursor: pointer;
	background: #9b9b93;
}
.iconsearch {
	align-self: end;
	margin-right: 20px;
	margin-top: -37px;
	z-index: 2;
	font-size: 20px;
}
</style>
