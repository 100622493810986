<template>
	<div class="box-register">
		<h2 :style="parceiroConfigCorSecundariaCorPrimaria">Você está próximo de se tornar um parceiro do {{ nomeParceiro }}</h2>
		<h3>Preencha seus dados abaixo para cadastrar a sua empresa</h3>

		<div class="box-forms-regiter">
			<form-fields
				:fields="fields"
				@save="completeRegister"
				@update:values="inputsValues = $event"></form-fields>
		</div>

		<div class="box-button-register">
			<button-lg
				:color="corPrimaria"
				:full="true"
				@click.native="completeRegister()"
				text="Cadastrar Empresa" ></button-lg>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import globalMethods from "@/mixins/globalMethods";
import mixins from "@/mixins/mixins";

import ButtonDefault from "@/components/buttons/ButtonDefault";
import FormFields from "@/components/FormFields";
import ButtonLg from "@/components/buttons/ButtonLg";

export default {
	name: "RegisterCreditor",
	
	components: { ButtonLg, FormFields, ButtonDefault },

	mixins: [globalMethods, mixins],

	data() {
		return {
			fields: [],
			identification: "",
			inputsValues: {}
		};
	},

	computed: {
		...mapState({
			showMessageModal: (estado) => estado.messageModal.show,
		})
	},

	mounted() {
		this.fields = [
			{
				variableName: "razaoSocial",
				fieldType: "TextField",
				label: "Razão Social",
				type: "text",
				cols: "8"
			},
			{
				variableName: "cnpj",
				fieldType: "TextFieldMask",
				label: "CNPJ",
				type: "text",
				cols: "4",
				maskFormat: "##.###.###/####-##"
			},
			{
				variableName: "nomeContato",
				fieldType: "TextField",
				label: "Nome para contato",
				type: "text",
				cols: "12"
			},
			{
				variableName: "telefoneCel",
				fieldType: "TextFieldMask",
				label: "Celular",
				maskFormat: "(##) #####-####",
				type: "text",
				cols: "6"
			},
			{
				variableName: "telefoneFixo",
				fieldType: "TextFieldMask",
				label: "Telefone",
				maskFormat: "(##) ####-####",
				type: "text",
				cols: "6"
			},
			{
				variableName: "email",
				fieldType: "TextField",
				label: "E-mail",
				type: "text",
				cols: "6"
			},
			{
				variableName: "website",
				fieldType: "TextField",
				label: "Site",
				type: "text",
				cols: "6"
			}
		];
	},

	methods: {
		...mapActions(["action_cadastrar_credor"]),

		completeRegister() {
			const { razaoSocial, cnpj, email, telefoneFixo, telefoneCel, nomeContato, website } = this.inputsValues;

			let required = false;
			Object.entries(this.inputsValues).forEach(([key, value]) => {

				if (!this.showMessageModal && key !== "website" && key !== "telefoneFixo" && (!value || value === "")) {
					required = true;
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `O campo ${this.fields.filter((field) => field.variableName === key)[0].label} é obrigatório`,
						styleMessage: "danger"
					});
				}
			});

			if (!required) {
				if (/.+@.+\..+/.test(email) === false) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um e-mail válido`,
						styleMessage: "danger"
					});

				} else if (telefoneCel.length < 15) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um celular válido`,
						styleMessage: "danger"
					});

				} else if (this.inputsValues.telefoneFixo != null && this.inputsValues.telefoneFixo !== "" && telefoneFixo.length < 9) {
					this.CHANGE_MESSAGE_MODAL({
						show: true,
						title: "Atenção",
						message: `Digite um telefone fixo válido`,
						styleMessage: "danger"
					});

				} else {
					this.SET_LOADING(true);
					this.SET_LOADING_MESSAGE("Realizando o seu cadastro");

					let obj = {
						razao_social: razaoSocial,
						cnpj: this.removeMaskCharacters(cnpj),
						email: email,
						telefone_cel: this.removeMaskCharacters(telefoneCel),
						telefone_fixo: this.removeMaskCharacters(telefoneFixo),
						website: website,
						nome_contato: nomeContato,
						link_cadastro: window.location.href.replace("/consulta/seja-parceiro-aquicob", "/cadastre-sua-empresa")
					};

					this.action_cadastrar_credor(obj).then((_) => {
						this.SET_LOADING_MESSAGE("");
						this.SET_LOADING(false);
						this.$router.push({ name: "LandPage" });
						this.CHANGE_MESSAGE_MODAL({
							show: true,
							title: "Cadastrado com sucesso",
							message: `Seus dados foram cadastrados com sucesso. Estamos enviando um e-mail para que você confirma, e pedimos para que você o link para completar o seu cadastro`,
							styleMessage: "success"
						});

					}).catch((_) => {
						this.SET_LOADING_MESSAGE("");
						this.SET_LOADING(false);
					});
				}
			}
		}
	}
};
</script>

<style scoped lang="scss">
.box-register {
	min-height: 100vh;
	display: flex;
	flex: 1;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background: #f3f5f9;
}

.box-register h2 {
	font-family: Montserrat-Bold;
	color: var(--primaryColor);
	font-size: 32px;
	text-align: center;
	@media only screen and (max-width: 990px) {
		font-size: 28px;
	}
}

.box-register h3 {
	font-family: Cabin;
	font-size: 18px;
	color: var(--grayColor);
	margin: 10px 0 30px 0;
	text-align: center;
}

.box-forms-regiter {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}

.box-button-register {
	width: 50vw;
	box-sizing: border-box;
	@media only screen and (max-width: 990px) {
		width: 90%;
	}
}
</style>
